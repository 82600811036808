import { useSelector, shallowEqual } from "utils/StoreUtil";
import { languageSelector } from "reducers/language";

function useLanguage(...keys) {
  const marketSegment = useSelector(state => state.config.marketSegment);
  const selectedLanguage = useSelector(state => state.language.selectedLanguage);

  return useSelector(state => {
    const languageMap = languageSelector.selectById(state, `${selectedLanguage}/${marketSegment}`);

    return keys.map(key => languageMap?.languageMap[key] ?? key);
  }, shallowEqual);
}

export { useLanguage as default, useLanguage };
