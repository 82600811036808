import React, { useEffect, memo } from "react";
import { useDispatch, useSelector } from "utils/StoreUtil";
import { Typography, Divider } from "@mui/material";
import { getNotifications, resetNotifications } from "actions/activityActions";
import Notification from "./Notification";
import ShowMore from "../ShowMore";
import CircularProgress from "@mui/material/CircularProgress";
import { getNewNotificationCount } from "reducers/comet";
import EmptyPage from "components/EmptyPage";
import NotificationNoteIcon from "@mui/icons-material/NotificationsNone";
import isIE11 from "utils/isIE11";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(theme => ({
  root: {
    height: theme.spacing(57),
    borderRadius: 4,
    overflow: "auto"
  },
  displayFlex: {
    display: "flex",
    flexDirection: "column"
  },
  header: {
    padding: theme.spacing(0.75, 2, 1.25),
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: 40
  },
  notifications: {
    flex: "1 1 250px"
  },
  showMoreWrapper: {
    padding: theme.spacing(0, 1.25, 1)
  },
  loading: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  button: {
    padding: theme.spacing(1)
  },
  emptyRoot: {
    marginTop: -theme.spacing(5),
    opacity: 0.3,
    width: "90%"
  },
  emptyIcon: {
    marginTop: 0,
    height: theme.spacing(4.5),
    width: theme.spacing(4.5)
  },
  emptyText: {
    color: theme.palette.text.secondary
  }
}));

const Notifications = ({ displayHeader }) => {
  const dispatch = useDispatch();
  const { classes, cx } = useStyles();

  const isLoading = useSelector(state => state.notifications.isLoading);
  const notifications = useSelector(state => state.notifications.notifications);
  const moreAvailable = useSelector(state => state.notifications.moreAvailable);

  const newNotificationCount = useSelector(state =>
    getNewNotificationCount(state.comet, state.user.user.userStatsId)
  );

  useEffect(() => {
    dispatch(getNotifications());
    return () => {
      dispatch(resetNotifications());
    };
  }, [dispatch]);

  const shouldDisplayLoader = isLoading && notifications.length < 1;
  const shouldDisplayEmptyMessage = !isLoading && notifications.length < 1;

  return (
    <div className={cx(classes.root, isIE11 ? "" : classes.displayFlex)}>
      {displayHeader && (
        <>
          <div className={classes.header}>
            <Typography align="left" variant="body1" color="textSecondary">
              Notifications
            </Typography>
            {newNotificationCount > 0 && !isLoading && (
              <ShowMore
                text="Show new"
                handleClick={() => dispatch(getNotifications(true))}
                isLoading={isLoading}
                fullWidth={false}
                size="medium"
              />
            )}
          </div>
          <Divider />
        </>
      )}
      <div
        className={cx(
          classes.notifications,
          shouldDisplayLoader || shouldDisplayEmptyMessage ? classes.loading : null
        )}
      >
        {shouldDisplayLoader && <CircularProgress />}

        {!displayHeader && !shouldDisplayEmptyMessage && newNotificationCount > 0 && (
          <ShowMore
            text="Show new"
            handleClick={() => dispatch(getNotifications(true))}
            isLoading={isLoading}
            size="medium"
          />
        )}

        {!shouldDisplayEmptyMessage &&
          notifications.map((notification, index) => (
            <Notification
              key={notification.id}
              notification={notification}
              displayDivider={index < notifications.length - 1}
            />
          ))}

        {shouldDisplayEmptyMessage && (
          <EmptyPage
            message="You don't have any notifications right now"
            Icon={NotificationNoteIcon}
            variant="body1"
            classes={{
              root: classes.emptyRoot,
              icon: classes.emptyIcon,
              empty: classes.emptyText
            }}
          />
        )}
      </div>
      {moreAvailable && notifications.length > 0 && (
        <div className={classes.showMoreWrapper}>
          <ShowMore
            handleClick={() => dispatch(getNotifications())}
            isLoading={isLoading && notifications.length > 0}
            size="medium"
          />
        </div>
      )}
    </div>
  );
};

export default memo(Notifications);
