import { FC, PropsWithChildren, useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "utils/StoreUtil";
import { externalLoginAttempt } from "actions/userActions";
import { parse } from "qs";
import { CircularProgress, Box } from "@mui/material";

const AttemptExternalLogin: FC<PropsWithChildren> = ({ children }) => {
  const [isAuthenticating, setIsAuthenticating] = useState(true);

  const dispatch = useDispatch();

  const history = useHistory();
  const location = useLocation();

  const parsedQuery = parse(location?.search ?? "", { ignoreQueryPrefix: true });

  const token = parsedQuery?.token;
  const pathname = location?.pathname;

  useEffect(() => {
    async function signIn() {
      try {
        if (!token) {
          return;
        }

        await dispatch(externalLoginAttempt(token));
      } finally {
        history.replace(pathname);

        setIsAuthenticating(false);
      }
    }

    signIn();
  }, [token, history, pathname, dispatch]);

  if (isAuthenticating) {
    return (
      <Box sx={{ height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
        <CircularProgress size={100} />
      </Box>
    );
  }

  return <>{children}</>;
};

const ExternalLoginGuard: FC<PropsWithChildren> = ({ children }) => {
  const isAuthorized: boolean = useSelector(state => state.user.isAuthorized);
  const shouldUseExternalLogin: boolean = useSelector(state => state.config.useExternalLogin);

  if (!isAuthorized && shouldUseExternalLogin) {
    return <AttemptExternalLogin>{children}</AttemptExternalLogin>;
  }

  return <>{children}</>;
};

export default ExternalLoginGuard;
