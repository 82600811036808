import React from "react";
import { useSelector } from "utils/StoreUtil";
import { lotsSelector } from "reducers/lots";
import { assetsSelector } from "reducers/assets";
import SegmentTable from "./SegmentTable";

const automotiveDisplayFields = [
  { label: "lotNumber", path: "lot.lotNumber" },
  { label: "Year", path: "asset.fields.year" },
  { label: "Make", path: "asset.fields.make" },
  { label: "Model", path: "asset.fields.model" },
  { label: "VIN", path: "asset.uifields.body.value" },
  { label: "Odometer", path: "asset.uifields.field2.value" }
];

const merchandiseDisplayFields = [
  { label: "lotNumber", path: "lot.lotNumber" },
  { label: "Title", path: "asset.uifields.header.value" },
  { label: "Description", path: "asset.uifields.body.value" },
  { label: "Stock#", path: "asset.uifields.field1.value" }
];

const bloodstockDisplayFields = [
  { label: "lotNumber", path: "lot.lotNumber" },
  { label: "Name", path: "asset.fields.name" },
  { label: "Sex", path: "asset.fields.sex" },
  { label: "Sire", path: "asset.fields.sire" },
  { label: "Dam", path: "asset.fields.dam" },
  { label: "DOB", path: "asset.fields.dateOfBirth" }
];

const cattleDisplayFields = [
  { label: "lotNumber", path: "lot.lotNumber" },
  { label: "Description", path: "asset.uifields.header.value" },
  { label: "Age", path: "asset.fields.age" },
  { label: "Average Weight", path: "asset.fields.averageWeight" },
  { label: "Breed", path: "asset.fields.breed" },
  { label: "Color", path: "asset.fields.color" }
];

const otherDisplayFields = [
  { label: "lotNumber", path: "lot.lotNumber" },
  { label: "Description", path: "asset.uifields.header.value" }
];

function separateBySchema(inventory) {
  const automotive = [];
  const merchandise = [];
  const bloodstock = [];
  const cattle = [];
  const others = [];

  inventory.forEach(item => {
    const schemaName = item.asset?.fields?.schemaName;

    switch (schemaName) {
      case "Automotive":
        automotive.push(item);
        break;
      case "Merchandise":
        merchandise.push(item);
        break;
      case "Bloodstock":
        bloodstock.push(item);
        break;
      case "Cattle":
        cattle.push(item);
        break;
      default:
        others.push(item);
        break;
    }
  });

  return {
    automotiveInventory: automotive,
    merchandiseInventory: merchandise,
    bloodstockInventory: bloodstock,
    cattleInventory: cattle,
    otherInventory: others
  };
}

function checkSchemaPresence(automotive, merchandise, bloodstock, cattle) {
  let count = 0;
  if (automotive.length > 0) {
    count++;
  }
  if (merchandise.length > 0) {
    count++;
  }
  if (bloodstock.length > 0) {
    count++;
  }
  if (cattle.length > 0) {
    count++;
  }
  return count;
}

function useLotsAndAssets() {
  const lotIds = useSelector(state => lotsSelector.selectIds(state.lots));
  const lotsMap = useSelector(state => lotsSelector.selectEntities(state.lots));
  const assetsMap = useSelector(state => assetsSelector.selectEntities(state.assets));

  return lotIds.map(lotId => {
    const lot = lotsMap[lotId];
    const asset = assetsMap[lot.assetId];
    return { lot, asset };
  });
}

function MinimalTable(props) {
  const inventory = useLotsAndAssets();
  const {
    automotiveInventory,
    merchandiseInventory,
    bloodstockInventory,
    cattleInventory,
    otherInventory
  } = separateBySchema(inventory);

  const isMultipleSchemasPresent =
    checkSchemaPresence(
      automotiveInventory,
      merchandiseInventory,
      bloodstockInventory,
      cattleInventory
    ) > 1;

  return (
    <>
      {automotiveInventory.length > 0 && (
        <SegmentTable
          inventory={automotiveInventory}
          displayFields={automotiveDisplayFields}
          title="Automotive"
          isMultipleSchemasPresent={isMultipleSchemasPresent}
        />
      )}

      {merchandiseInventory.length > 0 && (
        <SegmentTable
          inventory={merchandiseInventory}
          displayFields={merchandiseDisplayFields}
          title="Merchandise"
          isMultipleSchemasPresent={isMultipleSchemasPresent}
        />
      )}

      {bloodstockInventory.length > 0 && (
        <SegmentTable
          inventory={bloodstockInventory}
          displayFields={bloodstockDisplayFields}
          title="Bloodstock"
          isMultipleSchemasPresent={isMultipleSchemasPresent}
        />
      )}

      {cattleInventory.length > 0 && (
        <SegmentTable
          inventory={cattleInventory}
          displayFields={cattleDisplayFields}
          title="Livestock"
          isMultipleSchemasPresent={isMultipleSchemasPresent}
        />
      )}

      {otherInventory.length > 0 && (
        <SegmentTable
          inventory={otherInventory}
          displayFields={otherDisplayFields}
          title="Other"
          isMultipleSchemasPresent={isMultipleSchemasPresent}
        />
      )}
    </>
  );
}

export default React.memo(MinimalTable);
