import { SERVER_APP_STATE_REHYDRATE } from "actions/actionTypes";
import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  emails: []
};

const savedEmails = createSlice({
  name: "savedEmails",
  initialState,
  reducers: {
    addEmail: {
      reducer: (state, action) => {
        if (action.payload.email && !state.emails.includes(action.payload.email)) {
          state.emails = state.emails.concat(action.payload.email);
        }
      },
      prepare: email => ({ payload: { email } })
    },
    deleteEmail: {
      reducer: (state, action) => {
        state.emails = state.emails.filter(
          email => email !== action.payload.email && email !== null
        );
      },
      prepare: email => ({ payload: { email } })
    },
    updateEmail: {
      reducer: (state, action) => {
        const { emailToEdit, updatedEmail } = action.payload;
        if (emailToEdit && updatedEmail) {
          state.emails = state.emails.map(email => (email !== emailToEdit ? email : updatedEmail));
        }
      },
      prepare: (emailToEdit, updatedEmail) => ({ payload: { emailToEdit, updatedEmail } })
    }
  },
  extraReducers: builder => {
    builder.addCase(SERVER_APP_STATE_REHYDRATE, (state, action) => {
      if (action.payload.savedEmails) {
        state.emails = action.payload.savedEmails.emails;
      }
    });
  }
});

const { reducer, actions } = savedEmails;

export { reducer as default };

export const { addEmail, deleteEmail, updateEmail } = actions;
