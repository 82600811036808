import MoneyIcon from "@mui/icons-material/AttachMoney";
import BarcodeIcon from "components/icons/BarcodeIcon";
import NoteIcon from "@mui/icons-material/Note";
import AssignmentIcon from "@mui/icons-material/Assignment";
import EventIcon from "@mui/icons-material/EventAvailable";
import CampaignIcon from "@mui/icons-material/Campaign";
import HistoryIcon from "@mui/icons-material/History";
import EditIcon from "@mui/icons-material/Edit";
import Star from "@mui/icons-material/Star";
import StarBorder from "@mui/icons-material/StarBorder";
import GroupIcon from "@mui/icons-material/GroupWork";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import TitleIcon from "@mui/icons-material/Grading";

export const ANNOUNCEMENT = "announcement";
export const BARCODE = "barcode";
export const CONDITION_REPORT = "conditionReport";
export const EDIT = "edit";
export const HISTORY = "history";
export const PURCHASE = "purchase";
export const NOTE = "note";
export const STAR = "star";
export const STARBORDER = "starBorder";
export const WATCH = "watch";
export const GROUP = "group";
export const PEDIGREE = "pedigree";
export const DETAILS_TAB = "tab";
export const TITLE = "title";

export const iconTypes = {
  announcement: CampaignIcon,
  barcode: BarcodeIcon,
  calendar: EventIcon,
  conditionReport: AssignmentIcon,
  edit: EditIcon,
  history: HistoryIcon,
  note: NoteIcon,
  purchase: MoneyIcon,
  star: Star,
  starBorder: StarBorder,
  group: GroupIcon,
  check: CheckCircleIcon,
  [TITLE]: TitleIcon,
  [DETAILS_TAB]: OpenInNewIcon
};

export const sizeMapping = {
  small: {
    button: "small",
    icon: "small"
  },
  medium: {
    button: "small",
    icon: "medium"
  },
  large: {
    button: "medium",
    icon: "medium"
  }
  // config based on these two Material-UI components
  // - Icon
  // - IconButton
};
