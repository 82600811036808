//server app state middleware actions
export const SERVER_APP_STATE_REHYDRATE = "SERVER_APP_STATE_REHYDRATE";
export const SERVER_APP_STATE_UPDATE = "SERVER_APP_STATE_UPDATE";
//config actions
export const CONFIG_GET = "CONFIG_GET";
export const CONFIG_SET = "CONFIG_SET";
export const SET_COMPANY_CONFIGS = "SET_COMPANY_CONFIGS";
export const SET_COMPANY_LOCATIONS = "SET_COMPANY_LOCATIONS";
//user and auth actions
export const USER_LOGIN_ATTEMPT = "USER_LOGIN_ATTEMPT";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAILURE = "USER_LOGIN_FAILURE";
export const USER_GUEST_LOGIN = "USER_GUEST_LOGIN";
export const USER_LOGOUT = "USER_LOGOUT";
export const USER_UPDATE = "USER_UPDATE";
export const USER_UPDATE_ATTEMPT = "USER_UPDATE_ATTEMPT";
export const USER_UPDATE_RESOLUTION = "USER_UPDATE_RESOLUTION";
export const USER_UPDATE_REQUEST = "USER_UPDATE_REQUEST";
export const USER_UPDATE_FAILED = "USER_UPDATE_FAILED";
export const USER_GET_PERMISSIONS = "USER_GET_PERMISSIONS";
export const USER_SET_PERMISSIONS = "USER_SET_PERMISSIONS";
export const USER_RESET_PASSWORD_ATTEMPT = "USER_RESET_PASSWORD_ATTEMPT";
export const USER_RESET_PASSWORD_FAILURE = "USER_RESET_PASSWORD_FAILURE";
export const USER_RESET_PASSWORD_SUCCESS = "USER_RESET_PASSWORD_SUCCESS";
export const USER_REGISTRATION_ATTEMPT = "USER_REGISTRATION_ATTEMPT";
export const USER_REGISTRATION_SUCCESS = "USER_REGISTRATION_SUCCESS";
export const USER_REGISTRATION_FAILURE = "USER_REGISTRATION_FAILURE";
export const USER_SET_MEDIA = "USER_SET_MEDIA";
export const USER_SET_RECENT_SEARCHES = "USER_SET_RECENT_SEARCHES";
export const USER_CHANGE_EMAIL_REQUEST = "USER_CHANGE_EMAIL_REQUEST";
export const USER_CHANGE_EMAIL_REQUEST_COMPLETE = "USER_CHANGE_EMAIL_REQUEST_COMPLETE";
export const USER_SET_LAST_DEALERSHIP_SYNC_MESSAGE = "USER_SET_LAST_DEALERSHIP_SYNC_MESSAGE";
//membership actions
export const MEMBERSHIPS_GET = "MEMBERSHIPS_GET";
export const MEMBERSHIPS_SET = "MEMBERSHIPS_SET";
export const MEMBERSHIPS_SET_TOTAL = "MEMBERSHIP_SET_TOTAL";
export const MEMBERSHIPS_SET_PAGE = "MEMBERSHIP_SET_PAGE";
export const MEMBERSHIP_CREATE = "MEMBERSHIP_CREATE";
export const MEMBERSHIP_UPDATE = "MEMBERSHIP_UPDATE";
export const MEMBERSHIP_TOGGLE_FILTER = "MEMBERSHIP_TOGGLE_FILTER";
export const MEMBERSHIP_SET_SORT_BY = "MEMBERSHIP_SET_SORT_BY";
export const MEMBERSHIP_SAVE = "MEMBERSHIP_SAVE";
export const MEMBERSHIP_SAVE_COMPLETE = "MEMBERSHIP_SAVE_COMPLETE";
export const MEMBERSHIP_TOGGLE_HIDDEN_COLUMN = "MEMBERSHIP_TOGGLE_COMPANY_COLUMN";
//notification actions
export const NOTIFICATIONS_SHOW = "NOTIFICATIONS_SHOW";
export const NOTIFICATIONS_ERROR = "NOTIFICATIONS_ERROR";
export const NOTIFICATIONS_DISMISS = "NOTIFICATIONS_DISMISS";
export const NOTIFICATIONS_SET = "NOTIFICATIONS_SET";
export const NOTIFICATIONS_GET = "NOTIFICATIONS_GET";
export const NOTIFICATIONS_RESET = "NOTIFICATIONS_RESET";
export const NOTIFICATIONS_SEND = "NOTIFICATIONS_SEND";
export const NOTIFICATIONS_SENT = "NOTIFICATIONS_SENT";
//calendar actions
export const CALENDAR_FILTERS_RESET = "CALENDAR_FILTERS_RESET";
export const CALENDAR_FILTER_SET_SALE_TYPE = "CALENDAR_FILTER_SET_SALE_TYPE";
export const CALENDAR_FILTER_TOGGLE_COMPANY = "CALENDAR_FILTER_TOGGLE_COMPANY";
export const CALENDAR_FILTER_TOGGLE_ASSET_SCHEMA = "CALENDAR_FILTER_TOGGLE_ASSET_SCHEMA";
export const CALENDAR_GET_EVENT_FROM_ENDPOINT = "CALENDAR_GET_EVENT_FROM_ENDPOINT";
export const CALENDAR_SET_LAST_PAGE_TYPE = "CALENDAR_SET_LAST_PAGE_TYPE";
export const CALENDAR_FETCH = "CALENDAR_FETCH";
export const CALENDAR_FETCH_COMPLETE = "CALENDAR_FETCH_COMPLETE";
export const CALENDAR_MY_AUCTION_EVENTS = "CALENDAR_MY_AUCTION_EVENTS";
export const CALENDAR_TODAY_EVENTS = "CALENDAR_TODAY_EVENTS";
export const CALENDAR_UPCOMING_EVENTS = "CALENDAR_UPCOMING_EVENTS";
export const RESET_CALENDAR_ENTITIES = "RESET_CALENDAR_ENTITIES";
//company actions
export const COMPANIES_GET = "COMPANIES_GET";
export const COMPANIES_SET = "COMPANIES_SET";
export const ASSET_SCHEMAS_FETCH_COMPLETE = "ASSET_SCHEMAS_FETCH_COMPLETE";
//inventory search actions
export const VEHICLES_SET_PER_PAGE = "VEHICLES_SET_PER_PAGE";
export const VEHICLES_SET_PAGE = "VEHICLES_SET_PAGE";
export const VEHICLES_RUN_SEARCH_OPTIONS_SCRIPT = "VEHICLES_RUN_SEARCH_OPTIONS_SCRIPT";
export const VEHICLES_SET_SEARCH_OPTIONS = "VEHICLES_SET_SEARCH_OPTIONS";
export const INVENTORY_SEARCH_OPTIONS_RESET_STATE = "VEHICLES_RESET_SELECTED_SEARCH_OPTIONS";
export const INVENTORY_TOGGLE_SEARCH_OPTION = "INVENTORY_TOGGLE_SEARCH_OPTION";
export const INVENTORY_TOGGLE_ASSET_SCHEMA_ID_SEARCH_OPTION =
  "INVENTORY_TOGGLE_ASSET_SCHEMA_ID_SEARCH_OPTION";
export const INVENTORY_FILTER_EVENT_SELECT = "INVENTORY_FILTER_EVENT_SELECT";
export const INVENTORY_SET_SORT_BY = "INVENTORY_SET_SORT_BY";
export const INVENTORY_SET_SORT_ORDER = "INVENTORY_SET_SORT_ORDER";
export const INVENTORY_TOGGLE_SORT_BY = "INVENTORY_TOGGLE_SORT_BY";
export const INVENTORY_SET_LAYOUT = "INVENTORY_SET_LAYOUT";
export const INVENTORY_SET_QUERY_STRING = "INVENTORY_SET_QUERY_STRING";
export const INVENTORY_RESET_SELECTED_SEARCH_OPTION = "INVENTORY_RESET_SELECTED_SEARCH_OPTION";
export const INVENTORY_RESET_SEARCH_OPTIONS = "INVENTORY_RESET_SEARCH_OPTIONS";
//inventory search settings UI actions
export const INVENTORY_SET_SELECTED_SEARCH_OPTIONS = "INVENTORY_SET_SELECTED_SEARCH_OPTIONS";
export const INVENTORY_SELECT_SEARCH_OPTION = "INVENTORY_SELECT_SEARCH_OPTION";
export const INVENTORY_SET_SEARCH_OPTION_VALUES = "INVENTORY_SET_SEARCH_OPTION_VALUES";
export const INVENTORY_ADD_SEARCH_OPTION_FROM_PARAMS = "INVENTORY_ADD_SEARCH_OPTION_FROM_PARAMS";

//inventory detail actions
export const INVENTORY_DETAILS_GET_LOT_ID = "INVENTORY_DETAILS_GET_LOT_ID";

export const INVENTORY_UPSERT = "INVENTORY_UPSERT";
export const RECOMMENDATION_FETCH_COMPLETE = "RECOMMENDATION_FETCH_COMPLETE";

export const SET_PARENT_PAGE_CATEGORY = "SET_PARENT_PAGE_CATEGORY";

//media actions
export const MEDIAS_GET = "MEDIAS_GET";
export const MEDIAS_SET = "MEDIAS_SET";
export const MEDIA_SET = "MEDIA_SET";
export const MEDIA_CREATE = "MEDIA_CREATE";
export const MEDIA_DELETE = "MEDIA_DELETE";
export const MEDIA_DELETE_COMPLETE = "MEDIA_DELETE_COMPLETE";
export const MEDIA_UPDATE = "MEDIA_UPDATE";
export const MEDIA_UPDATE_COMPLETE = "MEDIA_UPDATE_COMPLETE";
export const MEDIAS_ASSET_SET = "MEDIAS_ASSET_SET";

//comet middleware actions
export const COMET_CONNECT = "COMET_CONNECT";
export const COMET_DISCONNECT = "COMET_DISCONNECT";
export const COMET_SUBSCRIBE = "COMET_SUBSCRIBE";
export const COMET_SUBSCRIBE_REQUEST = "COMET_SUBSCRIBE_REQUEST";
export const COMET_UNSUBSCRIBE = "COMET_UNSUBSCRIBE";
export const COMET_UNSUBSCRIBE_REQUEST = "COMET_UNSUBSCRIBE_REQUEST";
//admin actions
export const ADMIN_EVENTS_SET_PAGE = "ADMIN_EVENTS_SET_PAGE";
export const ADMIN_EVENTS_SET_COMPANIES = "ADMIN_EVENTS_SET_COMPANIES";
export const ADMIN_UPDATE_EVENT = "ADMIN_UPDATE_EVENT";
export const ADMIN_UPDATE_SALE = "ADMIN_UPDATE_SALE";
export const ADMIN_SALE_FETCH_COMPLETE = "ADMIN_SALE_FETCH_COMPLETE";
export const ADMIN_EVENTS_SET_SORT_BY = "ADMIN_EVENTS_SET_SORT_BY";
export const ADMIN_EVENTS_SET_SALE_TYPE = "ADMIN_EVENTS_SET_SALE_TYPE";
export const ADMIN_TOGGLE_EVENTS_RUNNING_FILTER = "ADMIN_TOGGLE_EVENTS_RUNNING_FILTER";
export const ADMIN_TOGGLE_EVENTS_CLOSED_FILTER = "ADMIN_TOGGLE_EVENTS_CLOSED_FILTER";
export const ADMIN_SET_SALES = "ADMIN_SET_SALES";
export const ADMIN_ATTENDEES_FETCH = "ADMIN_ATTENDEES_FETCH";
export const ADMIN_SET_ATTENDEES = "ADMIN_SET_ATTENDEES";
export const ADMIN_SET_ATTENDEE_STATS = "ADMIN_SET_ATTENDEE_STATS";
export const ADMIN_ATTENDEES_SET_PAGE = "ADMIN_ATTENDEES_SET_PAGE";
export const ADMIN_TOGGLE_ATTENDEE_SORT = "ADMIN_TOGGLE_ATTENDEE_SORT";
export const ADMIN_SET_DEALERSHIPS_FILTER = "ADMIN-SET_DEALERSHIPS_FILTER";
export const ADMIN_GET_DEALERSHIPS_CSV = "ADMIN_GET_DEALERSHIPS_CSV";
export const ADMIN_SCHEDULING_LIVE_LEGACY_SALE = "ADMIN_SCHEDULING_LIVE_LEGACY_SALE";
export const ADMIN_ERROR_SCHEDULING_LIVE_LEGACY_SALE = "ADMIN_ERROR_SCHEDULING_LIVE_LEGACY_SALE";
export const ADMIN_SET_BID_LOGS = "ADMIN_SET_BID_LOGS";
export const ADMIN_GET_BID_LOGS = "ADMIN_GET_BID_LOGS";
export const ADMIN_SET_BID_LOGS_TOTAL = "ADMIN_SET_BID_LOGS_TOTAL";
export const ADMIN_GET_BID_LOGS_CSV = "ADMIN_GET_BID_LOGS_CSV";
export const ADMIN_GET_SALE_STATS_CSV = "ADMIN_GET_SALE_STATS_CSV";
export const ADMIN_TOGGLE_BIDLOG_ACTION_FILTER = "ADMIN_TOGGLE_BIDLOG_ACTION_FILTER";
export const ADMIN_SET_BID_LOGS_PAGE = "ADMIN_SET_BID_LOGS_PAGE";
export const ADMIN_GET_SALE_LOG_ACTIONS = "ADMIN_GET_SALE_LOG_ACTIONS";
export const ADMIN_SET_SALE_LOG_ACTIONS = "ADMIN_SET_SALE_LOG_ACTIONS";
export const ADMIN_GET_LOT = "ADMIN_GET_LOT";
export const ADMIN_SET_LOT = "ADMIN_SET_LOT";
export const ADMIN_GET_INVENTORY = "ADMIN_GET_INVENTORY";
export const ADMIN_SET_INVENTORY = "ADMIN_SET_INVENTORY";
export const ADMIN_SET_COMPANY_USERS = "ADMIN_SET_COMPANY_USERS";
export const ADMIN_SET_EVENT_CONSIGNORS = "ADMIN_SET_EVENT_CONSIGNORS";
export const ADMIN_TOGGLE_INVENTORY_SORT = "ADMIN_TOGGLE_INVENTORY_SORT";
export const ADMIN_SET_INVENTORY_TOTAL = "ADMIN_SET_INVENTORY_TOTAL";
export const ADMIN_SET_INVENTORY_PAGE = "ADMIN_SET_INVENTORY_PAGE";
export const ADMIN_GET_INVENTORY_CSV = "ADMIN_GET_INVENTORY_CSV";
export const ADMIN_EVENT_INVENTORY_SET_FILTER_STOCK_ID =
  "ADMIN_EVENT_INVENTORY_SET_FILTER_STOCK_ID";
export const ADMIN_EVENT_INVENTORY_SET_FILTER_RUN_NUMBER =
  "ADMIN_EVENT_INVENTORY_SET_FILTER_RUN_NUMBER";
export const ADMIN_EVENT_INVENTORY_SET_FILTER_STATUS = "ADMIN_EVENT_INVENTORY_SET_FILTER_STATUS";
export const ADMIN_EVENT_INVENTORY_SET_FILTER_CONSIGNOR =
  "ADMIN_EVENT_INVENTORY_SET_FILTER_CONSIGNOR";
export const ADMIN_EVENT_INVENTORY_SET_FILTER_BUYER = "ADMIN_EVENT_INVENTORY_SET_FILTER_BUYER";
export const ADMIN_RESET_INVENTORY_PAGE = "ADMIN_RESET_INVENTORY_PAGE";
export const ADMIN_TOGGLE_SALES_ID_FILTER = "ADMIN_TOGGLE_SALES_ID_FILTER";
export const ADMIN_CLEAR_SALES_ID_FILTER = "ADMIN_CLEAR_SALES_ID_FILTER";
export const ADMIN_UPDATE_SALEUSER_CREDIT_LIMIT = "ADMIN_UPDATE_SALEUSER_CREDIT_LIMIT";
export const ADMIN_RUN_EVENT_COMPARE_SCRIPT = "ADMIN_RUN_EVENT_COMPARE_SCRIPT";
export const ADMIN_SET_EVENT_COMPARE_RESULT = "ADMIN_SET_EVENT_COMPARE_RESULT";
export const ADMIN_SYNC_SYSTEMS = "ADMIN_SYNC_SYSTEMS";
export const ADMIN_RESET_SYNC = "ADMIN_RESET_SYNC";
export const ADMIN_DOWNLOAD_EVENT_COMPARE_RESULT = "ADMIN_DOWNLOAD_EVENT_COMPARE_RESULT";
export const ADMIN_EVENT_MANAGEMENT_SET_SORT_BY = "ADMIN_EVENT_MANAGEMENT_SET_SORT_BY";
export const ADMIN_EVENT_MANAGEMENT_SET_PAGE = "ADMIN_EVENT_MANAGEMENT_SET_PAGE";
export const ADMIN_FETCH_CONSIGNOR_REPORT = "ADMIN_FETCH_CONSIGNOR_REPORT";
export const ADMIN_SET_CONSIGNOR_REPORT = "ADMIN_SET_CONSIGNOR_REPORT";
export const ADMIN_CONSIGNOR_REPORT_TOGGLE_CONSIGNOR = "ADMIN_CONSIGNOR_REPORT_TOGGLE_CONSIGNOR";
export const ADMIN_CONSIGNOR_REPORT_TOGGLE_LANE = "ADMIN_CONSIGNOR_REPORT_TOGGLE_LANE";
export const ADMIN_FETCH_CONSIGNORS = "ADMIN_FETCH_CONSIGNORS";
export const ADMIN_FETCH_CONSIGNORS_COMPLETE = "ADMIN_FETCH_CONSIGNORS_COMPLETE";
export const ADMIN_CONSIGNOR_REPORT_TOGGLE_SELECT_ALL_CONSIGNORS =
  "ADMIN_CONSIGNOR_REPORT_TOGGLE_SELECT_ALL_CONSIGNORS";
export const ADMIN_CONSIGNOR_REPORT_TOGGLE_SELECT_ALL_LANES =
  "ADMIN_CONSIGNOR_REPORT_TOGGLE_SELECT_ALL_LANES";
export const ADMIN_ATTENDEES_SET_FILTER = "ADMIN_ATTENDEES_SET_FILTER";
export const ADMIN_USERS_SELECT_BULK_ACTION = "ADMIN_USERS_SELECT_BULK_ACTION";
export const ADMIN_USERS_BULK_ACTION_SELECT_MEMBERSHIP_ID =
  "ADMIN_USERS_BULK_ACTION_SELECT_MEMBERSHIP_ID";
export const ADMIN_USERS_BULK_ACTION_SELECT_ALL_MEMBERSHIP_IDS =
  "ADMIN_USERS_BULK_ACTION_SELECT_ALL_MEMBERSHIP_IDS";
export const ADMIN_INVENTORY_TOGGLE_HIDDEN_COLUMN = "ADMIN_INVENTORY_TOGGLE_HIDDEN_COLUMN";

//attendance actions
export const ATTENDANCE_GET_AGREEMENTS = "ATTENDANCE_GET_AGREEMENTS";
export const ATTENDANCE_GET_DEFAULT_AGREEMENT = "ATTENDANCE_GET_DEFAULT_AGREEMENT";
export const ATTENDANCE_SET_AGREEMENTS = "ATTENDANCE_SET_AGREEMENTS";
export const ATTENDANCE_GET_AGREES = "ATTENDANCE_GET_AGREES";
export const ATTENDANCE_SET_AGREES = "ATTENDANCE_SET_AGREES";
export const ATTENDANCE_AGREE = "ATTENDANCE_AGREE";
export const ATTENDANCE_SET_DEALERSHIP_ID = "ATTENDANCE_SET_DEALERSHIP_ID";
export const ATTENDANCE_SET_DEALERSHIPS = "ATTENDANCE_SET_DEALERSHIPS";

//time actions
export const TIME_REQUEST_SYNC = "TIME_REQUEST_SYNC";
export const TIME_SYNC_COMPLETE = "TIME_SYNC_COMPLETE";

//sale actions
export const BUY_IT_NOW = "BUY_IT_NOW";
export const BID = "BID";

//report actions
export const REPORTS_EVENTS_FETCH = "REPORTS_EVENTS_FETCH";
export const REPORTS_EVENTS_FETCH_COMPLETE = "REPORTS_EVENTS_FETCH_COMPLETE";
export const REPORTS_SET_INVENTORY_STATUS = "REPORTS_SET_INVENTORY_STATUS";
export const REPORTS_SET_PAGE = "REPORTS_SET_PAGE";
export const REPORTS_SET_PER_PAGE = "REPORTS_SET_PER_PAGE";
export const REPORTS_SET_TO_DATE = "REPORTS_SET_TO_DATE";
export const REPORTS_SET_FROM_DATE = "REPORTS_SET_FROM_DATE";
export const REPORTS_CONCAT_EVENTS = "REPORTS_CONCAT_EVENTS";
export const REPORTS_SET_SORT = "REPORTS_SET_SORT";
export const REPORTS_SET_EVENTS_SORT = "REPORTS_SET_EVENTS_SORT";
export const REPORTS_SET_COMPANIES = "REPORTS_SET_COMPANIES";
export const REPORTS_REFRESH_DATES = "REPORTS_REFRESH_DATES";

//market report actions
export const MARKET_REPORT_SET_SORT_BY = "MARKET_REPORT_SET_SORT_BY";
export const MARKET_REPORT_SET_PAGE = "MARKET_REPORT_SET_PAGE";
export const MARKET_REPORT_SET_PER_PAGE = "MARKET_REPORT_SET_PER_PAGE";
export const MARKET_REPORT_TOGGLE_HIDDEN_COLUMNS = "MARKET_REPORT_TOGGLE_HIDDEN_COLUMNS";
export const MARKET_REPORT_SET_STATS = "MARKET_REPORT_SET_STATS";
export const MARKET_REPORT_FETCH_STATS = "MARKET_REPORT_FETCH_STATS";
export const MARKET_REPORT_SELECT_LOT_ID = "MARKET_REPORT_SELECT_LOT_ID";
export const MARKET_REPORT_SELECT_DATE = "MARKET_REPORT_SELECT_DATE";
export const MARKET_REPORT_SET_DAYS_RANGE = "MARKET_REPORT_SET_DAYS_RANGE";

//activity actions
export const ACTIVITY_INVENTORY_FETCH = "ACTIVITY_INVENTORY_FETCH";
export const ACTIVITY_SET_ACTIVITIES = "ACTIVITY_SET_ACTIVITIES";
export const ACTIVITY_RESET_ACTIVITIES = "ACTIVITY_RESET_ACTIVITIES";
export const ACTIVITY_SET_EVENT_FILTER_OPTION = "ACTIVITY_SET_EVENT_FILTER_OPTION";
export const ACTIVITY_DOWNLOAD_ACTIVITY_CSV = "ACTIVITY_DOWNLOAD_ACTIVITY_CSV";
export const ACTIVITY_SET_FILTER_VALUE = "ACTIVITY_SET_FILTER_VALUE";
export const ACTIVITY_RESET_SPECIFIC_FILTER = "ACTIVITY_RESET_SPECIFIC_FILTER";
export const ACTIVITY_SET_PAGE = "ACTIVITY_SET_PAGE";
export const ACTIVITY_BULK_SET_ACTION_TYPE = "ACTIVITY_BULK_SET_ACTION_TYPE";
export const ACTIVITY_BULK_TOGGLE_ACTION_SELECTED_IDS = "ACTIVITY_BULK_TOGGLE_ACTION_SELECTED_IDS";
export const ACTIVITY_BULK_ACTION_SELECT_ALL = "ACTIVITY_BULK_ACTION_SELECT_ALL";
export const ACTIVITY_BULK_ACTION_CLEAR_ALL = "ACTIVITY_BULK_ACTION_CLEAR_ALL";
export const ACTIVITY_BULK_SET_REPORT_RESPONSE = "ACTIVITY_BULK_SET_REPORT_RESPONSE";
export const ACTIVITY_BULK_GET_FASTLANE_REPORT = "ACTIVITY_BULK_GET_FASTLANE_REPORT";
export const ACTIVITY_BULK_SET_CONTRACT_REQUEST_TYPE = "ACTIVITY_BULK_SET_CONTRACT_REQUEST_TYPE";
export const ACTIVITY_BULK_SET_ACTIVE_STATUS = "ACTIVITY_BULK_SET_ACTIVE_STATUS";

//credit
export const CREDITS_GET = "CREDITS_GET";
export const CREDITS_SET = "CREDITS_SET";
export const CREDIT_UPDATE = "CREDIT_UPDATE";

//user details
export const DETAILS_FETCH_USER_DEALERSHIP_DETAILS = "DETAILS_USER_DEALERSHIP_DETAILS";
export const DETAILS_USER_DEALERSHIP_SET_DETAILS = "DETAILS_USER_DEALERSHIP_SET_DETAILS";
export const DETAILS_SYNC_DEALERSHIPS = "DETAILS_SYNC_DEALERSHIPS";
export const DETAILS_SYNC_DEALERSHIPS_COMPLETE = "DETAILS_SYNC_DEALERSHIPS_COMPLETE";

//stats
export const STATS_FETCH = "STATS_FETCH";
export const STATS_FETCH_COMPLETE = "STATS_FETCH_COMPLETE";
export const STATS_BULK_FETCH_COMPLETE = "STATS_BULK_FETCH_COMPLETE";

//dialog
export const DIALOG_RESET_STATE = "DIALOG_RESET_STATE";
export const DIALOG_OPEN = "DIALOG_OPEN";

//dashboard
export const DASHBOARD_ATTENDEES_CHART_SET_PROP = "DASHBOARD_ATTENDEES_CHART_SET_PROP";
export const DASHBOARD_SET_DATE = "DASHBOARD_SET_DATE";
export const DASHBOARD_TOGGLE_SALE_TYPE = "DASHBOARD_TOGGLE_SALE_TYPE";
export const DASHBOARD_SET_SALE_TYPES = "DASHBOARD_SET_SALE_TYPES";
export const DASHBOARD_SET_COMPANIES = "DASHBOARD_SET_COMPANIES";

//edit inventory
export const EDIT_INVENTORY_SAVE = "EDIT_INVENTORY_SAVE";
export const EDIT_INVENTORY_SAVE_COMPLETE = "EDIT_INVENTORY_SAVE_COMPLETE";
export const EDIT_INVENTORY_SELECT_BULK_ACTION = "EDIT_INVENTORY_SELECT_BULK_ACTION";
export const EDIT_INVENTORY_SELECT_BULK_ACTION_LOT_STATUS =
  "EDIT_INVENTORY_SELECT_BULK_ACTION_LOT_STATUS";
export const EDIT_INVENTORY_BULK_ACTION_SELECT_IDS = "EDIT_INVENTORY_BULK_ACTION_SELECT_IDS";
export const EDIT_INVENTORY_BULK_ACTION_SELECT_ID = "EDIT_INVENTORY_BULK_ACTION_SELECT_ID";
export const EDIT_INVENTORY_RESET = "EDIT_INVENTORY_RESET";

//schema
export const UI_SCHEMA_FETCH = "UI_SCHEMA_FETCH";
export const UI_SCHEMA_FETCH_COMPLETE = "UI_SCHEMA_FETCH_COMPLETE";
export const MEMBERSHIP_SCHEMA_FETCH = "MEMBERSHIP_SCHEMA_FETCH";
export const MEMBERSHIP_SCHEMA_FETCH_COMPLETE = "MEMBERSHIP_SCHEMA_FETCH_COMPLETE";

//ams
export const AMS_FETCH_COMPLETE = "AMS_FETCH_COMPLETE";
//currency
export const CURRENCY_FETCH = "CURRENCY_FETCH";
export const CURRENCY_FETCH_COMPLETE = "CURRENCY_FETCH_COMPLETE";
//facilities
export const FACILITIES_FETCH = "FACILITIES_FETCH";
export const FACILITIES_FETCH_COMPLETE = "FACILITIES_FETCH_COMPLETE";
//language
export const LANGUAGE_FETCH_COMPLETE = "LANGUAGE_FETCH_COMPLETE";
//links
export const ASSET_LINKS_FETCH = "ASSET_LINKS_FETCH";
export const ASSET_LINKS_FETCH_COMPLETE = "ASSET_LINKS_FETCH_COMPLETE";

//routing actions
export const SET_REDIRECT_URL = "SET_REDIRECT_URL";
export const SET_WELCOME_SCREEN_FLAG = "SET_WELCOME_SCREEN_FLAG";
