import React from "react";
import { useSelector } from "utils/StoreUtil";
import { Typography } from "@mui/material";
import { formatDateTime } from "utils/DateUtil";

function UserText() {
  const userName = useSelector(state => state.user.user.name);
  const locale = useSelector(state => state.user.user.locale);

  const date = formatDateTime(Date.now(), locale);

  return (
    <div>
      {userName && (
        <Typography align="left" variant="subtitle1">
          {`Report generated for ${userName.toUpperCase()}`}
        </Typography>
      )}
      {date && (
        <Typography align="left" variant="subtitle1">
          {`Report generated on ${date}`}
        </Typography>
      )}
    </div>
  );
}

export default React.memo(UserText);
