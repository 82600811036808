import Asset from "types/Asset";
import Lot from "types/Lot";
import Sale from "types/Sale";
import UISchema, { EntityType, UISchemaField } from "types/UISchema";

import get from "lodash/get";

type InventoryItem = {
  lot: Lot;
  asset: Asset;
  sale?: Sale;
  [key: string]: any;
};

export const getHeaders = (uiSchema: UISchema) => {
  return uiSchema.schemaFields.map(schemaField => schemaField.label);
};

export const getFormattedRow = (uiSchema: UISchema, item: InventoryItem) => {
  const row: Array<string> = [];

  uiSchema.schemaFields.forEach(schemaField => {
    row.push(get(getEntity(item, schemaField.entityType), schemaField.fieldName, "") ?? "");
  });

  return row;
};

export const getValue = (schemaField: UISchemaField, inventoryItem: InventoryItem) => {
  return get(getEntity(inventoryItem, schemaField.entityType), schemaField?.fieldName ?? "", "");
};

const getEntity = (item: InventoryItem, entityType?: EntityType) => {
  if (entityType == null) return;

  return item[entityType.toLowerCase()];
};
