import { useCallback, useMemo } from "react";
import IconButton from "@mui/material/IconButton";
import Snackbar from "@mui/material/Snackbar";
import CloseIcon from "@mui/icons-material/Close";
import { dismiss } from "actions/notificationActions";
import { connect } from "utils/StoreUtil";
import { useTheme, useMediaQuery } from "@mui/material";
import { Alert } from "@mui/material";

const autoHideDuration = 4000;

const useAnchorOrigin = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return useMemo(
    () => ({
      vertical: isMobile ? "top" : "bottom",
      horizontal: "right"
    }),
    [isMobile]
  );
};

const Notifications = ({ dismiss, notification, error, isOpen, severity }) => {
  const handleClose = useCallback(
    (event, reason) => {
      if (reason === "clickaway") return;
      dismiss();
    },
    [dismiss]
  );

  const anchorOrigin = useAnchorOrigin();

  return (
    <div>
      <Snackbar
        key={notification || error}
        anchorOrigin={anchorOrigin}
        open={isOpen}
        autoHideDuration={error ? null : autoHideDuration}
        onClose={handleClose}
        ContentProps={{ component: "div" }}
        action={[
          <IconButton key="close" aria-label="Close" color="inherit" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        ]}
      >
        <Alert onClose={handleClose} severity={severity} variant="filled">
          {notification}
        </Alert>
      </Snackbar>
    </div>
  );
};

const mapStateToProps = state => {
  const { notifications } = state;
  return {
    notification: notifications.notification,
    error: notifications.error,
    isOpen: notifications.isOpen,
    severity: notifications.severity
  };
};

export default connect(mapStateToProps, { dismiss })(Notifications);
