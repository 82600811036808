import { useState } from "react";
import useLocalStorage from "hooks/useLocalStorage";
import { makeStyles } from "tss-react/mui";
import Snackbar from "@mui/material/Snackbar";
import { Button, Collapse, Typography } from "@mui/material";
import { customColors } from "styles/theme";

const useStyles = makeStyles()(theme => ({
  root: {
    position: "fixed",
    display: "block",
    bottom: 0,
    left: 0
  },
  anchorOriginBottomCenter: {
    width: "100%",
    transform: "translateX(0%)",
    height: "auto",
    [theme.breakpoints.up("md")]: {
      bottom: 0
    }
  },
  button: {
    marginRight: theme.spacing(2)
  },
  snackbarContent: {
    width: "100%",
    minWidth: "100%",
    height: "auto",
    borderRadius: 0,
    backgroundColor: customColors.transparentBlack,
    justifyContent: "center",
    position: "relative",
    padding: 0,
    textAlign: "left",
    overflow: "hidden",
    display: "flex",
    [theme.breakpoints.down("md")]: {
      display: "block"
    }
  },
  action: {
    paddingLeft: 0,
    marginRight: 0,
    flex: 1,
    justifyContent: "flex-end",
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(2),
      paddingTop: 0,
      width: "100%",
      "& button": {
        width: "100%"
      }
    }
  },
  message: {
    padding: theme.spacing(1),
    display: "flex",
    flex: 1,
    justifyContent: "left",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      paddingLeft: 8,
      minHeight: "100%",
      justifyContent: "flex-start"
    }
  }
}));

const messageStyles = makeStyles()(theme => ({
  container: {
    display: "block",
    textAlign: "left",
    padding: theme.spacing(1),
    color: theme.palette.getContrastText(customColors.transparentBlack)
  }
}));

const CookieMessageContent = () => {
  const { classes } = messageStyles();
  return (
    <div className={classes.container}>
      <Typography color="inherit" variant="h6">
        Cookie Policy
      </Typography>
      <Typography color="inherit" variant="caption">
        By clicking “Accept All Cookies”, you agree to the storing of cookies on your device to
        enhance site navigation, analyze site usage, and assist in our marketing efforts.
      </Typography>
      <Typography
        component="a"
        color="primary"
        variant="subtitle1"
        href="https://www.xcira.com/cookie-policy/"
        target="_blank"
      >
        View Policy
      </Typography>
    </div>
  );
};

const origin = { vertical: "bottom", horizontal: "center" };

const CookieMessage = () => {
  const { classes } = useStyles();
  const [hasDismissed, setDismissed] = useLocalStorage(`cookieMessageAccepted`, false);
  const [isOpen, setIsOpen] = useState(!hasDismissed);

  const dismiss = () => {
    setDismissed(true);
    setIsOpen(false);
  };

  return (
    <Snackbar
      message={<CookieMessageContent />}
      open={isOpen}
      anchorOrigin={origin}
      TransitionComponent={Collapse}
      transitionDuration={{ enter: 0, exit: 250 }}
      ContentProps={{
        classes: {
          root: classes.snackbarContent,
          action: classes.action,
          message: classes.message
        },
        elevation: 0
      }}
      classes={{
        anchorOriginBottomCenter: classes.anchorOriginBottomCenter,
        root: classes.root
      }}
      action={[
        <Button
          className={classes.button}
          onClick={dismiss}
          key="close"
          variant="contained"
          color="primary"
        >
          Accept All Cookies
        </Button>
      ]}
    />
  );
};

export default CookieMessage;
