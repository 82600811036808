import axios from "axios";
import { dispatch, getState } from "../store";
import {
  showError as showErrorActionCreator,
  showNotification as showNotificationActionCreator
} from "actions/notificationActions";
import { logout } from "actions/userActions";
import { bindActionCreators } from "@reduxjs/toolkit";
import capitalize from "lodash/capitalize";
import flatten from "lodash/flatten";
import { stringify } from "qs";
import { trackError } from "hooks/useTracking";
import { createTermsFilter, createRange } from "utils/SearchUtil";

const BASE_URL = window.location.protocol + "//" + window.location.host + "/services/";
const TIMEOUT_ERROR_MESSAGE = "Request timed out";
const REQUEST_PROCESSING = "Processing request, please wait";

const http = axios.create({
  timeout: 90000,
  withCredentials: true
});

const GET = "get";
const POST = "post";
const DEL = "delete";
const PUT = "put";

const universalBOM = "\uFEFF";

export const CancelToken = axios.CancelToken;

const showError = bindActionCreators(showErrorActionCreator, dispatch);
const showNotification = bindActionCreators(showNotificationActionCreator, dispatch);

const requests = {};

async function makeRequest(
  method,
  url,
  payload = {},
  filters = [],
  Range = "items=0-10",
  Sort,
  baseUrl,
  options = {},
  extraHeaders
) {
  let timer;
  const { shouldShowError, ...remainingOptions } = options;
  try {
    const source = CancelToken.source();
    let headers = { Filters: JSON.stringify(filters), ...(Range && { Range }) };
    if (Sort && !Array.isArray(Sort)) {
      Sort = [Sort];
    }
    if (Sort) {
      headers = { ...headers, Sort };
    }
    if (extraHeaders) {
      headers = { ...headers, ...extraHeaders };
    }
    makeApiRequest(url, source);
    timer = setInterval(() => showNotification(REQUEST_PROCESSING), 30000);
    const res = await http({
      method,
      url: baseUrl + url,
      data: payload,
      headers,
      cancelToken: source.token,
      ...remainingOptions
    });
    completeApiRequest(url);
    return res;
  } catch (error) {
    handleApiError(error, url, baseUrl, shouldShowError);
  } finally {
    clearInterval(timer);
  }
}

export async function get(
  url,
  filters = [],
  Range = "items=0-10",
  Sort,
  baseUrl = BASE_URL,
  extraHeaders
) {
  return makeRequest(GET, url, {}, filters, Range, Sort, baseUrl, undefined, extraHeaders);
}

// TODO: are default payloads safe here?
export async function post(
  url,
  payload = {},
  filters = [],
  Range = "items=0-10",
  Sort,
  baseUrl = BASE_URL,
  options
) {
  return makeRequest(POST, url, payload, filters, Range, Sort, baseUrl, options);
}

export async function put(url, payload = {}, baseUrl = BASE_URL, options) {
  return makeRequest(PUT, url, payload, undefined, undefined, undefined, baseUrl, options);
}

export async function del(url, baseUrl = BASE_URL) {
  return makeRequest(DEL, url, undefined, undefined, undefined, undefined, baseUrl);
}

const defaultDownloadHeaders = {
  "Content-Type": "application/x-www-form-urlencoded"
};

export async function download(
  url,
  type,
  fileName,
  content,
  method = "post",
  headers = defaultDownloadHeaders,
  payload,
  options = {}
) {
  const { dispatchErrorNotification, ...remainingOptions } = options;

  try {
    const source = CancelToken.source();
    makeApiRequest(url, source);
    const res = await http[method](url, payload || stringify({ fileName, content }), {
      headers,
      cancelToken: source.token,
      responseType: "blob"
    });
    completeApiRequest(url);
    let data = handleDownloadOptions(res.data, remainingOptions);
    createLinkElement(data, type, fileName);
  } catch (error) {
    handleApiError(error, `output/${type}`);
  }
}

function handleDownloadOptions(data, options = {}) {
  if (options["charset"] === "utf-8") {
    return universalBOM + data;
  }
  return data;
}

export async function downloadExternalResource(
  url,
  type,
  fileName = "",
  config = {},
  method = "get"
) {
  try {
    const res = await getResourceFromUrl(url, method, config);
    createLinkElement(res.data, type, fileName);
  } catch (error) {
    handleApiError(error, url);
  }
}

export async function getResourceFromUrl(url, method = "get", config = {}) {
  if (!url) throw new Error("Url is required");
  return await axios[method](url, config);
}

function createLinkElement(data, type, fileName) {
  const blob = new Blob([data], { type: `application/${type}` });
  const reader = new FileReader();
  reader.onloadend = evt => {
    let a = document.createElement("a");
    document.body.appendChild(a);
    a.style.cssText = "display: none";

    if (navigator.msSaveOrOpenBlob) {
      navigator.msSaveOrOpenBlob(blob, `${fileName}.${type}`);
    } else {
      a.href = reader.result;
      a.download = `${fileName}.${type}`;
      a.click();
    }
    document.body.removeChild(a);
  };
  reader.readAsDataURL(blob);
}

export async function upload(url, headers, payload, baseUrl = BASE_URL, method = POST) {
  return makeRequest(method, url, payload, undefined, undefined, undefined, baseUrl, {
    headers,
    timeout: 180000
  });
}

export function getTotal(response) {
  const total = response.headers["content-range"] || "";
  return parseInt(total.split("/").pop(), 10) || 0;
}

function makeApiRequest(url, source) {
  if (requests[url]) cancelRequest(url);

  requests[url] = source;
}

function completeApiRequest(url) {
  delete requests[url];
}

export function cancelRequest(url) {
  let request = requests[url];

  if (request) {
    requests[url].cancel("cancelled");
    completeApiRequest(url);
  }
}

function handleApiError(error, url, baseUrl, shouldShowError) {
  if (!axios.isCancel(error)) {
    completeApiRequest(url);
  }
  if (baseUrl === BASE_URL) {
    dispatchErrorNotifications(error, url, shouldShowError);
  }
}

function dispatchErrorNotifications(error, url, shouldShowError = true) {
  if (error.message && error.message.includes("timeout")) {
    showError(TIMEOUT_ERROR_MESSAGE);
    trackError(`${TIMEOUT_ERROR_MESSAGE}: ${url}`, false);
  } else if (error.response && error.response?.data?.messageObject?.type === "reject") {
    showError(formatErrorMessage(error.response?.data?.message));

    throw new Error(error.response.data.message);
  } else if (error.response && error.response.status === 403) {
    showError(formatErrorMessage(error.response.data));
    //dispatch a logout for the user with the error message
    //error.response.data probably
  } else if (error.response && error.response.status === 401 && getState().user.isAuthorized) {
    showError(formatErrorMessage(error.response.data));
    dispatch(logout());
  } else if (error.response && error.response.status !== 401 && shouldShowError) {
    showError(formatErrorMessage(error.response.data));
    //dispatch an error message
    //error.response.data probably
  }
  throw error;
}

function formatErrorMessage(message) {
  return capitalize(message.replace(/\.$/, ""));
}

export async function chunkRequestsSync({
  request,
  sort,
  total,
  filterByProperty,
  filterValues,
  extraFilters = []
}) {
  const results = [];
  const length = total ?? filterValues.length;

  for (let i = 0, chunkSize = 500; i <= length; i += chunkSize) {
    const start = i;
    const end = Math.min(length, i + chunkSize);
    const filters = [...extraFilters];

    if (Boolean(filterByProperty)) {
      filters.push(createTermsFilter(filterByProperty, filterValues.slice(start, end)));
    }

    const response = await request(filters, createRange(start, end > 0 ? end - 1 : 0), sort).then(
      res => res.data ?? res
    );
    results.push(response);
  }
  return flatten(results);
}

export async function chunkRequests(request, chunkSize, total, filters = [], sort = null) {
  const promises = [];
  for (let i = 0; i <= total; i += chunkSize) {
    promises.push(request(filters, `items=${i}-${i + chunkSize - 1}`, sort).then(res => res.data));
  }
  return Promise.all(promises).then(flatten);
}
