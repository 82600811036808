import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Help from "@mui/icons-material/Help";
import IconButton from "@mui/material/IconButton";

const SearchHelp = ({ toggleHelpOpen, isHelpOpen, buttonRef }) => {
  return (
    <>
      <IconButton onClick={toggleHelpOpen} ref={buttonRef}>
        <Help style={{ fontSize: "18px" }} />
      </IconButton>

      <Dialog maxWidth="md" open={isHelpOpen} onClose={toggleHelpOpen}>
        <DialogTitle>Search Help</DialogTitle>
        <DialogContent>
          <DialogContentText component="div">
            Full text search can be used to easily identify content by using search terms in the
            search box but also advanced punctuation can be used to further filter the results.
            Please see the following examples to better understand how to use it.
            <br />
            <ul>
              <li>
                <b>chevrolet</b> - a simple search for chevrolet inventory items
              </li>
              <li>
                <b>2015 red chevrolet camaro coupe lane01</b> - a simple search for terms 2015 or
                red or chevrolet or camaro or coupe or lane01, the items containing as many terms
                will show up first based on relevance
              </li>
              <li>
                <b>"automatic transmission"</b> - a simple search for the exact term "automatic
                transmission", where the two words have to appear in this particular sequence next
                to each other
              </li>
              <li>
                <b>"4-cylinder"</b> - a simple search for the exact term "4-cylinder", since a
                search for 4-cylinder is equivalent to 4 OR cylinder
              </li>
              <li>
                <b>chevrolet AND camaro</b> - a simple search for terms chevrolet and camaro, where
                both terms have to be present, in no particular order
              </li>
              <li>
                <b>chevrolet AND (camaro OR corvette)</b> - a search where chevrolet has to be
                present, additionally either camaro or corvette has to be present
              </li>
              <li>
                <b>chevrolet -camaro</b> - a search for the term chevrolet, specifically excluding
                any mention of the term camaro
              </li>
              <li>
                <b>chevrolet +camaro</b> - a search for the term chevrolet, specifically where the
                term camaro has to be present
              </li>
              <li>
                <b>chevrol?t</b> - a term search where a single character substitution can be
                performed, ex. chevrolet will match
              </li>
              <li>
                <b>chevro*t</b> - a term search where any sequence of characters can be substituted,
                ex. chevrolet will match
              </li>
              <li>
                <b>make:chevrolet model:camaro</b> - a specific search where terms need to match
                specific fields, ex. chevrolet needs to be found as a make, and camaro as a model.
                Supported fields are:
                <i>
                  name, description, year, make, model, color, vin, engine, vehicleType,
                  transmission, exteriorColor, interiorColor, trim, fuelType, options, unitNumber,
                  stockNumber, lotNumber, laneNumber, merchandise, announcement, conditionGrade,
                  overallConditionGrade
                </i>
              </li>
            </ul>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleHelpOpen} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SearchHelp;
