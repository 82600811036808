import { createSlice, createEntityAdapter } from "@reduxjs/toolkit";
import { REHYDRATE } from "redux-persist/lib/constants";

import {
  ADMIN_UPDATE_EVENT,
  CALENDAR_SET_LAST_PAGE_TYPE,
  CALENDAR_FETCH,
  CALENDAR_FETCH_COMPLETE,
  RESET_CALENDAR_ENTITIES
} from "actions/actionTypes";
import Event from "types/Event";
import Sale from "types/Sale";
import { inventoryFetchComplete } from "actions/sharedActions";

const NAME = "events";
const EVENT_SALE_USER_COUNT = "eventSaleUserCount";

const eventsAdapter = createEntityAdapter<Event>();

export const eventsSelector = eventsAdapter.getSelectors();

export const initialState = eventsAdapter.getInitialState({
  total: 0,
  isLoading: true,
  lastPageType: "calendar"
});

const eventsSlice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    removeEvent(state, action) {
      eventsAdapter.removeOne(state, action.payload);
    }
  },
  extraReducers: builder => {
    builder
      .addCase(REHYDRATE, () => eventsAdapter.getInitialState({ ...initialState }))
      .addCase(RESET_CALENDAR_ENTITIES, state => {
        eventsAdapter.setAll(state, []);
      })
      .addCase(CALENDAR_FETCH, state => {
        state.isLoading = true;
      })
      .addCase(CALENDAR_FETCH_COMPLETE, (state, action) => {
        // @ts-ignore
        const events = action.payload[NAME] || [];
        // @ts-ignore
        const saleUserCountMap = action.payload[EVENT_SALE_USER_COUNT];

        state.isLoading = false;
        // @ts-ignore
        state.lastPageType = action.payload.lastPageType;

        // @ts-ignore
        if (action.payload.shouldMerge) {
          eventsAdapter.upsertMany(state, events);
        } else {
          eventsAdapter.setAll(state, events);
          // @ts-ignore
          state.total = action.payload.total || 0;
        }

        if (saleUserCountMap) {
          const saleUserCount = [];

          for (let key in saleUserCountMap) {
            saleUserCount.push({ id: key, saleUserCount: saleUserCountMap[key] });
          }

          // @ts-ignore :TODO: erik look into this
          eventsAdapter.upsertMany(state, saleUserCount);
        }
      })
      .addCase(CALENDAR_SET_LAST_PAGE_TYPE, (state, action) => {
        // @ts-ignore
        state.lastPageType = action.payload.lastPageType;
      })
      .addCase(ADMIN_UPDATE_EVENT, (state, action) => {
        // @ts-ignore
        eventsAdapter.updateOne(state, action.payload.update);
      })
      .addCase(inventoryFetchComplete, (state, action) => {
        // @ts-ignore
        const events = action.payload[NAME];
        if (events) {
          // @ts-ignore
          if (action.payload.shouldMerge) {
            eventsAdapter.upsertMany(state, events);
          } else {
            eventsAdapter.setAll(state, events);
          }
        }
      });
  }
});

export const { removeEvent } = eventsSlice.actions;

export default eventsSlice.reducer;

export function hasSaleType(event: Event, saleType: Sale["saleType"]) {
  return Boolean(event?.saleTypes?.find(eventSaleType => eventSaleType.includes(saleType)));
}
