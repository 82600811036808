import { handleLabelConversions } from "utils/LanguageUtil";

export class Csv {
  constructor(languageMap = {}) {
    this.header = [];
    this.rows = [];
    this.languageMap = languageMap;
  }

  setHeader(header) {
    this.header = header;
  }

  setRows(rows) {
    this.rows = rows;
  }

  addRow(row) {
    this.rows.push(row);
  }

  get() {
    return JSON.stringify(this._get());
  }

  getRows() {
    return this._get();
  }

  _get() {
    const labels = this.header.map(label => handleLabelConversions(label, this.languageMap));
    return [labels, ...this.rows];
  }
}

export function reduceResults(results = []) {
  return results.reduce((inventory, result) => {
    for (let key in result) {
      const value = result[key];

      if (Array.isArray(value)) {
        if (!inventory[key]) inventory[key] = [];

        inventory[key] = inventory[key].concat(result[key]);
      } else {
        if (!inventory[key]) {
          inventory[key] = value;
        }
      }
    }

    return inventory;
  }, {});
}

export function getShouldFormatDate(assetSchemaHeader, assetSchemaName, asset) {
  return (
    assetSchemaHeader.schemaName === assetSchemaName &&
    asset.uifields[assetSchemaHeader.key]?.label === "DOB"
  );
}
