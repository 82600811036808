import { defaultIntlUtil } from "./IntlUtil";

export function abbreviateNumber(number, decPlaces) {
  decPlaces = Math.pow(10, decPlaces);
  let abbrev = ["k", "m", "b", "t"];
  for (let i = abbrev.length - 1; i >= 0; i--) {
    let size = Math.pow(10, (i + 1) * 3);
    if (size <= number) {
      number = Math.round((number * decPlaces) / size) / decPlaces;
      if (number === 1000 && i < abbrev.length - 1) {
        number = 1;
        i++;
      }
      number += abbrev[i];
      break;
    }
  }
  return number;
}

export function formatNumber(amount) {
  return defaultIntlUtil.formatNumber(amount);
}

export function formatNumberWithOptions(amount, options) {
  return defaultIntlUtil.formatNumber(amount, options);
}

export function formatNumberWithFractions(amount) {
  if (amount != null) {
    return defaultIntlUtil.formatNumber(amount, { showFractionalDigits: true });
  }
}

const defaultValue = "";
const defaultSearchValue = /,/g;

export function parseStringToInt(
  value = defaultValue,
  searchValue = defaultSearchValue,
  replaceValue = defaultValue
) {
  return parseInt(value.replace(searchValue, replaceValue), 10);
}

export function isStringNumeric(str) {
  if (typeof str != "string") return false;
  return !isNaN(str) && !isNaN(parseFloat(str));
}
