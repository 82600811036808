import { Box, lighten, Stack, Typography } from "@mui/material";
import EventIcon from "@mui/icons-material/Event";
import moment from "moment";
import { LOCALE_CODE } from "utils/constants";

const { EN_US, EN_CA, EN_GB, EN_NZ, EN_ZA, EN_NA } = LOCALE_CODE;

const localeMap = {
  [EN_US]: "MM/DD hh:mma",
  [EN_CA]: "DD/MM hh:mma",
  [EN_GB]: "DD/MM hh:mma",
  [EN_NZ]: "D/MM hh:mma",
  [EN_ZA]: "MM/DD hh:mma",
  [EN_NA]: "MM/DD hh:mma"
};

const EventDateTime: React.FC<{
  startTime: number;
  endTime: number;
  locale: string;
  saleType?: string;
}> = ({ startTime, endTime, locale, saleType }) => {
  const startDateTime = moment(startTime).tz(moment.tz.guess());
  const endDateTime = moment(endTime).tz(moment.tz.guess());

  let dateTimeString = "";

  const showStartOnly =
    saleType?.toLowerCase() === "live" && endDateTime.isSame(startDateTime, "day");

  dateTimeString += startDateTime.format(localeMap[locale] + (showStartOnly ? " z" : ""));

  if (!showStartOnly) {
    dateTimeString += " - ";
    dateTimeString += endDateTime.format(localeMap[locale] + " z");
  }

  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={1}
      sx={{
        backgroundColor: theme => lighten(theme.palette.primary.main, 0.9),
        color: theme => theme.palette.getContrastText(lighten(theme.palette.primary.main, 0.9)),
        textAlign: "left",
        padding: 1,
        borderRadius: 1
      }}
    >
      <EventIcon color="inherit" />

      {saleType && (
        <Typography color="inherit" sx={{ fontWeight: "fontWeightMedium" }}>
          {saleType}
        </Typography>
      )}

      <Box>
        <Typography color="inherit">{dateTimeString}</Typography>
      </Box>
    </Stack>
  );
};

export { EventDateTime as default, EventDateTime };
