import React, { Suspense, memo, useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { makeStyles } from "tss-react/mui";
import Loading from "./Loading";
import useTracking from "hooks/useTracking";
import { useSelector } from "utils/StoreUtil";
import { BACK_TO_TOP_ANCHOR, MAIN_CONTAINER_DIV } from "utils/constants";
import ScrollTop from "components/scrolling/ScrollTopContainer";
import { useShowTopNavbarMobile } from "components/navbar/usePublicPaths";

const AsyncWelcome = React.lazy(() => import("components/Welcome"));
const AsyncCalendar = React.lazy(() => import("./calendar/Calendar"));
const AsyncEventDetails = React.lazy(() => import("./calendar/eventDetails/index"));
const AsyncInventoryMain = React.lazy(() => import("./inventory/search/InventoryMain"));
const AsyncInventoryDetails = React.lazy(() => import("./inventory/details/InventoryDetails"));
const AsyncFastlaneDetailsContainer = React.lazy(() =>
  import("./inventory/details/FastlaneDetailsContainer")
);
const AsyncActivity = React.lazy(() => import("./activity/index"));
const AsyncLogin = React.lazy(() => import("./login/index"));
const AsyncUser = React.lazy(() => import("views/user/UserContainer"));
const AsyncAdmin = React.lazy(() => import("views/admin"));
const AsyncAdminReports = React.lazy(() => import("views/admin/events/reports/index"));
const AsyncAdminEventManagement = React.lazy(() => import("views/admin/events/eventManagement"));
const AsyncAdminUserDetail = React.lazy(() => import("views/admin/details/UserDetail"));
const AsyncAdminDealershipDetail = React.lazy(() => import("views/admin/details/DealershipDetail"));
const AsyncBidLogs = React.lazy(() => import("views/admin/events/reports/inventory/bidLogs"));
const AsyncReports = React.lazy(() => import("views/reports"));
const AsyncNotFound = React.lazy(() => import("./NotFound"));
const AsyncRegister = React.lazy(() => import("views/register"));
const AsyncLoading = React.lazy(() => import("views/Loading"));
const AsyncHelp = React.lazy(() => import("views/help/index"));
const AsyncChangeEmailSuccess = React.lazy(() => import("views/ChangeEmailSuccess"));
const AsyncConfiguration = React.lazy(() => import("views/configuration"));

const RedirectToActivity = () => <Redirect to="/home/activity/buyer/purchases" />;

const useStyles = makeStyles()((theme, { showTopNavbar }) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    flex: 1,
    [theme.breakpoints.down("md")]: {
      minHeight: "100vh",
      overflow: "visible",
      marginTop: showTopNavbar ? 56 : 0
    },
    [theme.breakpoints.down("lg")]: {
      paddingBottom: showTopNavbar ? 50 : 0
    }
  }
}));

const Main = () => {
  useTracking();

  useEffect(function didMount() {
    window.scrollTo(0, 0);
    document.querySelector(`#${MAIN_CONTAINER_DIV}`)?.scrollTo(0, 0);
  });

  const requirePasswordReset = useSelector(state => state.user.user.requirePasswordReset);
  const useExternalLogin = useSelector(state => state.config.useExternalLogin);

  const showTopNavbar = useShowTopNavbarMobile();

  const { classes, cx } = useStyles({ showTopNavbar });

  return (
    <>
      <div id={BACK_TO_TOP_ANCHOR} />
      <main className={cx("mainContainer", classes.root)} id={MAIN_CONTAINER_DIV}>
        <Suspense fallback={<Loading />}>
          <Switch>
            <Route exact path="/" component={AsyncWelcome} />
            {!useExternalLogin && <Route path="/login" component={AsyncLogin} />}
            {!useExternalLogin && <Route path="/register" component={AsyncRegister} />}
            <Route path="/user" component={AsyncUser} />
            {requirePasswordReset && <Redirect to="/user/password" />}
            <Route exact path="/changeEmailSuccess" component={AsyncChangeEmailSuccess} />
            <Route path="/admin/events/:eventId" component={AsyncAdminReports} />
            <Route path="/admin/management/event" component={AsyncAdminEventManagement} />
            <Route path="/admin/event/management/:eventId" component={AsyncAdminEventManagement} />
            <Route path="/admin/bidLogs/:lotId" component={AsyncBidLogs} />
            <Route exact path="/admin/user/details/:userId" component={AsyncAdminUserDetail} />
            <Route
              exact
              path="/admin/dealership/details/:dealershipId"
              component={AsyncAdminDealershipDetail}
            />
            <Route path="/admin" component={AsyncAdmin} />
            <Route path="/configuration" component={AsyncConfiguration} />
            <Route path="/recommender" component={AsyncConfiguration} />
            <Route path="/research" component={AsyncReports} />
            <Route exact path="/calendar" component={AsyncCalendar} />
            <Route path="/calendar/company/:companyId" component={AsyncCalendar} />
            <Route path="/calendar/events/:eventId" component={AsyncEventDetails} />
            <Route exact path="/inventory" component={AsyncInventoryMain} />
            <Route
              exact
              path="/inventory/event/:eventNumber/item/:assetNumber"
              component={AsyncFastlaneDetailsContainer}
            />
            <Route
              exact
              path="/inventory/company/:companyCode/event/:eventNumber/item/:assetNumber"
              component={AsyncFastlaneDetailsContainer}
            />
            <Route path="/inventory/watchlist" component={AsyncInventoryMain} />
            <Route path="/inventory/sale/:saleId" component={AsyncInventoryMain} />
            <Route path="/inventory/event/:eventId" component={AsyncInventoryMain} />
            <Route path="/inventory/company/:companyId" component={AsyncInventoryMain} />
            <Route path="/inventory/:lotId" component={AsyncInventoryDetails} />
            <Route exact path="/purchases" render={RedirectToActivity} />
            <Route path="/home" component={AsyncActivity} />
            <Route exact path="/loading" component={AsyncLoading} />
            <Route path="/help" component={AsyncHelp} />
            <Route path="/404" component={AsyncNotFound} />
            <Redirect to="/404" />
          </Switch>
        </Suspense>
      </main>
      <ScrollTop />
    </>
  );
};

export default memo(Main);
