import { NOTIFICATIONS_SHOW, NOTIFICATIONS_ERROR, NOTIFICATIONS_DISMISS } from "./actionTypes";
import {
  fetchNotificationGroups,
  fetchNotificationGroupsComplete
} from "reducers/notificationGroups";
import { createTermsFilter, createTermFilter } from "utils/SearchUtil";
import {
  getNotificationGroups as getNotificationGroupFromAPI,
  createNotificationGroup,
  updateNotificationGroup as updateNotificationGroupFromApi
} from "api/notificationRequests";
import { NOTIFICATION_GROUP_TYPE } from "../utils/constants";
import {
  notificationGroupsSelector,
  addNotificationGroup,
  updateNotificationGroup,
  save,
  saveComplete
} from "reducers/notificationGroups";
import { createDefaultNotificationGroup } from "../reducers/notifications";
import { getCompanyIds } from "reducers/companies";
import { Severity } from "types/alert";

export function getNotificationGroups() {
  return async (dispatch, getState) => {
    dispatch(fetchNotificationGroups());

    const {
      companies: { companies }
    } = getState();

    try {
      const filters = [
        createTermsFilter("companyId", getCompanyIds(companies)),
        createTermFilter("notificationGroupType", NOTIFICATION_GROUP_TYPE.CUSTOMER_ADMIN)
      ];

      const notificationGroups = await getNotificationGroupFromAPI(filters);

      dispatch(fetchNotificationGroupsComplete(notificationGroups.data, notificationGroups.total));
    } catch (error) {
      dispatch(fetchNotificationGroupsComplete([], 0));
    }
  };
}

export function addUserToNotificationGroup(userId, companyId) {
  return async (dispatch, getState) => {
    dispatch(save());

    const notificationGroup = notificationGroupsSelector.selectById(getState(), companyId);

    try {
      if (notificationGroup) {
        validateNotificationGroupUserIds(notificationGroup);

        let updatedNotificationGroup = {
          ...notificationGroup,
          userIds: [...notificationGroup.userIds, userId]
        };

        await updateNotificationGroupFromApi(updatedNotificationGroup);

        dispatch(updateNotificationGroup({ id: companyId, changes: updatedNotificationGroup }));
      } else {
        let newNotificationGroup = await getCompanyNotificationGroup(companyId);

        validateNotificationGroupUserIds(newNotificationGroup);

        if (!newNotificationGroup) {
          newNotificationGroup = createDefaultNotificationGroup(companyId);
        }

        newNotificationGroup.userIds.push(userId);

        const id = await createNotificationGroup(newNotificationGroup);

        newNotificationGroup.id = id;

        dispatch(addNotificationGroup(newNotificationGroup));
      }

      dispatch(showNotification("Added to notification group", Severity.info));
    } catch (error) {
      dispatch(showError(error.response?.data || error.message));
      throw error;
    } finally {
      dispatch(saveComplete());
    }
  };
}

export function removeUserFromNotificationGroup(userId, companyId) {
  return async (dispatch, getState) => {
    dispatch(save());

    const notificationGroup = notificationGroupsSelector.selectById(getState(), companyId);
    try {
      if (notificationGroup.userIds.length < 2) {
        throw new Error("Cannot remove, notification group needs at least one user");
      }

      const updatedNotificationGroup = {
        ...notificationGroup,
        userIds: notificationGroup.userIds.filter(id => id !== userId)
      };

      await updateNotificationGroupFromApi(updatedNotificationGroup);

      dispatch(updateNotificationGroup({ id: companyId, changes: updatedNotificationGroup }));
      dispatch(showNotification("Removed from notification group", Severity.info));
    } catch (error) {
      dispatch(showError(error.response?.data || error.message));
      throw error;
    } finally {
      dispatch(saveComplete());
    }
  };
}

export function showNotification(notification, severity) {
  return {
    type: NOTIFICATIONS_SHOW,
    payload: {
      notification,
      severity: severity || Severity.info
    }
  };
}

export function showError(notification) {
  return {
    type: NOTIFICATIONS_ERROR,
    error: true,
    payload: {
      notification,
      severity: Severity.error
    }
  };
}

export function dismiss() {
  return {
    type: NOTIFICATIONS_DISMISS
  };
}

async function getCompanyNotificationGroup(companyId) {
  const filters = [
    createTermFilter("companyId", companyId),
    createTermFilter("notificationGroupType", NOTIFICATION_GROUP_TYPE.CUSTOMER_ADMIN)
  ];

  let {
    data: [notificationGroup]
  } = await getNotificationGroupFromAPI(filters);

  return notificationGroup;
}

const NOTIFICATION_GROUP_MAX_USER_IDS = 10;

function validateNotificationGroupUserIds(notificationGroup) {
  if (notificationGroup?.userIds.length >= NOTIFICATION_GROUP_MAX_USER_IDS) {
    throw new Error(
      `Unable to add user. Max number of allowed users is ${NOTIFICATION_GROUP_MAX_USER_IDS}`
    );
  }
}
