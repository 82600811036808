import { useState, useEffect } from "react";

export default function useMediaLoadingToOrchestratePrint(total = 0) {
  const [imgCount, setImgCount] = useState(0);
  const [isReadyToPrint, setReadyStatus] = useState(false);

  const incrementImgCount = () => setImgCount(count => count + 1);

  useEffect(() => {
    if (imgCount === total) {
      setReadyStatus(true);
    }
  }, [imgCount, total]);

  return { isReadyToPrint, increment: incrementImgCount };
}
