type SelectedSearchOptions = {
  [key: string]: any;
};

export const mainAutomotiveSearchOptions = ["year", "make", "model", "trim", "mileage"];
export const advancedAutomotiveSearchOptions = [
  "grade",
  "consignor",
  "consignorType",
  "engine",
  "transmission",
  "fuelType",
  "drive",
  "exteriorColor",
  "interiorColor",
  "bodyStyle"
];
export const allowedAutomotiveSaveSearchOptions = [
  "fromYear",
  "toYear",
  "year",
  "make",
  "model",
  "trim",
  "odometer",
  "fromMileage",
  "toMileage",
  "odometerUnit",
  "fromGrade",
  "toGrade",
  "consignor",
  "consignorType",
  "engine",
  "transmission",
  "fuelType",
  "drive",
  "exteriorColor",
  "interiorColor",
  "bodyStyle",
  "keys",
  "queryString",
  "titleStatus"
];

export function getSelectedSearchOptions(selectedSearchOptions: SelectedSearchOptions = {}) {
  return Object.keys(selectedSearchOptions).reduce<SelectedSearchOptions>((acc, key) => {
    if (allowedAutomotiveSaveSearchOptions.includes(key)) {
      acc[key] = selectedSearchOptions[key];
    }

    return acc;
  }, {});
}

export function canSaveSavedSearchOption(selectedSearchOptions: SelectedSearchOptions) {
  return Object.keys(selectedSearchOptions).some(key => {
    const selectedSearchOptionValue = selectedSearchOptions[key];

    if (allowedAutomotiveSaveSearchOptions.includes(key)) {
      return Array.isArray(selectedSearchOptionValue)
        ? selectedSearchOptionValue.length > 0
        : Boolean(selectedSearchOptionValue);
    }

    return false;
  });
}
