import { batch } from "react-redux";
import { Action, Dispatch, Middleware, MiddlewareAPI, isAction } from "@reduxjs/toolkit";

import {
  COMET_SUBSCRIBE_REQUEST,
  COMET_UNSUBSCRIBE_REQUEST,
  USER_LOGOUT,
  USER_LOGIN_SUCCESS,
  COMET_SUBSCRIBE,
  COMET_UNSUBSCRIBE
} from "actions/actionTypes";
import { showError, showNotification } from "actions/notificationActions";
import { connect, disconnect } from "actions/cometActions";
import CometClient from "./CometClient";
import { cometMessage } from "actions/sharedActions";
import { parseCometMessage } from "reducers/comet";

function createMiddleware(): Middleware {
  let client: CometClient;
  let isInitialized = false;

  async function initialize(store: MiddlewareAPI<Dispatch<Action>, any>) {
    client = new CometClient();

    client.onConnect = () => {
      store.dispatch(connect());
    };

    client.onReconnect = () => {
      batch(() => {
        store.dispatch(showNotification("Connection reestablished"));
        store.dispatch(connect());
      });
    };

    client.onDisconnect = () => {
      batch(() => {
        store.dispatch(showError("Disconnected"));
        store.dispatch(disconnect());
      });
    };

    client.onMessage = msg => {
      store.dispatch(cometMessage(parseCometMessage(msg as any)));
    };

    client.onSubscribe = channel => {
      store.dispatch({ type: COMET_SUBSCRIBE, payload: { channel } });
    };

    client.onUnsubscribe = channel => {
      store.dispatch({ type: COMET_UNSUBSCRIBE, payload: { channel } });
    };

    client.init();
    isInitialized = true;
  }

  return store => next => async action => {
    const result = next(action);

    if (isAction(action)) {
      switch (action.type) {
        case COMET_SUBSCRIBE_REQUEST: {
          if (!isInitialized) {
            await initialize(store);
          }
          // @ts-ignore
          client?.subscribe(action.payload.channel);
          break;
        }

        case COMET_UNSUBSCRIBE_REQUEST: {
          // @ts-ignore
          client?.unsubscribe(action.payload.channel);
          break;
        }

        // redo handshake with current credentials
        case USER_LOGIN_SUCCESS:
        case USER_LOGOUT:
          if (isInitialized) {
            client?.bounce();
          }
          break;

        default:
          break;
      }
    }

    return result;
  };
}

export default createMiddleware();
