import { CREDITS_GET, CREDITS_SET, CREDIT_UPDATE } from "actions/actionTypes";
import { REHYDRATE } from "redux-persist/lib/constants";
import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  credits: [],
  loading: false
};

// TODO: normalize credits

const credit = createReducer(initialState, builder => {
  builder
    .addCase(REHYDRATE, state => {
      state = initialState;
    })
    .addCase(CREDITS_GET, state => {
      state.loading = true;
    })
    .addCase(CREDITS_SET, (state, action) => {
      state.loading = false;
      state.credits = action.payload.credits;
    })
    .addCase(CREDIT_UPDATE, (state, action) => {
      state.credits = state.credits.map(credit => {
        if (credit.id === action.payload.credit.id) {
          credit = { ...credit, ...action.payload.credit };
        }

        return credit;
      });
    });
});

export default credit;
