import { useMemo } from "react";
import { useSelector } from "utils/StoreUtil";
import { getSalesByEventId } from "reducers/sales";

export function getLotCount(sales) {
  let count = sales?.reduce((count, sale) => {
    const activeLots = sale.totalLots - sale.totalWithdrawnLots;
    count += activeLots;
    return count;
  }, 0);
  if (isNaN(count)) {
    count = 0;
  }
  return count;
}

function getSaleCount(sales) {
  return sales.filter(sale => sale.totalLots - sale.totalWithdrawnLots > 0).length;
}

export default function useGetCounts(eventId) {
  const sales = useSelector(state => state.sales);

  const eventSales = getSalesByEventId(sales, eventId);

  const counts = useMemo(() => {
    return {
      saleCount: getSaleCount(eventSales),
      lotCount: getLotCount(eventSales)
    };
  }, [eventSales]);

  return counts;
}
