import React, { useContext } from "react";
import { SidebarContext } from "../../components/SidebarNavigation";
import { useSelector } from "utils/StoreUtil";
import { Badge, Box, Divider, Typography } from "@mui/material";
import UserAvatar from "components/UserAvatar";
import { formatDate, formatYear } from "utils/DateUtil";
import { makeStyles } from "tss-react/mui";
import Fade from "@mui/material/Fade";
import Tooltip from "@mui/material/Tooltip";
import PhotoUploadIcon from "views/user/PhotoUploadIcon";
import { setUserMedia } from "actions/userActions";

const useStyles = makeStyles()((theme, { isOpen }) => ({
  avatar: {
    height: isOpen ? "80px" : "32px",
    width: isOpen ? "80px" : "32px"
  },
  avatarBox: {
    maxHeight: "128px",
    minHeight: "80px",
    backgroundColor: theme.palette.background.paper,
    borderBottom: theme.borderBottom,
    padding: theme.spacing(3),
    textAlign: "center"
  },
  space: {
    padding: theme.spacing(1, 0, 1, 0),
    backgroundColor: theme.palette.background.paper
  },
  label: {
    textTransform: "capitalize"
  },
  textCenter: {
    textAlign: "center"
  },
  pointer: {
    cursor: "pointer"
  },
  badgeSize: {
    "& .MuiBadge-anchorOriginBottomRightCircular": {
      transform: isOpen ? "scale(1) translate(50%, 50%)" : "scale(0.5) translate(50%, 50%)"
    }
  },
  photoIcon: {
    backgroundColor: `${theme.palette.grey["100"]}`,
    color: theme.palette.primary.dark,
    padding: theme.spacing(0.75),
    "&:hover": {
      backgroundColor: `${theme.palette.grey["100"]}`,
      color: theme.palette.primary.main
    }
  }
}));

const UserSidebarHeader = () => {
  const isOpen = useContext(SidebarContext);
  const { classes } = useStyles({ isOpen });
  const locale = useSelector(state => state.user.user.locale);
  const userState = useSelector(state => state.user.user);
  const userMedia = useSelector(state => state.user.media);
  const user = {
    initials:
      userState?.firstName?.charAt(0).toUpperCase() + userState?.lastName?.charAt(0).toUpperCase(),
    id: userState?.id,
    lastName: userState?.lastName,
    name: userState?.name,
    email: userState?.email,
    createdOn: userState.createdOn,
    companyId: userState?.companyId
  };

  return (
    <div className={classes.textCenter}>
      <Box className={classes.avatarBox}>
        <Badge
          overlap="circular"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right"
          }}
          badgeContent={
            <PhotoUploadIcon
              entityType="USER"
              entityId={user?.id}
              companyId={user?.companyId}
              mediaId={userMedia?.id}
              mediaName="profile"
              onCompleteAction={setUserMedia}
            />
          }
          className={classes.badgeSize}
        >
          <UserAvatar className={classes.avatar} />
        </Badge>
      </Box>

      <Box
        component={Typography}
        fontWeight="fontWeightBold"
        variant="body1"
        py={0.5}
        className={classes.label}
      >
        {isOpen ? (
          user?.name
        ) : (
          <Tooltip
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 600 }}
            title={user.email}
            placement={"right-start"}
          >
            <span className={classes.pointer}>{user?.initials}</span>
          </Tooltip>
        )}
      </Box>

      {isOpen && (
        <Box component={Typography} fontWeight="fontWeightRegular" variant="body2" py={0.5}>
          {user?.email?.length >= 24 ? (
            <Tooltip
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 600 }}
              title={user.email}
            >
              <span className={classes.pointer}>{user?.email.substring(0, 20)}...</span>
            </Tooltip>
          ) : (
            user.email
          )}
        </Box>
      )}

      <Box component={Typography} fontWeight="fontWeightRegular" variant="caption" py={0.5}>
        {isOpen ? `Member since ${formatDate(user.createdOn, locale)}` : formatYear(user.createdOn)}
      </Box>

      <div className={classes.space}>
        <Divider variant={"middle"} />
      </div>
    </div>
  );
};

export default UserSidebarHeader;
