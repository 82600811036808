import { createSlice, createEntityAdapter } from "@reduxjs/toolkit";
import { REHYDRATE } from "redux-persist/lib/constants";

import { AMS_FETCH_COMPLETE } from "actions/actionTypes";
import Ams from "types/Ams";

const amsAdapter = createEntityAdapter<Ams, number>({
  selectId: amsConfiguration => amsConfiguration.companyId
});

export const amsSelector = amsAdapter.getSelectors();

const amsSlice = createSlice({
  name: "ams",
  initialState: amsAdapter.getInitialState(),
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(REHYDRATE, () => amsAdapter.getInitialState())
      .addCase(AMS_FETCH_COMPLETE, (state, action) => {
        // @ts-ignore
        amsAdapter.setAll(state, action.payload || []);
      });
  }
});

export default amsSlice.reducer;
