import { createSlice, createSelector } from "@reduxjs/toolkit";

import {
  ACTIVITY_RESET_ACTIVITIES,
  ACTIVITY_SET_FILTER_VALUE,
  ATTENDANCE_SET_DEALERSHIP_ID
} from "actions/actionTypes";
import { REHYDRATE } from "redux-persist/lib/constants";

import { toggleArrayValue } from "./vehicles";
import { USER_ACTIVITY_TYPE, PATHNAMES } from "../utils/constants";
import { toLowerCase } from "utils/StringUtil";
import { inventoryFetchComplete } from "actions/sharedActions";
import { lotsSelector } from "./lots";

export const bulkActionTypes = {
  NO_ACTION: "",
  GET_CONTRACTS: "receipts",
  GET_GATEPASSES: "gatepasses",
  FLOOR_VEHICLES: "floorvehicles",
  REQUEST_PSI: "request psi"
};

export const contractRequestTypes = {
  EMAIL: "email",
  DOWNLOAD: "onetimelink"
};

export const initialState = {
  isLoading: true,
  page: 0,
  total: 0,
  perPage: 50,
  sortBy: "",
  bulkActionType: bulkActionTypes.NO_ACTION,
  contractRequestType: contractRequestTypes.EMAIL,
  isBulkActionActive: false,
  bulkActionSelectedIds: [],
  bulkActionSelectedIdsMapValues: {},
  bulkRequestResponse: null,
  isFetchingReport: false
};

const resetBulkActionState = state => {
  state.bulkActionType = initialState.bulkActionType;
  state.contractRequestType = initialState.contractRequestType;
  state.isBulkActionActive = initialState.isBulkActionActive;
  state.bulkActionSelectedIds = initialState.bulkActionSelectedIds;
  state.bulkActionSelectedIdsMapValues = initialState.bulkActionSelectedIdsMapValues;
  state.bulkRequestResponse = initialState.bulkRequestResponse;
};

const activitySlice = createSlice({
  name: "activity",
  initialState,
  reducers: {
    setIsLoading: state => {
      state.isLoading = true;
    },
    setPage: (state, action) => {
      state.page = action.payload;
      resetBulkActionState(state);
    },
    setBulkActionType: {
      reducer: (state, action) => {
        const { type, lotId } = action.payload;

        state.bulkActionType = type || null;
        state.bulkActionSelectedIds = lotId == null ? initialState.bulkActionSelectedIds : [lotId];
        state.bulkRequestResponse = initialState.bulkRequestResponse;
        state.bulkActionSelectedIdsMapValues = initialState.bulkActionSelectedIdsMapValues;

        state.isBulkActionActive = lotId == null ? type != null : false;
      },
      prepare: (type, lotId) => ({ payload: { type, lotId } })
    },
    setContractRequestType: (state, action) => {
      state.contractRequestType = action.payload;
    },
    toggleBulkActionSelectedIds: (state, action) => {
      state.bulkActionSelectedIds = toggleArrayValue(state.bulkActionSelectedIds, action.payload);
    },
    bulkSelectAll: (state, action) => {
      state.bulkActionSelectedIds = getEligibleLotIds(action.payload);
    },
    bulkClearAll: state => {
      state.bulkActionSelectedIds = initialState.bulkActionSelectedIds;
      state.bulkActionSelectedIdsMapValues = initialState.bulkActionSelectedIdsMapValues;
    },
    setIsFetching: state => {
      state.isFetchingReport = true;
    },
    setReportResponse: (state, action) => {
      state.isFetchingReport = false;
      state.bulkRequestResponse = action.payload;
    },
    setBulkActionSelectedIdValue: (state, action) => {
      const { selectedId, key, value } = action.payload;

      if (key != null) {
        state.bulkActionSelectedIdsMapValues[key] = value;
      }

      if (selectedId != null && !state.bulkActionSelectedIds.includes(selectedId)) {
        state.bulkActionSelectedIds.push(selectedId);
      }
    },
    removeBulkActionSelectedIdValue: (state, action) => {
      const { selectedId, key } = action.payload;

      if (key != null && selectedId != null) {
        delete state.bulkActionSelectedIdsMapValues[key];
        state.bulkActionSelectedIds = state.bulkActionSelectedIds.filter(id => id !== selectedId);
      }
    },
    setSortBy: (state, action) => {
      state.sortBy = action.payload || initialState.sortBy;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(REHYDRATE, state => {
        return { ...state };
      })
      .addCase(inventoryFetchComplete, (state, action) => {
        state.total = action.payload.total || initialState.total;
        state.isLoading = false;
      })
      .addCase(ACTIVITY_SET_FILTER_VALUE, state => {
        state.page = initialState.page;
        state.total = initialState.total;
        resetBulkActionState(state);
      })
      .addCase(ACTIVITY_RESET_ACTIVITIES, state => {
        state.page = initialState.page;
        state.total = initialState.total;
      })
      .addCase(ATTENDANCE_SET_DEALERSHIP_ID, state => {
        resetBulkActionState(state);
      });
  }
});

const { reducer, actions } = activitySlice;

export const {
  setIsLoading,
  setPage,
  setBulkActionType,
  setContractRequestType,
  toggleBulkActionSelectedIds,
  bulkSelectAll,
  bulkClearAll,
  setIsFetching,
  setReportResponse,
  setBulkActionSelectedIdValue,
  removeBulkActionSelectedIdValue,
  setSortBy
} = actions;

export default reducer;

export const mapActivityName = (activityName, inventoryText) => {
  switch (activityName) {
    case "unlisted":
    case "listed":
      return `${activityName} ${toLowerCase(inventoryText)}`;
    default:
      return activityName;
  }
};

export function reduceSelectedIdsByCompany(assets, lots) {
  return (accumulator, lotId) => {
    const lot = lots[lotId];
    const asset = assets[lot?.assetId];
    if (asset) {
      const companyId = asset.companyId;
      if (accumulator[companyId]) {
        accumulator[companyId].push(asset.assetNumber);
      } else {
        accumulator[companyId] = [asset.assetNumber];
      }
      return accumulator;
    }
  };
}

export const getLotsCompanyIds = createSelector(
  state => state.lots,
  lotsState => {
    if (lotsSelector.selectTotal(lotsState) < 1) return [];
    const companyIds = new Set(lotsSelector.selectAll(lotsState).map(lot => lot.companyId));

    return Array.from(companyIds);
  }
);

function getEligibleLotIds({ lots, sales }) {
  const saleIds = Object.values(sales).reduce(getSaleIds, []);
  return Object.values(lots).reduce(getLotIds(saleIds), []);
}

function getSaleIds(saleIds, sale) {
  if (sale.saleType !== "TIMED") {
    saleIds.push(sale.id);
  }
  return saleIds;
}

function getLotIds(saleIds) {
  return (lotIds, lot) => {
    if (lot.status === "SOLD" && saleIds.includes(lot.saleId)) {
      lotIds.push(lot.id);
    }
    return lotIds;
  };
}

export const calculateTotalFees = (fees, displayCharges, shouldSubtract) => {
  return displayCharges
    ? fees.reduce((total, fee) => {
        if (shouldSubtract) {
          total -= fee.amount;
        } else {
          total += fee.amount;
        }
        return total;
      }, 0)
    : 0;
};

export function getDealershipIdsFromBulkSelectedIds(state) {
  const {
    activity: { bulkActionSelectedIds },
    lots: { entities: lotsMap }
  } = state;
  return bulkActionSelectedIds.reduce((dealershipIds, lotId) => {
    const lot = lotsMap[lotId];
    const dealershipId = lot?.winningBid?.dealershipId;
    if (dealershipId && !dealershipIds.includes(dealershipId)) {
      dealershipIds.push(dealershipId);
    }
    return dealershipIds;
  }, []);
}

export function getUserActivityTypeBy(pathname) {
  switch (pathname) {
    case PATHNAMES.OFFER:
    case PATHNAMES.PURCHASE:
      return USER_ACTIVITY_TYPE.PURCHASE;
    case PATHNAMES.BID:
      return USER_ACTIVITY_TYPE.BID;
    default:
      return null;
  }
}
