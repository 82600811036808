import { withStyles } from "tss-react/mui";
import { getLotCount } from "hooks/event/useGetCounts";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";

const styles = theme => ({
  card: {
    textAlign: "left",
    marginRight: theme.spacing(3),
    marginLeft: theme.spacing(3),
    width: "250px",
    height: "250px",
    boxShadow: theme.shadows[6],
    transition: "0.3s",
    "&:hover": {
      boxShadow: theme.shadows[12]
    },
    [theme.breakpoints.down("md")]: {
      margin: "30px auto auto auto"
    }
  },
  container: {
    padding: "2px 30px"
  },
  title: {
    color: theme.palette.secondary.main
  },
  calendarImg: {
    paddingTop: 15,
    paddingLeft: 30,
    position: "relative",
    backgroundSize: "contain",
    width: 100,
    height: 75
  },
  date: {
    position: "absolute",
    left: 47,
    color: "white"
  },
  vehicles: {
    color: theme.palette.secondary.main,
    textDecoration: "none"
  }
});

const AuctionCard = ({ classes, companyName, eventId, eventStart, date, sales }) => {
  const lots = getLotCount(sales);

  return (
    <div className={classes.card}>
      <div className={classes.calendarImg}>
        <h1 className={classes.date}>{date.toString().padStart(2, "0")}</h1>
        <img
          src={`${process.env.PUBLIC_URL}/images/calendarImg.png`}
          alt=""
          width="100px"
          height="75px"
        />
      </div>
      <div className={classes.container}>
        <h3 className={classes.title}>{companyName} </h3>
        <h4>{eventStart}</h4>
        <Typography
          component={Link}
          to={`/inventory/event/${eventId}`}
          className={classes.vehicles}
        >
          {lots} Vehicles
        </Typography>
      </div>
    </div>
  );
};

export default withStyles(AuctionCard, styles);
