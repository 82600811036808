import {
  COMPANIES_GET,
  COMPANIES_SET,
  SET_COMPANY_CONFIGS,
  SET_COMPANY_LOCATIONS
} from "actions/actionTypes";
import {
  getCompanies as getCompaniesFromApi,
  getCompanyConfigurations,
  getCompanyLocations
} from "api/companyRequests";

export function getCompanies() {
  return async (dispatch, getState) => {
    dispatch({ type: COMPANIES_GET });
    try {
      let companies = await getCompaniesFromApi();
      dispatch(setCompanies(companies));
      return companies;
    } catch (error) {
      return [];
      //TODO: handle error
    }
  };
}

export function setCompanies(companies) {
  return {
    type: COMPANIES_SET,
    payload: {
      companies
    }
  };
}

export function fetchCompanyConfigurations() {
  return async dispatch => {
    const configs = await getCompanyConfigurations();
    dispatch({
      type: SET_COMPANY_CONFIGS,
      payload: {
        configs
      }
    });
  };
}

export function fetchCompanyLocations() {
  return async dispatch => {
    const locations = await getCompanyLocations();
    dispatch({
      type: SET_COMPANY_LOCATIONS,
      payload: {
        locations
      }
    });
  };
}
