import {
  USER_LOGIN_ATTEMPT,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAILURE,
  USER_LOGOUT,
  USER_GUEST_LOGIN,
  USER_UPDATE,
  USER_UPDATE_FAILED,
  USER_GET_PERMISSIONS,
  USER_SET_PERMISSIONS,
  USER_RESET_PASSWORD_ATTEMPT,
  USER_RESET_PASSWORD_SUCCESS,
  USER_RESET_PASSWORD_FAILURE,
  USER_REGISTRATION_ATTEMPT,
  USER_REGISTRATION_SUCCESS,
  USER_REGISTRATION_FAILURE,
  USER_UPDATE_REQUEST,
  USER_SET_MEDIA,
  USER_CHANGE_EMAIL_REQUEST,
  USER_CHANGE_EMAIL_REQUEST_COMPLETE,
  SERVER_APP_STATE_REHYDRATE,
  USER_SET_LAST_DEALERSHIP_SYNC_MESSAGE,
  SET_REDIRECT_URL
} from "actions/actionTypes";
import { REHYDRATE } from "redux-persist/lib/constants";
import { createSelector } from "@reduxjs/toolkit";

const initialState = {
  isAuthorized: false,
  user: {},
  isLoading: false,
  isGuest: true,
  arePermissionsLoading: true,
  permissions: [],
  appPermissions: [],
  media: null,
  error: null,
  redirectUrl: null
};

export default function user(state = initialState, action) {
  switch (action.type) {
    case SERVER_APP_STATE_REHYDRATE: {
      const user = action.payload.user;
      if (user) {
        return {
          ...state,
          ...user
        };
      }
      return state;
    }
    case REHYDRATE:
      if (action.payload) {
        return {
          ...state,
          ...action.payload.user,
          error: null
        };
      } else {
        return {
          ...state
        };
      }
    case USER_LOGIN_ATTEMPT:
      return {
        ...state,
        isLoading: true
      };

    case USER_LOGIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isAuthorized: true,
        isGuest: false,
        user: action.payload.user,
        media: action.payload.media,
        error: null
      };

    case USER_LOGIN_FAILURE:
      return {
        ...state,
        isLoading: false,
        isAuthorized: false,
        error: action.payload.error
      };

    case USER_LOGOUT:
      return {
        ...state,
        isLoading: false,
        isAuthorized: false,
        isGuest: true,
        user: {}
      };

    case USER_GUEST_LOGIN:
      return {
        ...state,
        isLoading: false,
        isAuthorized: false,
        isGuest: true,
        user: action.payload.user
      };

    case USER_UPDATE_REQUEST:
      return {
        ...state,
        isLoading: true
      };

    case USER_UPDATE:
      return {
        ...state,
        user: action.payload.user,
        isLoading: false
      };

    case USER_UPDATE_FAILED:
      return {
        ...state,
        isLoading: false
      };

    case USER_GET_PERMISSIONS:
      return {
        ...state,
        arePermissionsLoading: true
      };

    case USER_SET_PERMISSIONS:
      return {
        ...state,
        arePermissionsLoading: false,
        permissions: action.payload.permissions,
        appPermissions: action.payload.appPermissions
      };

    case USER_CHANGE_EMAIL_REQUEST:
    case USER_RESET_PASSWORD_ATTEMPT:
      return {
        ...state,
        isLoading: true
      };

    case USER_RESET_PASSWORD_FAILURE:
      return {
        ...state,
        isLoading: false
      };

    case USER_CHANGE_EMAIL_REQUEST_COMPLETE:
    case USER_RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoading: false
      };

    case USER_REGISTRATION_ATTEMPT:
      return {
        ...state,
        isLoading: true
      };

    case USER_REGISTRATION_SUCCESS: {
      return {
        ...state,
        isLoading: false
      };
    }

    case USER_REGISTRATION_FAILURE:
      return {
        ...state,
        isLoading: false
      };

    case USER_SET_MEDIA:
      return {
        ...state,
        media: { ...state.media, ...action.payload.media }
      };

    case USER_SET_LAST_DEALERSHIP_SYNC_MESSAGE:
      return {
        ...state,
        user: {
          ...state.user,
          lastDealershipSyncMessage: action.payload || null
        }
      };

    case SET_REDIRECT_URL:
      return {
        ...state,
        redirectUrl: action.payload.url
      };

    default:
      return state;
  }
}

export const getError = state => state.error;
export const getPermissions = state => state.permissions;
export const getAppPermissions = state => state.appPermissions;
export const getCombinedPermissions = createSelector(
  getPermissions,
  getAppPermissions,
  (permissions, appPermissions) => [...permissions, ...appPermissions]
);
export const shouldResendActivationEmail = createSelector(
  getError,
  error => error && error.response && error.response.data.toLowerCase().includes("inactive")
);
export const isDuplicateUserError = error =>
  error?.response?.data?.toLowerCase().includes("user already exists") ||
  error?.toLowerCase?.().includes("user already exists");

export function isNotUsaOrCanada(countryCode) {
  return !(countryCode === "US" || countryCode === "CA");
}
