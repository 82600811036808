import React from "react";
import Fab from "@mui/material/Fab";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import RefreshIcon from "@mui/icons-material/Refresh";
import { withStyles } from "tss-react/mui";
import PropTypes from "prop-types";
import { history } from "store";

const styles = theme => ({
  buttons: {
    display: "flex",
    padding: [[theme.spacing(3), theme.spacing(3), 0]]
  },
  button: {
    margin: [[0, theme.spacing(1)]]
  }
});

export const goBack = () => history.goBack();

export const refresh = () => window.location.reload();

const NavFabs = ({ classes, shouldRefreshDisplay, clearError = () => null }) => (
  <div className={classes.buttons}>
    <Fab
      color="primary"
      className={classes.button}
      onClick={() => {
        clearError();
        goBack();
      }}
    >
      <ArrowBackIcon />
    </Fab>
    {shouldRefreshDisplay && (
      <Fab color="primary" className={classes.button} onClick={refresh}>
        <RefreshIcon />
      </Fab>
    )}
  </div>
);

NavFabs.defaultProps = {
  shouldRefreshDisplay: true
};

NavFabs.propTypes = {
  classes: PropTypes.object.isRequired,
  shouldRefreshDisplay: PropTypes.bool.isRequired
};

export default withStyles(NavFabs, styles);
