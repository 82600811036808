import { makeStyles } from "tss-react/mui";
import { Grid, Link, Hidden, Typography } from "@mui/material";

import GoNative from "services/gonative";
import useLanguage from "hooks/useLanguage";
import { useSelector } from "utils/StoreUtil";

const useStyles = makeStyles<{ backgroundUrl: string }>()((theme, { backgroundUrl }) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[1],
    borderRadius: 5,
    Height: 408,
    flexGrow: 1,
    overflow: "hidden"
  },
  bannerLogo: {
    maxWidth: 180
  },
  textHeader: {
    fontSize: "1.5rem",
    lineHeight: "1.5",
    fontWeight: 400,
    textAlign: "left"
  },
  textContent: {
    display: "flex",
    flexWrap: "wrap"
  },
  appBadge: {
    width: "100%",
    maxWidth: "200px"
  },
  imageWrapper: {
    maxHeight: 408,
    padding: 0
  },
  image: {
    objectFit: "cover",
    height: "100%",
    width: "100%"
  },
  contentWrapper: {
    minHeight: 408,
    textAlign: "left",
    padding: theme.spacing(2),
    position: "relative"
  },
  contentBackground: {
    "&::before": {
      backgroundImage: `url( ${backgroundUrl})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      opacity: 0.25,
      content: '""',
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      height: 408,
      zIndex: 0
    }
  }
}));

interface IMobileBannerProps {
  appAndroidUrl?: string;
  appAppleUrl?: string;
  backgroundUrl?: string;
}

const AppBadge = (
  linkUrl: string | null | undefined,
  imgUrl: string,
  imgClass: string
): JSX.Element | void => {
  if (linkUrl) {
    return (
      <Grid item xs={6} sm={6} md={6} zIndex={1}>
        <Link underline="none" href={linkUrl}>
          <img src={imgUrl} alt="Download App" className={imgClass} />
        </Link>
      </Grid>
    );
  }
};

const CONFIG_KEY = "ctaBannerContent";

const MobileAppBanner = (props: IMobileBannerProps): JSX.Element => {
  const { appAndroidUrl, appAppleUrl, backgroundUrl = "" } = props;

  const baseUrl = process.env.PUBLIC_URL;
  const bannerLogoUrl = `${baseUrl}/images/OnlineRingmanLogo.png`;
  const appleBadgeUrl = `${baseUrl}/images/blackDownloadiOS.png`;
  const androidBadgeUrl = `${baseUrl}/images/googlePlayBadge.png`;

  const [ctaBannerContent] = useLanguage(CONFIG_KEY);

  const isMobile = useSelector(state => state.viewport.isMobile);

  const { classes } = useStyles({ backgroundUrl });

  return (
    <Grid container className={classes.root} direction="row" justifyContent="space-between">
      <Grid item padding={0} md={backgroundUrl ? 6 : 12}>
        <Grid
          container
          spacing={0}
          direction="column"
          justifyContent="space-around"
          alignItems="stretch"
          className={classes.contentWrapper}
        >
          <Grid item className={isMobile && backgroundUrl ? classes.contentBackground : ""}>
            <Grid item>
              <img src={bannerLogoUrl} alt="" className={classes.bannerLogo} />
            </Grid>
          </Grid>

          <Grid item>
            <Typography className={classes.textHeader} my={2}>
              {ctaBannerContent === CONFIG_KEY ? (
                <>
                  Access auctions from <strong>your pocket</strong>
                </>
              ) : (
                <> {ctaBannerContent} </>
              )}
            </Typography>
          </Grid>

          {!GoNative.IsGoNative() && (
            <Grid item mt={2}>
              <Grid
                container
                spacing={2}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <>
                  {AppBadge(appAppleUrl, appleBadgeUrl, classes.appBadge)}

                  {AppBadge(appAndroidUrl, androidBadgeUrl, classes.appBadge)}
                </>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>

      <Hidden mdDown>
        {backgroundUrl && (
          <Grid item className={classes.imageWrapper} md={6}>
            <img src={backgroundUrl} alt="" className={classes.image} />
          </Grid>
        )}
      </Hidden>
    </Grid>
  );
};

export default MobileAppBanner;
