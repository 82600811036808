import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "utils/StoreUtil";

import { openDialogWithProps } from "actions/dialogActions";
import { DIALOG_CONTENT_TYPE } from "utils/constants";

const STORAGE_KEY = "loginPrompt";

function useLoginPrompt() {
  const dispatch = useDispatch();

  const timerRef = useRef<number | null>(null);

  const isGuest = useSelector(state => state.user.isGuest);

  const delay = useSelector(state => state.config.loginPromptDelayInMilliseconds);
  const useExternalLogin = useSelector(state => state.config.useExternalLogin);

  const hasPrompted = sessionStorage.getItem(STORAGE_KEY) === "true";

  const enabled = delay && delay > 0 && !hasPrompted && !useExternalLogin;

  const { pathname } = useLocation();

  useEffect(() => {
    if (!isGuest && timerRef.current) {
      window.clearTimeout(timerRef.current);
      timerRef.current = null;
    }

    if (isGuest && enabled) {
      if (pathname.startsWith("/inventory")) {
        if (!timerRef.current) {
          timerRef.current = window.setTimeout(() => {
            sessionStorage.setItem(STORAGE_KEY, "true");
            dispatch(openDialogWithProps(DIALOG_CONTENT_TYPE.LOGIN_PROMPT, {}));
            timerRef.current = null;
          }, delay);
        }
      } else if (pathname.startsWith("/login") || pathname.startsWith("/register")) {
        if (timerRef.current) {
          window.clearTimeout(timerRef.current);
          timerRef.current = null;
        }
      }
    }
  }, [dispatch, delay, enabled, isGuest, pathname]);
}

const LoginPrompt: React.FC = () => {
  useLoginPrompt();
  return <div />;
};

export { LoginPrompt as default, LoginPrompt };
