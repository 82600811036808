import React, { PureComponent } from "react";
import { connect } from "utils/StoreUtil";
import isEqual from "lodash/isEqual";
import { getDataFromLandingPage } from "actions/landingPageActions";
import { compose } from "@reduxjs/toolkit";
import { salesSelector } from "reducers/sales";
import { eventsSelector } from "reducers/events";

const requiresInitialization = Component => props => {
  if (isEqual(props.user, {}) || props.companies.length <= 0) {
    return null;
  }
  return <Component {...props} />;
};

class ConnectedUpcomingAuctions extends PureComponent {
  componentDidMount() {
    this.props.getDataFromLandingPage();
  }
  render() {
    const { render, ...props } = this.props;
    return <React.Fragment>{render(props)}</React.Fragment>;
  }
}

const mapStateToProps = ({ events, sales, companies: { companies }, user: { user } }) => ({
  isEventLoading: events.isLoading,
  user,
  companies,
  sales: salesSelector.selectAll(sales),
  events: eventsSelector.selectAll(events),
  total: events.total
});

export default compose(
  connect(mapStateToProps, { getDataFromLandingPage }),
  requiresInitialization
)(ConnectedUpcomingAuctions);
