import React from "react";
import { makeStyles } from "tss-react/mui";
import { useSelector } from "utils/StoreUtil";

const useStyles = makeStyles()(theme => ({
  root: {
    display: "flex"
  }
}));

function PrintHeader({ increment }) {
  const { classes } = useStyles();
  const companyId = useSelector(state => state.user.user.companyId);
  const company = useSelector(state =>
    state.companies.companies.find(company => company.id === companyId)
  );

  return (
    <div className={classes.root}>
      <img
        src={`/media/${company?.mediaId}?size=medium`}
        alt={company?.name ? `${company.name} Logo` : "Logo"}
        height={70}
        onLoad={increment}
        onError={increment}
      />
    </div>
  );
}

export default React.memo(PrintHeader);
