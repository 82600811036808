import { useSelector } from "utils/StoreUtil";
import useComet from "hooks/sale/useComet";

function useNotificationCount() {
  const userStatsId = useSelector(state => state.user.user.userStatsId);
  const newNotificationCount = useComet(
    "userStats",
    userStatsId,
    userStats => userStats.newNotificationCount
  );

  return newNotificationCount;
}

export { useNotificationCount as default };
