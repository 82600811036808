import React from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import { useSelector } from "utils/StoreUtil";
import { Table, TableHead, TableBody, TableRow, TableCell, Typography } from "@mui/material";
import { getLanguageMap } from "reducers/language";
import { handleLabelConversions } from "utils/LanguageUtil";
import { getWeightWithUnitType, handleAnyConversions } from "utils/AssetUtil";
import camelCase from "lodash/camelCase";

function SegmentRow({ inventoryItem, displayFields }) {
  const companyName = useSelector(
    state =>
      state.companies.companies.find(company => company.id === inventoryItem.asset.companyId)?.name
  );
  const locale = useSelector(state => state.user.user.locale);

  const options = {
    isYear: inventoryItem.asset?.fields?.dateOfBirthYearOnly,
    locale
  };

  return (
    <TableRow>
      {displayFields.map(field => {
        let value;

        if (camelCase(field.label) === "averageWeight") {
          value = getWeightWithUnitType(inventoryItem?.asset, "averageWeight");
        } else {
          value = handleAnyConversions(field.label, get(inventoryItem, field.path, null), options);
        }
        return <TableCell key={field.label}>{value || "-"}</TableCell>;
      })}
      <TableCell>{companyName || "-"}</TableCell>
    </TableRow>
  );
}

function SegmentTable({ inventory, displayFields, title, isMultipleSchemasPresent }) {
  const languageMap = useSelector(state => getLanguageMap(state));

  return (
    <>
      {isMultipleSchemasPresent && <Typography>{title}</Typography>}
      <Table size="small" padding="none">
        <TableHead>
          <TableRow>
            {displayFields
              .map(field => {
                field.label = handleLabelConversions(field.label, languageMap);
                return field;
              })
              .map(field => (
                <TableCell key={field.label}>{field.label}</TableCell>
              ))}
            <TableCell>Auction House</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {inventory.map((inventoryItem, i) => (
            <SegmentRow key={i} inventoryItem={inventoryItem} displayFields={displayFields} />
          ))}
        </TableBody>
      </Table>
    </>
  );
}

SegmentTable.propTypes = {
  inventory: PropTypes.arrayOf(PropTypes.shape({ lot: PropTypes.object, asset: PropTypes.object })),
  displayFields: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string, path: PropTypes.string })
  ).isRequired,
  title: PropTypes.string,
  isMultipleSchemasPresent: PropTypes.bool
};

export default SegmentTable;
