import { useSelector } from "utils/StoreUtil";

import { eventsSelector } from "reducers/events";
import { Media } from "types/Media";
import { getEntityMediaKey } from "reducers/media";
import { COMPANY_DEFAULT_MEDIA, ENTITY_TYPE } from "utils/constants";

function useCompanyLogoUrl(eventId: number) {
  const event = useSelector(state => eventsSelector.selectById(state.events, eventId));

  const companyMedias: Array<Media> = useSelector(
    state => state.media.entityMedia[getEntityMediaKey(event?.companyId, ENTITY_TYPE.COMPANY)]
  );

  const logoMediaName = COMPANY_DEFAULT_MEDIA.LOGO;
  const companyLogo = companyMedias?.find(media => media.name === logoMediaName);
  const companyLogoUrl = companyLogo?.urls?.large;

  return companyLogoUrl;
}

export default useCompanyLogoUrl;
