import { createSlice, createEntityAdapter } from "@reduxjs/toolkit";
import { REHYDRATE } from "redux-persist/lib/constants";

import { CALENDAR_FETCH_COMPLETE } from "actions/actionTypes";

const NAME = "integrations";

const integrationsAdapter = createEntityAdapter({
  selectId: integration => integration.companyId
});

const integrations = createSlice({
  name: NAME,
  initialState: integrationsAdapter.getInitialState(),
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(REHYDRATE, () => integrationsAdapter.getInitialState())
      .addCase(CALENDAR_FETCH_COMPLETE, (state, action) => {
        const integrations = action.payload[NAME] || [];
        integrationsAdapter.setAll(state, integrations);
      });
  }
});

const { reducer } = integrations;
export default reducer;

export const integrationsSelector = integrationsAdapter.getSelectors(state => state[NAME]);

export const directedIntegrationsSelector = integrationsAdapter.getSelectors();
