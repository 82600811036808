import React from "react";
import { withStyles } from "tss-react/mui";
import CircularProgress from "@mui/material/CircularProgress";
import { formatDateTimeZone } from "utils/DateUtil";
import { useSelector } from "utils/StoreUtil";
import AuctionCard from "./AuctionCard";
import UpcomingAuctionsText from "./UpcomingAuctionsText";

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up("lg")]: {
      justifyContent: "center"
    },
    [theme.breakpoints.down("lg")]: {
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(2)
    }
  },
  progressWrapper: {
    justifyContent: "center",
    alignItems: "center",
    width: "100%"
  },
  auctionsWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "wrap"
  }
});

const UpcomingAuctions = ({
  classes,
  isEventLoading,
  companies,
  events,
  sales,
  focusOnLogin,
  total
}) => {
  const locale = useSelector(state => state.user.user.locale);
  return (
    <div className={classes.root}>
      <div>
        <UpcomingAuctionsText total={total} />
      </div>
      {isEventLoading ? (
        <div className={classes.progressWrapper}>
          <CircularProgress />
        </div>
      ) : (
        <>
          <div className={classes.auctionsWrapper}>
            {events.map(event => {
              let company = companies.find(company => event.companyId === company.id);
              const date = new Date(event.startTime);
              const day = date.getDate();
              const filteredSales = sales?.filter(sale => sale.eventId === event.id);
              return (
                <AuctionCard
                  key={event?.id}
                  focusOnLogin={focusOnLogin}
                  companyName={company?.name}
                  eventId={event?.id}
                  sales={filteredSales}
                  eventStart={formatDateTimeZone(event?.startTime, locale)}
                  date={day}
                />
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};

export default withStyles(UpcomingAuctions, styles);
