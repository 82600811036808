import { REHYDRATE } from "redux-persist/lib/constants";
import {
  ADMIN_EVENTS_SET_SORT_BY,
  ADMIN_EVENTS_SET_SALE_TYPE,
  ADMIN_TOGGLE_EVENTS_RUNNING_FILTER,
  ADMIN_TOGGLE_EVENTS_CLOSED_FILTER,
  ADMIN_SET_SALES,
  ADMIN_TOGGLE_SALES_ID_FILTER,
  ADMIN_CLEAR_SALES_ID_FILTER,
  ADMIN_SCHEDULING_LIVE_LEGACY_SALE,
  ADMIN_ERROR_SCHEDULING_LIVE_LEGACY_SALE,
  ADMIN_UPDATE_SALE,
  ADMIN_RUN_EVENT_COMPARE_SCRIPT,
  ADMIN_SET_EVENT_COMPARE_RESULT,
  ADMIN_SYNC_SYSTEMS,
  ADMIN_EVENTS_SET_PAGE,
  ADMIN_EVENTS_SET_COMPANIES
} from "actions/actionTypes";
import { createRangeFilter, createTermsFilter } from "utils/SearchUtil";
import moment from "moment-timezone";

const initialState = {
  sales: [],
  eventFilters: [
    createRangeFilter(
      "endTime",
      moment()
        .startOf("day")
        .valueOf(),
      "GTE",
      "SHOULD"
    )
  ],
  saleFilters: [],
  saleSorts: [],
  selectedCompanyIds: [],
  eventCompareResults: {},
  areSalesLoading: true,
  areEventCompareResultsLoading: true,
  isEventCompareSyncingSystems: false,
  sortBy: "+startTime",
  saleType: null,
  total: 0,
  page: 0
};

export default function adminEvents(state = initialState, action) {
  switch (action.type) {
    case REHYDRATE:
      if (action.payload) {
        return {
          ...state,
          ...action.payload.adminEvents,
          sales: [],
          eventCompareResults: {},
          areSalesLoading: true,
          areEventCompareResultsLoading: true,
          isEventCompareSyncingSystems: false
        };
      } else {
        return {
          ...state
        };
      }

    case ADMIN_EVENTS_SET_COMPANIES:
      return {
        ...state,
        selectedCompanyIds: action.payload.selectedCompanyIds || []
      };

    case ADMIN_EVENTS_SET_SORT_BY:
      return {
        ...state,
        sortBy: action.payload.sortBy
      };

    case ADMIN_EVENTS_SET_SALE_TYPE:
      return {
        ...state,
        saleType: action.payload.saleType
      };

    case ADMIN_TOGGLE_EVENTS_RUNNING_FILTER:
      return {
        ...state,
        eventFilters: isRunningFilterSet(state.eventFilters)
          ? state.eventFilters.filter(filter => filter !== getRunningFilter(state.eventFilters))
          : [...state.eventFilters, createRunningFilter()]
      };

    case ADMIN_TOGGLE_EVENTS_CLOSED_FILTER:
      return {
        ...state,
        eventFilters: isClosedFilterSet(state.eventFilters)
          ? state.eventFilters.filter(filter => filter !== getClosedFilter(state.eventFilters))
          : [...state.eventFilters, createClosedFilter()]
      };

    case ADMIN_UPDATE_SALE:
      return {
        ...state,
        schedulingSaleId: null
      };

    case ADMIN_SCHEDULING_LIVE_LEGACY_SALE:
      return {
        ...state,
        schedulingSaleId: action.payload.saleId
      };

    case ADMIN_ERROR_SCHEDULING_LIVE_LEGACY_SALE:
      return {
        ...state,
        schedulingSaleId: null
      };

    case ADMIN_SET_SALES:
      if (saleFiltersMatchSales(action.payload.sales, state.saleFilters)) {
        return {
          ...state,
          sales: filterSalesBySaleFilterValues(action.payload.sales, state.saleFilters),
          areSalesLoading: false
        };
      }
      return {
        ...state,
        sales: action.payload.sales,
        saleFilters: removeSaleIdFilters(state.saleFilters),
        areSalesLoading: false
      };

    case ADMIN_TOGGLE_SALES_ID_FILTER: {
      const saleId = action.payload.saleId;
      return {
        ...state,
        saleFilters: isSaleIdFilterSet(saleId, state.saleFilters)
          ? removeSaleIdFilter(saleId, state.saleFilters)
          : addSaleIdFilter(saleId, state.saleFilters)
      };
    }

    case ADMIN_CLEAR_SALES_ID_FILTER:
      return {
        ...state,
        saleFilters: []
      };

    case ADMIN_RUN_EVENT_COMPARE_SCRIPT:
      return {
        ...state,
        areEventCompareResultsLoading: true,
        isEventCompareSyncingSystems: false
      };

    case ADMIN_SYNC_SYSTEMS:
      return {
        ...state,
        areEventCompareResultsLoading: false,
        isEventCompareSyncingSystems: true
      };

    case ADMIN_SET_EVENT_COMPARE_RESULT:
      return {
        ...state,
        eventCompareResults: action.payload.eventCompareResults,
        areEventCompareResultsLoading: false,
        isEventCompareSyncingSystems: false
      };

    case ADMIN_EVENTS_SET_PAGE:
      return {
        ...state,
        page: action.payload.page
      };

    default:
      return state;
  }
}

//TODO: update all of these to use filters ref instead of state directly
export const isRunningFilterSet = eventFilters => Boolean(getRunningFilter(eventFilters));

const getRunningFilter = eventFilters =>
  eventFilters.find(filter => filter.field === "endTime" && filter.equalityType === "GTE");

export const createRunningFilter = () =>
  createRangeFilter(
    "endTime",
    moment()
      .startOf("day")
      .valueOf(),
    "GTE",
    "SHOULD"
  );

export const isClosedFilterSet = eventFilters => Boolean(getClosedFilter(eventFilters));

const getClosedFilter = eventFilters =>
  eventFilters.find(filter => filter.field === "endTime" && filter.equalityType === "LTE");

const createClosedFilter = () =>
  createRangeFilter(
    "endTime",
    moment()
      .startOf("day")
      .valueOf(),
    "LTE",
    "SHOULD"
  );

export const filterSalesBySaleFilterValues = (sales, saleFilters) => {
  const saleIdFilter = getSaleIdFilter(saleFilters);
  if (saleIdFilter) {
    return sales.filter(sale => saleIdFilter.values.includes(sale.id));
  }
  return sales;
};

export const isSaleIdFilterSet = (saleId, saleFilters) => {
  if (saleFilters.length <= 0) {
    return false;
  }
  const saleIdFilter = getSaleIdFilter(saleFilters);
  if (saleIdFilter == null) {
    return false;
  }
  return saleIdFilter.values.includes(saleId);
};

const isSaleIdFilter = filter => filter.filterType === "TERMS" && filter.field === "id";

const getSaleIdFilter = saleFilters => saleFilters.find(isSaleIdFilter);

const removeSaleIdFilters = saleFilters =>
  saleFilters.filter(filter => filter !== getSaleIdFilter(saleFilters));

const removeSaleIdFilter = (saleId, saleFilters) => {
  let saleIdFilter = getSaleIdFilter(saleFilters);
  saleIdFilter.values = saleIdFilter.values.filter(filterSaleId => filterSaleId !== saleId);
  if (saleIdFilter.values <= 0) {
    return removeSaleIdFilters(saleFilters);
  } else {
    return saleFilters.map(filter => {
      if (filter === getSaleIdFilter(saleFilters)) {
        filter = saleIdFilter;
      }
      return filter;
    });
  }
};

const addSaleIdFilter = (saleId, saleIdFilters) => {
  let saleIdFilter = getSaleIdFilter(saleIdFilters);
  if (saleIdFilter) {
    saleIdFilter.values = [...saleIdFilter.values, saleId];
  } else {
    saleIdFilter = createSaleIdFilter(saleId);
  }
  return [...saleIdFilters.filter(filter => !isSaleIdFilter(filter)), saleIdFilter];
};

const createSaleIdFilter = saleId => createTermsFilter("id", [saleId]);

const saleFiltersMatchSales = (sales, saleFilters) => {
  const saleIdFilter = getSaleIdFilter(saleFilters);
  if (saleIdFilter == null) return true;
  const saleIds = sales.map(sale => sale.id);
  return saleIdFilter.values.every(saleId => saleIds.includes(saleId));
};

export const getSaleCompareResult = (eventCompareResults, eventId, saleNumber) => {
  if (eventCompareResults.result && String(eventCompareResults.eventId) === eventId) {
    return eventCompareResults.result.reduce(function(saleCompareResult, result) {
      if (String(result.lane) === saleNumber) {
        return saleCompareResult + 1;
      }
      return saleCompareResult;
    }, 0);
  }
  return null;
};

export const prepareSaleForUpload = sale => {
  const { saleUserCount, ...saleProps } = sale;
  return { ...saleProps };
};

export function filterSaleFromSaleFilters(saleFilters = []) {
  return sale => {
    const saleIdFilter = saleFilters.find(saleFilter => saleFilter.field === "id");
    if (saleIdFilter) {
      return saleIdFilter.values.includes(sale.id);
    } else {
      return true;
    }
  };
}
