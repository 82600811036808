import { createReducer } from "@reduxjs/toolkit";
import { REHYDRATE } from "redux-persist/lib/constants";
import {
  COMPANIES_GET,
  COMPANIES_SET,
  SET_COMPANY_CONFIGS,
  SET_COMPANY_LOCATIONS
} from "actions/actionTypes";
import { createSelector } from "@reduxjs/toolkit";
import uniqBy from "lodash/uniqBy";
import sortBy from "lodash/sortBy";
import { getValue } from "./config";
import { toMap } from "utils/CollectionUtil";

const initialState = {
  companies: [],
  areCompaniesLoading: true,
  configs: {},
  locations: {}
};

const companiesReducer = createReducer(initialState, builder => {
  builder
    .addCase(REHYDRATE, (state, action) => {
      if (action.payload) {
        state = { ...state, ...action.payload.companies };
      }
    })
    .addCase(COMPANIES_GET, state => {
      state.areCompaniesLoading = true;
    })
    .addCase(COMPANIES_SET, (state, action) => {
      state.areCompaniesLoading = false;
      state.companies = action.payload.companies;
    })
    .addCase(SET_COMPANY_CONFIGS, (state, action) => {
      state.configs = action.payload.configs.reduce((acc, config) => {
        acc[config.companyId] = {};

        config.configurationProperties.forEach(configProperty => {
          acc[config.companyId][configProperty.name] = getValue(configProperty);
        });

        return acc;
      }, {});
    })
    .addCase(SET_COMPANY_LOCATIONS, (state, action) => {
      state.locations = action.payload.locations.reduce(toMap("companyId"), {});
    });
});

export default companiesReducer;

export const getCompanyById = (companies, companyId) =>
  companies.find(company => company.id === companyId);

export const getCompanyIds = (companies = []) => companies.map(company => company.id);

export function buildCompaniesMap(companies) {
  return companies.reduce((companiesMap, company) => {
    companiesMap[company.id] = company;
    return companiesMap;
  }, {});
}

const getChildren = (companies = [], parentId) =>
  companies.filter(company => company.parentCompanyIds.includes(parentId));

const getCompanies = (state, props) => state.companies.companies;

const getIsParent = (state, props = {}) => props.isParent;

const getCompaniesFiltered = createSelector(
  getCompanies,
  getIsParent,
  (companies, isParent = false) => {
    if (isParent) return companies;
    return getNonParentCompanies(companies);
  }
);

export const buildCompaniesWithChildren = createSelector(getCompaniesFiltered, companies =>
  companies.reduce((companiesMap, company) => {
    let children = getChildren(companies, company.id);
    companiesMap[company.id] = {
      ...company,
      children
    };
    return companiesMap;
  }, {})
);

export const buildChildCompanies = createSelector(buildCompaniesWithChildren, companiesMap => {
  const companies = uniqBy(
    Object.values(companiesMap).reduce(
      (childCompanies, company) => [...childCompanies, ...company.children, company],
      []
    ),
    "id"
  );
  return sortBy(getChildCompanies(companies), "name");
});

function getParentCompanyIds(companies) {
  return companies.reduce((ids, company) => [...ids, ...company.parentCompanyIds], []);
}

export function getChildCompanies(companies = []) {
  const parentIds = getParentCompanyIds(companies);
  return companies.filter(company => !parentIds.includes(company.id));
}

export function getSortedChildCompanies(companies) {
  return sortBy(getChildCompanies(companies), "name");
}

export const getSortedChildCompaniesSelector = createSelector(
  getCompanies,
  getSortedChildCompanies
);

export const getSortedCompanies = (companies = []) => sortBy(companies, "name");

export function getChildCompanyIds(companies) {
  const parentIds = getParentCompanyIds(companies);
  return companies.reduce((companyIds, company) => {
    if (!parentIds.includes(company.id)) {
      companyIds.push(company.id);
    }
    return companyIds;
  }, []);
}

export const getNonParentCompanies = companies => companies.filter(company => !company.isParent);

const getMemberships = state => state.memberships.memberships;

function getUserCompanyId(state) {
  return state.user.user.companyId;
}

export const buildMembershipCompanies = createSelector(
  getCompanies,
  getMemberships,
  getUserCompanyId,
  (companies, memberships, userCompanyId) => {
    const mappedCompanies = companies.reduce(toMapProp("id"), {});
    return memberships
      .filter(
        membership =>
          membership.companyId === userCompanyId ||
          (mappedCompanies[membership.companyId] &&
            mappedCompanies[membership.companyId].parentCompanyIds.includes(userCompanyId)) ||
          mappedCompanies[userCompanyId].parentCompanyIds.length === 0
      )
      .reduce((memberships, membership) => {
        const company = mappedCompanies[membership.companyId];
        if (company) {
          const parentId = company.parentCompanyIds[0];
          const parentCompany = mappedCompanies[parentId];
          const isParentCommunity = parentCompany && parentCompany.parentCompanyIds.length === 0;
          if (parentCompany && !isParentCommunity) {
            if (memberships[parentId]) {
              memberships[parentId].children = [...memberships[parentId].children, company];
            } else {
              memberships[parentId] = {
                ...parentCompany,
                children: [company]
              };
            }
          } else {
            memberships[membership.companyId] = {
              children: [company],
              ...company
            };
          }
        }
        return memberships;
      }, {});
  }
);

export const buildCompanies = createSelector(getCompanies, companies => {
  const mappedCompanies = companies.reduce(toMapProp("id"), {});
  return companies.reduce((acc, company) => {
    const children = getChildren(companies, company.id);
    const hasChildren = children.length > 0;
    const parentId = company.parentCompanyIds[0];
    const parentCompany = mappedCompanies[parentId];
    const isParentCommunity = parentCompany && parentCompany.parentCompanyIds.length === 0;

    if (!hasChildren) {
      if (isParentCommunity) {
        acc[company.id] = {
          ...company,
          children: [company]
        };
      } else {
        if (acc[parentId]) {
          acc[parentId].children = [...acc[parentId].children, company];
        } else {
          acc[parentId] = { ...parentCompany, children: [company] };
        }
      }
    }
    return acc;
  }, {});
});

export const toMapProp = prop => (accumulator, element) => {
  accumulator[element[prop]] = element;
  return accumulator;
};

export const getLocation = (locationsMap, companyId) => {
  return locationsMap?.[companyId];
};
