import jsBarcode from "jsbarcode";

export function barcodeUrl(value) {
  const options = {
    format: "CODE39",
    height: 16,
    width: 1,
    displayValue: false,
    margin: 0,
    marginLeft: 0,
    marginRight: 0,
    marginTop: 0,
    marginBottom: 0,
    background: "#fafafa"
  };
  let canvas = document.createElement("canvas");
  jsBarcode(canvas, value, options);
  let dataUrl = canvas.toDataURL("image/png");
  try {
    canvas.remove();
  } catch (error) {
    // IE11 fallback
    canvas = undefined;
  }
  return dataUrl;
}
