import { memo } from "react";
import NotificationIcon from "@mui/icons-material/Notifications";
import Badge from "@mui/material/Badge";
import Button from "@mui/material/Button";
import { makeStyles } from "tss-react/mui";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import useNotificationCount from "hooks/useNotificationCount";

const useStyles = makeStyles()(theme => ({
  icon: {},
  badge: {
    "& > svg": {
      height: theme.spacing(1.6),
      width: theme.spacing(1.6)
    }
  }
}));

const NotificationIndicator = props => {
  const {
    Icon = NotificationIcon,
    as = Button,
    displayBadgeOnly,
    customBadge,
    handleClick,
    buttonRef
  } = props;
  const Component = as;

  const { classes } = useStyles(undefined, {
    props
  });
  const notificationCount = useNotificationCount();

  const shouldDisableCount = notificationCount < 1;
  if (displayBadgeOnly) {
    return (
      <Badge
        color="secondary"
        badgeContent={customBadge ? <PriorityHighIcon /> : notificationCount || 0}
        classes={{ badge: classes.badge }}
        invisible={shouldDisableCount || !notificationCount}
      >
        <div />
      </Badge>
    );
  }
  return (
    <Component color="inherit" onClick={handleClick} ref={buttonRef}>
      <Badge
        color="secondary"
        classes={{ badge: classes.badge }}
        badgeContent={notificationCount || 0}
        invisible={shouldDisableCount || !notificationCount}
      >
        <Icon className={classes.icon} />
      </Badge>
    </Component>
  );
};

export default memo(NotificationIndicator);
