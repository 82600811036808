import { combineReducers } from "@reduxjs/toolkit";

import { USER_LOGOUT } from "actions/actionTypes";

import activity from "./activity";
import activityFilterOptions from "./activityFilterOptions";
import activityFilters from "./activityFilters";
import adminAttendees from "./admin/attendees";
import adminBidlogs from "./admin/bidlogs";
import adminConsignorReport from "./admin/consignorReport";
import adminEvents from "./admin/events";
import adminInventory from "./admin/inventory";
import ams from "./ams";
import announcements from "./announcements";
import assets from "./assets";
import attendance from "./attendance";
import attendeesChart from "./dashboard/attendeesChart";
import bids from "./bids";
import calendar from "./calendar";
import comet from "./comet";
import companies from "./companies";
import config from "./config";
import conditions from "./conditions";
import credit from "./credit";
import currency from "./currency";
import dashboard from "./dashboard/dashboard";
import dealerships from "./dealerships";
import dialog from "./dialog";
import disclosures from "./disclosures";
import drawer from "./drawer";
import editInventory from "./editInventory";
import events from "./events";
import eventManagement from "./admin/eventManagement";
import facilities from "./facilities";
import fees from "./fees";
import inspections from "./inspections";
import integrations from "./integrations";
import inventorySearchOptions from "./inventorySearchOptions";
import language from "./language";
import links from "./links";
import lots from "./lots";
import marketReport from "./marketReport";
import media from "./media";
import memberships from "./memberships";
import membershipSchemas from "./membershipSchemas";
import notes from "./notes";
import notificationGroups from "./notificationGroups";
import notifications from "./notifications";
import recentlyViewed from "./recentlyViewed";
import recentSearches from "./recentSearches";
import reports from "./reports";
import sales from "./sales";
import { salesApi } from "./salesApi";
import saleUsers from "./saleUsers";
import savedEmails from "./savedEmails";
import savedSearches from "./savedSearches";
import similarItems from "./similarItems";
import stats from "./stats";
import time from "./time";
import uiSchemas from "./uiSchemas";
import user from "./user";
import userDealershipDetails from "./userDealershipDetails";
import users from "./users";
import vehicles from "./vehicles";
import viewport from "./viewport";
import watches from "../features/watchlist/watchesReducer";

const appReducer = combineReducers({
  activity,
  activityFilters,
  activityFilterOptions,
  adminAttendees,
  adminBidlogs,
  adminConsignorReport,
  adminEvents,
  adminInventory,
  ams,
  announcements,
  assets,
  attendance,
  attendeesChart,
  bids,
  calendar,
  comet,
  companies,
  conditions: conditions.reducer,
  config,
  credit,
  currency,
  dashboard,
  dealerships: dealerships.reducer,
  dialog,
  disclosures,
  drawer,
  editInventory,
  eventManagement,
  events,
  facilities,
  fees,
  integrations,
  inspections,
  inventorySearchOptions,
  language,
  links,
  lots,
  marketReport,
  media,
  memberships,
  membershipSchemas,
  notes: notes.reducer,
  notifications,
  notificationGroups,
  recentlyViewed,
  recentSearches,
  reports,
  sales,
  [salesApi.reducerPath]: salesApi.reducer,
  saleUsers,
  savedEmails,
  savedSearches,
  similarItems,
  stats,
  time,
  uiSchemas,
  user,
  users,
  userDealershipDetails,
  vehicles,
  viewport,
  watches
});

const rootReducer = (state, action) => {
  if (action.type === USER_LOGOUT) {
    state = {
      config: state.config,
      language: state.language,
      viewport: state.viewport,
      time: state.time
    };
  }
  return appReducer(state, action);
};

export default rootReducer;
