import { memo, useEffect, useRef, useState, useCallback } from "react";
import { MenuItem, Button, ListItemIcon, ListItemText } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { alpha } from "@mui/material";
import { useSelector, useDispatch } from "utils/StoreUtil";
import DealershipMenuItem from "./DealershipMenuItem";
import PopperMenu from "./PopperMenu";
import useMediaQuery from "@mui/material/useMediaQuery";
import { getDealerships } from "actions/attendanceActions";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import get from "lodash/get";
import { getDealershipById } from "reducers/attendance";
import SwapVert from "@mui/icons-material/SwapVert";
import useLanguage from "hooks/useLanguage";

const useStyles = makeStyles()((theme, { offsetWidth }) => ({
  secondaryText: {
    textTransform: "capitalize"
  },
  popper: {
    zIndex: 10000,
    width: offsetWidth
  },
  paper: {
    maxHeight: 360,
    overflowX: "hidden",
    overflowY: "auto"
  },
  buttonRoot: {
    lineHeight: "48px",
    padding: "0",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    marginLeft: theme.spacing(2),
    textAlign: "left",
    width: "200px",
    color: theme.palette.getContrastText(theme.palette.company.accent),
    backgroundColor: theme.palette.company.accent,
    "&:hover": {
      backgroundColor: alpha(theme.palette.company.accent, 0.8)
    }
  },
  dealershipName: {
    width: "calc(100% - 25px)",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textAlign: "left",
    textOverflow: "ellipsis"
  },
  arrowIcon: {
    paddingTop: theme.spacing(0)
  }
}));

const ButtonAnchor = memo(props => {
  const { anchorRef, onClick, selectedDealershipName, disabled, dealershipsLabel } = props;
  const { classes } = useStyles({});

  return (
    <Button
      ref={anchorRef}
      onClick={onClick}
      disabled={disabled}
      variant={disabled ? "contained" : "text"}
      classes={{
        root: classes.buttonRoot
      }}
    >
      <div className={classes.dealershipName}>
        {disabled ? `No ${dealershipsLabel}` : selectedDealershipName}
      </div>
      <ArrowDropDown className={classes.arrowIcon} />
    </Button>
  );
});

const MenuItemAnchor = memo(props => {
  const { anchorRef, onClick, disabled, dealershipsLabel } = props;
  return (
    <div ref={anchorRef}>
      <MenuItem onClick={onClick} disabled={disabled}>
        <ListItemIcon>
          <SwapVert />
        </ListItemIcon>
        <ListItemText primary={`My ${dealershipsLabel}`} />
      </MenuItem>
    </div>
  );
});

function DealershipSelectMenu(props) {
  const dispatch = useDispatch();

  const anchorRef = useRef(null);
  const didMountRef = useRef(false);

  const { classes, theme } = useStyles({ offsetWidth: anchorRef.current?.offsetWidth });

  const [isOpen, setIsOpen] = useState(false);

  const dealerships = useSelector(state => state.attendance.dealerships);
  const user = useSelector(state => state.user.user);
  const selectedDealershipName = useSelector(state =>
    get(getDealershipById(state.attendance, state.attendance.selectedDealershipId), "name")
  );

  const isCommunityDealershipUser = user.fields?.isCommunityDealershipUser;
  const auctionAccessId = user.fields?.auctionAccessId;

  const [dealershipLabel] = useLanguage("dealership");
  const [dealershipsLabel] = useLanguage("dealerships");

  const placement = useMediaQuery(theme.breakpoints.down("md")) ? "left" : "bottom-start";
  const AnchorComponent = props.anchorVariant === "menuItem" ? MenuItemAnchor : ButtonAnchor;

  useEffect(() => {
    if ((auctionAccessId || isCommunityDealershipUser) && !didMountRef.current) {
      dispatch(getDealerships());
    }
    didMountRef.current = true;
  }, [dispatch, auctionAccessId, isCommunityDealershipUser]);

  const close = useCallback(() => {
    setIsOpen(false);
  }, []);

  const open = useCallback(() => {
    setIsOpen(true);
  }, []);

  if (!auctionAccessId && !isCommunityDealershipUser) {
    return null;
  }

  return (
    <>
      <AnchorComponent
        onClick={open}
        selectedDealershipName={selectedDealershipName}
        anchorRef={anchorRef}
        disabled={dealerships?.length === 0}
        dealershipsLabel={dealershipsLabel}
      />
      <PopperMenu
        isOpen={isOpen}
        anchorEl={anchorRef.current}
        handleClose={close}
        placement={placement}
        classes={{ paper: classes.paper, popper: classes.popper }}
      >
        <MenuItem disabled>Select {dealershipLabel}</MenuItem>
        {dealerships.map(dealership => (
          <DealershipMenuItem key={dealership.id} dealership={dealership} handleClose={close} />
        ))}
      </PopperMenu>
    </>
  );
}

export default memo(DealershipSelectMenu);
