import { createReducer } from "@reduxjs/toolkit";
import {
  ATTENDANCE_GET_AGREEMENTS,
  ATTENDANCE_GET_DEFAULT_AGREEMENT,
  ATTENDANCE_SET_AGREEMENTS,
  ATTENDANCE_GET_AGREES,
  ATTENDANCE_SET_AGREES,
  ATTENDANCE_AGREE,
  ATTENDANCE_SET_DEALERSHIPS,
  SERVER_APP_STATE_REHYDRATE,
  ATTENDANCE_SET_DEALERSHIP_ID
} from "actions/actionTypes";
import differenceBy from "lodash/differenceBy";
import moment from "moment";
import { REHYDRATE } from "redux-persist/lib/constants";

const initialState = {
  areAgreementsLoading: false,
  agreements: [],
  areAgreesLoading: false,
  agrees: [],
  dealerships: [],
  saleUsers: [],
  selectedDealershipId: null
};

const attendance = createReducer(initialState, builder => {
  builder
    .addCase(REHYDRATE, (state, action) => {
      if (action.payload) {
        return {
          ...action.payload.attendance,
          agrees: [],
          agreements: []
        };
      }
    })
    .addCase(SERVER_APP_STATE_REHYDRATE, (state, action) => {
      return {
        ...state,
        ...action.payload.attendance
      };
    })
    .addCase(ATTENDANCE_GET_AGREEMENTS, state => {
      state.areAgreementsLoading = true;
    })
    .addCase(ATTENDANCE_GET_DEFAULT_AGREEMENT, state => {
      state.areAgreementsLoading = true;
    })
    .addCase(ATTENDANCE_SET_AGREEMENTS, (state, action) => {
      state.agreements = differenceBy(state.agreements, action.payload.agreements, "id").concat(
        action.payload.agreements
      );
      state.areAgreementsLoading = false;
    })
    .addCase(ATTENDANCE_GET_AGREES, state => {
      state.areAgreesLoading = true;
    })
    .addCase(ATTENDANCE_SET_AGREES, (state, action) => {
      state.areAgreesLoading = false;
      state.agrees = differenceBy(state.agrees, action.payload.agrees, "id").concat(
        action.payload.agrees
      );
    })
    .addCase(ATTENDANCE_AGREE, state => {
      state.areAgreesLoading = true;
    })
    .addCase(ATTENDANCE_SET_DEALERSHIPS, (state, action) => {
      state.dealerships = action.payload.dealerships;
    })
    .addCase(ATTENDANCE_SET_DEALERSHIP_ID, (state, action) => {
      state.selectedDealershipId = action.payload.selectedDealershipId;
    });
});

export default attendance;

const getAgreements = state => state.agreements;

const getAgrees = state => state.agrees;

export const getAgreementById = (state, agreementId) =>
  getAgreements(state).find(agreement => agreement.id === agreementId);

export const getAgreement = (state, agreementId) => getAgreementById(state, agreementId);

const getAgreeForUser = (state, agreementId, userId) =>
  getAgrees(state).find(agree => agree.agreementId === agreementId && agree.userId === userId);

export const hasAgreedByUserId = (state, agreementId, userId) =>
  !agreementId || Boolean(getAgreeForUser(state, agreementId, userId));

export const getCompanyDefaultAgreement = (state, companyId) =>
  getAgreements(state).find(
    agreement => agreement.companyId === companyId && agreement.defaultAgreement === true
  );

export const canAttend = sale =>
  ["PRESALE", "ACTIVE"].includes(sale.status) &&
  ["LIVE_LEGACY"].includes(sale.saleType) &&
  moment().isBetween(
    moment(sale.startTime).startOf("day"),
    moment(sale.endTime).endOf("day"),
    null,
    []
  );

export const isComplete = sale => {
  const now = moment().valueOf();
  return ["COMPLETED", "CLOSED"].includes(sale.status) || sale.endTime < now;
};

export const getDealerships = state => state.dealerships;

export const getDealershipIds = state => getDealerships(state).map(dealership => dealership.id);

export const getDealershipById = (state, dealershipId) =>
  getDealerships(state).find(dealership => dealership.id === dealershipId);
