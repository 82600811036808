import { Link } from "react-router-dom";
import { useTheme, useMediaQuery } from "@mui/material";

const WithClickToNewWindow = ({ component, id, disabled, path, children, ...props }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  path = path ? path : `/inventory/${id}`;
  let Component = component;
  let componentProp = disabled ? "div" : "a";
  let target = "_blank";
  if (isMobile && !disabled) {
    componentProp = Link;
    target = "";
  }
  return (
    <Component
      {...props}
      path={path}
      href={path}
      to={path}
      component={componentProp}
      target={target}
    >
      {children}
    </Component>
  );
};

export default WithClickToNewWindow;
