import { TIME_REQUEST_SYNC, TIME_SYNC_COMPLETE } from "actions/actionTypes";

const initialState = {
  time: new Date().getTime(),
  offset: 0,
  lastUpdated: new Date().getTime(),
  isSynching: false
};

export default function time(state = initialState, action) {
  switch (action.type) {
    case TIME_REQUEST_SYNC:
      return {
        ...state,
        isSynching: true
      };
    case TIME_SYNC_COMPLETE:
      return {
        ...state,
        isSynching: false,
        time: action.payload.time,
        offset: action.payload.offset,
        lastUpdate: Date.now()
      };
    default:
      return state;
  }
}
