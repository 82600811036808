import React, { memo } from "react";
import { useSelector } from "utils/StoreUtil";
import { Avatar as MUIAvatar } from "@mui/material";
import Person from "@mui/icons-material/Person";
import { makeStyles } from "tss-react/mui";
import AddAlertIcon from "@mui/icons-material/AddAlert";

function UserAvatar(props) {
  const userMedia = useSelector(state => state.user.media);
  return <Avatar media={userMedia} className={props.className} />;
}

export function Avatar({ media, className, Icon = Person, variant = "circular" }) {
  return (
    <MUIAvatar
      variant={variant}
      className={className}
      src={
        media?.urls?.thumbnail
          ? `${media?.urls?.thumbnail}?request.preventCache=${Date.now()}`
          : undefined
      }
    >
      {!media && <Icon />}
    </MUIAvatar>
  );
}

const useStyles = makeStyles()(theme => ({
  root: {
    position: "relative",
    display: "inline-block"
  },
  indicator: {
    position: "absolute",
    height: 15,
    width: 15,
    right: 0,
    bottom: 0,
    backgroundColor: theme.palette.background.default,
    borderRadius: "50%"
  }
}));

export function AvatarWithIndicator({
  showIndicator,
  Indicator = AddAlertIcon,
  indicatorTitle,
  ...props
}) {
  const { classes } = useStyles();
  return (
    <div className={classes.root}>
      <Avatar {...props} />
      {showIndicator && (
        <div title={indicatorTitle}>
          <Indicator className={classes.indicator} />
        </div>
      )}
    </div>
  );
}

export default memo(UserAvatar);
