import React from "react";
import { useSelector } from "utils/StoreUtil";
import { Table, TableBody } from "@mui/material";

import { lotsSelector } from "reducers/lots";
import BigTableListItem from "./BigTableListItem";

function BigTableList(props) {
  const lotIds = useSelector(state => lotsSelector.selectIds(state.lots));

  return (
    <Table>
      <TableBody>
        {lotIds.map(id => (
          <BigTableListItem key={id} lotId={id} />
        ))}
      </TableBody>
    </Table>
  );
}

export default React.memo(BigTableList);
