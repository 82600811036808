import React from "react";
import { useSelector } from "utils/StoreUtil";
import { Redirect } from "react-router-dom";
import GoNative from "services/gonative";
import MobileAppLogin from "views/landing/cubed/components/MobileAppLogin";

import Login from "./Login";

const LoginSwitch = () => {
  const isAuthorized = useSelector(state => state.user.isAuthorized);

  if (isAuthorized) {
    return <Redirect to="/" />;
  }

  if (GoNative.IsGoNative()) {
    return <MobileAppLogin />;
  }

  return <Login />;
};

export default LoginSwitch;
