import {
  MEDIA_DELETE,
  MEDIA_DELETE_COMPLETE,
  MEDIA_UPDATE,
  MEDIA_UPDATE_COMPLETE,
  MEDIAS_ASSET_SET,
  MEDIA_SET,
  MEDIAS_SET
} from "actions/actionTypes";
import { MEDIAS_GET } from "./actionTypes";
import { createTermFilter } from "utils/SearchUtil";
import {
  getMedias,
  createMedia as createMediaApi,
  updateMedia as updateMediaApi,
  deleteMedia as deleteMediaFromApi
} from "api/mediaRequests";
import { upload } from "api/api";
import { showError, showNotification } from "./notificationActions";
import { findMedia, isMediaTypeImageDocument } from "reducers/media";
import { MEDIA_TYPE } from "utils/constants";
import { updateLot } from "api/inventoryRequests";
import { Severity } from "types/alert";

export function getAssetMedia(assetId) {
  return async dispatch => {
    dispatch({ type: MEDIAS_GET });

    let filters = [createTermFilter("entityId", assetId), createTermFilter("entityType", "asset")];
    let { total } = await getMedias(filters, "items=0-0");
    let { data: medias } = await getMedias(filters, `items=0-${total}`, [
      "+position",
      "+createdOn"
    ]);

    dispatch(setAssetMedias(assetId, medias));
  };
}

export function deleteMedia(mediaId) {
  return async dispatch => {
    dispatch({ type: MEDIA_DELETE });
    try {
      await deleteMediaFromApi(mediaId);
      dispatch({
        type: MEDIA_DELETE_COMPLETE,
        payload: {
          mediaId
        }
      });
    } catch (error) {
      dispatch({
        type: MEDIA_DELETE_COMPLETE
      });
    }
  };
}

function updateMediaInState(media) {
  return {
    type: MEDIA_UPDATE,
    payload: {
      media
    }
  };
}

export function setAssetMedias(assetId, medias) {
  return {
    type: MEDIAS_ASSET_SET,
    payload: {
      assetId,
      medias
    }
  };
}

export function upsertMedia(updatedMedia, file, lotId) {
  return async (dispatch, getState) => {
    const mediaId = updatedMedia.id;
    const isUpdating = Boolean(mediaId);
    try {
      dispatch({ type: MEDIA_UPDATE });

      const {
        media: { medias },
        lots: { entities: lotsMap }
      } = getState();

      const { urls, ...selectedMedia } = findMedia(medias, mediaId) || {};
      const lot = lotsMap[lotId];

      const media = {
        ...selectedMedia,
        ...updatedMedia,
        downloadExternal: false,
        name: file ? file.name : selectedMedia.name
      };

      if (file) {
        media.contentType = null;

        const headers = {
          "Content-Type": "multipart/form-data"
        };

        let data = new FormData();

        data.append("file", file);
        data.append("media", JSON.stringify(media));

        if (isUpdating) {
          await upload(`media/${mediaId}`, headers, data, undefined, "put");
        } else {
          await upload("media", headers, data);
        }
      } else {
        await updateMediaApi(media);
      }

      dispatch(updateMediaInState(media));

      if (lot) {
        await syncLot(lot);
      }

      dispatch(showNotification(`Media ${mediaId ? "updated" : "created"}`, Severity.success));
    } catch (error) {
      dispatch(showError(getErrorMessage(error, isUpdating)));
    } finally {
      dispatch({ type: MEDIA_UPDATE_COMPLETE });
    }
  };
}

export function upsertMediaFromUrl(updatedMedia, url, lotId) {
  return async (dispatch, getState) => {
    const mediaId = updatedMedia.id;
    const isUpdating = Boolean(mediaId);
    try {
      dispatch({ type: MEDIA_UPDATE });

      const {
        media: { medias },
        lots: { entities: lotsMap }
      } = getState();

      const { urls, ...selectedMedia } = findMedia(medias, mediaId) || {};
      const lot = lotsMap[lotId];

      const external = !updatedMedia.downloadExternal;

      const media = {
        ...selectedMedia,
        ...updatedMedia,
        name: url,
        urls: { source: url },
        contentType: null,
        external
      };

      if (media.external) {
        media.urls.large = url;
        media.urls.medium = url;
        media.urls.small = url;
        media.urls.thumbnail = url;
      }

      if (isUpdating) {
        await updateMediaApi(media);
      } else {
        await createMediaApi(media);
      }
      if (lot) {
        await syncLot(lot);
      }
      dispatch(showNotification(`Media ${isUpdating ? "updated" : "created"}`, Severity.success));
    } catch (error) {
      dispatch(showError(getErrorMessage(error, isUpdating)));
    } finally {
      dispatch({ type: MEDIA_UPDATE_COMPLETE });
    }
  };
}

function getErrorMessage(error, isUpdating) {
  return (
    error.response?.data || error.message || `Unable to ${isUpdating ? "update" : "create"} media`
  );
}

export function makeMediaPrimary(mediaId, entityId, lotId) {
  return async (dispatch, getState) => {
    const {
      media: { entityMedia },
      lots: { entities: lotsMap }
    } = getState();

    const mediasForAsset = entityMedia[entityId];
    const lot = lotsMap[lotId];

    const currentPrimaryMedias = mediasForAsset?.filter(media => media.primary);

    if (currentPrimaryMedias?.length > 0) {
      currentPrimaryMedias.forEach(media => {
        dispatch(upsertMedia({ id: media.id, primary: false }));
      });
    }

    await dispatch(upsertMedia({ id: mediaId, primary: true }));

    if (lot) {
      await syncLot(lot);
    }
  };
}

export function makeMediaDocument(mediaId, entityId, lotId) {
  return async (dispatch, getState) => {
    const {
      media: { entityMedia },
      lots: { entities: lotsMap }
    } = getState();

    const mediasForAsset = entityMedia[entityId];
    const lot = lotsMap[lotId];

    const currentMediaDocument = mediasForAsset?.filter(media => isMediaTypeImageDocument(media));

    if (currentMediaDocument?.length > 0) {
      currentMediaDocument.forEach(media => {
        dispatch(upsertMedia({ id: media.id, mediaType: MEDIA_TYPE.IMAGE }));
      });
    }

    await dispatch(upsertMedia({ id: mediaId, mediaType: MEDIA_TYPE.IMAGE_DOCUMENT }));

    if (lot) {
      await syncLot(lot);
    }
  };
}

export async function syncLot(lot) {
  await updateLot({ ...lot, isSynced: false });
}

export function setMedias(medias, shouldMerge = false) {
  return {
    type: MEDIAS_SET,
    payload: {
      medias,
      shouldSetEntityType: true,
      shouldMerge
    }
  };
}

export function setMedia(media) {
  return {
    type: MEDIA_SET,
    payload: {
      media
    }
  };
}
