import { useCallback, useRef, useState } from "react";
import { AppBar, Box, IconButton, Toolbar, Stack } from "@mui/material";
import Search from "@mui/icons-material/Search";
import { makeStyles } from "tss-react/mui";

import { useSelector } from "utils/StoreUtil";
import { useShowTopNavbarMobile } from "./usePublicPaths";

import MenuButton from "../MenuButton";
import NotificationIndicator from "../notification/NotificationIndicator";
import NotificationDropdown from "../notification/NotificationDropdown";
import SearchBox from "views/inventory/search/search-options/SearchBox";
import LoginOrRegister from "../LoginOrRegister";
import HideOnScroll from "components/transition/HideOnScroll";

const useStyles = makeStyles()(theme => ({
  topNav: {
    minHeight: theme.spacing(7),
    backgroundColor: theme.palette.company.header,
    color: theme.palette.getContrastText(theme.palette.company.header)
  }
}));

const menuButtonProps = { disableRipple: true };

const TopNavbarMobile = () => {
  const { classes } = useStyles();
  const isAuthorized = useSelector(state => state.user.isAuthorized);

  const [isNotificationOpen, setNotificationOpen] = useState(false);
  const [isSearchOpen, setSearchIsOpen] = useState(false);

  const buttonRef = useRef(null);

  const handleClick = useCallback(() => {
    setNotificationOpen(true);
  }, []);
  const handleClose = useCallback(() => {
    setNotificationOpen(false);
  }, []);

  const handleSearchClose = evt => {
    setSearchIsOpen(false);
  };
  const toggleSearchIsOpen = () => {
    setSearchIsOpen(isOpen => !isOpen);
  };

  const handleSearchClick = evt => {
    setSearchIsOpen(true);
    evt.nativeEvent.stopImmediatePropagation();
  };

  const shouldShow = useShowTopNavbarMobile();

  if (!shouldShow) return null;

  return (
    <>
      <HideOnScroll threshold={300}>
        <AppBar position="fixed" className={classes.topNav}>
          <Toolbar variant="dense" className={classes.topNav}>
            <Box display="flex" width="100%">
              {!isSearchOpen && (
                <IconButton onClick={handleSearchClick} color="inherit">
                  <Search />
                </IconButton>
              )}

              <Box flex={1} />
              {isAuthorized && (
                <Stack spacing={2} direction="row">
                  <NotificationIndicator
                    buttonRef={buttonRef}
                    handleClick={handleClick}
                    as={IconButton}
                  />
                  <MenuButton
                    as={IconButton}
                    componentProps={menuButtonProps}
                    MenuProps={{ placement: "bottom" }}
                  />
                </Stack>
              )}
            </Box>

            {isAuthorized && (
              <NotificationDropdown
                isOpen={isNotificationOpen}
                elementRef={buttonRef}
                handleClose={handleClose}
              />
            )}

            {!isAuthorized && <LoginOrRegister />}
          </Toolbar>
        </AppBar>
      </HideOnScroll>

      <>
        {isSearchOpen && (
          <HideOnScroll alwaysShow={true}>
            <SearchBox
              isOpen={isSearchOpen}
              handleClose={handleSearchClose}
              toggleIsOpen={toggleSearchIsOpen}
            />
          </HideOnScroll>
        )}
      </>
    </>
  );
};

export default TopNavbarMobile;
