import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { Announcement } from "types/Announcement";

export const announcementType = {
  CANNED: "CANNED",
  CUSTOM: "CUSTOM"
};

const source = {
  AUTOCHECK: "AUTOCHECK",
  CARFAX: "CARFAX",
  CUSTOM: "CUSTOM",
  COMMENT: "COMMENT"
};

export const announcementErrors = {
  DUPLICATE_ANNOUNCEMENT:
    "Duplicate custom announcements. Announcements need to have different text. Please update."
};

const announcementsAdapter = createEntityAdapter<Announcement, number>({
  selectId: announcement => announcement.announcementNumber
});

export const announcementsSelector = announcementsAdapter.getSelectors();

export const initialState = announcementsAdapter.getInitialState({ isLoading: false });

const announcementsSlice = createSlice({
  name: "announcements",
  initialState,
  reducers: {
    fetch: (state, action) => {
      state.isLoading = true;
    },
    fetchComplete: (state, action) => {
      state.isLoading = false;
      announcementsAdapter.setAll(state, action.payload);
    }
  }
});

export function isInAnnouncements(announcements: Announcement[], announcement: Announcement) {
  const prop = getAnnouncementPropName(announcement);
  return Boolean(findAnnouncementByProp(announcements, announcement[prop], prop));
}

export function findAnnouncementByProp(
  announcements: Announcement[],
  announcementProp: any,
  prop: keyof Announcement
) {
  return announcements?.find(announcement => announcement[prop] === announcementProp);
}

export function filterAnnouncements(announcement: Announcement, index: number) {
  const prop = getAnnouncementPropName(announcement);
  if (prop === "announcementNumber" && !announcement[prop]) {
    return filterAnnouncementByIndex(index);
  }
  return filterAnnouncementByProp(announcement?.[prop], prop);
}

function filterAnnouncementByProp(announcementProp: any, prop: keyof Announcement) {
  return (announcement: Announcement) => announcement[prop] !== announcementProp;
}

function filterAnnouncementByIndex(index: number = 0) {
  return (_: any, i: number) => i !== index;
}

function getAnnouncementPropName(announcement: Announcement) {
  return isAnnouncementTypeCustom(announcement) ? "announcementNumber" : "cannedAnnouncementNumber";
}

export function isAnnouncementTypeCustom(announcement: Announcement) {
  return announcement?.announcementType?.toLowerCase() === announcementType.CUSTOM.toLowerCase();
}

export function isAnnouncementTypeCanned(announcement: Announcement) {
  return announcement?.announcementType?.toLowerCase() === announcementType.CANNED.toLowerCase();
}

export function isAnnouncementEditable(announcement: Announcement) {
  const announcementSource = announcement?.source;
  return !(
    announcementSource === source.AUTOCHECK ||
    announcementSource === source.CARFAX ||
    announcementSource === source.COMMENT
  );
}

export function validateAnnouncements(announcements: Announcement[] = []) {
  validateDuplicates(announcements);
}

function validateDuplicates(announcements: Announcement[] = []) {
  announcements.forEach(announcement => {
    if (
      isAnnouncementTypeCustom(announcement) &&
      hasDuplicateTextAnnouncement(announcement, announcements)
    ) {
      throw new Error(announcementErrors.DUPLICATE_ANNOUNCEMENT);
    }
  });
}

export function hasDuplicateTextAnnouncement(
  announcement: Announcement,
  announcements: Announcement[]
) {
  return Boolean(announcements.find(findAnnouncement(announcement)));
}

function findAnnouncement({ announcementNumber, announcement: announcementText }: Announcement) {
  return (announcement: Announcement) =>
    announcement.announcement?.toLowerCase() === announcementText?.toLowerCase() &&
    announcement.announcementNumber !== announcementNumber;
}

const { actions, reducer } = announcementsSlice;

export const { fetch, fetchComplete } = actions;
export default reducer;
