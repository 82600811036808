import { AMS_FETCH_COMPLETE } from "./actionTypes";
import { getAmsConfigurations } from "api/configRequests";

export function fetchAmsConfigurations() {
  return async dispatch => {
    let ams = [];
    try {
      ams = await getAmsConfigurations();
    } finally {
      dispatch({ type: AMS_FETCH_COMPLETE, payload: ams });
    }
  };
}
