import camelCase from "lodash/camelCase";
import startCase from "lodash/startCase";

export function handleLabelConversions(label = "", languageMap = {}) {
  const field = camelCase(label);

  if (field in languageMap) {
    return languageMap[field];
  } else {
    const startCased = startCase(label);
    return startCased.split(" ").join("").length === label.length ? startCased : label;
  }
}
