import {
  ADMIN_TOGGLE_EVENTS_RUNNING_FILTER,
  ADMIN_TOGGLE_EVENTS_CLOSED_FILTER,
  ADMIN_EVENTS_SET_SORT_BY,
  ADMIN_EVENTS_SET_SALE_TYPE,
  ADMIN_SET_SALES,
  ADMIN_SET_ATTENDEES,
  ADMIN_SET_ATTENDEE_STATS,
  ADMIN_SET_BID_LOGS,
  ADMIN_TOGGLE_BIDLOG_ACTION_FILTER,
  ADMIN_SET_SALE_LOG_ACTIONS,
  ADMIN_GET_SALE_LOG_ACTIONS,
  ADMIN_GET_BID_LOGS,
  ADMIN_SET_BID_LOGS_TOTAL,
  ADMIN_SET_BID_LOGS_PAGE,
  ADMIN_GET_BID_LOGS_CSV,
  ADMIN_GET_LOT,
  ADMIN_SET_LOT,
  ADMIN_SET_INVENTORY_PAGE,
  ADMIN_TOGGLE_SALES_ID_FILTER,
  ADMIN_CLEAR_SALES_ID_FILTER,
  ADMIN_GET_SALE_STATS_CSV,
  ADMIN_ERROR_SCHEDULING_LIVE_LEGACY_SALE,
  ADMIN_SCHEDULING_LIVE_LEGACY_SALE,
  ADMIN_TOGGLE_INVENTORY_SORT,
  ADMIN_TOGGLE_ATTENDEE_SORT,
  ADMIN_UPDATE_SALEUSER_CREDIT_LIMIT,
  ADMIN_RUN_EVENT_COMPARE_SCRIPT,
  ADMIN_SET_EVENT_COMPARE_RESULT,
  ADMIN_DOWNLOAD_EVENT_COMPARE_RESULT,
  ADMIN_SYNC_SYSTEMS,
  ADMIN_SET_DEALERSHIPS_FILTER,
  ADMIN_GET_DEALERSHIPS_CSV,
  MEDIAS_GET,
  MEDIAS_SET,
  MEDIA_SET,
  ADMIN_EVENTS_SET_PAGE,
  ADMIN_RESET_SYNC,
  ADMIN_FETCH_CONSIGNOR_REPORT,
  ADMIN_SET_CONSIGNOR_REPORT,
  ADMIN_CONSIGNOR_REPORT_TOGGLE_CONSIGNOR,
  ADMIN_CONSIGNOR_REPORT_TOGGLE_LANE,
  ADMIN_FETCH_CONSIGNORS,
  ADMIN_FETCH_CONSIGNORS_COMPLETE,
  STATS_FETCH,
  STATS_FETCH_COMPLETE,
  ADMIN_ATTENDEES_FETCH,
  STATS_BULK_FETCH_COMPLETE,
  ADMIN_CONSIGNOR_REPORT_TOGGLE_SELECT_ALL_CONSIGNORS,
  ADMIN_CONSIGNOR_REPORT_TOGGLE_SELECT_ALL_LANES,
  MEMBERSHIP_UPDATE,
  ADMIN_UPDATE_SALE,
  ADMIN_SALE_FETCH_COMPLETE,
  CALENDAR_FETCH,
  CALENDAR_FETCH_COMPLETE,
  NOTIFICATIONS_SEND,
  NOTIFICATIONS_SENT,
  ADMIN_ATTENDEES_SET_PAGE,
  ADMIN_EVENTS_SET_COMPANIES
} from "./actionTypes";
import {
  runEventCompareScript as callEventCompareScript,
  updateCredit as updateCreditScript
} from "api/scriptRequests";
import {
  getLot as getLotFromApi,
  getLots as getLotsFromApi,
  updateLot as updateLotInApi
} from "api/inventoryRequests";
import {
  createTermFilter,
  createTermsFilter,
  createRange,
  createExistsFilter,
  createQueryFilter
} from "utils/SearchUtil";
import { getRange } from "utils/Pagination";
import { formatSaleActionLog as formatLog } from "reducers/admin/bidlogs";
import {
  getFilteredDealerships,
  getAttendeeById,
  formatDealership
} from "reducers/admin/attendees";
import { downloadCsv } from "api/outputRequests";
import { Csv } from "./csv/utils";
import {
  resetSync as resetSyncApi,
  getSaleActionLogs as getSaleActionLogsApi,
  getSaleLogActions as getSaleLogActionsApi,
  getSaleActionLogResults
} from "api/saleRequests";
import {
  getAttendeeStats,
  getSaleStatsWithoutCurrencyConversion as getSaleStatsAPI
} from "api/statsRequests";
import { showNotification, dismiss } from "./notificationActions";
import { prepareSaleForUpload } from "reducers/admin/events";
import {
  updateSale,
  getConsignorReport as getConsignorReportFromAPI,
  fastlaneAssetSync,
  getConsignors as getConsignorsAPI,
  updateMembership as updateMembershipAPI,
  getSaleActionLogReportResults,
  getItemDetails,
  sendUpcomingNotification as sendUpcomingNotificationFromAPI,
  updateDealership,
  createDealership,
  getAttendees,
  createCommunityDealership,
  updateCredit as updateCreditAPI
} from "api/adminRequests";
import { getMediasV2 as getMediasFromApi } from "api/mediaRequests";
import startCase from "lodash/startCase";
import chunk from "lodash/chunk";
import flatten from "lodash/flatten";
import uniq from "lodash/uniq";
import { batch } from "utils/StoreUtil";
import { formatStats } from "reducers/stats";
import { actions as dealershipActions } from "reducers/dealerships";
import { trackSearch } from "hooks/useTracking";
import { eventsSelector } from "reducers/events";
import { salesSelector, getSalesByEventId } from "reducers/sales";
import { filterSaleFromSaleFilters } from "reducers/admin/events";
import { getSale as getSaleFromApi } from "api/adminRequests";
import { updateAdminEventAction } from "actions/eventActions";
import { formatDateTime } from "utils/DateUtil";
import { saleUsersFetchComplete } from "reducers/saleUsers";
import { fetchUsersComplete } from "reducers/users";
import { getLanguageMap } from "reducers/language";
import { setTotal } from "reducers/lots";
import { getMemberships as getMembershipsFromApi } from "api/attendanceRequests";
import { setMembership } from "./attendanceActions";
import IntlUtil from "utils/IntlUtil";
import { MARKET_SEGMENTS, SALE_ACTION_LOG_TYPE } from "utils/constants";
import { getMarketVisibility } from "hooks/useMarketVisibility";
import { getCalendarEntitiesForAdmin } from "api/calendarRequests";
import { Severity } from "types/alert";
import { getRegistrationCompany } from "api/companyRequests";
import TimerUtil from "utils/TimerUtil";
import { inventoryFetchComplete } from "./sharedActions";

export function fetchCalendarResources(args = { shouldMerge: false, pageType: "calendar" }) {
  return async dispatch => {
    const filters = args.filters || [];
    const page = args.page || 0;
    const range = args.range || getRange(page, 25);
    const sortBy = args.sortBy || "+startTime";
    const forAdmin = args.forAdmin || false;

    dispatch({ type: CALENDAR_FETCH });

    let results = {};
    try {
      const { data } = await getCalendarEntitiesForAdmin(
        filters,
        range,
        sortBy,
        forAdmin,
        args.pageSubType
      );
      results = { ...data };
      results.shouldMerge = args.shouldMerge;
      results.lastPageType = args.pageType;
    } finally {
      dispatch({ type: CALENDAR_FETCH_COMPLETE, payload: results });
    }

    return results;
  };
}

export function getEntitiesForAdmin(props) {
  return async (dispatch, getState) => {
    try {
      let {
        adminEvents: { eventFilters, sortBy, saleType, page, selectedCompanyIds }
      } = getState();

      dispatch({ type: "ADMIN_GET_CALENDAR_RESOURCES" });

      const filters = [...eventFilters];

      if (selectedCompanyIds?.length > 0) {
        filters.push(createTermsFilter("companyId", selectedCompanyIds));
      }

      if (saleType) {
        let saleTypes = [saleType.toLowerCase(), saleType.toLowerCase() + "_legacy"];
        filters.push(createTermsFilter("saleTypes", saleTypes));
      }

      trackSearch(filters, "/admin");

      return dispatch(
        fetchCalendarResources({
          filters,
          page,
          sortBy,
          forAdmin: true,
          pageType: "admin",
          ...props
        })
      );
    } catch (error) {
      return [];
    }
  };
}

export function getEventFromCalendarEndpoint(eventId) {
  return async dispatch => {
    const filters = [createTermFilter("id", eventId)];
    dispatch({ type: "ADMIN_GET_EVENT_FROM_CALENDAR_RESOURCES" });

    const response = await dispatch(
      fetchCalendarResources({
        filters,
        forAdmin: true,
        shouldMerge: true,
        pageType: "admin"
      })
    );

    return response?.payload?.events[0];
  };
}

export function setAdminEventsCompanies(selectedCompanyIds) {
  return {
    type: ADMIN_EVENTS_SET_COMPANIES,
    payload: { selectedCompanyIds }
  };
}

export function setAdminEventsSortBy(sortBy) {
  return {
    type: ADMIN_EVENTS_SET_SORT_BY,
    payload: {
      sortBy
    }
  };
}

export function setAdminEventsSaleType(saleType) {
  return {
    type: ADMIN_EVENTS_SET_SALE_TYPE,
    payload: {
      saleType
    }
  };
}

export function toggleAdminEventsFilterChange() {
  return async (dispatch, getState) => {
    dispatch({ type: ADMIN_TOGGLE_EVENTS_RUNNING_FILTER });
    dispatch({ type: ADMIN_TOGGLE_EVENTS_CLOSED_FILTER });
    //TODO: maybe we should do this in UI? update in calendar if we decide to change this too
    dispatch(getEntitiesForAdmin());
  };
}

export function resetAdminEventsPage(page) {
  return async dispatch => {
    dispatch({
      type: ADMIN_EVENTS_SET_PAGE,
      payload: { page }
    });
  };
}

export function setAdminEventsPage(page) {
  return async dispatch => {
    dispatch({
      type: ADMIN_EVENTS_SET_PAGE,
      payload: { page }
    });
    dispatch(getEntitiesForAdmin());
  };
}

export function scheduleLiveLegacySale(saleId) {
  return async (dispatch, getState) => {
    dispatch({ type: ADMIN_SCHEDULING_LIVE_LEGACY_SALE, payload: { saleId } });
    let sale = prepareSaleForUpload(salesSelector.selectById(getState().sales, saleId));
    sale = { ...sale, status: "SCHEDULED" };
    try {
      await updateSale(sale);
      batch(() => {
        dispatch({
          type: ADMIN_UPDATE_SALE,
          payload: {
            update: {
              id: sale.id,
              changes: sale
            }
          }
        });
        dispatch(showNotification("Sale scheduled", Severity.success));
      });
    } catch (error) {
      sale = { ...sale, status: "STAGED" };
      await updateSale(sale);
      dispatch({ type: ADMIN_ERROR_SCHEDULING_LIVE_LEGACY_SALE, payload: { saleId } });
    }
  };
}

export function getSale(saleId) {
  return async dispatch => {
    const sale = await getSaleFromApi(saleId);
    dispatch({ type: ADMIN_SALE_FETCH_COMPLETE, payload: sale });
  };
}

export function setAdminSales(sales) {
  return {
    type: ADMIN_SET_SALES,
    payload: {
      sales
    }
  };
}

export function getAdminAttendeesResources(eventId, companyId) {
  return async (dispatch, getState) => {
    try {
      const state = getState();

      const {
        adminAttendees: { page }
      } = state;

      const filters = addAttendeesFilters(state, eventId);

      dispatch({ type: ADMIN_ATTENDEES_FETCH });

      const { data: attendees, total } = await getAttendees(filters, getRange(page, 50));

      if (attendees) {
        const userIds = attendees.map(attendee => attendee.user.id);

        if (userIds.length > 0) {
          const { data: memberships } = await getMembershipsFromApi(
            [createTermsFilter("userId", userIds), createTermFilter("companyId", companyId)],
            getRange(0, userIds.length)
          );

          dispatch(setMembership(memberships));

          const saleActionLogFilters = [
            createTermFilter("saleAction.type", "chat"),
            createTermFilter("saleAction.eventId", eventId),
            createTermsFilter("saleAction.userId", userIds)
          ];

          let { total: totalSaleActionLogs } = await getSaleActionLogsApi(
            saleActionLogFilters,
            "items=0-0"
          );

          if (totalSaleActionLogs > 0) {
            let { data: saleActionLogs } = await getSaleActionLogsApi(
              saleActionLogFilters,
              `items=0-${total}`,
              "-saleAction.timestamp"
            );

            batch(() => {
              dispatch(setBidLogsTotal(totalSaleActionLogs));
              dispatch(setBidLogs(saleActionLogs));
            });
          }
        }
      }

      dispatch(setAttendees(attendees, total));
    } catch (error) {
      dispatch(setAttendees([]));
    }
  };
}

export function getAdminAttendeeStats(eventId, userId) {
  return async (dispatch, getState) => {
    try {
      const filters = getAttendeeStatsFilters(getState(), eventId);

      const attendeeStats = await getAttendeeStats(filters, userId);

      dispatch(setAttendeeStats(attendeeStats));
    } catch (error) {}
  };
}

export function getChatLogs(eventId, userIds) {
  return async dispatch => {
    dispatch({ type: ADMIN_GET_BID_LOGS });

    const filters = [
      createTermFilter("saleAction.type", "chat"),
      createTermFilter("saleAction.eventId", eventId),
      createTermsFilter("saleAction.userId", userIds)
    ];

    let { total } = await getSaleActionLogsApi(filters, "items=0-0");

    let { data } = await getSaleActionLogsApi(filters, `items=0-${total}`, "-saleAction.timestamp");

    batch(() => {
      dispatch(setBidLogsTotal(total));
      dispatch(setBidLogs(data));
    });
  };
}

export function getUserChatLogs(eventId, userId) {
  return async (dispatch, getState) => {
    const { bidLogsPage: page } = getState().adminBidlogs;

    dispatch({ type: ADMIN_GET_BID_LOGS });

    const filters = [
      createTermFilter("saleAction.type", "chat"),
      createTermFilter("saleAction.eventId", eventId),
      createTermsFilter("saleAction.from", [`a${userId}`, userId], "SHOULD"),
      createTermsFilter("saleAction.to", [`a${userId}`, userId, "all"], "SHOULD")
    ];

    let { total, data } = await getSaleActionLogResults(
      filters,
      getRange(page, 50),
      "-saleAction.timestamp"
    );

    const saleActionLogs = data.saleActionLogs;
    const lotIds = saleActionLogs.reduce((lotIds, saleActionLog) => {
      const lotId = saleActionLog.saleAction?.lotId;

      if (lotId != null && !lotIds.includes(lotId)) {
        lotIds.push(lotId);
      }
      return lotIds;
    }, []);

    let lots = [];

    if (lotIds.length > 0) {
      const { data } = await getLotsFromApi(
        [createTermsFilter("id", lotIds)],
        createRange(0, lotIds.length)
      );

      lots = data;
    }

    batch(() => {
      dispatch(setBidLogsTotal(total));
      dispatch(setBidLogs(saleActionLogs));
      dispatch(inventoryFetchComplete({ lots, users: data.users, saleUsers: data.saleUsers }));
      dispatch(setTotal(lots.length));
    });
  };
}

function setAttendees(attendees, total) {
  return {
    type: ADMIN_SET_ATTENDEES,
    payload: {
      attendees,
      total
    }
  };
}

function setAttendeeStats(attendeeStats) {
  return {
    type: ADMIN_SET_ATTENDEE_STATS,
    payload: attendeeStats
  };
}

export function setAttendeePage(page) {
  return {
    type: ADMIN_ATTENDEES_SET_PAGE,
    payload: page
  };
}

export function getSaleActionLogReport(saleId) {
  return async (dispatch, getState) => {
    const state = getState();
    const saleName = salesSelector.selectById(state.sales, saleId)?.name;

    dispatch(showNotification("Generating report", Severity.info));

    const logs = await getSaleActionLogReportResults(saleId);

    const csv = new Csv(getLanguageMap(getState()));

    csv.setHeader([
      "Sale Name",
      "Lot Number",
      "Badge",
      "User Name",
      "Timestamp",
      "Action",
      "Bid Type",
      "Amount",
      "IP Address"
    ]);

    for (const log of logs) {
      csv.addRow(formatSaleActionLog(log, saleName, state.user.user.locale));
    }

    dispatch(showNotification("Report is ready", Severity.success));

    await downloadCsv(
      `${saleName}_Detailed_Activity_Log_Report_${new Date().toString()}`,
      csv.get()
    );

    dispatch(dismiss());
  };
}

function formatSaleActionLog(log, saleName, locale) {
  return [
    saleName,
    log.lotNumber,
    log.saleUserNumber,
    log.userName,
    log.saleAction.timestamp ? formatDateTime(log.saleAction.timestamp, locale, "hh:mm:ss a") : "",
    log.saleAction.type,
    log.saleAction?.bidType ?? "",
    log.saleAction?.bid?.amount ??
      log.saleAction?.winningBid?.amount ??
      log.saleAction?.amount ??
      "",
    log.saleAction.ipAddress
  ];
}

export function getItemDetailsReport(saleId) {
  return async (dispatch, getState) => {
    const state = getState();

    const sale = salesSelector.selectById(state.sales, saleId);

    dispatch(showNotification("Generating report", Severity.info));

    const lots = await getItemDetails(saleId);

    const languageMap = getLanguageMap(getState());

    const csv = new Csv(languageMap);

    const repText = languageMap.rep || "Rep";

    csv.setHeader([
      "Sale Name",
      "Lot Number",
      "Year",
      "Make",
      "Model",
      "Body Style",
      "Mileage",
      "VIN",
      "Status",
      "Reserve Price",
      "Starting Bid",
      "High Bid",
      "Start Date",
      "End Date",
      "High Bidder Number",
      "High Bidder Name",
      `${repText} Name`,
      `${repText} Action`,
      `${repText} Action Date`,
      `${repText} Action Amount`,
      `${repText} IP Address`
    ]);

    csv.setRows(lots.map(lot => formatItemDetails(lot, sale, state.user.user.locale)));

    dispatch(showNotification("Report is ready", Severity.success));

    await downloadCsv(
      `${sale?.name}_Virtual_Lane_Recording_Report_${new Date().toString()}`,
      csv.get()
    );

    dispatch(dismiss());
  };
}

function formatItemDetails(lot, sale, locale) {
  return [
    sale?.name,
    lot.lotNumber,
    lot.filterable?.year,
    lot.filterable?.make,
    lot.filterable?.model,
    lot.filterable?.bodyStyle,
    lot.filterable?.mileage,
    lot.filterable?.vin,
    lot.status,
    lot.reserveAmount,
    lot.firstAcceptedBid?.amount,
    lot.highBid?.amount,
    formatDateTime(sale.startTime, locale, "hh:mm a"),
    formatDateTime(sale.endTime, locale, "hh:mm a"),
    lot.filterable?.highBidderSaleUserNumber,
    lot.filterable?.highBidderName,
    lot.filterable?.sellerName,
    lot.repAction?.repActionType,
    lot.repAction ? formatDateTime(lot.repAction?.timestamp, locale, "hh:mm:ss a") : "",
    lot.repAction?.amount,
    lot.repAction?.ipAddress
  ];
}

export function toggleAttendeeSort(field, order = "ASC") {
  return {
    type: ADMIN_TOGGLE_ATTENDEE_SORT,
    payload: {
      field,
      order
    }
  };
}

export function getDealershipsCsv(userId) {
  return async (dispatch, getState) => {
    dispatch({ type: ADMIN_GET_DEALERSHIPS_CSV });
    dispatch(showNotification("Generating report", Severity.info));

    const attendees = getState().adminAttendees;

    const filteredDealerships = getFilteredDealerships(attendees, parseInt(userId, 10));

    const csv = new Csv(getLanguageMap(getState()));

    csv.setHeader([
      "Badge",
      "Dealer",
      "Credit Limit",
      "Units With Bids",
      "Bids Placed",
      "Units Purchased",
      "Amount Purchased",
      "Units Pending",
      "Amount Pending"
    ]);

    csv.setRows(
      filteredDealerships
        .map(formatDealership)
        .map(({ saleUserId, ...dealership }) => Object.values(dealership))
    );

    dispatch(showNotification("Report is ready", Severity.success));
    const user = getAttendeeById(attendees.attendees, parseInt(userId, 10));
    await downloadCsv(
      `${startCase(user.name.toLowerCase()).replace(
        / /g,
        "_"
      )}_Dealerships_${new Date().toString()}`,
      csv.get()
    );
    dispatch(dismiss());
  };
}

const blacklistedSaleLogActions = [SALE_ACTION_LOG_TYPE.CHAT, SALE_ACTION_LOG_TYPE.VIEW];

export function getBidLogs(lotId) {
  return async (dispatch, getState) => {
    let { bidLogFilters: filters, bidLogsPage: page } = getState().adminBidlogs;
    filters = [
      ...filters,
      createTermFilter("saleAction.lotId", lotId),
      createTermsFilter("saleAction.type", blacklistedSaleLogActions, "MUST_NOT")
    ];

    dispatch({ type: ADMIN_GET_BID_LOGS });

    let { total, data } = await getSaleActionLogResults(filters, getRange(page, 50), "-id");

    batch(() => {
      dispatch(setBidLogsTotal(total));
      dispatch(saleUsersFetchComplete({ saleUsers: data.saleUsers }));
      dispatch(fetchUsersComplete(data.users));
      dispatch(setBidLogs(data.saleActionLogs));
    });
  };
}

export function setBidLogs(bidLogs) {
  return {
    type: ADMIN_SET_BID_LOGS,
    payload: {
      bidLogs
    }
  };
}

export function setBidLogsTotal(total) {
  return {
    type: ADMIN_SET_BID_LOGS_TOTAL,
    payload: {
      total
    }
  };
}

export function setBidLogsPage(page) {
  return {
    type: ADMIN_SET_BID_LOGS_PAGE,
    payload: {
      page
    }
  };
}

export function toggleActionFilter(action) {
  return {
    type: ADMIN_TOGGLE_BIDLOG_ACTION_FILTER,
    payload: {
      action
    }
  };
}

export function getSaleLogActionsForLot(lotId) {
  const filters = [createTermFilter("lotId", lotId)];
  return async (dispatch, getState) => dispatch(getSaleLogActions(filters));
}

function getSaleLogActions(filters) {
  return async dispatch => {
    dispatch({ type: ADMIN_GET_SALE_LOG_ACTIONS });
    const actions = await getSaleLogActionsApi(filters);

    const filteredActions = actions.filter(
      action => !blacklistedSaleLogActions.includes(action.toLowerCase())
    );

    dispatch(setSaleLogActions(filteredActions));
  };
}

function setSaleLogActions(actions) {
  return {
    type: ADMIN_SET_SALE_LOG_ACTIONS,
    payload: {
      actions
    }
  };
}

export function getBidLogsCsv(lotId) {
  return async (dispatch, getState) => {
    const state = getState();
    const locale = state.user.user.locale;
    let { bidLogFilters: filters, lot } = state.adminBidlogs;
    filters = [
      ...filters,
      createTermFilter("saleAction.lotId", lotId),
      createTermsFilter("saleAction.type", blacklistedSaleLogActions, "MUST_NOT")
    ];

    dispatch({ type: ADMIN_GET_BID_LOGS_CSV });
    dispatch(showNotification("Generating report", Severity.info));

    const { total } = await getSaleActionLogsApi(filters, "items=0-0", "-id");
    let { data } = await getSaleActionLogResults(filters, `items=0-${total}`, "-id");

    const csv = new Csv(getLanguageMap(getState()));

    csv.setHeader([
      "Id",
      "Timestamp",
      "Bid Type",
      "Origin",
      "Action",
      "Action Value",
      "Next Proposal",
      "Accepted Bid",
      "Badge",
      "Bidder"
    ]);

    csv.setRows(
      data?.saleActionLogs.map(log => {
        const userId = log.saleAction?.userId;
        const sale = salesSelector.selectById(state.sales, log.saleAction?.saleId);
        const userName = data.users.find(user => userId === user.id)?.name;
        const formattedLog = formatLog(log, sale, userName, locale);

        return Object.values(formattedLog);
      })
    );

    dispatch(showNotification("Report is ready", Severity.success));

    await downloadCsv(`${lot.lotNumber}_BidLogs_${new Date().toString()}`, csv.get());

    dispatch(dismiss());
  };
}

export function getLot(lotId) {
  return async (dispatch, getState) => {
    dispatch({ type: ADMIN_GET_LOT });
    const lot = await getLotFromApi(lotId);
    dispatch(setLot(lot));
  };
}

function setLot(lot) {
  return {
    type: ADMIN_SET_LOT,
    payload: {
      lot
    }
  };
}

export function setInventoryPage(page) {
  return {
    type: ADMIN_SET_INVENTORY_PAGE,
    payload: {
      page
    }
  };
}

export function toggleInventorySort(field, order = "ASC") {
  return {
    type: ADMIN_TOGGLE_INVENTORY_SORT,
    payload: {
      field,
      order
    }
  };
}

export function resetSync(saleId) {
  return async dispatch => {
    dispatch({
      type: ADMIN_RESET_SYNC,
      payload: {
        saleId
      }
    });
    await resetSyncApi(saleId);
    dispatch(showNotification("Sale re-synced", Severity.success));
  };
}

export function syncSystems(eventCompareResults) {
  return async dispatch => {
    const rowsNotInAmsAndInCubed = eventCompareResults.results.filter(
      row => row.inCubed && !row.inAMS
    );
    const rowsNotInAmsAndInOLR = eventCompareResults.results.filter(row => row.inOLR && !row.inAMS);
    const rowsInAmsCubedAndNotInOLR = eventCompareResults.results.filter(
      row =>
        row.saleStatus !== "STAGED" &&
        row.saleStatus !== "CLOSED" &&
        !row.inOLR &&
        row.inCubed &&
        row.inAMS
    );
    const rowsInAmsAndNotInCubedOrOLR = eventCompareResults.results.filter(
      row => row.inAMS && !row.inOLR && !row.inCubed
    );

    const count =
      rowsNotInAmsAndInCubed.length +
      rowsNotInAmsAndInOLR.length +
      rowsInAmsCubedAndNotInOLR.length +
      rowsInAmsAndNotInCubedOrOLR.length;

    if (count > 0) {
      batch(() => {
        dispatch(showNotification(`Processing ${count} items`, Severity.info));
        dispatch({ type: ADMIN_SYNC_SYSTEMS });
      });

      const withdrawRes = await withdrawLots(rowsNotInAmsAndInCubed.concat(rowsNotInAmsAndInOLR));
      const createRes = await createOLRLots(rowsInAmsCubedAndNotInOLR);
      const amsSyncRes = await requestSyncFromAMS(rowsInAmsAndNotInCubedOrOLR);

      const res = [...withdrawRes, ...createRes, ...amsSyncRes];

      const successCount = res.filter(item => !(item instanceof Error)).length;
      const errorCount = res.filter(item => item instanceof Error).length;
      const errorMsg = errorCount > 0 ? `with ${errorCount} errors` : "";

      dispatch(showNotification(`Processed ${successCount} items ${errorMsg}`, Severity.success));
    } else {
      dispatch(showNotification(`No items to process`, Severity.info));
    }
  };
}

async function getUnwithdrawnLotsForRows(rows) {
  let results = await Promise.all(chunk(rows, 50).map(getUnwithdrawnLotsForRowsChunk));

  return flatten(results);
}

async function getUnwithdrawnLotsForRowsChunk(rows) {
  const { data: lots } = await getLotsFromApi(
    [
      createTermsFilter("assetId", uniq(rows.map(row => row.assetId))),
      createTermsFilter("saleId", uniq(rows.map(row => row.saleId))),
      createTermsFilter("lotNumber", uniq(rows.map(row => row.lotNumber))),
      createTermFilter("status", "WITHDRAWN", "MUST_NOT")
    ],
    createRange(0, 49),
    "+lotNumber"
  );

  return lots;
}

async function withdrawLots(rows) {
  const lots = await getUnwithdrawnLotsForRows(rows);

  return lots.map(withdrawLot);
}

function withdrawLot(lot) {
  lot.status = "WITHDRAWN";
  return updateLot(lot);
}

async function createOLRLots(rows) {
  const lots = await getUnwithdrawnLotsForRows(rows);
  // updating the lot causes it to be marked for sync processing,
  // which creates it in OLR if it isn't there
  return Promise.all(lots.map(updateLot));
}

async function updateLot(lot) {
  try {
    await updateLotInApi(lot);
    return lot;
  } catch (error) {
    return error;
  }
}

function requestSyncFromAMS(rows) {
  return Promise.all(rows.map(row => fastlaneAssetSync(row.assetNumber, row.companyId)));
}

export function compareEvent(eventId) {
  return async (dispatch, getState) => {
    let { saleFilters } = getState().adminEvents;
    let filters = [...saleFilters, createTermFilter("eventId", eventId)];

    dispatch({ type: ADMIN_RUN_EVENT_COMPARE_SCRIPT });

    let eventCompareResults = await callEventCompareScript(filters);

    dispatch({
      type: ADMIN_SET_EVENT_COMPARE_RESULT,
      payload: {
        eventId,
        eventCompareResults
      }
    });
  };
}

export function downloadEventCompareResults() {
  return async (dispatch, getState) => {
    let { eventCompareResults, areEventCompareResultsLoading } = getState().adminEvents;

    if (!areEventCompareResultsLoading) {
      dispatch({ type: ADMIN_DOWNLOAD_EVENT_COMPARE_RESULT });

      const csv = new Csv(getLanguageMap(getState()));

      csv.setHeader([
        "Lane",
        "Asset Number",
        "Lot Number",
        "Year",
        "Make",
        "Model",
        "VIN",
        "In AMS?",
        "In Cubed?",
        "In OLR?"
      ]);

      csv.setRows(
        eventCompareResults.results.map(result => Object.values(formatEventCompareResult(result)))
      );

      const csvData = csv._get();

      const summaries = [
        ["Event: " + eventCompareResults.eventNumber],
        [
          "AMS Summary: " + eventCompareResults.amsSummary,
          "",
          "",
          "AMS Errors: " + eventCompareResults.amsErrors
        ],
        [
          "Cubed Summary: " + eventCompareResults.cubedSummary,
          "",
          "",
          "Cubed Errors: " + eventCompareResults.cubedErrors
        ],
        [
          "OLR Summary: " + eventCompareResults.olrSummary,
          "",
          "",
          "OLR Errors: " + eventCompareResults.olrErrors
        ],
        []
      ];

      csvData.unshift(...summaries);

      dispatch(showNotification("Report is ready", Severity.success));
      await downloadCsv(
        `EventCompareResults_${eventCompareResults.eventNumber}_${new Date().toString()}`,
        JSON.stringify(csvData)
      );
      dispatch(dismiss());
    }
  };
}

const formatEventCompareResult = ({
  saleNumber,
  assetNumber,
  lotNumber,
  year,
  make,
  model,
  vin,
  inAMS,
  inCubed,
  inOLR
}) => ({
  saleNumber,
  assetNumber,
  lotNumber,
  year: year ? year : "",
  make: make ? make : "",
  model: model ? model : "",
  vin: vin ? vin : "",
  inAMS: inAMS ? true : false,
  inCubed: inCubed ? true : false,
  inOLR: inOLR ? true : false
});

export function clearSaleIdFilter() {
  return {
    type: ADMIN_CLEAR_SALES_ID_FILTER
  };
}

export function toggleSaleIdFilter(saleId) {
  return {
    type: ADMIN_TOGGLE_SALES_ID_FILTER,
    payload: {
      saleId
    }
  };
}

export function getSaleStats(eventId) {
  return async (dispatch, getState) => {
    const state = getState();
    const {
      adminEvents: { saleFilters }
    } = state;
    const sales = getSalesByEventId(state.sales, eventId);
    const saleIds = sales.filter(filterSaleFromSaleFilters(saleFilters)).map(sale => sale.id);
    if (saleIds.length > 0) {
      const filters = [createTermsFilter("id", saleIds)];

      dispatch(fetchStats());

      const eventStats = await getSaleStatsAPI(filters);

      dispatch(statsFetchComplete(eventId, "event", eventStats));
    }
  };
}

export function fetchStats() {
  return {
    type: STATS_FETCH
  };
}

export function statsFetchComplete(id, type, stats) {
  return {
    type: STATS_FETCH_COMPLETE,
    payload: {
      id,
      type,
      stats
    }
  };
}

export function statsBulkFetchComplete(type, stats) {
  return {
    type: STATS_BULK_FETCH_COMPLETE,
    payload: {
      type,
      stats
    }
  };
}

export function getStatisticsCsv(eventId, eventName) {
  return async (dispatch, getState) => {
    const state = getState();

    const shouldDisplayCalculatedAmount = getMarketVisibility(state.config.marketSegment, [
      MARKET_SEGMENTS.CATTLE,
      MARKET_SEGMENTS.MERCHANDISE
    ]);

    const saleCurrency = getSalesByEventId(getState().sales, eventId)?.[0]?.currency;
    const intl = new IntlUtil(saleCurrency);

    dispatch(showNotification("Generating report", Severity.info));
    let stats = formatStats(state, eventId, { formatter: intl, shouldDisplayCalculatedAmount });
    dispatch({ type: ADMIN_GET_SALE_STATS_CSV });
    const csvData = [["Statistic", "Value"], ...stats.map(stat => [stat.label, stat.value])];
    dispatch(showNotification("Report is ready", Severity.success));
    await downloadCsv(`${eventName}_Statistics_${new Date().toString()}`, JSON.stringify(csvData));
    dispatch(dismiss());
  };
}

export function updateSaleUserCreditLimit({ userId, saleUserId, creditLimit, pointsLimit }) {
  return async (dispatch, getState) => {
    const { useCreditScript } = getState().config.featureFlags;
    if (useCreditScript) {
      await updateCreditScript({ saleUserId, creditLimit, pointsLimit });
    } else {
      await updateCreditAPI({ saleUserId, creditLimit, pointsLimit });
    }

    dispatch({
      type: ADMIN_UPDATE_SALEUSER_CREDIT_LIMIT,
      payload: { userId, saleUserId, creditLimit, pointsLimit }
    });
    dispatch(showNotification("Credit limit updated", Severity.success));
  };
}

export function setDealershipFilter(filter) {
  return {
    type: ADMIN_SET_DEALERSHIPS_FILTER,
    payload: {
      filter
    }
  };
}

export function getMedia(entityType, entityId) {
  return async dispatch => {
    let filters = [
      createTermFilter("entityType", entityType),
      createTermFilter("entityId", entityId),
      createTermFilter("primary", true)
    ];
    dispatch({ type: MEDIAS_GET });
    const { data: medias } = await getMediasFromApi(filters, "items=0-0");
    if (medias.length > 0) {
      dispatch(setEntityMedia(medias?.[0]));
    }
  };
}

export function setEntityMedia(media) {
  return {
    type: MEDIA_SET,
    payload: {
      id: media.entityId,
      media
    }
  };
}

export function setMedias(medias) {
  return {
    type: MEDIAS_SET,
    payload: {
      medias
    }
  };
}

export function getConsignors(eventId) {
  return async dispatch => {
    dispatch({ type: ADMIN_FETCH_CONSIGNORS });
    const consignors = await getConsignorsAPI(eventId);
    dispatch({ type: ADMIN_FETCH_CONSIGNORS_COMPLETE, payload: { consignors } });
  };
}

export function getConsignorReport(eventId) {
  return async (dispatch, getState) => {
    dispatch({ type: ADMIN_FETCH_CONSIGNOR_REPORT });

    const { selectedLanes, selectedConsignorNumbers } = getState().adminConsignorReport;

    const filters = [];

    if (selectedLanes.length > 0) {
      filters.push(createTermsFilter("saleNumber", selectedLanes));
    }

    if (selectedConsignorNumbers.length > 0) {
      filters.push(createTermsFilter("consignorNumber", selectedConsignorNumbers));
    }

    const consignorReport = await getConsignorReportFromAPI(eventId, filters);
    dispatch({ type: ADMIN_SET_CONSIGNOR_REPORT, payload: { ...consignorReport } });
  };
}

export function downloadConsignorReport(report) {
  return async dispatch => {
    dispatch(showNotification("Generating report", Severity.info));
    const date = report.find(item => item.label === "Date");
    const fileName = `${date.value} Consignor Report`;
    const csv = JSON.stringify(formatConsignorReportForCsv(report));
    dispatch(showNotification("Report is ready", Severity.success));
    await downloadCsv(fileName, csv);
  };
}

function formatConsignorReportForCsv(report) {
  return report.map(item => [item.label, item.value]);
}

export function consignorReportToggleConsignor(consignorNumber) {
  return {
    type: ADMIN_CONSIGNOR_REPORT_TOGGLE_CONSIGNOR,
    payload: {
      consignorNumber
    }
  };
}

export function consignorReportToggleLane(saleNumber) {
  return {
    type: ADMIN_CONSIGNOR_REPORT_TOGGLE_LANE,
    payload: {
      saleNumber
    }
  };
}

export function toggleSelectAllConsignors() {
  return {
    type: ADMIN_CONSIGNOR_REPORT_TOGGLE_SELECT_ALL_CONSIGNORS,
    payload: {}
  };
}

export function toggleSelectAllLanes(lanes) {
  return {
    type: ADMIN_CONSIGNOR_REPORT_TOGGLE_SELECT_ALL_LANES,
    payload: {
      lanes
    }
  };
}

export function updateMembership(membership, notify = true) {
  return async dispatch => {
    await updateMembershipAPI(membership);

    dispatch({ type: MEMBERSHIP_UPDATE, payload: { membership } });
    if (notify) {
      dispatch(showNotification("Membership fields updated", Severity.success));
    }
  };
}

export function updateLocalMembership(membership) {
  return {
    type: MEMBERSHIP_UPDATE,
    payload: { membership }
  };
}

export function sendUpcomingNotification(eventId) {
  return async (dispatch, getState) => {
    const event = eventsSelector.selectById(getState().events, eventId);
    if (event) {
      try {
        dispatch({ type: NOTIFICATIONS_SEND });
        await sendUpcomingNotificationFromAPI(eventId);
        dispatch(updateAdminEventAction({ ...event, upcomingNotificationSent: true }));
        dispatch(showNotification("Event email notifications sent", Severity.success));
      } finally {
        dispatch({ type: NOTIFICATIONS_SENT });
      }
    }
  };
}

export function upsertDealership(dealership) {
  return async (dispatch, getState) => {
    if (dealership.id) {
      await updateDealership(dealership);
      const currentDealership = getState().dealerships.dealerships[dealership.id];
      dealership = { ...currentDealership, ...dealership };
    } else {
      const id = await createDealership(dealership);
      dealership.id = id;
    }

    dispatch(dealershipActions.upsertDealership(dealership));
  };
}

export function upsertCommunityDealership(dealership) {
  return async (dispatch, getState) => {
    if (dealership.id) {
      await updateDealership(dealership);
      const currentDealership = getState().dealerships.dealerships[dealership.id];
      dealership = { ...currentDealership, ...dealership };
    } else {
      const company = await getRegistrationCompany();

      if (company == null) {
        throw new Error("Registration company not found");
      }

      dealership.companyId = company.id;

      const id = await createCommunityDealership(dealership);
      dealership.id = id;
    }

    await TimerUtil.delay(1000);

    dispatch(dealershipActions.upsertDealership(dealership));
  };
}

function getAttendeeStatsFilters(state, eventId) {
  const {
    adminEvents: { saleFilters }
  } = state;

  const filters = [createTermFilter("eventId", eventId)];
  const sales = getSalesByEventId(state.sales, eventId);

  if (sales.length > 0) {
    filters.push(
      createTermsFilter(
        "id",
        sales.filter(filterSaleFromSaleFilters(saleFilters)).map(sale => sale.id)
      )
    );
  }

  return filters;
}

function addAttendeesFilters(state, eventId) {
  const {
    adminAttendees,
    adminEvents: { saleFilters }
  } = state;

  const filters = [createTermFilter("eventId", eventId)];
  const sales = getSalesByEventId(state.sales, eventId);

  if (sales.length > 0) {
    filters.push(
      createTermsFilter(
        "saleId",
        sales.filter(filterSaleFromSaleFilters(saleFilters)).map(sale => sale.id)
      )
    );
  }

  for (const field in adminAttendees.filters) {
    const value = adminAttendees.filters[field];

    if (!value) {
      continue;
    }

    if (field === "isPublic") {
      filters.push(
        createExistsFilter("user.fields.auctionAccessId", JSON.parse(value) ? "MUST_NOT" : "MUST")
      );
    } else if (field === "search") {
      filters.push(createQueryFilter(`*${value}*`, undefined, "user."));
    } else {
      filters.push(createTermFilter(field, value));
    }
  }

  return filters;
}
