import { CONFIG_GET, CONFIG_SET, SET_WELCOME_SCREEN_FLAG } from "./actionTypes";
import { getApplicationConfiguration as getAppConfigFromApi } from "api/configRequests";

export function getApplicationConfiguration() {
  return async dispatch => {
    dispatch({ type: CONFIG_GET });
    try {
      const appConfig = await getAppConfigFromApi();
      dispatch(setApplicationConfiguration(appConfig.configurationProperties));
    } catch (error) {}
  };
}

function setApplicationConfiguration(config) {
  return {
    type: CONFIG_SET,
    payload: {
      config
    }
  };
}

export function setWelcomeScreenFlag(showMobileWelcomeScreen) {
  return {
    type: SET_WELCOME_SCREEN_FLAG,
    payload: {
      showMobileWelcomeScreen
    }
  };
}
