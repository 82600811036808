export function getRange(page, perPage) {
  let rangeStart = page * perPage;
  let rangeEnd = (page + 1) * perPage - 1;
  rangeEnd = Math.max(0, rangeEnd);
  return `items=${rangeStart}-${rangeEnd}`;
}

export function getPageCount(total, perPage) {
  return Math.ceil(total / perPage);
}
