import ScrollTop from "./ScrollToTop";
import { Fab } from "@mui/material";
import { KeyboardArrowUp } from "@mui/icons-material";

const ScrollTopContainer = () => {
  return (
    <>
      <ScrollTop>
        <Fab color="primary" size="small" aria-label="scroll back to top">
          <KeyboardArrowUp />
        </Fab>
      </ScrollTop>
    </>
  );
};

export default ScrollTopContainer;
