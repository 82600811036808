import { createReducer } from "@reduxjs/toolkit";
import {
  DASHBOARD_SET_DATE,
  DASHBOARD_TOGGLE_SALE_TYPE,
  DASHBOARD_SET_SALE_TYPES,
  DASHBOARD_SET_COMPANIES
} from "actions/actionTypes";
import moment from "moment";
import { createSelector } from "@reduxjs/toolkit";

const dateFrom = moment()
  .subtract(7, "day")
  .startOf("day")
  .valueOf();
const dateTo = moment()
  .endOf("day")
  .valueOf();

const initialState = {
  dateFrom,
  dateTo,
  saleTypes: [],
  selectedCompanyIds: []
};

export default createReducer(initialState, builder => {
  builder
    .addCase(DASHBOARD_SET_DATE, (state, action) => {
      const { prop, date } = action.payload;
      state[prop] = date ? date : initialState[prop];
    })
    .addCase(DASHBOARD_TOGGLE_SALE_TYPE, (state, action) => {
      const { saleType } = action.payload;
      const saleTypes = state.saleTypes;
      if (saleTypes.includes(saleType)) {
        state.saleTypes = saleTypes.filter(filterSaleType(saleType));
      } else {
        saleTypes.push(saleType);
      }
    })
    .addCase(DASHBOARD_SET_SALE_TYPES, (state, action) => {
      const { saleTypes } = action.payload;
      state.saleTypes = saleTypes || initialState.saleTypes;
    })
    .addCase(DASHBOARD_SET_COMPANIES, (state, action) => {
      const { selectedCompanyIds } = action.payload;
      state.selectedCompanyIds = selectedCompanyIds || [];
    });
});

export function filterSaleType(value) {
  return saleType => saleType !== value;
}

export const getDashboardDateRangeKey = createSelector(
  state => state.dashboard.dateFrom,
  state => state.dashboard.dateTo,
  (from, to) => `${from}-${to}`
);
