import { matchPath, useLocation } from "react-router-dom";

const useLocationMatches = (to, exact) => {
  const location = useLocation();
  if (location.hash && exact) return location.hash === to.hash;
  const matches = Boolean(matchPath(location.pathname, { path: to, exact }));
  return matches;
};

export { useLocationMatches as default };
