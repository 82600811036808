import _uniq from "lodash/uniq";

export function toMap(prop) {
  return function(entityMap, entity) {
    entityMap[entity[prop]] = entity;
    return entityMap;
  };
}

export function uniq(list) {
  return _uniq(list);
}
