import React from "react";
import { makeStyles } from "tss-react/mui";

type StackProps = {
  direction?: "row" | "column";
  spacing?: number;
  divider?: React.ReactNode;
  children: React.ReactElement[];
};

const useStyles = makeStyles<StackProps>()((theme, { direction, spacing }) => ({
  stack: {
    flexShrink: 0,
    display: "flex",
    alignItems: "center",
    flexDirection: direction,
    "& > :not(:first-child)": {
      margin: direction === "row" ? theme.spacing(0, 0, 0, spacing!) : theme.spacing(spacing!, 0, 0)
    }
  },
  stack_divider: {
    flexShrink: 0,
    display: "flex",
    alignItems: "center",
    flexDirection: direction,
    "& > *": {
      margin: direction === "row" ? theme.spacing(0, spacing!) : theme.spacing(spacing!, 0)
    }
  }
}));

function Stack(props: StackProps) {
  const { classes } = useStyles(props, {
    props
  });

  const { children, divider } = props;

  const numberOfChildren = React.Children.count(children);

  if (divider) {
    return (
      <div className={classes.stack_divider}>
        {React.Children.map(children, (child, index) => {
          if (index === numberOfChildren - 1) return child;

          return (
            <>
              {child}
              {divider}
            </>
          );
        })}
      </div>
    );
  } else {
    return <div className={classes.stack}>{children}</div>;
  }
}

Stack.defaultProps = {
  direction: "row",
  spacing: 0
};

export default Stack;
