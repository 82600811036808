import { post, get } from "./api";

export async function runSearchHistoryScript() {
  const { data } = await get("application/rubix/script/searchHistory/run");
  return data;
}

export async function runEventCompareScript(filters, range, sort) {
  const { data } = await get("application/rubix/script/eventCompare/run", filters, range, sort);
  return data;
}

export async function registerBadges(payload) {
  const { data } = await post("application/rubix/script/olrRegisterBadges/run", payload);
  return data;
}

export async function fastlaneGetLotId(assetNumber, eventNumber, companyCode) {
  const { data } = await post("application/rubix/script/fastlaneGetLotId/run", {
    assetNumber,
    eventNumber,
    companyCode
  });
  return data;
}

export async function updateCredit(credit) {
  const { data } = await post("application/rubix/script/updateCredit/run", {
    credit
  });
  return data;
}

export async function getClerkMasterFiles(companyId) {
  const { data } = await post("application/rubix/script/getClerkMasterFiles/run", {
    companyId
  });
  return data;
}
