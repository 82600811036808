import { createSlice, createEntityAdapter } from "@reduxjs/toolkit";
import { REHYDRATE } from "redux-persist/lib/constants";

import { LANGUAGE_FETCH_COMPLETE } from "actions/actionTypes";

const languageAdapter = createEntityAdapter({
  selectId: languageMap => `${languageMap.language}/${languageMap.marketSegment}`
});

const initialState = { selectedLanguage: "english", isLoading: true };

const language = createSlice({
  name: "language",
  initialState: languageAdapter.getInitialState(initialState),
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(REHYDRATE, () => languageAdapter.getInitialState(initialState))
      .addCase(LANGUAGE_FETCH_COMPLETE, (state, action) => {
        state.isLoading = false;
        languageAdapter.upsertOne(state, action.payload);
      });
  }
});

const { reducer } = language;
export default reducer;

export const languageSelector = languageAdapter.getSelectors(state => state.language);

export const directLanguageSelector = languageAdapter.getSelectors();

export function getLanguageMap(state) {
  const marketSegment = state.config.marketSegment;
  const selectedLanguage = state.language.selectedLanguage;
  return languageSelector.selectById(state, `${selectedLanguage}/${marketSegment}`)?.languageMap;
}
