import { memo } from "react";
import { useDispatch, useSelector } from "utils/StoreUtil";
import { makeStyles } from "tss-react/mui";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Switch from "@mui/material/Switch";

import LightIcon from "@mui/icons-material/WbSunny";
import DarkIcon from "@mui/icons-material/NightsStay";

const useStyles = makeStyles()(theme => ({
  label: {
    marginLeft: 0
  }
}));

function DarkModeSwitch(props) {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const isDarkMode = useSelector(state => state.config.darkMode);

  const handleClick = () => dispatch({ type: "TOGGLE_DARK_MODE" });

  return (
    <>
      <ListItem>
        <ListItemIcon>{isDarkMode ? <DarkIcon /> : <LightIcon />}</ListItemIcon>
        <FormGroup row>
          <FormControlLabel
            labelPlacement="start"
            control={<Switch checked={isDarkMode} onChange={handleClick} name="darkMode" />}
            label="Dark Mode"
            classes={{ labelPlacementStart: classes.label }}
          />
        </FormGroup>
      </ListItem>
    </>
  );
}

export default memo(DarkModeSwitch);
