import { createSlice, createEntityAdapter, createSelector } from "@reduxjs/toolkit";
import { REHYDRATE } from "redux-persist/lib/constants";
import { inventoryFetchComplete } from "actions/sharedActions";

const NAME = "fees";

const feesAdapter = createEntityAdapter();

const fees = createSlice({
  name: NAME,
  initialState: feesAdapter.getInitialState(),
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(REHYDRATE, () => feesAdapter.getInitialState())
      .addCase(inventoryFetchComplete, (state, action) => {
        const fees = action.payload[NAME];
        if (fees) {
          feesAdapter.setAll(state, fees);
        }
      });
  }
});

function getFeeIds(state) {
  return state.ids;
}

function getFees(state) {
  return state.entities;
}

function getAssetId(_, assetId) {
  return assetId;
}

export function makeGetAssetFees() {
  return createSelector(getFeeIds, getFees, getAssetId, (feeIds, fees, assetId) => {
    return feeIds.reduce((assetFees, feeId) => {
      const fee = fees[feeId];
      if (fee && fee.assetId === assetId) {
        assetFees.push(fee);
      }
      return assetFees;
    }, []);
  });
}

const { reducer } = fees;

export const feesSelector = feesAdapter.getSelectors(state => state.fees);

export default reducer;
