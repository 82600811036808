import { get, post, put, del, getTotal, download } from "./api";
import uniqueId from "lodash/uniqueId";

export async function getEvent(eventId) {
  const { data } = await get(`event/${eventId}`);
  return data;
}

export async function createEvent(event) {
  const { data } = await post("event", { ...event });
  return data;
}

export async function updateEvent(event, eventId) {
  return put(`event/${eventId}`, { ...event });
}

export async function flushEvent(eventId) {
  await del(`application/community/event/${eventId}`);
}

export async function getSale(saleId) {
  const { data } = await get(`sale/${saleId}`);
  return data;
}

export async function createSale(sale) {
  const { data } = await post("sale", { ...sale });
  return data;
}

export async function updateSale(sale) {
  return put(`sale/${sale.id}`, { ...sale });
}

export async function flushSale(saleId) {
  await del(`application/community/sale/${saleId}`);
}

export async function getFacilities(filters) {
  const res = await get("facility", filters, "items=0-0", undefined, undefined, {
    hideChildCompanyResults: true
  });
  const { data } = await get(
    "facility",
    filters,
    `items=0-${getTotal(res)}`,
    undefined,
    undefined,
    { hideChildCompanyResults: true }
  );
  return data;
}

export async function getPolicies(filters, range, sort) {
  const res = await get("policy", filters, range, sort);
  return { data: res.data, total: getTotal(res) };
}

export async function getPolicy(policyId) {
  const { data } = await get(`policy/${policyId}`);
  return data;
}

export async function downloadInventoryCsv(csvMediaId, fileName) {
  return download(`/media/${csvMediaId}`, "csv", fileName, undefined, "get");
}

export async function getCredits(filters, range, sort) {
  const res = await get("credit", filters, range, sort);
  return { data: res.data, total: getTotal(res) };
}

export async function updateCredit(credit) {
  const { data } = await post(`application/community/updateCredit`, credit);
  return data;
}

export async function getCompanySchemas(companyId) {
  const { data } = await get(`company/${companyId}/schema/asset`);
  return data;
}

export async function getSchema(schemaId) {
  const { data } = await get(`schema/${schemaId}`);
  return data;
}

export async function getSchemas(filters, range, sort) {
  const res = await get("schema", filters, range, sort);
  return { data: res.data, total: getTotal(res) };
}

export async function getDataTypes(filters) {
  const res = await get("dataType", filters, "items=0-0");

  const { data } = await get("dataType", filters, `items=0-${getTotal(res)}`);

  return data;
}

export async function getConsignors(eventId) {
  const { data } = await get(`application/community/event/${eventId}/consignors`);
  return data;
}

export async function getConsignorReport(eventId, filters) {
  const { data } = await get(`application/community/fastlane/consignorReport/${eventId}`, filters);
  return data;
}

export async function getSaleActionLogReportResults(saleId) {
  const { data } = await get(`application/community/sale/${saleId}/saleActionLog`);
  return data;
}

export async function getItemDetails(saleId) {
  const { data } = await get(`application/community/sale/${saleId}/itemDetails`);
  return data;
}

export async function fastlaneAssetSync(assetNumber, companyId) {
  return put(`ams/sync/${companyId}/asset/${assetNumber}`);
}

export async function getUserDealershipDetails(detailsType, id, filters) {
  const { data } = await get(`application/community/details/${detailsType}/${id}`, filters);
  return data;
}

export async function getEventInventory(filters, range, sort) {
  const { data } = await get(`application/community/admin/inventory`, filters, range, sort);
  return data;
}

export async function getEventManagementInventory(filters, range, sort) {
  const { data } = await get(
    `application/community/admin/inventory/eventManagement`,
    filters,
    range,
    sort
  );
  return data;
}

export async function getEventmanagementResources(filters) {
  const { data } = await get(`application/community/admin/eventManagement`, filters);
  return data;
}

export async function getDealerships(filters, range, sort) {
  const res = await get(
    `application/community/dealerships?request.preventCache=${uniqueId("dealership")}`,
    filters,
    range,
    sort
  );
  return { data: res.data, total: getTotal(res) };
}

export async function getParentDealerships(filters, range, sort) {
  const res = await get(
    `application/community/parentDealerships?request.preventCache=${uniqueId("dealership")}`,
    filters,
    range,
    sort
  );
  return { data: res.data, total: getTotal(res) };
}

export async function getDealershipUsers(filters, range, sort) {
  const res = await get(
    `application/community/dealershipUser?request.preventCache=${uniqueId("dealershipUser")}`,
    filters,
    range,
    sort
  );
  return { data: res.data, total: getTotal(res) };
}

export async function updateDealershipUser(dealershipUser) {
  await put(`application/community/dealershipUser/${dealershipUser.id}`, dealershipUser);
}

export async function createDealershipUser(dealershipUser) {
  const { data: id } = await post("application/community/dealershipUser", dealershipUser);

  return id;
}

export async function getStandaloneDealerships(filters, range, sort) {
  const res = await get(
    `dealership?request.preventCache=${uniqueId("dealership")}`,
    filters,
    range,
    sort
  );
  return { data: res.data, total: getTotal(res) };
}

export async function createDealership(dealership) {
  const { data: id } = await post("dealership", dealership);
  return id;
}

export async function createCommunityDealership(dealership) {
  const { data: id } = await post("application/community/dealership", dealership);
  return id;
}

export async function updateDealership(dealership) {
  await put(`application/community/dealerships/${dealership.id}`, dealership);
}

export async function updateMembership(membership) {
  const { data } = await put(`membership/${membership.id}`, membership);
  return data;
}

export async function downloadCsvTemplates(schemaName) {
  download(
    `${process.env.PUBLIC_URL}/templates/csv/${schemaName}.csv`,
    "csv",
    "template",
    undefined,
    "get",
    undefined,
    undefined,
    {
      charset: "utf-8"
    }
  );
}

export async function sendUpcomingNotification(eventId) {
  await get(`event/${eventId}/sendUpcomingNotification`);
}

export async function getMembershipSchemas(filters, range, sort, extraHeaders) {
  const res = await get("membershipSchema", filters, range, sort, undefined, extraHeaders);

  return { data: res.data, total: getTotal(res) };
}

export async function getMembershipSchema(companyId) {
  const { data } = await get(`membershipSchema/company/${companyId}`);

  return data;
}

export async function createMembershipSchema(membershipSchema) {
  const { data: id } = await post("membershipSchema", membershipSchema);

  return id;
}

export async function updateMembershipSchema(membershipSchema) {
  await put(`membershipSchema/${membershipSchema.id}`, membershipSchema);
}

export async function getMembershipSchemaFields(filters) {
  const { data: membershipFieldsSchemas } = await getMembershipSchemas(
    filters,
    "items=0-0",
    undefined,
    {
      hideChildCompanyResults: true
    }
  );
  return membershipFieldsSchemas[0]?.schemaFields || [];
}

export async function getUISchemas(filters, range, sort, extraHeaders) {
  const res = await get("uiSchema", filters, range, sort, undefined, extraHeaders);

  return { data: res.data, total: getTotal(res) };
}

export async function createUISchema(uiSchema) {
  const { data: id } = await post("uiSchema", uiSchema);

  return id;
}

export async function updateUISchema(uiSchema) {
  await put(`uiSchema/${uiSchema.id}`, uiSchema);
}

export async function getAttendees(filters, range) {
  const res = await get("application/community/attendees", filters, range);
  return { data: res.data, total: getTotal(res) };
}

export async function getCompanyUsers(filters, range, sort, extraHeaders) {
  const res = await get(
    "application/community/companyUsers",
    filters,
    range,
    sort,
    undefined,
    extraHeaders
  );

  return { data: res.data, total: getTotal(res) };
}
