import { createReducer, createSelector } from "@reduxjs/toolkit";
import {
  COMET_SUBSCRIBE,
  COMET_UNSUBSCRIBE,
  COMET_CONNECT,
  COMET_DISCONNECT
} from "actions/actionTypes";
import { cometMessage } from "actions/sharedActions";

const initialState = {
  isConnected: false,
  channels: {}
};

const comet = createReducer(initialState, builder => {
  builder
    .addCase(COMET_CONNECT, state => {
      state.isConnected = true;
    })
    .addCase(COMET_DISCONNECT, state => {
      state.isConnected = false;
    })
    .addCase(COMET_SUBSCRIBE, (state, action) => {
      const { channel } = action.payload;
      if (!(channel in state.channels)) {
        state.channels[channel] = {
          message: null
        };
      }
    })
    .addCase(COMET_UNSUBSCRIBE, (state, action) => {
      const { channel } = action.payload;
      if (channel in state.channels) {
        delete state.channels[channel];
      }
    })
    .addCase(cometMessage, (state, action) => {
      const { channel, data } = action.payload;
      state.channels[channel].message = data;
    });
});

export default comet;

export const getChannels = state => state.channels;

export const getChannel = (state, props) => getChannels(state)[props.channel];

export const getLotChannel = (state, lotId) => getChannels(state)[`/lot/${lotId}`];

export const getSaleChannel = (state, saleId) => getChannels(state)[`/sale/${saleId}`];

export const getPrebidChannel = (state, saleId) => getChannels(state)[`/preBids/${saleId}`];

export const getWatchListChannel = (state, saleId) => getChannels(state)[`/watches/${saleId}`];

export const channelExists = createSelector(getChannel, channel => !!channel);

export const filterSubscribers = (state, props) => {
  const channel = getChannel(state, props);
  return (
    channel && channel.subscribers && channel.subscribers.filter(sub => sub !== props.subscriber)
  );
};

export const parseCometMessage = ({ data, channel }) => ({
  data: JSON.parse(data),
  channel
});

export const getSale = (state, saleId) => {
  const channel = getSaleChannel(state, saleId);
  return channel && channel.message;
};

export const getSaleStatus = createSelector(
  getSaleChannel,
  channel => channel && channel.message && channel.message.status
);

export const getSaleId = createSelector(
  getLotChannel,
  channel => channel && channel.message && channel.message.saleId
);

export const subscriptionCanBeRemoved = createSelector(
  filterSubscribers,
  subscribers => subscribers && subscribers.length <= 0
);

export const makeGetCurrentLotId = () =>
  createSelector(
    getSaleChannel,
    filterSubscribers,
    channel => channel && channel.message && channel.message.currentLotId
  );

export const makeGetSaleIdFromLot = () =>
  createSelector(getLotChannel, channel => channel && channel.message && channel.message.saleId);

export const getLotStatus = (state, lotId) => {
  const channel = getLotChannel(state, lotId);
  let status = channel && channel.message && channel.message.status;
  if (status === "IFSOLD") status = "PENDING";
  return status;
};

export const getPrebidByLotId = (state, saleUserId, saleId, lotId) => {
  const channel = getPrebidChannel(state, saleId);
  const preBids = channel && channel.message;
  if (preBids && preBids.length > 0) {
    return preBids.find(preBid => preBid.lotId === lotId && preBid.saleUserId === saleUserId);
  }
  return null;
};

export const getProposalAmounts = (state, lotId) => {
  const channel = getLotChannel(state, lotId);
  const lastAction = channel && channel.message && channel.message.lastAction;
  const proposalAmounts = lastAction && lastAction.type === "reject" && lastAction.proposalAmounts;
  if (proposalAmounts && proposalAmounts.length > 0) {
    return proposalAmounts;
  }
  return null;
};

export const getUserStatsChannel = (state, userStatsId) =>
  getChannels(state)[`/userStats/${userStatsId}`];

export const getWatchCount = (state, userStatsId) => {
  const channel = getUserStatsChannel(state, userStatsId);
  return channel && channel.message && channel.message.watchCount;
};

export const getNewNotificationCount = (state, userStatsId) => {
  const channel = getUserStatsChannel(state, userStatsId);
  return channel && channel.message && channel.message.newNotificationCount;
};
