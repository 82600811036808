import { createSlice, createEntityAdapter } from "@reduxjs/toolkit";
import { REHYDRATE } from "redux-persist/lib/constants";

import { INVENTORY_SEARCH_OPTIONS_RESET_STATE } from "actions/actionTypes";

import { allowedAutomotiveSaveSearchOptions } from "utils/SavedSearchUtil";

type SavedSearch = {
  id: number;
  createdOn: number;
  createdById: number;
  updatedOn: number;
  updatedById: number;
  name: string;
  filters: any[];
  originalFilters: any[];
  query: string;
  companyId: number;
  userId: number;
  searchType: string;
  shouldNotify: boolean;
};

export const savedSearchesAdapter = createEntityAdapter<SavedSearch>({
  sortComparer: (a, b) => b.createdOn - a.createdOn
});

export const initialState = savedSearchesAdapter.getInitialState({
  isSaving: false,
  isLoading: false,
  selectedId: null
});

const savedSearchesSlice = createSlice({
  name: "savedSearches",
  initialState,
  reducers: {
    selectSavedSearch: (state, action) => {
      state.selectedId = action.payload || initialState.selectedId;
    },
    savedSearchesSaving: state => {
      state.isSaving = true;
    },
    savedSearchesLoading: state => {
      state.isLoading = true;
    },
    setSavedSearchesResults: (state, action) => {
      state.isLoading = false;
      if (action.payload) {
        savedSearchesAdapter.setAll(state, action.payload);
      }
    },
    upsertSavedSearch: (state, action) => {
      state.isSaving = false;
      if (action.payload) {
        savedSearchesAdapter.upsertOne(state, action.payload);
      }
    },
    removeSavedSearch: (state, action) => {
      if (action.payload) {
        savedSearchesAdapter.removeOne(state, action.payload);
      }
    }
  },
  extraReducers: builder => {
    builder
      .addCase(REHYDRATE, () => ({ ...initialState }))
      .addCase(INVENTORY_SEARCH_OPTIONS_RESET_STATE, state => {
        state.selectedId = initialState.selectedId;
      });
  }
});

export const {
  selectSavedSearch,
  savedSearchesSaving,
  savedSearchesLoading,
  setSavedSearchesResults,
  upsertSavedSearch,
  removeSavedSearch
} = savedSearchesSlice.actions;

export default savedSearchesSlice.reducer;

export const savedSearchesSelector = savedSearchesAdapter.getSelectors();

export function mapFiltersToSelectedSearchOptions(filters: any[] = []) {
  return filters.reduce((selectedSearchOptions, filter) => {
    const keyName = getKeyName(filter);
    if (allowedAutomotiveSaveSearchOptions.includes(keyName)) {
      // @ts-ignore
      selectedSearchOptions[getKeyName(filter)] = getValue(keyName, filter);
    }
    return selectedSearchOptions;
  }, {});
}

function getKeyName(filter: any) {
  switch (filter.field) {
    case "sale.eventId":
      return "events";
    case "sale.id":
      return filter.filterType === "TERMS" ? "saleId" : "sale.id";
    case "companyId":
      return "companies";
    case "lot.consignorName":
      return "consignor";
    case "lot.status":
      return "lotStatus";
    case "asset.fields.year":
      return filter.equalityType === "GTE" ? "fromYear" : "toYear";
    case "asset.fields.mileage":
      return filter.equalityType === "GTE" ? "fromMileage" : "toMileage";
    case "asset.fields.odometerUnit":
      return "odometerUnit";
    case "asset.fields.estimates.source":
      return "MMR";
    case "sale.saleType":
      return filter.value === "staged" ? "upcomingInventory" : "saleType";
    case "sale.allowBuyItNow":
      return "buyItNow";
    case "asset.fields.overallConditionGradeNumber":
      return filter.equalityType === "GTE" ? "fromGrade" : "toGrade";
    case "":
      return "queryString";
    default:
      const fields = filter.field.split(".");
      return fields[fields.length - 1];
  }
}

function getValue(keyName: string, filter: any) {
  switch (keyName) {
    case "keys":
    case "MMR":
    case "watchlist":
    case "buyItNow":
      return true;
    case "queryString":
      return filter.value;
    case "upcomingInventory":
      return filter.boolType === "MUST" ? "unlisted" : "listed";
    case "saleType":
      return filter.boolType === "MUST" ? "timed" : "simulcast";
    default:
      return filter.filterType === "TERMS" ? filter.values : filter.value;
  }
}
