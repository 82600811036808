import { useMemo } from "react";
import { useSelector } from "utils/StoreUtil";
import { useLot, useSale, useCreditBalances } from "hooks/sale";
import useForceUpdate from "hooks/useForceUpdate";
import useScheduled from "hooks/useScheduled";
import moment from "moment";
import useCustomBid from "hooks/sale/useCustomBid";
import { BIDDING_ERRORS } from "utils/constants";
import { formatCurrency } from "utils/CurrencyUtil";

const ACTIVE = "ACTIVE";
const CLOSED = "CLOSED";
const COMPLETED = "COMPLETED";
const TIMED = "TIMED";
const PRE = "PRE";
const MAX = "MAX";
const BUY_NOW = "BUY_NOW";
const QUICK = "QUICK";
const UNSOLD = "UNSOLD";
const OFFER = "OFFER";

const LIVE_LOT_ACTIVE_STATUSES = ["PREBID", UNSOLD];
const TIMED_LOT_ACTIVE_STATUSES = ["PREBID", UNSOLD];

const LIVE_SALE_ACTIVE_STATUSES = ["STAGED", "PRESALE"];

const LIVE_BID_TYPES = [BUY_NOW, PRE];
const TIMED_BID_TYPES = [BUY_NOW, QUICK, OFFER, MAX];
const STAGED_BID_TYPES = [BUY_NOW];

function useCanBid(lotId, saleId, saleUserId, bidType) {
  const forceUpdate = useForceUpdate();

  const { saleStatus, saleType, saleCreatedOn, saleStartTime, saleCurrency } = useSale(
    saleId,
    sale => ({
      saleStatus: sale.status,
      saleType: sale.saleType,
      saleEndTime: sale.endTime,
      saleCreatedOn: sale.createdOn,
      saleStartTime: sale.startTime,
      saleCurrency: sale.currency
    })
  );
  const {
    lotStatus,
    lotConsignorNumber,
    bidAmount,
    highBidder,
    lotStartTime,
    lotEndTime,
    highBidType,
    pointsLimit: lotPointsLimit
  } = useLot(lotId, lot => ({
    lotStatus: lot.status,
    lotStartTime: lot.startTime,
    lotEndTime: lot.endTime,
    lotConsignorNumber: lot.consignorNumber,
    bidAmount: bidType === BUY_NOW ? lot.buyNowAmount : lot.askAmount,
    highBidder: lot?.highBid?.saleUserId,
    highBidType: lot?.highBid?.bidType,
    pointsLimit: lot?.pointsLimit
  }));
  const maxBidAmount = useCustomBid(lotId, saleId, saleUserId, "MAX", bid => bid.amount);

  const saleUser = useSelector(state =>
    state.saleUsers[saleId]?.find(saleUser => saleUser.id === saleUserId)
  );
  const offset = useSelector(state => state.time.offset);
  const { creditBalance, pointsBalance: userPointsBalance } = useCreditBalances(saleUser?.id);
  const now = Date.now() + offset;

  const startTime = useMemo(() => {
    switch (saleType) {
      case TIMED:
        return lotStartTime;
      case "LIVE":
      case "LIVE_LEGACY":
        return saleCreatedOn;
      case "STAGED":
        return lotStartTime;
      default:
        return null;
    }
  }, [lotStartTime, saleCreatedOn, saleType]);

  const endTime = useMemo(() => {
    switch (saleType) {
      case TIMED:
        return lotEndTime || null;
      case "LIVE":
      case "LIVE_LEGACY": {
        const startTime = moment(saleStartTime).subtract({ days: 1 });
        return startTime.valueOf();
      }
      case "STAGED":
        return lotEndTime;
      default:
        return null;
    }
  }, [saleType, lotEndTime, saleStartTime]);

  const isActive = getIsActive(saleType, bidType, lotStatus, saleStatus, startTime, endTime, now); // this is null logic

  const { canBid, error } = getCanBid(
    isActive,
    bidAmount,
    creditBalance,
    lotConsignorNumber,
    saleUser,
    bidType,
    highBidder,
    highBidType,
    maxBidAmount,
    saleCurrency,
    lotPointsLimit,
    userPointsBalance
  );

  useScheduled(forceUpdate, startTime);
  useScheduled(forceUpdate, endTime);

  return useMemo(
    () => ({
      isActive,
      canBid,
      error
    }),
    [isActive, canBid, error]
  );
}

export function getIsActive(saleType, bidType, lotStatus, saleStatus, startTime, endTime, now) {
  return (
    validateTimes(startTime, endTime, now, bidType, saleType) &&
    validateBidType(saleType, bidType) &&
    validateSaleStatus(saleType, saleStatus, bidType) &&
    validateLotStatus(saleType, lotStatus)
  );
}

export function validateTimes(startTime, endTime, now, bidType) {
  if (bidType === BUY_NOW) {
    return now < endTime;
  }
  return now >= startTime && now < endTime;
}

export function validateBidType(saleType, bidType) {
  switch (saleType) {
    case TIMED:
      return TIMED_BID_TYPES.includes(bidType);
    case "LIVE_LEGACY":
    case "LIVE":
      return LIVE_BID_TYPES.includes(bidType);
    case "STAGED":
      return STAGED_BID_TYPES.includes(bidType);
    default:
      return false;
  }
}

export function validateSaleStatus(saleType, saleStatus, bidType) {
  switch (saleType) {
    case TIMED:
      if (bidType === BUY_NOW) {
        return ![CLOSED, COMPLETED].includes(saleStatus);
      }
      return saleStatus === ACTIVE;
    case "LIVE_LEGACY":
    case "LIVE":
      return LIVE_SALE_ACTIVE_STATUSES.includes(saleStatus);
    case "STAGED":
      return saleStatus === ACTIVE;
    default:
      return false;
  }
}

export function validateLotStatus(saleType, lotStatus) {
  switch (saleType) {
    case TIMED:
      return TIMED_LOT_ACTIVE_STATUSES.includes(lotStatus);
    case "LIVE_LEGACY":
    case "LIVE":
      return LIVE_LOT_ACTIVE_STATUSES.includes(lotStatus);
    case "STAGED":
      return lotStatus === UNSOLD;
    default:
      return false;
  }
}

function getCanBid(
  isActive,
  amount,
  creditBalance,
  lotConsignorNumber,
  saleUser,
  bidType,
  highBidder,
  highBidType,
  maxBidAmount,
  saleCurrency,
  lotPointsLimit,
  userPointsBalance
) {
  let error;

  if (!isActive) {
    error = BIDDING_ERRORS.NOT_ACTIVE;
  } else if (!saleUser) {
    error = BIDDING_ERRORS.NO_BADGE;
  } else if (saleUser?.error) {
    error = saleUser?.error;
  } else if (Boolean(lotConsignorNumber) && lotConsignorNumber === saleUser?.consignorNumber) {
    error = BIDDING_ERRORS.CONSIGNOR;
  } else if (highBidder === saleUser?.id && ![BUY_NOW, OFFER].includes(bidType)) {
    if (highBidType === "PROXY" && !Number.isNaN(maxBidAmount) && maxBidAmount >= amount) {
      error = `${BIDDING_ERRORS.MAX_BID_SET} to ${formatCurrency(maxBidAmount, "USD")}`; // hack to stay compatible with current <BidButton />, change to use saleCurrency later
    } else {
      error = BIDDING_ERRORS.HIGH_BIDDER;
    }
  } else if (amount && creditBalance != null && amount > creditBalance && bidType !== OFFER) {
    error = BIDDING_ERRORS.CREDIT_LIMIT_EXCEEDED;
  } else if (lotPointsLimit != null && lotPointsLimit > userPointsBalance) {
    error = BIDDING_ERRORS.POINTS_LIMIT_EXCEEDED;
  }

  return {
    canBid: !Boolean(error),
    error
  };
}

export { useCanBid as default };
