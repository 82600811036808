import { REHYDRATE } from "redux-persist/lib/constants";
import {
  CALENDAR_FILTER_TOGGLE_COMPANY,
  CALENDAR_FILTER_SET_SALE_TYPE,
  CALENDAR_FILTER_TOGGLE_ASSET_SCHEMA,
  CALENDAR_MY_AUCTION_EVENTS,
  CALENDAR_TODAY_EVENTS,
  CALENDAR_UPCOMING_EVENTS,
  RESET_CALENDAR_ENTITIES,
  CALENDAR_FILTERS_RESET
} from "actions/actionTypes";
import { uniq } from "utils/CollectionUtil";

export const initialState = {
  selectedSaleType: null,
  selectedAssetSchemasIds: [],
  selectedCompanyIds: [],
  myAuctionEvents: {
    isLoading: true,
    total: 0,
    page: 0,
    eventIds: []
  },
  todayEvents: {
    isLoading: true,
    total: 0,
    page: 0,
    eventIds: []
  },
  upcomingEvents: {
    isLoading: true,
    total: 0,
    page: 0,
    eventIds: []
  }
};

export default function calendar(state = initialState, action) {
  switch (action.type) {
    case REHYDRATE:
      if (action.payload) {
        return {
          ...state,
          ...action.payload.calendar
        };
      } else {
        return {
          ...state
        };
      }

    case RESET_CALENDAR_ENTITIES:
      return {
        ...state,
        myAuctionEvents: {
          ...initialState.myAuctionEvents
        },
        todayEvents: {
          ...initialState.todayEvents
        },
        upcomingEvents: {
          ...initialState.upcomingEvents
        }
      };

    case CALENDAR_MY_AUCTION_EVENTS:
      return {
        ...state,
        myAuctionEvents: {
          isLoading: false,
          page: action.payload.page,
          total: action.payload.total,
          eventIds: uniq(action.payload.shouldMerge ? state.myAuctionEvents.eventIds : []).concat(
            action.payload.eventIds
          )
        }
      };

    case CALENDAR_TODAY_EVENTS:
      return {
        ...state,
        todayEvents: {
          isLoading: false,
          page: action.payload.page,
          total: action.payload.total,
          eventIds: uniq(action.payload.shouldMerge ? state.todayEvents.eventIds : []).concat(
            action.payload.eventIds
          )
        }
      };

    case CALENDAR_UPCOMING_EVENTS:
      return {
        ...state,
        upcomingEvents: {
          isLoading: false,
          page: action.payload.page,
          total: action.payload.total,
          eventIds: uniq(action.payload.shouldMerge ? state.upcomingEvents.eventIds : []).concat(
            action.payload.eventIds
          )
        }
      };

    case CALENDAR_FILTERS_RESET:
      return {
        ...state,
        selectedSaleType: null,
        selectedAssetSchemasIds: [],
        selectedCompanyIds: []
      };

    case CALENDAR_FILTER_SET_SALE_TYPE:
      return {
        ...state,
        selectedSaleType: action.payload.saleType
      };

    case CALENDAR_FILTER_TOGGLE_COMPANY:
      return {
        ...state,
        selectedCompanyIds: toggleCompanySelection(
          action.payload.companyId,
          state.selectedCompanyIds
        )
      };

    case "CALENDAR_SET_COMPANY_FROM_URL":
      return {
        ...state,
        selectedCompanyIds: [action.payload.companyId] || []
      };

    case CALENDAR_FILTER_TOGGLE_ASSET_SCHEMA:
      return {
        ...state,
        selectedAssetSchemasIds: toggleAssetSchemaSelection(
          action.payload.assetSchemaId,
          state.selectedAssetSchemasIds
        )
      };

    default:
      return state;
  }
}

function toggleCompanySelection(companyId, selectedCompanyIds) {
  if (companyId === null) return [];

  return selectedCompanyIds.includes(companyId)
    ? selectedCompanyIds.filter(id => id !== companyId)
    : [...selectedCompanyIds, companyId];
}

const toggleAssetSchemaSelection = (assetSchemaId, selectedAssetSchemasIds = []) => {
  if (assetSchemaId == null) return [];

  return selectedAssetSchemasIds.includes(assetSchemaId)
    ? selectedAssetSchemasIds.filter(id => id !== assetSchemaId)
    : [...selectedAssetSchemasIds, assetSchemaId];
};
