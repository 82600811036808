import { FC } from "react";

import HtmlContainer from "./HtmlContainer";
import Title from "./Title";
import Table from "./Table";

interface SimpleTableProps {
  rows: Array<Array<string>>;
  title?: string;
  logoUrl?: string;
  date?: string;
  totalLabel?: string;
}

const imgStyle = { width: "160px", height: "100%" };

const SimpleTable: FC<SimpleTableProps> = ({ logoUrl, title, rows, date, totalLabel }) => {
  return (
    <HtmlContainer>
      {logoUrl && (
        <div style={{ textAlign: "center" }}>
          <img src={logoUrl} alt="" style={imgStyle} />
        </div>
      )}

      <Title title={title} date={date} totalLabel={totalLabel} />

      <Table rows={rows} />
    </HtmlContainer>
  );
};

export default SimpleTable;
