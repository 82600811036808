import React from "react";
import { IconButton, MenuItem, ListItemIcon, ListItemText } from "@mui/material";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import { makeStyles } from "tss-react/mui";
import { useDispatch, useSelector } from "utils/StoreUtil";

import { openDialogWithProps } from "actions/dialogActions";
import { DIALOG_CONTENT_TYPE } from "utils/constants";

const useStyles = makeStyles()(theme => ({
  root: {
    marginLeft: theme.spacing(1)
  },
  iconWrapper: {
    padding: theme.spacing(0.15),
    backgroundColor: theme.palette.company.accent,
    display: "flex",
    borderRadius: "50%",
    "& > svg": {
      height: theme.spacing(2),
      width: theme.spacing(2),
      color: theme.palette.getContrastText(theme.palette.company.accent)
    }
  }
}));

function MenuItemVariant() {
  const dispatch = useDispatch();
  return (
    <MenuItem
      onClick={() => {
        dispatch(openDialogWithProps(DIALOG_CONTENT_TYPE.USER_DEALERSHIP_SYNC_MESSAGE));
      }}
    >
      <ListItemIcon>
        <PriorityHighIcon />
      </ListItemIcon>
      <ListItemText primary="Problem Updating Dealerships" />
    </MenuItem>
  );
}

function UserDealershipSyncMessage(props) {
  const { classes } = useStyles();
  const dispatch = useDispatch();

  const auctionAccessId = useSelector(state => state.user.user.fields?.auctionAccessId);
  const lastDealershipSyncMessage = useSelector(state => state.user.user.lastDealershipSyncMessage);

  if (!auctionAccessId || !lastDealershipSyncMessage) {
    return null;
  }

  if (props.variant === "menuItem") {
    return <MenuItemVariant />;
  }

  return (
    <IconButton
      color="secondary"
      classes={{ root: classes.root }}
      onClick={() => {
        dispatch(openDialogWithProps(DIALOG_CONTENT_TYPE.USER_DEALERSHIP_SYNC_MESSAGE));
      }}
      title="Problem Updating Dealerships"
    >
      <div className={classes.iconWrapper}>
        <PriorityHighIcon />
      </div>
    </IconButton>
  );
}

export default UserDealershipSyncMessage;
