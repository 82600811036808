import { createSlice } from "@reduxjs/toolkit";
import { CALENDAR_FETCH_COMPLETE } from "actions/actionTypes";
import { inventoryFetchComplete } from "actions/sharedActions";
import { REHYDRATE } from "redux-persist/lib/constants";

function getSaleIds(saleUsers) {
  return new Set(saleUsers.map(saleUser => saleUser.saleId));
}

function fetchComplete(state, action) {
  const { saleUsers, error } = action.payload;

  state.isLoading = false;

  if (!error && Array.isArray(saleUsers)) {
    const saleIds = getSaleIds(saleUsers);

    saleIds.forEach(saleId => {
      state[saleId] = saleUsers.filter(saleUser => saleUser.saleId === saleId);
    });
  }
}

const saleUsers = createSlice({
  name: "saleUsers",
  initialState: {
    isLoading: false
  },
  reducers: {
    fetch: state => {
      state.isLoading = true;
    },
    fetchComplete,
    fetchError: state => {
      state.isLoading = false;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(REHYDRATE, () => ({ isLoading: false }))
      .addCase(inventoryFetchComplete, (state, action) => {
        if (action.payload.saleUsers) {
          return fetchComplete(state, action);
        }
      })
      .addCase(CALENDAR_FETCH_COMPLETE, (state, action) => {
        const { saleUsers } = action.payload;

        if (saleUsers && Array.isArray(saleUsers)) {
          const saleIds = getSaleIds(saleUsers);

          for (const saleId of saleIds) {
            state[saleId] = saleUsers.filter(saleUser => saleUser.saleId === saleId);
          }
        }
      });
  }
});

const reducer = saleUsers.reducer;

export { reducer as default };
export const {
  fetch: saleUsersFetch,
  fetchComplete: saleUsersFetchComplete,
  fetchError: saleUsersFetchError
} = saleUsers.actions;

export function getSaleUserBy(saleUsers, prop, compareTo) {
  return saleUsers?.find(saleUser => saleUser[prop] === compareTo);
}

export function saleUserHasIssue(saleUser) {
  return Boolean(saleUser.error);
}

export function getSaleUsers(state, saleId) {
  return state.saleUsers[saleId];
}
