import Hidden from "@mui/material/Hidden";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Search from "@mui/icons-material/Search";
import { memo, useState, useEffect, useRef } from "react";
import SearchHelp from "./SearchHelp";
import FormControl from "@mui/material/FormControl";
import Divider from "@mui/material/Divider";
import Close from "@mui/icons-material/Close";
import { makeStyles } from "tss-react/mui";
import { history } from "store";
import useLocationMatches from "hooks/useLocationMatches";
import { useDispatch, useSelector } from "utils/StoreUtil";
import {
  vehiclesInventorySetSortBy,
  setQueryString as setSavedQueryString,
  searchInventory
} from "actions/inventoryActions";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import RecentSearches from "./RecentSearches";
import useLanguage from "hooks/useLanguage";
import useMarketVisibility from "hooks/useMarketVisibility";
import { MARKET_SEGMENTS } from "utils/constants";
import useTabTrap from "hooks/useTabTrap";
import { getDefaultSort } from "utils/MarketUtil";

const useStyles = makeStyles()(theme => ({
  searchBox: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1) / 2,
    alignSelf: "flex-end",
    transition: "top 0.2s linear",
    transitionDelay: "500ms",
    left: "0",
    background: theme.palette.background.paper,
    [theme.breakpoints.up("md")]: {
      width: "100%",
      margin: "0",
      height: "100%",
      borderRadius: "0!important",
      position: "absolute",
      top: "-100%",
      flex: 1
    },
    [theme.breakpoints.down("md")]: {
      padding: 0,
      margin: 0,
      width: "100%",
      height: "calc(100% - 51px)",
      background: theme.palette.divider
    }
  },
  searchVisible: {
    top: "0!important",
    visibility: "visible"
  },
  textFieldWrapper: {
    width: "100%"
  },
  label: {
    display: "flex",
    alignItems: "center"
  },
  hidden: {
    textAlign: "left"
  },
  searchForm: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: theme.palette.primary.main,
    maxHeight: theme.spacing(8),
    zIndex: "1101",
    width: "100%",
    float: "left",
    height: "100%",
    position: "relative",
    [theme.breakpoints.down("md")]: {
      flexWrap: "wrap",
      padding: 0,
      margin: 0,
      width: "100%",
      maxHeight: "initial",
      height: "auto",
      borderBottom: theme.palette.divider
    }
  },
  searchIcon: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.getContrastText(theme.palette.primary.dark),
    order: "1",
    maxHeight: theme.spacing(7),
    padding: theme.spacing(2),
    paddingTop: theme.spacing(1.3),
    lineHeight: "54px"
  },
  searchInput: {
    backgroundColor: theme.palette.background.paper,
    order: "2",
    flexGrow: "2",
    width: "auto",
    maxHeight: theme.spacing(8),
    padding: theme.spacing(1),
    "& input": {
      width: "100%"
    }
  },
  searchHelp: {
    background: theme.palette.background.paper,
    order: "3",
    borderRadius: "0",
    padding: theme.spacing(1.5),
    paddingLeft: "0"
  },
  divider: {
    width: 1,
    height: 28,
    margin: 4
  },
  mobileList: {
    width: "100%",
    order: "3",
    flexBasis: "100%"
  }
}));

const SearchBox = ({ handleClose, isOpen }) => {
  const { classes, cx } = useStyles();
  const dispatch = useDispatch();
  const isInventory = useLocationMatches("/inventory", false);
  const savedQueryString = useSelector(
    state => state.inventorySearchOptions.defaultSelectedSearchOptions.queryString
  );
  const [isHelpOpen, setIsHelpOpen] = useState(false);
  const inputRef = useRef(null);
  const lastElementRef = useRef(null);
  const marketSegment = useSelector(state => state.config.marketSegment);

  const [searchBarPlaceholder] = useLanguage("searchBarPlaceholder");

  const showSearchHelp = useMarketVisibility(MARKET_SEGMENTS.AUTOMOTIVE);

  useEffect(() => {
    const input = inputRef.current;
    if (input && input.value) {
      if (!savedQueryString) {
        input.value = "";
        dispatch(vehiclesInventorySetSortBy(getDefaultSort(marketSegment)));
        dispatch(searchInventory());
      }
    }
  }, [savedQueryString, dispatch, marketSegment]);

  function handleChange(q) {
    let sort = q ? "" : getDefaultSort(marketSegment);

    dispatch(vehiclesInventorySetSortBy(sort));
    if (q !== savedQueryString) {
      dispatch(setSavedQueryString(q));
      update();
    }
  }

  function handleKeyPress(evt) {
    if (evt.key === "Enter") {
      handleChange(evt.target.value);
    }
  }

  function handleOnBlur(evt) {
    handleChange(evt.target.value);
  }

  function search() {
    dispatch(searchInventory());
  }

  function navigate() {
    history.push("/inventory");
  }

  function toggleIsHelpOpen(evt) {
    setIsHelpOpen(!isHelpOpen);
    evt.nativeEvent.stopImmediatePropagation();
  }

  function update() {
    handleClose();

    if (isInventory) {
      search();
    } else {
      navigate();
    }
  }

  useEffect(() => {
    if (isOpen) inputRef.current.focus();
  }, [isOpen]);

  useTabTrap(inputRef.current, lastElementRef.current);

  return (
    <div className={cx(classes.searchBox, isOpen ? classes.searchVisible : "")}>
      <ClickAwayListener
        onClickAway={() => {
          if (isOpen && !isHelpOpen) {
            handleClose();
          }
        }}
      >
        <FormControl className={classes.searchForm} fullWidth>
          <div className={classes.searchIcon}>
            <Search />
          </div>
          <Input
            id="search"
            placeholder={searchBarPlaceholder}
            fullWidth
            type="text"
            disableUnderline={true}
            className={classes.searchInput}
            inputRef={inputRef}
            onKeyPress={handleKeyPress}
            onBlur={handleOnBlur}
            inputProps={{ autocomplete: "off" }}
            endAdornment={
              <InputAdornment position="end">
                <Hidden mdDown>
                  <RecentSearches inputRef={inputRef} onUpdate={update} searchOpen={isOpen} />
                </Hidden>
                <Divider className={classes.divider} />
                <IconButton
                  ref={showSearchHelp ? null : lastElementRef}
                  className={classes.searchIconButton}
                  onClick={handleClose}
                >
                  <Close fontSize="small" />
                </IconButton>
              </InputAdornment>
            }
          />
          {showSearchHelp && (
            <Hidden mdDown>
              <div className={classes.searchHelp}>
                <SearchHelp
                  buttonRef={lastElementRef}
                  isHelpOpen={isHelpOpen}
                  toggleHelpOpen={toggleIsHelpOpen}
                />
              </div>
            </Hidden>
          )}

          <Hidden mdUp>
            <div className={classes.mobileList}>
              <RecentSearches inputRef={inputRef} onUpdate={update} searchOpen={isOpen} />
            </div>
          </Hidden>
        </FormControl>
      </ClickAwayListener>
    </div>
  );
};

export default memo(SearchBox);
