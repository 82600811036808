import React from "react";
import { makeStyles } from "tss-react/mui";
import { useSelector } from "utils/StoreUtil";
import startCase from "lodash/startCase";

const useStyles = makeStyles()(theme => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(0.5, 1),
    backgroundColor: theme.palette.grey[200],
    border: theme.shape.borderLight
  },
  addMargin: {
    marginRight: "3px"
  }
}));

function useMarketLabelForLot() {
  const marketSegment = useSelector(state => state.config.marketSegment);
  switch (marketSegment) {
    case "bloodStock":
      return "Hip Number";
    case "automotive":
    default:
      return "Lot Number";
  }
}

function TableHeader(props) {
  const { classes } = useStyles();
  const marketLabelForLot = useMarketLabelForLot();

  const { page, perPage, sortBy } = useSelector(state => state.vehicles);
  const total = useSelector(state => state.lots.total);

  const sortPieces = sortBy.split(".");
  const selectedSort = startCase(sortPieces[sortPieces.length - 1]) || "Relevance";

  const shouldShowRange = total > 0 && perPage > 0 && page >= 0;
  const rangeStart = page * perPage + 1;
  const rangeEnd = Math.min(total, (page + 1) * perPage);

  return (
    <div className={classes.root}>
      <div>
        <span>Sorted by: </span>
        <span>{selectedSort === "Lot Number" ? marketLabelForLot : ` ${selectedSort}`}</span>
      </div>
      <div>
        <span className={classes.addMargin}>
          {shouldShowRange ? `${rangeStart} - ${rangeEnd} of ${total}` : "Total: 0"}
        </span>
      </div>
    </div>
  );
}

export default React.memo(TableHeader);
