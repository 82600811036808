import React from "react";
import { makeStyles } from "tss-react/mui";
import { useSelector } from "utils/StoreUtil";

const useStyles = makeStyles()(theme => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    position: "fixed",
    bottom: 0,
    width: "100%",
    marginRight: theme.spacing(2)
  }
}));

function PrintFooter() {
  const { classes } = useStyles();
  const companyId = useSelector(state => state.user.user.companyId);
  const company = useSelector(state =>
    state.companies.companies.find(company => company.id === companyId)
  );
  const year = new Date().getFullYear();

  return (
    <div className={classes.root}>
      <span>{company?.name || "Community"}</span>
      <span>&copy; Xcira &amp; Community {year}</span>
    </div>
  );
}

export default React.memo(PrintFooter);
