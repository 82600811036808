import { Box, Button, Divider, Hidden, Paper, Stack, Typography } from "@mui/material";
import moment from "moment";
import { Link } from "react-router-dom";

import { INVENTORY_FILTER_EVENT_SELECT } from "actions/actionTypes";
import { Locale } from "types/Company";
import { useDispatch, useSelector } from "utils/StoreUtil";
import { formatNumber } from "utils/NumberUtil";
import useCompanyLogoUrl from "hooks/company/useCompanyLogoUrl";
import useLanguage from "hooks/useLanguage";
import { salesSelector } from "reducers/sales";
import { getSaleType } from "reducers/admin/inventory";
import { useGetSaleRangeLotCountsQuery } from "reducers/salesApi";

import { AttendSaleButton } from "components/AttendButton";
import DownloadReport from "../components/DownloadReport";
import PreviewHeader from "./components/PreviewHeader";
import PreviewDescription from "./components/PreviewDescription";
import PreviewDateTime from "./components/PreviewDateTime";

type RunRange = {
  id?: number;
  description?: string;
  startRun?: string;
  endRun?: string;
};

function RunRangeLink({
  runRange,
  count = 0,
  companyId,
  eventId,
  saleId
}: {
  runRange: RunRange;
  count?: string | number;
  companyId: number;
  eventId?: number;
  saleId: number;
}) {
  const dispatch = useDispatch();

  const { id, description, startRun, endRun } = runRange;
  const label = description || "No Description";
  const labelCount = `(${count})`;
  const runLabel = `${startRun} to ${endRun}`;

  function handleClick() {
    dispatch({
      type: INVENTORY_FILTER_EVENT_SELECT,
      payload: {
        companyId,
        eventId,
        saleId,
        rangeNumber: String(id)
      }
    });
  }

  if (!id && !description) return null;

  if (!count) return null;

  return (
    <Box sx={{ textAlign: "left", pl: 1.5 }}>
      <Typography
        sx={{
          color: "info.main",
          "&:hover": {
            color: "info.dark"
          }
        }}
        variant="subtitle1"
        to="/inventory"
        component={Link}
        onClick={handleClick}
      >
        {`${label} ${labelCount}`}
      </Typography>
      <Typography variant="body1" color="textSecondary">
        {runLabel}
      </Typography>
    </Box>
  );
}

interface SalePreviewProps {
  saleId: number;
  locale?: Locale;
}

const SalePreview: React.FC<SalePreviewProps> = props => {
  const { saleId } = props;

  const dispatch = useDispatch();

  const { data: rangeLotCounts = [] } = useGetSaleRangeLotCountsQuery(saleId);

  const sale = useSelector(state => salesSelector.selectById(state.sales, saleId));
  const userLocale = useSelector(state => state.user.user.locale);

  const companyLogoUrl = useCompanyLogoUrl(sale?.eventId);

  const [inventoryText] = useLanguage("inventory");

  const lotCount = sale?.totalLots - sale?.totalWithdrawnLots;
  const locale = props.locale || userLocale;

  const isInventoryDisabled = moment()
    .startOf("day")
    .isAfter(moment(sale?.endTime));

  let runRanges = [];

  try {
    runRanges = JSON.parse(sale?.fields?.runRanges) || [];
  } catch (err) {}

  function handleInventoryClick() {
    dispatch({
      type: INVENTORY_FILTER_EVENT_SELECT,
      payload: {
        companyId: sale?.companyId,
        eventId: sale?.eventId,
        saleId
      }
    });
  }

  return (
    <Paper sx={{ width: 350 }}>
      <PreviewHeader
        imgSrc={companyLogoUrl}
        label={sale?.name}
        typographyProps={{ variant: "body1" }}
      >
        <Hidden mdDown>
          <DownloadReport entity={sale} entityType="sale" color="inherit" />
        </Hidden>
      </PreviewHeader>

      <Box padding={1}>
        <PreviewDescription text={sale?.description} viewable />

        {Boolean(sale?.description) && <Divider sx={{ my: 1 }} />}

        <Stack spacing={1}>
          {runRanges.map((range: { id?: number; description?: string }) => {
            const rangeLotCount = rangeLotCounts.find(
              (rangeCount: any) => rangeCount.key === String(range.id)
            );
            return (
              <RunRangeLink
                key={range.id}
                runRange={range}
                count={rangeLotCount?.count}
                companyId={sale?.companyId}
                eventId={sale?.eventId}
                saleId={saleId}
              />
            );
          })}
        </Stack>

        <Box sx={{ mt: 1 }} />

        <PreviewDateTime
          startTime={sale?.startTime}
          endTime={sale?.endTime}
          locale={locale}
          saleType={getSaleType(sale)!}
        />

        <Divider sx={{ my: 1 }} />

        <Stack direction="row" spacing={1}>
          <Button
            fullWidth
            disabled={lotCount < 1 || isInventoryDisabled}
            component={Link}
            to="/inventory"
            onClick={handleInventoryClick}
            color="primary"
            variant="outlined"
            sx={{ minWidth: "160px", height: "42px", padding: 0 }}
          >
            {inventoryText} {formatNumber(lotCount)}
          </Button>

          <AttendSaleButton saleId={saleId} />
        </Stack>
      </Box>
    </Paper>
  );
};

export { SalePreview as default, SalePreview };
