import SaleList from "./components/SaleList";
import { Grid } from "@mui/material";

const DefaultMainContent = () => {
  return (
    <Grid container direction="row" justifyContent="center" alignItems="center">
      <Grid item md={10} xs={12} sm={12} lg={11} xl={8}>
        <SaleList />
      </Grid>
    </Grid>
  );
};

export default DefaultMainContent;
