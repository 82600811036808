import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { REHYDRATE } from "redux-persist/lib/constants";

import { Media } from "types/Media";

type RecentlyViewedItem = {
  lotId: number;
  assetId: number;
  title: string;
  media?: Media;
};

const recentlyViewedAdapter = createEntityAdapter<RecentlyViewedItem, number>({
  selectId: recentItem => recentItem.lotId
});

const recentlyViewedSlice = createSlice({
  name: "recentlyViewed",
  initialState: recentlyViewedAdapter.getInitialState(),
  reducers: {
    upsertRecentlyViewed: (state, action) => {
      if (action.payload) {
        recentlyViewedAdapter.upsertOne(state, action.payload);
      }
    }
  },
  extraReducers: builder => {
    builder.addCase(REHYDRATE, (state, action) => {
      // @ts-ignore
      if (action.payload) {
        // @ts-ignore
        return recentlyViewedAdapter.getInitialState(action.payload.recentlyViewed);
      }
      return state;
    });
  }
});

export const { upsertRecentlyViewed } = recentlyViewedSlice.actions;

export default recentlyViewedSlice.reducer;
