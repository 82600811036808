import { useEffect, useRef, useState } from "react";
import { withStyles } from "tss-react/mui";
import { Hidden, Grid } from "@mui/material";

import { COMPANY_DEFAULT_MEDIA, ENTITY_TYPE } from "utils/constants";
import { useDispatch, useSelector } from "utils/StoreUtil";
import { createTermFilter } from "utils/SearchUtil";
import { showError } from "actions/notificationActions";
import { getMedias as getMediasFromApi } from "api/mediaRequests";

import LoginComponent from "./components/LoginComponent";
import CompanyLogo from "components/CompanyLogo";
import CompanyStatement from "../landing/cubed/components/CompanyStatement";
import MobileAppBanner from "../landing/cubed/components/MobileAppBanner";

const styles = theme => {
  return {
    root: {
      background: theme.palette.company.header
    },
    mobileTitle: {
      color: theme.palette.getContrastText(theme.palette.company.header),
      fontWeight: theme.typography.fontWeightMedium,
      margin: theme.spacing(2, 0)
    }
  };
};

function useHomepageLogo() {
  const dispatch = useDispatch();
  const [logoUrl, setLogoUrl] = useState("");
  const companyId = useSelector(state => state.user.user?.companyId);

  const calledRef = useRef(false);

  useEffect(() => {
    async function fetchImages() {
      const filters = [
        createTermFilter("entityType", ENTITY_TYPE.COMPANY),
        createTermFilter("entityId", companyId),
        createTermFilter("name", COMPANY_DEFAULT_MEDIA.LOGIN_PAGE_IMG)
      ];

      try {
        const { data: medias } = await getMediasFromApi(filters, "items=0-0");

        if (medias.length > 0) {
          setLogoUrl(medias[0].urls?.medium || "");
        }
      } catch ({ message }) {
        dispatch(showError(message));
      }
    }

    if (!logoUrl && companyId && !calledRef.current) {
      fetchImages();
      calledRef.current = true;
    }
  }, [companyId, logoUrl, dispatch]);

  return logoUrl;
}

const Header = ({
  classes,
  mediaId,
  loginAttempt,
  isLoading,
  showResendLink,
  resendActivationEmail,
  emailRef,
  showTechSupportNumber,
  techSupportNumber,
  helpUrl,
  openDialogWithProps,
  appAndroidUrl,
  appAppleUrl
}) => {
  const homepageLogo = useHomepageLogo();

  return (
    <div className={classes.root}>
      <Grid container spacing={2} className={classes.root}>
        <Hidden mdUp>
          <Grid item my={1}>
            <CompanyLogo mediaId={mediaId} />
          </Grid>
        </Hidden>
      </Grid>

      <Grid
        container
        spacing={2}
        className={classes.root}
        direction="row"
        justifyContent="center"
        mt={1}
        pb={2}
      >
        <Grid item xl={5} lg={7} md={7} sm={6} xs={12}>
          {appAndroidUrl || appAppleUrl ? (
            <MobileAppBanner
              appAndroidUrl={appAndroidUrl}
              appAppleUrl={appAppleUrl}
              backgroundUrl={homepageLogo}
            />
          ) : (
            <CompanyStatement logoUrl={homepageLogo} />
          )}
        </Grid>

        <Grid item xl={3} lg={4} md={5} sm={6} xs={12}>
          <LoginComponent
            loginAttempt={loginAttempt}
            isLoading={isLoading}
            showResendLink={showResendLink}
            resendActivationEmail={resendActivationEmail}
            emailRef={emailRef}
            showTechSupportNumber={showTechSupportNumber}
            techSupportNumber={techSupportNumber}
            helpUrl={helpUrl}
            openDialogWithProps={openDialogWithProps}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default withStyles(Header, styles);
