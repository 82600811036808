import { useMediaQuery, useTheme } from "@mui/material";
import { useLayoutEffect } from "react";
import { useDispatch } from "utils/StoreUtil";

import { viewportActions } from "reducers/viewport";

export function useViewportMeta() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    dispatch(viewportActions.setViewportSize(isMobile));
  }, [isMobile, dispatch]);
}
