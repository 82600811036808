import React from "react";
import { useSelector } from "utils/StoreUtil";

import DirectionsCarFilledIcon from "@mui/icons-material/DirectionsCar";
import ViewListIcon from "@mui/icons-material/ViewList";

export default function MarketSegmentIcon(props) {
  const marketSegment = useSelector(state => state.config.marketSegment);

  switch (marketSegment) {
    case "automotive":
      return <DirectionsCarFilledIcon {...props} />;
    case "bloodStock":
    case "cattle":
    default:
      return <ViewListIcon {...props} />;
  }
}
