import { TypographyVariant, useTheme } from "@mui/material";

export function useTypographyHeight(typographyVariant: TypographyVariant = "body2") {
  const {
    typography: {
      htmlFontSize,
      [typographyVariant]: { fontSize, lineHeight }
    }
  } = useTheme();

  return parseFloat(fontSize as string) * (lineHeight as number) * htmlFontSize;
}
