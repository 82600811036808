import { download } from "./api";
import { replaceSpecialCharacters } from "utils/StringUtil";

export async function downloadCsv(fileName, content) {
  return download("/services/output/csv", "csv", replaceSpecialCharacters(fileName), content);
}

export async function downloadPdf(fileName, content) {
  download("/services/output/pdf", "pdf", replaceSpecialCharacters(fileName), content);
}
