import { useEffect } from "react";
import { useSelector } from "utils/StoreUtil";

function useTabTrap(firstElement, lastElement) {
  const isMobile = useSelector(state => state.viewport.isMobile);

  useEffect(() => {
    if (!isMobile) {
      document.addEventListener("keydown", trackTab);
    }

    function trackTab(event) {
      if (event.keyCode === 9) {
        if (event.shiftKey) {
          if (document.activeElement === firstElement) {
            event.preventDefault();
            lastElement.focus();
          }
        } else {
          if (document.activeElement === lastElement) {
            event.preventDefault();
            firstElement.focus();
          }
        }
      }
    }

    return () => {
      if (!isMobile) {
        document.removeEventListener("keydown", trackTab);
      }
    };
  }, [firstElement, lastElement, isMobile]);
}

export default useTabTrap;
