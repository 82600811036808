import { createSlice, createEntityAdapter } from "@reduxjs/toolkit";
import { REHYDRATE } from "redux-persist/lib/constants";
import { UI_SCHEMA_FETCH, UI_SCHEMA_FETCH_COMPLETE } from "actions/actionTypes";
import { UI_SCHEMA_TYPE } from "utils/constants";

const NAME = "uiSchemas";

const uiSchemasAdapter = createEntityAdapter({
  selectId: uiSchema => getUISchemaSelectId(uiSchema.companyId, uiSchema.uiSchemaType)
});

const initialState = {
  isLoading: false
};

const uiSchemas = createSlice({
  name: NAME,
  initialState: uiSchemasAdapter.getInitialState(initialState),
  reducers: {
    addUISchema(state, { payload }) {
      if (payload) {
        uiSchemasAdapter.addOne(state, payload);
      }
    },
    addUISchemas(state, { payload }) {
      if (payload) {
        uiSchemasAdapter.addMany(state, payload);
      }
    },
    updateUISchema: {
      reducer: (state, action) => {
        const { uiSchema } = action.payload;

        if (uiSchema) {
          uiSchemasAdapter.updateOne(state, {
            id: getUISchemaSelectId(uiSchema.companyId, uiSchema.uiSchemaType),
            changes: uiSchema
          });
        }
      },
      prepare: uiSchema => ({ payload: { uiSchema } })
    }
  },
  extraReducers: builder => {
    builder
      .addCase(REHYDRATE, () => uiSchemasAdapter.getInitialState())
      .addCase(UI_SCHEMA_FETCH, state => {
        state.isLoading = true;
      })
      .addCase(UI_SCHEMA_FETCH_COMPLETE, (state, { payload = [] }) => {
        state.isLoading = false;
        uiSchemasAdapter.addMany(state, payload);
      });
  }
});

export const getUISchemaSelectId = (companyId, uiSchemaType = UI_SCHEMA_TYPE.FILTER_INVENTORY) =>
  `${companyId}/${uiSchemaType}`;

export const uiSchemasSelector = uiSchemasAdapter.getSelectors(state => state[NAME]);

export const directedUISchemasSelector = uiSchemasAdapter.getSelectors();

const { reducer, actions } = uiSchemas;
export const { addUISchema, addUISchemas, updateUISchema } = actions;
export default reducer;
