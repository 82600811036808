import { STATS_FETCH, STATS_FETCH_COMPLETE, STATS_BULK_FETCH_COMPLETE } from "actions/actionTypes";
import { createReducer } from "@reduxjs/toolkit";
import { createSelector } from "@reduxjs/toolkit";
import startCase from "lodash/startCase";
import { formatDuration } from "utils/DateUtil";

const initialState = {
  event: {},
  sale: {},
  dealership: {},
  user: {},
  dateRange: {},
  isLoading: true
};

export default createReducer(initialState, builder => {
  builder
    .addCase(STATS_FETCH, state => {
      state.isLoading = true;
    })
    .addCase(STATS_FETCH_COMPLETE, (state, action) => {
      const { type, id, stats } = action.payload;

      state[type][id] = stats;
      state.isLoading = false;
    })
    .addCase(STATS_BULK_FETCH_COMPLETE, (state, action) => {
      const { type, stats } = action.payload;

      state[type] = stats;
      state.isLoading = false;
    });
});

function getEventStats(state, eventId) {
  return state.stats.event[eventId];
}

function getSales(state, eventId) {
  // eslint-disable-next-line eqeqeq
  const sales = Object.values(state.sales.entities).filter(sale => sale.eventId == eventId);
  return sales.filter(sale => sale.totalLots - sale.totalWithdrawnLots > 0);
}

function formatStat(key, path, value, { formatter }) {
  if (
    key === "all" ||
    Array.isArray(value) ||
    value == null ||
    (key === "calculatedAmount" && !path.includes("bidsStats"))
  )
    return [];

  if (typeof value === "object") {
    return Object.keys(value)
      .map(childKey =>
        formatStat(childKey, `${path}.${childKey}`, value[childKey], {
          formatter
        })
      )
      .reduce((results, stats) => results.concat(stats), []);
  }

  const label = getLabelFromPath(path);
  const formattedValue = key.toLowerCase().includes("amount")
    ? formatter.formatNumber(value, { style: "currency" })
    : value;

  return {
    label,
    value: formattedValue,
    rawValue: value,
    path
  };
}

function formatDurationStats(eventStats = {}, sales = []) {
  const { durationStats } = eventStats;
  const saleDurationsMap = durationStats?.sales || {};

  let eventStart = 0;
  let eventEnd = 0;

  const stats = [];

  for (const sale of sales) {
    const saleName = sale.name || sale.id;

    const start = saleDurationsMap[sale.id]?.start || 0;
    const end = saleDurationsMap[sale.id]?.end || 0;

    if (start < eventStart || eventStart === 0) {
      eventStart = start;
    }

    if (end > eventEnd) {
      eventEnd = end;
    }

    const duration = end - start;

    stats.push({
      label: `${saleName} duration`,
      value: formatDuration(duration),
      rawValue: duration,
      path: ""
    });
  }

  const eventTotal = eventEnd - eventStart;

  stats.unshift({
    label: `Total Sale(s) Duration`,
    value: formatDuration(eventTotal),
    rawValue: eventTotal,
    path: ""
  });

  return stats;
}

function modifyStats(stat = {}, eventStats = {}, { formatter }) {
  const bidsStats = eventStats.bidsStats || {};

  if (stat.label === "Internet Bids Amount") {
    const value =
      (bidsStats?.quickBids?.amount || 0) +
      (bidsStats?.proxyBids?.amount || 0) +
      (bidsStats?.preBids?.amount || 0) +
      (bidsStats?.preBidsCancelled?.amount || 0) +
      (bidsStats?.buyNowBids?.amount || 0);
    stat.value = formatter.formatNumber(value, { style: "currency" });
    stat.rawValue = value;
  } else if (stat.label === "Internet Bids Count") {
    const value =
      (bidsStats?.quickBids?.count || 0) +
      (bidsStats?.proxyBids?.count || 0) +
      (bidsStats?.preBids?.count || 0) +
      (bidsStats?.preBidsCancelled?.count || 0) +
      (bidsStats?.buyNowBids?.count || 0);

    stat.value = value;
    stat.rawValue = value;
  }
  return stat;
}

function getLabelFromPath(path = "") {
  let pathValues = path.split(".");
  pathValues = pathValues.length > 2 ? pathValues.slice(1) : pathValues;
  return startCase(pathValues.join(" ").replace(/stats/gi, ""));
}

const statsBlackList = ["consignorStats", "durationStats"];

export const formatStats = createSelector(
  getEventStats,
  getSales,
  (_, __, options) => options,
  (eventStats = {}, sales = [], { shouldDisplayCalculatedAmount, ...options } = {}) => {
    return Object.keys(eventStats)
      .filter(key => eventStats[key] != null && !statsBlackList.includes(key))
      .reduce((results, key) => results.concat(formatStat(key, key, eventStats[key], options)), [])
      .reduce((stats, stat) => {
        if (!shouldDisplayCalculatedAmount && stat.label?.includes("Calculated")) {
          return stats;
        }

        stats.push(stat);
        return stats;
      }, [])
      .concat(formatDurationStats(eventStats, sales))
      .map(stat => modifyStats(stat, eventStats, options));
  }
);

const idAndTypeMatch = (stat, type, id) => stat[type] === parseInt(id, 10);

const getAttendeeStats = stats => stats.attendeeStats;
const getEntityId = (_, entityId) => entityId;
const getIdType = (_, userId, idType) => idType;
export const findAttendeeStats = createSelector(
  getAttendeeStats,
  getEntityId,
  getIdType,
  (attendeeStats = [], entityId, idType) =>
    attendeeStats.find(attendeeStat => idAndTypeMatch(attendeeStat, idType, entityId)) || {}
);

export const getDateRangeStat = createSelector(
  dateRangeKey => dateRangeKey,
  (_, dateRangeState) => dateRangeState,
  (dateRangeKey, dateRangeState) => dateRangeState[dateRangeKey]
);
