import { get, getTotal } from "./api";

export async function getEvent(eventId) {
  const { data } = await get(`event/${eventId}`);
  return data;
}

export async function getEvents(filters, range, sort) {
  const res = await get("event", filters, range, sort);
  return { data: res.data, total: getTotal(res) };
}

export async function getLandingPageData(filters, sort, range) {
  const res = await get("application/community/landingPage", filters, sort, range);
  return { data: res.data, total: res.data.total };
}

export async function getCalendarEntitiesForCalendar(filters, range, sort, pageSubType) {
  let url = `application/community/calendar?request.preventCache=${pageSubType}`;
  const res = await get(url, filters, range, sort);
  return { data: res.data, total: res.data.total };
}

export async function getCalendarEntitiesForInventory(filters, range, sort) {
  const res = await get("application/community/calendar/inventory", filters, range, sort);
  return { data: res.data, total: res.data.total };
}

export async function getCalendarEntitiesForAdmin(filters, range, sort) {
  const res = await get("application/community/calendar/admin", filters, range, sort);
  return { data: res.data, total: res.data.total };
}

export async function getEventDealerships(eventId, filters, range, sort) {
  const res = await get(
    `application/community/event/${eventId}/consignors/inventoryCount`,
    filters,
    range,
    sort
  );

  return res.data;
}
