import { configureStore, Tuple } from "@reduxjs/toolkit";
import { thunk } from "redux-thunk";
import { createBrowserHistory } from "history";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage/session";

import reducers from "./reducers";
import { salesApi } from "reducers/salesApi";
import cometMiddleware from "./middleware/comet";

const persistConfig = {
  key: "root",
  blacklist: ["notifications", "config", "comet", "similarItems"],
  storage
};

const persistedReducers = persistReducer(persistConfig, reducers);

export const history = createBrowserHistory();

const middleware = [thunk, salesApi.middleware];

if (process.env.NODE_ENV !== "test") {
  middleware.push(cometMiddleware);
}

export const store = configureStore({
  reducer: persistedReducers,
  middleware: () => new Tuple(...middleware)
});

if (process.env.NODE_ENV === "development" && module.hot) {
  module.hot.accept("reducers", () => {
    const newRootReducer = require("reducers").default;
    store.replaceReducer(newRootReducer);
  });
}

export const persistor = persistStore(store);
export const { dispatch, getState } = store;
