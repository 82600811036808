import { get } from "./api";
import uniqueId from "lodash/uniqueId";

export async function getSaleStats(filters) {
  const { data } = await get(
    `saleStats?request.preventCache=${uniqueId("saleStats")}`,
    filters,
    undefined,
    undefined,
    undefined,
    { hideChildCompanyResults: true }
  );
  return data;
}

export async function getSaleStatsWithoutCurrencyConversion(filters) {
  const { data } = await get(
    `saleStats/rawCurrency?request.preventCache=${uniqueId("saleStats")}`,
    filters
  );
  return data;
}

export async function getAttendeesStats(filters) {
  const { data } = await get("application/community/attendeesStats", filters);
  return data;
}

export async function getAttendeeStats(filters, userId) {
  const { data } = await get(`application/community/attendeeStats/${userId}`, filters);
  return data;
}
