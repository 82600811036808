import { REHYDRATE } from "redux-persist/lib/constants";
import { createReducer } from "@reduxjs/toolkit";

import { DIALOG_RESET_STATE, DIALOG_OPEN } from "actions/actionTypes";
import { PURCHASE, NOTE, HISTORY } from "components/card/ActionsBarIconConfig";
import { DIALOG_CONTENT_TYPE } from "utils/constants";

export const initialState = {
  isOpen: false,
  contentType: null,
  props: null
};

const dialog = createReducer(initialState, builder => {
  builder
    .addCase(REHYDRATE, () => {
      return {
        isOpen: initialState.isOpen,
        contentType: initialState.contentType,
        props: initialState.props
      };
    })
    .addCase(DIALOG_RESET_STATE, () => initialState)
    .addCase(DIALOG_OPEN, handleDialogOpen);
});

function handleDialogOpen(state, action) {
  const { contentType, props } = action.payload;

  state.isOpen = true;
  state.contentType = contentType;
  state.props = props;
}

export function formatPayload(props) {
  return {
    lot: props?.lot,
    lotId: props?.lot?.id,
    asset: props?.asset,
    announcement: props?.asset?.fields?.announcement,
    companyId: props?.lot?.companyId,
    itemDetails: {
      secondaryText: props?.lot?.lotNumber || "Upcoming",
      primaryText: props?.asset?.uifields?.header?.value ?? ""
    }
  };
}

export function getDialogTitle(contentType) {
  switch (contentType) {
    case DIALOG_CONTENT_TYPE.ADMIN_BIDDER_INFO:
    case DIALOG_CONTENT_TYPE.ATTEND:
    case DIALOG_CONTENT_TYPE.ATTEND_NEW:
    case DIALOG_CONTENT_TYPE.GET_SALE_USERS:
    case DIALOG_CONTENT_TYPE.CHECK_DEALERSHIPS:
    case DIALOG_CONTENT_TYPE.USER_DEALERSHIP_SYNC_MESSAGE:
    case NOTE:
    case DIALOG_CONTENT_TYPE.ADMIN_NOTE:
    case DIALOG_CONTENT_TYPE.BID_CONTROLS:
    case DIALOG_CONTENT_TYPE.WARNING_DIFFERENT_FILES_UPLOADED:
    case "Result List":
    case "Media Management":
    case DIALOG_CONTENT_TYPE.MEMBERSHIP_FIELDS:
    case DIALOG_CONTENT_TYPE.MEMBERSHIP_STATUS:
    case DIALOG_CONTENT_TYPE.UPSERT_DEALERSHIP:
    case DIALOG_CONTENT_TYPE.UPSERT_MEMBERSHIP_SCHEMA_FIELD:
    case DIALOG_CONTENT_TYPE.UPSERT_UI_SCHEMA_FIELD:
    case DIALOG_CONTENT_TYPE.FLUSH_CONFIRMATION:
    case DIALOG_CONTENT_TYPE.RESEND_ACTIVATION_EMAIL:
    case DIALOG_CONTENT_TYPE.APPLICATION_MESSAGE:
    case DIALOG_CONTENT_TYPE.UNSAVED_CHANGES_CONFIRMATION:
    case DIALOG_CONTENT_TYPE.UPLOAD_MEDIA:
    case DIALOG_CONTENT_TYPE.REGISTRATION_CONFIRMATION:
    case DIALOG_CONTENT_TYPE.SHOW_ALL_CONSIGNORS:
    case DIALOG_CONTENT_TYPE.UPLOAD_USERS:
    case DIALOG_CONTENT_TYPE.ATTEND_VIEW_ONLY:
    case DIALOG_CONTENT_TYPE.MANAGE_COMMUNITY_DEALERSHIPS:
    case "AGREEMENT":
    case "DEPOSIT":
    case "BADGE_ERROR":
    case DIALOG_CONTENT_TYPE.LOGIN_PROMPT:
    case DIALOG_CONTENT_TYPE.FLOORPLAN_ERRORS:
    case DIALOG_CONTENT_TYPE.ADD_AAID:
      return null;
    case PURCHASE:
      return "Fees Estimate";
    case HISTORY:
      return "Bid Activity";
    case "BUY_NOW":
      return "Purchase Confirmation";
    case "EDIT_LOT_STATUS_WITHDRAW":
      return "Withdraw Confirmation";
    case "SHOW_BADGES":
      return "Badges Granted";
    default:
      return contentType;
  }
}

export function getDialogProps(contentType) {
  switch (contentType) {
    case DIALOG_CONTENT_TYPE.UPSERT_MEMBERSHIP_SCHEMA_FIELD:
    case DIALOG_CONTENT_TYPE.FLOORPLAN_ERRORS:
      return {
        maxWidth: "md"
      };
    case DIALOG_CONTENT_TYPE.UNRESOLVED_PSI_REQUEST:
    case DIALOG_CONTENT_TYPE.ATTEND_NEW:
    case DIALOG_CONTENT_TYPE.MEMBERSHIP_STATUS:
    case "BADGE_ERROR":
      return {
        maxWidth: "xs",
        fullWidth: true
      };
    case DIALOG_CONTENT_TYPE.EVENT_PRINT:
      return {
        maxWidth: "sm",
        fullWidth: true
      };
    default:
      return {};
  }
}

export default dialog;
