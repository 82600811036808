import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import {
  inventoryFetch,
  inventoryFetchComplete,
  recommendationFetchComplete
} from "actions/sharedActions";

type Disclosure = {
  id: number;
  disclosure: string;
  assetId: number;
  companyId: number;
};

const disclosuresAdapter = createEntityAdapter<Disclosure, number>({
  selectId: disclosure => disclosure.assetId
});

export const disclosuresSelector = disclosuresAdapter.getSelectors();

export const initialState = disclosuresAdapter.getInitialState({
  isLoading: true
});

const disclosuresSlice = createSlice({
  name: "disclosures",
  initialState,
  reducers: {
    fetch: (state, action) => {
      state.isLoading = true;
    },
    upsertOne: (state, action) => {
      state.isLoading = false;
      disclosuresAdapter.upsertOne(state, action.payload);
    },
    upsert: (state, action) => {
      state.isLoading = false;
      disclosuresAdapter.upsertMany(state, action.payload);
    }
  },
  extraReducers: builder => {
    builder.addCase(inventoryFetch, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(inventoryFetchComplete, (state, action) => {
      state.isLoading = false;
      if (action.payload.disclosures?.length > 0) {
        disclosuresAdapter.upsertMany(state, action.payload.disclosures);
      }
    });
    builder.addCase(recommendationFetchComplete, (state, action) => {
      if (action.payload.disclosures?.length > 0) {
        disclosuresAdapter.upsertMany(state, action.payload.disclosures);
      }
    });
  }
});

const { actions, reducer } = disclosuresSlice;

export const { fetch, upsert, upsertOne } = actions;
export default reducer;
