import { post, downloadExternalResource, get, download } from "./api";

export async function getLotActivity(filters, range, sorts, isConsignor) {
  const { data } = await get(
    `application/community/activity/lots?consignor=${isConsignor}`,
    filters,
    range,
    sorts
  );

  return data;
}

export async function getBidActivity(filters, range, sorts) {
  const { data } = await get("application/community/activity/bids", filters, range, sorts);

  return data;
}

export async function getNoteActivity(filters, range, sorts) {
  const { data } = await get("application/community/activity/notes", filters, range, sorts);

  return data;
}

export async function getActivityFilters(filters, activityRoute) {
  const { data } = await get(`application/community/activity/${activityRoute}/event`, filters);
  return data;
}

export async function getContracts(payload) {
  const { data } = await post("ams/contracts", payload);
  return data;
}

export async function getGatePasses(payload) {
  const { data } = await post("ams/gatePasses", payload);
  return data;
}

export async function getConsignedReport(payload) {
  const { data } = await post("ams/consignedReport", payload);
  return data;
}

export async function handleDownload(apiResponse, bulkActionType) {
  await Promise.all(
    apiResponse
      .filter(response => response.link)
      .map(response => {
        return downloadExternalResource(response.link, "pdf", bulkActionType, {
          responseType: "arraybuffer"
        });
      })
  );
}

export async function getCompanyReports(companyIds, getCompanyPayload, getCompanyReport) {
  const promises = [];

  companyIds.forEach(companyId => {
    const payload = getCompanyPayload(companyId);
    promises.push(getCompanyReport(payload));
  });

  return await Promise.all(promises);
}

export async function emailPdfReport(filters, payload) {
  const res = await post("application/community/reportRequestEmail", payload, filters);
  return { data: res.data };
}

export async function downloadPdfReport(fileName, filters, sorts, payload) {
  const headers = {
    "Content-Type": "application/json",
    Filters: JSON.stringify(filters)
  };

  if (sorts) {
    headers.Sort = sorts;
  }

  return download(
    "/services/application/community/reportRequestDownload",
    "pdf",
    fileName,
    undefined,
    "post",
    headers,
    payload
  );
}
