import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import { store, persistor } from "./store";
import { PersistGate } from "redux-persist/lib/integration/react";

const app = AppComponent => (
  <Provider store={store}>
    <PersistGate loading="loading?" persistor={persistor}>
      <AppComponent />
    </PersistGate>
  </Provider>
);

const root = createRoot(document.getElementById("root"));

root.render(app(App));

if (module.hot) {
  module.hot.accept();
  const NextApp = require("./App").default;
  root.render(app(NextApp));
}
