class StorageUtil {
  static getItem(key: string, defaultValue = null) {
    try {
      const item = localStorage.getItem(key);

      return item ? JSON.parse(item) : defaultValue;
    } catch (error) {
      return defaultValue;
    }
  }

  static setItem(key: string, value: any) {
    try {
      localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {}
  }
}

export { StorageUtil as default, StorageUtil };
