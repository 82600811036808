import React, { FC } from "react";

const Title: FC<{ title?: string; date?: string; totalLabel?: string }> = ({
  title,
  date,
  totalLabel
}) => {
  if (title == null) return null;

  return (
    <div style={{ position: "relative" }}>
      <div>
        {date && <span style={{ fontSize: "14px", marginRight: 8 }}>{date}</span>}
        <span style={{ fontSize: "14px" }}>{title}</span>
      </div>
      <div style={{ position: "absolute", right: 0, top: 0 }}>
        {totalLabel && <span style={{ fontSize: "14px" }}>{totalLabel}</span>}
      </div>
    </div>
  );
};

export default Title;
