import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "tss-react/mui";
import { DialogContent, DialogTitle, List } from "@mui/material";
import { eventsSelector } from "reducers/events";
import { useSelector } from "utils/StoreUtil";
import Consignor from "views/calendar/consignors/Consignor";
import DialogTitleContent from "../DialogTitleContent";

const useStyles = makeStyles()(theme => ({
  root: {
    [theme.breakpoints.up("md")]: {
      width: 450
    }
  },
  dialogRoot: {
    display: "inline",
    borderBottom: 0
  },
  consignorImages: {
    display: "flex",
    flexDirection: "column",
    marginBottom: theme.spacing(2)
  },
  consignorCard: {
    display: "flex",
    flexDirection: "row",
    padding: theme.spacing(2),
    borderBottom: theme.shape.borderLight,
    width: "100%",
    border: "none",
    boxShadow: "none",
    marginLeft: 0,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "baseline"
    },
    "&:hover": {
      boxShadow: "none"
    }
  },
  text: {
    width: 150,
    height: 125
  },
  link: {
    margin: 0,
    width: 175
  },
  additionalInfo: {
    marginLeft: theme.spacing(3),
    padding: theme.spacing(1),
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0
    }
  },
  title: {
    padding: theme.spacing(0)
  }
}));

function DialogContentConsignorsList({ handleClose, eventId, total, consignors = [] }) {
  const { classes } = useStyles();

  const event = useSelector(state => eventsSelector.selectById(state.events, eventId));

  const eventName = event?.name;

  return (
    <>
      <DialogTitle>
        <DialogTitleContent
          title={`${total} Consignors`}
          subTitle={eventName && eventName}
          onClose={handleClose}
        />
      </DialogTitle>
      <DialogContent className={classes.root}>
        <List disablePadding>
          {consignors.map(consignor => {
            return (
              <Consignor
                key={consignor.id}
                consignor={consignor}
                variant="list"
                eventId={eventId}
              />
            );
          })}
        </List>
      </DialogContent>
    </>
  );
}

DialogContentConsignorsList.propTypes = {
  handleClose: PropTypes.func.isRequired
};

export default DialogContentConsignorsList;
