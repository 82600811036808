import MenuList from "@mui/material/MenuList";
import Popper from "@mui/material/Popper";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Paper from "@mui/material/Paper";
import Grow from "@mui/material/Grow";
import matchesMedia from "utils/MatchMedia";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(theme => ({
  popper: {},
  arrow: {
    "&:after": {
      borderBottom: `${theme.spacing(1.5)} solid ${theme.palette.common.white}`,
      borderLeft: `${theme.spacing(1.5)} solid transparent`,
      borderRight: `${theme.spacing(1.5)} solid transparent`,
      top: -10,
      content: "''",
      position: "absolute",
      left: "50%",
      transform: "translateX(-50%)",
      width: 0,
      height: 0
    }
  },
  paper: {}
}));

const PopperMenu = ({
  isOpen,
  anchorEl,
  disablePortal = false,
  handleClose,
  placement,
  children,
  displayArrow,
  keepMounted,
  ...props
}) => {
  const { classes, theme } = useStyles(undefined, { props });

  if (!placement) {
    placement = matchesMedia(theme.breakpoints.down("md")) ? "top" : "bottom";
  }

  return (
    <Popper
      open={isOpen}
      anchorEl={anchorEl}
      transition
      disablePortal={disablePortal}
      placement={placement}
      className={classes.popper}
      keepMounted={keepMounted}
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin:
              placement === "bottom" || placement === "left-start" ? "center top" : "center bottom"
          }}
        >
          <div>
            {displayArrow && <span className={classes.arrow} />}
            <Paper className={classes.paper}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList variant="menu">{children}</MenuList>
              </ClickAwayListener>
            </Paper>
          </div>
        </Grow>
      )}
    </Popper>
  );
};

export default PopperMenu;
