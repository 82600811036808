import { createSlice, createEntityAdapter } from "@reduxjs/toolkit";
import { REHYDRATE } from "redux-persist/lib/constants";

import { MEMBERSHIP_SCHEMA_FETCH, MEMBERSHIP_SCHEMA_FETCH_COMPLETE } from "actions/actionTypes";
import MembershipSchema from "types/MembershipSchema";

const membershipSchemasAdapter = createEntityAdapter<MembershipSchema, number>({
  selectId: membershipSchema => membershipSchema.companyId
});

const initialState = {
  isLoading: false
};

const membershipSchemasSlice = createSlice({
  name: "membershipSchemas",
  initialState: membershipSchemasAdapter.getInitialState(initialState),
  reducers: {
    addMembershipSchema(state, { payload }) {
      if (payload) {
        membershipSchemasAdapter.addOne(state, payload);
      }
    },
    addMembershipSchemas(state, { payload }) {
      if (payload) {
        membershipSchemasAdapter.setAll(state, payload);
      }
    },
    updateMembershipSchema: (state, { payload }) => {
      const membershipSchema = payload;

      if (membershipSchema) {
        membershipSchemasAdapter.updateOne(state, {
          id: membershipSchema.companyId,
          changes: membershipSchema
        });
      }
    }
  },
  extraReducers: builder => {
    builder
      .addCase(REHYDRATE, () => {
        return membershipSchemasAdapter.getInitialState(initialState);
      })
      .addCase(MEMBERSHIP_SCHEMA_FETCH, state => {
        state.isLoading = true;
      })
      .addCase(MEMBERSHIP_SCHEMA_FETCH_COMPLETE, (state, action) => {
        state.isLoading = false;
        // @ts-ignore
        membershipSchemasAdapter.setAll(state, action.payload || []);
      });
  }
});

const getSchemaFields = (membershipSchema: MembershipSchema) =>
  membershipSchema?.schemaFields ?? [];

export const getActiveMembershipSchemaFields = (membershipSchema: MembershipSchema) =>
  getSchemaFields(membershipSchema).filter(schemaField => schemaField.isActive);

export const getHasActiveMembershipSchemaFields = (membershipSchema: MembershipSchema) => {
  return getActiveMembershipSchemaFields(membershipSchema).length > 0;
};

export const membershipSchemasSelector = membershipSchemasAdapter.getSelectors();

export const {
  addMembershipSchema,
  updateMembershipSchema,
  addMembershipSchemas
} = membershipSchemasSlice.actions;

export default membershipSchemasSlice.reducer;
