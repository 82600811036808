import { getSchemas, getUISchemas, getMembershipSchemas } from "api/adminRequests";
import { createTermFilter } from "utils/SearchUtil";
import {
  ASSET_SCHEMAS_FETCH_COMPLETE,
  UI_SCHEMA_FETCH,
  UI_SCHEMA_FETCH_COMPLETE,
  MEMBERSHIP_SCHEMA_FETCH,
  MEMBERSHIP_SCHEMA_FETCH_COMPLETE
} from "actions/actionTypes";

export function fetchAssetSchemas() {
  return async dispatch => {
    let schemas;

    try {
      const { data } = await getSchemas([createTermFilter("schemaType", "ASSET")]);
      schemas = data;
    } finally {
      dispatch({ type: ASSET_SCHEMAS_FETCH_COMPLETE, payload: schemas });
    }

    return schemas;
  };
}

export function fetchUISchema(companyId, uiSchemaType) {
  return async dispatch => {
    dispatch({ type: UI_SCHEMA_FETCH });

    let schema;

    try {
      const filters = [createTermFilter("companyId", companyId)];

      if (uiSchemaType) {
        filters.push(createTermFilter("uiSchemaType", uiSchemaType));
      }

      const { data } = await getUISchemas(filters, undefined, undefined, {
        hideChildCompanyResults: true
      });
      schema = data;
    } finally {
      dispatch({ type: UI_SCHEMA_FETCH_COMPLETE, payload: schema });
    }

    return schema;
  };
}

export function fetchMembershipSchema(companyId) {
  return async dispatch => {
    dispatch({ type: MEMBERSHIP_SCHEMA_FETCH });

    let schema;

    try {
      const { data } = await getMembershipSchemas(
        [createTermFilter("companyId", companyId)],
        undefined,
        undefined,
        {
          hideChildCompanyResults: true
        }
      );
      schema = data;
    } finally {
      dispatch({ type: MEMBERSHIP_SCHEMA_FETCH_COMPLETE, payload: schema });
    }

    return schema;
  };
}
