import {
  VEHICLES_SET_PER_PAGE,
  VEHICLES_SET_PAGE,
  VEHICLES_RUN_SEARCH_OPTIONS_SCRIPT,
  VEHICLES_SET_SEARCH_OPTIONS,
  INVENTORY_SEARCH_OPTIONS_RESET_STATE,
  INVENTORY_SET_LAYOUT,
  INVENTORY_SET_QUERY_STRING,
  INVENTORY_TOGGLE_SEARCH_OPTION,
  INVENTORY_ADD_SEARCH_OPTION_FROM_PARAMS,
  INVENTORY_SET_SORT_BY,
  INVENTORY_SET_SORT_ORDER,
  INVENTORY_TOGGLE_SORT_BY,
  CONFIG_SET,
  INVENTORY_TOGGLE_ASSET_SCHEMA_ID_SEARCH_OPTION,
  SET_PARENT_PAGE_CATEGORY,
  USER_LOGOUT
} from "actions/actionTypes";
import { REHYDRATE } from "redux-persist/lib/constants";
import { createSelector } from "@reduxjs/toolkit";
import { toggleSort } from "./reports";
import { getConfigValue } from "./config";
import { getDefaultSort } from "utils/MarketUtil";
import { MARKET_SEGMENTS } from "utils/constants";

const DEFAULT_PER_PAGE = 20;

function getLocalState() {
  const value = localStorage.getItem("inventoryPerPage");
  return /^\d+$/.test(value) ? Number(value) : DEFAULT_PER_PAGE;
}

function setLocalState(value) {
  localStorage.setItem("inventoryPerPage", JSON.stringify(value));
}

export const initialState = {
  searchOptions: [],
  perPage: getLocalState(),
  page: 0,
  sortBy: getDefaultSort(MARKET_SEGMENTS.AUTOMOTIVE),
  areSearchOptionsLoading: true,
  currentLayout: "list",
  parentPageCategory: null
};

export default function vehicles(state = initialState, action) {
  switch (action.type) {
    case REHYDRATE:
      if (action.payload) {
        return {
          ...state,
          ...action.payload.vehicles,
          searchOptions: [],
          areSearchOptionsLoading: true,
          parentPageCategory: null,
          page: 0
        };
      } else {
        return {
          ...state
        };
      }

    case CONFIG_SET:
      const marketSegment = getConfigValue(action.payload.config, "marketSegment");
      const useInventoryTableView = getConfigValue(action.payload.config, "useInventoryTableView");

      return {
        ...state,
        sortBy: getDefaultSort(marketSegment),
        currentLayout: getLayout({ useInventoryTableView }, getDefaultLayout(marketSegment))
      };

    case VEHICLES_SET_PER_PAGE: {
      const { perPage } = action.payload;
      setLocalState(perPage);
      return {
        ...state,
        perPage
      };
    }
    case VEHICLES_SET_PAGE:
      return {
        ...state,
        page: action.payload.page
      };

    case VEHICLES_RUN_SEARCH_OPTIONS_SCRIPT:
      return {
        ...state,
        areSearchOptionsLoading: action.payload.displayLoader
      };

    case VEHICLES_SET_SEARCH_OPTIONS:
      return {
        ...state,
        searchOptions: action.payload.searchOptions,
        areSearchOptionsLoading: false
      };

    case INVENTORY_SEARCH_OPTIONS_RESET_STATE:
      return {
        ...state,
        selectedSearchOptionsCounter: 0,
        page: initialState.page
      };

    case INVENTORY_TOGGLE_SEARCH_OPTION:
      return {
        ...state,
        page: initialState.page
      };

    case INVENTORY_TOGGLE_ASSET_SCHEMA_ID_SEARCH_OPTION:
      return {
        ...state,
        page: initialState.page
      };

    case INVENTORY_SET_SORT_BY:
      return {
        ...state,
        sortBy: getSortBy(action.payload.sortBy)
      };

    case INVENTORY_SET_SORT_ORDER: {
      const { sortOrder } = action.payload;
      return {
        ...state,
        sortBy: sortOrder + state.sortBy.slice(1)
      };
    }

    case INVENTORY_TOGGLE_SORT_BY:
      return {
        ...state,
        sortBy: toggleSort(state.sortBy, action.payload.sortBy)
      };

    case INVENTORY_SET_LAYOUT:
      return {
        ...state,
        currentLayout: action.payload.layout
      };

    case INVENTORY_SET_QUERY_STRING:
      return {
        ...state,
        page: initialState.page
      };

    case INVENTORY_ADD_SEARCH_OPTION_FROM_PARAMS:
      return {
        ...state,
        page: action.payload.page
      };

    case SET_PARENT_PAGE_CATEGORY:
      return {
        ...state,
        parentPageCategory: action.payload.parentPageCategory
      };

    case USER_LOGOUT:
      return {
        ...state,
        perPage: getLocalState()
      };

    default:
      return state;
  }
}

function getDefaultLayout(marketSegment) {
  switch (marketSegment) {
    case "bloodStock":
      return "table";
    default:
      return "list";
  }
}

function getLayout(configState, defaultValue) {
  const { useInventoryTableView } = configState;

  const layoutsTurnedOff = [];

  if (!useInventoryTableView) {
    layoutsTurnedOff.push("table");
  }

  if (layoutsTurnedOff.includes(defaultValue)) {
    return "list";
  } else {
    return defaultValue;
  }
}

export function getSortBy(sort) {
  if (!sort || sort.length <= 1) {
    return "";
  }

  const hasOrderPrefix = sort[0] === "+" || sort[0] === "-";

  if (hasOrderPrefix) {
    return sort;
  }

  if (sort === "lot.createdOn") {
    return "-" + sort;
  } else {
    return "+" + sort;
  }
}

export const toggleSearchOptionValue = (selectedSearchOptions, { searchOption, value }) => {
  return Array.isArray(selectedSearchOptions[searchOption])
    ? toggleArrayValue(selectedSearchOptions[searchOption], value)
    : toggleValue(selectedSearchOptions[searchOption], searchOption, value);
};

export const toggleArrayValue = (array, value) => {
  return array.includes(value)
    ? array.filter(searchOption => searchOption !== value)
    : [...array, value];
};

const toggleValue = (selectedSearchOption, searchOption, value) => {
  if (!selectedSearchOption) {
    return value;
  } else {
    if (searchOption === "queryString") {
      return value;
    } else {
      return selectedSearchOption === value ? null : value;
    }
  }
};

export const mapSearchOptionName = searchOption => {
  switch (searchOption) {
    case "fromYear":
    case "fromMileage":
    case "fromGrade":
    case "dateFrom":
    case "fromAverageWeight":
    case "fromBaseWeight":
    case "fromAge":
    case "fromQuantity":
    case "fromWeaningWeight":
    case "fromYearlingWeight":
      return "From: ";
    case "toYear":
    case "toMileage":
    case "toGrade":
    case "dateTo":
    case "toAverageWeight":
    case "toBaseWeight":
    case "toAge":
    case "toQuantity":
    case "toWeaningWeight":
    case "toYearlingWeight":
      return "To: ";
    case "odometerUnit":
      return "Type: ";
    default:
      return "";
  }
};

export const mapSearchOptionValue = value => {
  switch (value) {
    case "mileage":
      return "odometer";
    case "mi":
      return "Miles";
    case "ki":
    case "km":
      return "Kilometers";
    case "hr":
      return "Hours";
    case "ifsold":
      return "pending";
    case "nosale":
      return "No sale";
    default:
      return value;
  }
};

const getInventory = state => state.vehicles;

const getSearchOptions = searchOptions => searchOptions;

export const combineSearchOptionFilters = createSelector(
  getSearchOptions,
  ({ main, advanced }) => ({ ...main, ...advanced })
);

const getSaleId = (state, saleId) => saleId;

// TODO: update or delete
export const filterInventoryBySaleId = createSelector(
  getInventory,
  getSaleId,
  (inventory, saleId) => {
    return inventory.filter(
      inventoryItem => parseInt(inventoryItem.sale.id, 10) === parseInt(saleId, 0)
    );
  }
);

export const getInventoryIds = createSelector(getInventory, inventory =>
  inventory.map(inventoryItem => inventoryItem.asset.id)
);

export const getSelectedSearchOptionCount = (
  selectedSearchOptions,
  shouldFilterEventOrSales = false
) => {
  let count = 0;
  const { watchlist, note, companies, ...restSelectedSearchOptions } = selectedSearchOptions;

  Object.keys(restSelectedSearchOptions)
    .filter(option => {
      if (shouldFilterEventOrSales) {
        return option !== "sales" && option !== "events";
      } else {
        return option;
      }
    })
    .forEach(option => {
      if (Array.isArray(restSelectedSearchOptions[option])) {
        count += restSelectedSearchOptions[option].length;
      } else if (restSelectedSearchOptions[option]) {
        count++;
      }
    });
  return count;
};
