import React, { Component } from "react";
import { connect } from "utils/StoreUtil";
import { createPortal } from "react-dom";
import { withStyles } from "tss-react/mui";
import PropTypes from "prop-types";
import classNames from "classnames";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import Hidden from "@mui/material/Hidden";
import IconButton from "@mui/material/IconButton";
import FilterList from "@mui/icons-material/FilterList";
import MenuIcon from "@mui/icons-material/Menu";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Divider from "@mui/material/Divider";
import FilterIcon from "./icons/FilterIcon";
import Stack from "./layout/Stack";
import { Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const styles = theme => ({
  container: {
    position: "relative",
    "overflow-y": "auto",
    "overflow-x": "hidden",
    backgroundColor: theme.palette.background.paper,
    borderRightStyle: "solid",
    borderRightWidth: "1px",
    borderRightColor: theme.palette.divider,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: [[0, 0]],
    flex: [[1, 1]]
  },
  wrapper: {
    textAlign: "center",
    overflow: "auto"
  },
  buttonStyle: {
    margin: "-0.1rem 0 0",
    padding: "0.25rem 0",
    color: theme.palette.primary.dark,
    "&:hover": {
      backgroundColor: theme.palette.action.hover
    }
  },
  moveLeft: {
    minWidth: "77px",
    flex: "0 0 0%",
    overflow: "hidden",
    transition: theme.transitions.create("min-width", {
      easing: theme.transitions.easing.easeIn,
      duration: 250
    })
  },
  moveRight: {
    minWidth: "250px",
    flex: "0 0 15%",
    transition: theme.transitions.create(["min-width", "flex"], {
      easing: theme.transitions.easing.easeOut,
      duration: 250
    })
  },
  noOpacity: {
    display: "none",
    opacity: 0,
    transition: theme.transitions.create("opacity", {
      easing: theme.transitions.easing.easeIn,
      duration: 250
    })
  },
  showOpacity: {
    opacity: 1,
    transition: theme.transitions.create("opacity", {
      easing: theme.transitions.easing.easeOut,
      duration: 250
    })
  },
  mobileDrawerWidthFix: {
    width: "75%"
  },
  flexEnd: {
    display: "flex",
    justifyContent: "flex-end"
  },
  actionText: {
    margin: "0 !important",
    color: theme.palette.text.secondary
  },
  actionButton: {
    color: theme.palette.text.secondary,
    textTransform: "none",
    minWidth: 45
  },
  closeButton: {
    height: 32,
    width: 32,
    position: "absolute",
    zIndex: 1010,
    left: "85%",
    marginTop: theme.spacing(1)
  }
});

const Sidebar = ({ children, classes, handleOpenClose }) => (
  <aside className={classNames("sideBar", classes.container)}>
    <IconButton color="primary" className={classes.closeButton} onClick={handleOpenClose}>
      <CloseIcon />
    </IconButton>
    <div className={classNames(classes.wrapper, "sideBarContent")}>{children}</div>
  </aside>
);

const CollapsibleSidebar = ({ children, isOpen, render, handleOpenClose, classes }) => (
  <aside
    className={classNames(
      "sideBar",
      classes.container,
      isOpen ? classes.moveRight : classes.moveLeft
    )}
  >
    <div className={classNames(classes.wrapper, "sideBarContent")}>
      {isOpen ? children : render(handleOpenClose, isOpen)}
    </div>
    <div>
      <Divider />
      {isOpen ? (
        <Button
          disableRipple
          fullWidth
          size="small"
          color="primary"
          className={classNames("triggerButton", classes.buttonStyle)}
          onClick={handleOpenClose}
        >
          <Stack direction="row" spacing={-2}>
            <KeyboardArrowLeft />
            <KeyboardArrowLeft />
          </Stack>
        </Button>
      ) : (
        <Button
          disableRipple
          fullWidth
          size="small"
          color="primary"
          className={classes.buttonStyle}
          onClick={handleOpenClose}
        >
          <Stack direction="row" spacing={-2}>
            <KeyboardArrowRight />
            <KeyboardArrowRight />
          </Stack>
        </Button>
      )}
    </div>
  </aside>
);

const hamburger = "hamburger";
const filter = "filter";
const filterIcon = "filterIcon";

export const MobileDrawer = ({
  isOpen,
  handleOpenClose,
  children,
  classes,
  anchor = "left",
  domSelector = "#mobileDrawer",
  iconVariant = hamburger,
  swipeAreaWidth,
  ...props
}) => {
  const element = props.element || document.querySelector(domSelector);

  if (element) {
    return createPortal(
      <React.Fragment>
        {(iconVariant === hamburger || iconVariant === filter) && (
          <IconButton onClick={handleOpenClose} className={classes.menu}>
            {iconVariant === "hamburger" && <MenuIcon />}
            {iconVariant === "filter" && <FilterList />}
          </IconButton>
        )}

        {iconVariant === filterIcon && (
          <Button onClick={handleOpenClose} className={classes.actionButton}>
            <Stack spacing={1} direction="column">
              <FilterIcon />
              <Typography variant="caption" className={classes.actionText}>
                Filter
              </Typography>
            </Stack>
          </Button>
        )}

        <SwipeableDrawer
          variant="temporary"
          anchor={anchor}
          open={isOpen}
          onOpen={handleOpenClose}
          onClose={handleOpenClose}
          ModalProps={{ keepMounted: true }}
          classes={{ paper: classes.mobileDrawerWidthFix }}
          swipeAreaWidth={swipeAreaWidth}
          disableBackdropTransition
        >
          <Sidebar
            children={children}
            classes={classes}
            handleOpenClose={handleOpenClose}
            {...props}
          />
        </SwipeableDrawer>
      </React.Fragment>,
      element
    );
  }
  return null;
};

class SidebarContainer extends Component {
  state = {
    isOpen: !this.props.isMobile
  };

  static defaultProps = {
    isCollapsable: true,
    render: () => null
  };

  static propTypes = {
    isCollapsable: PropTypes.bool
  };

  handleOpenClose = () => {
    this.setState(prevState => ({ isOpen: !prevState.isOpen }));
  };

  render() {
    const { isCollapsable } = this.props;
    return (
      <React.Fragment>
        <Hidden mdDown>
          {isCollapsable ? (
            <CollapsibleSidebar
              {...this.props}
              {...this.state}
              handleOpenClose={this.handleOpenClose}
            />
          ) : (
            <Sidebar {...this.props} />
          )}
        </Hidden>
        <Hidden mdUp>
          <MobileDrawer {...this.props} {...this.state} handleOpenClose={this.handleOpenClose} />
        </Hidden>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    isMobile: state.viewport.isMobile
  };
}

export default connect(mapStateToProps)(withStyles(SidebarContainer, styles));
