import { TIME_REQUEST_SYNC, TIME_SYNC_COMPLETE } from "actions/actionTypes";
import { getTime } from "api/systemRequests";

export function sync() {
  return async dispatch => {
    dispatch({ type: TIME_REQUEST_SYNC });
    const time = await getTime();
    const offset = time - Date.now();
    dispatch({
      type: TIME_SYNC_COMPLETE,
      payload: {
        time,
        offset
      }
    });
  };
}
