import { FC, PropsWithChildren, createContext } from "react";
import useStockWaveLibrary from "./useStockWaveLibrary";

const StockWaveIntegrationInitProviderContext = createContext(false);

const StockWaveIntegrationInitProvider: FC<PropsWithChildren> = ({ children }) => {
  const didLoad = useStockWaveLibrary();

  return (
    <StockWaveIntegrationInitProviderContext.Provider value={didLoad}>
      {children}
    </StockWaveIntegrationInitProviderContext.Provider>
  );
};

export {
  StockWaveIntegrationInitProvider as default,
  StockWaveIntegrationInitProvider,
  StockWaveIntegrationInitProviderContext
};
