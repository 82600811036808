import React from "react";
import CircularProgress from "@mui/material/CircularProgress";

export default function Loading({ size = 100 }) {
  return (
    <div className="emptyState">
      <CircularProgress size={size} />
    </div>
  );
}
