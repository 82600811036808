import { PropsWithChildren } from "react";
import { Box, Stack, TypographyProps } from "@mui/material";
import { ClippedTypography } from "components/typography/ClippedTypography";
import CompanyLogo from "../CompanyLogo";

const PreviewHeader: React.FC<PropsWithChildren<{
  label: string;
  imgSrc?: string;
  typographyProps?: TypographyProps;
}>> = ({ label, imgSrc, typographyProps, children }) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        backgroundColor: "primary.main",
        color: "primary.contrastText",
        padding: 1,
        borderRadius: 1,
        borderBottomRightRadius: 0,
        borderBottomLeftRadius: 0
      }}
    >
      <div>
        <CompanyLogo mediaUrl={imgSrc} />
      </div>

      <Box flex={1}>
        <ClippedTypography lines={1} variant="h6" color="inherit" align="left" {...typographyProps}>
          {label}
        </ClippedTypography>
      </Box>

      {children}
    </Stack>
  );
};

export { PreviewHeader as default, PreviewHeader };
