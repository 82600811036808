import { makeStyles } from "tss-react/mui";
import NavTabs from "./NavTabs";
import { AppBar, Tab, Toolbar } from "@mui/material";
import EventIcon from "@mui/icons-material/Event";
import ListAltIcon from "@mui/icons-material/ListAlt";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { useSelector } from "utils/StoreUtil";
import { Link } from "react-router-dom";
import { getCombinedPermissions } from "reducers/user";
import matchesPermissions from "utils/Permissions";
import useMediaQuery from "@mui/material/useMediaQuery";
import useReportsRoute from "hooks/useReportsRoute";
import MarketSegmentIcon from "components/icons/MarketSegmentIcon";
import AdminIcon from "components/icons/AdminIcon";

const useStyles = makeStyles()(theme => ({
  root: {
    backgroundColor: theme.palette.company.header,
    color: theme.palette.getContrastText(theme.palette.company.header),
    maxHeight: theme.spacing(9),
    bottom: 0,
    left: 0,
    top: "auto",
    "@supports (-webkit-touch-callout: none)": {
      maxHeight: theme.spacing(12),
      paddingBottom: theme.spacing(2)
    }
  },
  wrapper: {
    flexDirection: "column"
  },
  indicator: {
    top: 0,
    height: theme.spacing(0.5),
    background: theme.palette.company.accent
  },
  labelIcon: {
    minHeight: theme.spacing(4),
    padding: 0
  },
  tabsRoot: {
    flex: 1
  },
  tabRoot: {
    minHeight: theme.spacing(1),
    padding: theme.spacing(1),
    fontSize: theme.typography.caption.fontSize,
    textTransform: "capitalize"
  },
  tabsFlexContainer: {
    height: "100%"
  },
  tabsFixed: {
    overflowX: "initial"
  },
  tabContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    minWidth: "48px"
  }
}));

const NavbarMobile = () => {
  const { classes, theme } = useStyles();
  const tabClasses = {
    root: classes.tabRoot,
    wrapper: classes.wrapper,
    labelIcon: classes.labelIcon
  };

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const isAuthorized = useSelector(state => state.user.isAuthorized);
  const permissions = useSelector(state => getCombinedPermissions(state.user));
  const reportsRoute = useReportsRoute(permissions);

  if (!isAuthorized) return null;

  return (
    <AppBar id="bottomNav" className={classes.root}>
      <Toolbar disableGutters={isMobile}>
        <NavTabs
          variant="scrollable"
          classes={{
            indicator: classes.indicator,
            root: classes.tabsRoot,
            flexContainer: classes.tabsFlexContainer,
            fixed: classes.tabsFixed
          }}
        >
          {matchesPermissions(["viewCalendar"], permissions) && (
            <Tab
              classes={tabClasses}
              icon={<EventIcon className={classes.icon} />}
              component={Link}
              to="/calendar"
              value="/calendar"
              data-testid="calendar-nav-tab"
              label="Calendar"
            />
          )}
          {matchesPermissions(["viewInventory"], permissions) && (
            <Tab
              classes={tabClasses}
              icon={<MarketSegmentIcon className={classes.icon} />}
              component={Link}
              to="/inventory"
              value="/inventory"
              label="Inventory"
              data-testid="inventory-nav-tab"
            />
          )}
          {matchesPermissions(["viewPurchases"], permissions) && (
            <Tab
              classes={tabClasses}
              icon={<ListAltIcon className={classes.icon} />}
              component={Link}
              to="/home/activity/buyer/purchases"
              value="/home"
              label="My Activity"
              data-testid="activity-nav-tab"
            />
          )}

          {reportsRoute && (
            <Tab
              classes={tabClasses}
              icon={<DashboardIcon className={classes.icon} />}
              component={Link}
              to={reportsRoute}
              value="/research"
              label="Research"
              data-testid="research-nav-tab"
            />
          )}

          {matchesPermissions([{ entity: "EVENT", operations: ["UPDATE"] }], permissions) && (
            <Tab
              classes={tabClasses}
              icon={<AdminIcon className={classes.icon} />}
              component={Link}
              to="/admin"
              value="/admin"
              label="Admin"
              data-testid="admin-nav-tab"
            />
          )}
        </NavTabs>
      </Toolbar>
    </AppBar>
  );
};

export default NavbarMobile;
