import SaleUser from "types/SaleUser";
import { ORIGIN } from "./constants";

class AttendanceUtil {
  static hasFloorSaleUserWithNoCredit(saleUsers: SaleUser[] = []) {
    return saleUsers.some(saleUser => {
      return AttendanceUtil.isFloorSaleUserWithNoCredit(saleUser);
    });
  }

  static shouldFetchEventSaleUsers(
    saleIds: number[] = [],
    saleUserMap: Record<number, SaleUser[]> = {}
  ) {
    for (const saleId of saleIds) {
      const saleUsers = saleUserMap[saleId] || [];
      const saleUsersWithoutErrors = saleUsers.filter(saleUser => !saleUser.error);

      if (
        saleUsersWithoutErrors.length === 0 ||
        AttendanceUtil.hasFloorSaleUserWithNoCredit(saleUsersWithoutErrors)
      ) {
        return true;
      }
    }

    return false;
  }

  private static isFloorSaleUserWithNoCredit(saleUser: SaleUser) {
    return saleUser.origin === ORIGIN.FLOOR && saleUser.creditLimit <= 0;
  }
}

export { AttendanceUtil as default, AttendanceUtil };
