import {
  ADMIN_EVENT_MANAGEMENT_SET_PAGE,
  ADMIN_EVENT_MANAGEMENT_SET_SORT_BY,
  CONFIG_SET
} from "actions/actionTypes";
import { toggleSort } from "../reports";
import { getConfigValue } from "reducers/config";
import { getDefaultLotSort } from "utils/MarketUtil";
import { MARKET_SEGMENTS } from "utils/constants";

const initialState = {
  sortBy: getDefaultLotSort(MARKET_SEGMENTS.AUTOMOTIVE),
  page: 0,
  perPage: 25
};

export default function eventManagement(state = initialState, action) {
  switch (action.type) {
    case ADMIN_EVENT_MANAGEMENT_SET_PAGE:
      return {
        ...state,
        page: action.payload.page
      };
    case ADMIN_EVENT_MANAGEMENT_SET_SORT_BY:
      return {
        ...state,
        sortBy: toggleSort(state.sortBy, action.payload.sortBy)
      };
    case CONFIG_SET:
      const marketSegment = getConfigValue(action.payload.config, "marketSegment");
      return {
        ...state,
        sortBy: getDefaultLotSort(marketSegment)
      };
    default:
      return state;
  }
}

export const renderSaleTypeLabel = saleType => {
  switch (saleType) {
    case "LIVE_LEGACY":
      return "Live";
    default:
      return saleType;
  }
};

const requiredErrorMessage = "Required";

export function validateEntityRequiredValues(entity, requiredKeys, errors) {
  requiredKeys.forEach(key => {
    validateRequiredValue(entity[key], key, errors);
  });
}

export function validateRequiredRefValue(ref, name, errors) {
  validateRequiredValue(ref.current?.value, name, errors);
}

export function validateRequiredValue(value, name, errors) {
  if (value == null) {
    errors[name] = requiredErrorMessage;
  } else {
    if (typeof value === "string" && !value.trim()) {
      errors[name] = requiredErrorMessage;
    } else if (Array.isArray(value) && value.length < 1) {
      errors[name] = requiredErrorMessage;
    }
  }
}
