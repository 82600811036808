import React from "react";
import PropTypes from "prop-types";
import Login from "views/login/index";
import { useSelector } from "utils/StoreUtil";

function AuthWrapped({ FallbackComponent, Component, ...props }) {
  const isAuthorized = useSelector(state => state.user.isAuthorized);

  if (!isAuthorized) {
    return <FallbackComponent />;
  }
  return <Component {...props} />;
}

const AuthWrapper = (FallbackComponent = Login) => Component => props => (
  <AuthWrapped FallbackComponent={FallbackComponent} Component={Component} {...props} />
);

AuthWrapper.propTypes = {
  isAuthorized: PropTypes.bool.isRequired
};

export default AuthWrapper;
