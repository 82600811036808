import Hidden from "@mui/material/Hidden";

import GoNative from "services/gonative";

import NavbarMobile from "./NavbarMobile";
import NavbarDesktop from "./NavbarDesktop";
import TopNavbarMobile from "./TopNavbarMobile";

const Navbar = () => {
  return (
    <>
      <Hidden mdDown>
        <NavbarDesktop />
      </Hidden>

      <Hidden mdUp>
        <TopNavbarMobile />
      </Hidden>

      {!GoNative.IsGoNative() && (
        <Hidden mdUp>
          <NavbarMobile />
        </Hidden>
      )}
    </>
  );
};

export default Navbar;
