import { useState } from "react";
import useInterval from "../useInterval";
import { useSelector } from "utils/StoreUtil";

function useCountdown(endTime, delay = 1000) {
  const offset = useSelector(state => state.time.offset);
  const [now, setNow] = useState(Date.now() + offset);

  useInterval(
    () => {
      setNow(Date.now() + offset);
    },
    now < endTime ? delay : null
  );

  return now;
}

export { useCountdown as default };
