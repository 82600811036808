import { useRef } from "react";
import uniqueId from "lodash/uniqueId";

function useUniqueId(prefix) {
  const uniqueIdRef = useRef(uniqueId(prefix));

  return uniqueIdRef.current;
}

export { useUniqueId as default };
