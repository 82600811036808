import { ADMIN_UPDATE_EVENT, ADMIN_UPDATE_SALE } from "actions/actionTypes";
import { updateEvent, updateSale } from "api/adminRequests";
import { showNotification } from "./notificationActions";
import { Severity } from "types/alert";

export function updateAdminEvent(event, eventId) {
  return async dispatch => {
    await updateEvent(event, eventId);
    dispatch(updateAdminEventAction(event));
    dispatch(showNotification("Event updated", Severity.success));
  };
}

export function updateAdminSale(sale) {
  return async dispatch => {
    await updateSale(sale);
    dispatch({
      type: ADMIN_UPDATE_SALE,
      payload: {
        update: {
          id: sale.id,
          changes: sale
        }
      }
    });
  };
}

export function updateAdminEventAction(event) {
  const eventId = event?.id;
  return {
    type: ADMIN_UPDATE_EVENT,
    payload: {
      update: {
        id: eventId,
        changes: {
          id: eventId,
          ...event
        }
      }
    }
  };
}
