import { trackEvent } from "hooks/useTracking";
import StyledFrame from "./StyledFrame";

const styles = { style: { opacity: 0 }, height: 0, width: 0, frameBorder: 0 };

const PrintFrame = ({ callback, children, trackedEvent }) => {
  function print(source = window) {
    source.focus();

    if (trackedEvent) {
      trackEvent(trackedEvent);
    }

    let result = false;

    try {
      // IE
      result = source.document.execCommand("print", false, null);
    } catch {
      // Chrome throws on the execCommand call
      // do nothing
    }

    // Chrome, Firefox and Safari
    if (!result) {
      if (source.contentWindow) {
        // iframe object
        source.contentWindow.print();
      } else {
        // normal window object
        source.print();
      }
    }
  }

  function triggerPrint(source) {
    print(source);
    callback?.();
  }

  return (
    <StyledFrame {...styles} triggerPrint={triggerPrint}>
      {children}
    </StyledFrame>
  );
};

export default PrintFrame;
