import {
  NOTIFICATIONS_SHOW,
  NOTIFICATIONS_ERROR,
  NOTIFICATIONS_DISMISS,
  NOTIFICATIONS_SET,
  NOTIFICATIONS_GET,
  NOTIFICATIONS_RESET,
  NOTIFICATIONS_SEND,
  NOTIFICATIONS_SENT
} from "actions/actionTypes";
import get from "lodash/get";

const initialState = {
  notification: "",
  error: "",
  isOpen: false,
  notifications: [],
  isLoading: true,
  moreAvailable: true,
  lastRequestTimestamp: 0,
  isSending: false
};

export default function notifications(state = initialState, action) {
  switch (action.type) {
    case NOTIFICATIONS_SHOW:
      return {
        ...state,
        notification: action.payload.notification,
        severity: action.payload.severity,
        error: false,
        isOpen: true,
        newNotificationsCount: 0
      };

    case NOTIFICATIONS_ERROR:
      return {
        ...state,
        notification: action.payload.notification,
        severity: action.payload.severity,
        error: true,
        isOpen: true,
        isLoading: false
      };

    case NOTIFICATIONS_DISMISS:
      return {
        ...state,
        notification: "",
        error: "",
        isOpen: false
      };

    case NOTIFICATIONS_GET:
      return {
        ...state,
        isLoading: true
      };

    case NOTIFICATIONS_SET: {
      const { notifications, moreAvailable, newOnly } = action.payload;
      return {
        ...state,
        notifications: newOnly
          ? mapNotifications(notifications, state.notifications)
          : [...state.notifications, ...notifications],
        moreAvailable,
        lastRequestTimestamp: Date.now(),
        isLoading: false
      };
    }

    case NOTIFICATIONS_RESET:
      return {
        ...state,
        notifications: initialState.notifications
      };

    case NOTIFICATIONS_SEND:
      return {
        ...state,
        isSending: true
      };

    case NOTIFICATIONS_SENT:
      return {
        ...state,
        isSending: false
      };

    default:
      return state;
  }
}

function mapNotifications(newNotifications, prevNotifications) {
  return [
    ...newNotifications.map(notification => ({ ...notification, isNewNotification: true })),
    ...prevNotifications.map(notification => ({ ...notification, isNewNotification: false }))
  ];
}

export const getNotificationSecondaryTextByType = notification => {
  switch (notification.entityType) {
    case "USER":
      return getNotificationUserText(notification);
    default:
      return get(notification, "asset.uifields.header.value", "");
  }
};

const getNotificationUserText = notification => {
  const name = get(notification, "user.name", "");
  const email = get(notification, "user.email", "");
  return `${name} ${name && email && " - "} ${email}`;
};

export const getNotificationPathByType = notification => {
  switch (notification.entityType) {
    case "USER":
      return notification.entityId ? `/admin/user/details/${notification.entityId}?nav=true` : "";
    default:
      return notification.lotId ? `/inventory/${notification.lotId}` : "";
  }
};

export function createDefaultNotificationGroup(companyId) {
  return {
    companyId,
    name: "Admin Group",
    userIds: [],
    notificationGroupType: "CUSTOMER_ADMIN"
  };
}

export const getErrorMessage = error => error?.response?.data ?? error?.message;
