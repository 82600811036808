import { FC, CSSProperties } from "react";
import uniqueId from "lodash/uniqueId";

interface TableProps {
  rows: Array<Array<string>>;
}

const tableStyle: CSSProperties = {
  fontSize: "14px",
  borderCollapse: "collapse"
};

const thStyle: CSSProperties = {
  whiteSpace: "nowrap",
  padding: "5px"
};

const tdStyle: CSSProperties = {
  padding: "5px",
  border: "1px solid black"
};

const Table: FC<TableProps> = props => {
  const [headers = [], ...rows] = props.rows;

  return (
    <div>
      <table style={tableStyle}>
        <thead>
          <tr>
            {headers.map(header => (
              <th style={thStyle} key={uniqueId("pdfHeader")}>
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map(row => (
            <tr key={uniqueId("pdfBodyRow")}>
              {row.map(rowItem => (
                <td key={uniqueId("pdfRowItem")} style={tdStyle}>
                  {rowItem}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
