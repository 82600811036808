import {
  MEMBERSHIPS_GET,
  MEMBERSHIPS_SET,
  MEMBERSHIP_CREATE,
  MEMBERSHIP_UPDATE,
  MEMBERSHIPS_SET_TOTAL,
  MEMBERSHIPS_SET_PAGE,
  MEMBERSHIP_TOGGLE_FILTER,
  MEMBERSHIP_SET_SORT_BY,
  ADMIN_USERS_SELECT_BULK_ACTION,
  ADMIN_USERS_BULK_ACTION_SELECT_MEMBERSHIP_ID,
  ADMIN_USERS_BULK_ACTION_SELECT_ALL_MEMBERSHIP_IDS,
  MEMBERSHIP_SAVE,
  MEMBERSHIP_SAVE_COMPLETE,
  MEMBERSHIP_TOGGLE_HIDDEN_COLUMN,
  SERVER_APP_STATE_REHYDRATE,
  RECOMMENDATION_FETCH_COMPLETE,
  CALENDAR_FETCH_COMPLETE
} from "actions/actionTypes";
import { REHYDRATE } from "redux-persist/lib/constants";
import uniqueBy from "lodash/uniqBy";
import { MEMBERSHIP_STATUS_TYPE, BULK_ACTIONS_TYPE } from "utils/constants";
import { inventoryFetchComplete } from "actions/sharedActions";

export const initialState = {
  memberships: [],
  areMembershipsLoading: false,
  total: 0,
  page: 0,
  sortBy: "-createdOn",
  membershipFilters: [],
  isSaving: false,
  selectedMembershipIds: [],
  defaultHiddenColumns: undefined,
  companyHiddenColumns: undefined
};

export default function memberships(state = initialState, action) {
  switch (action.type) {
    case REHYDRATE: {
      const membershipsState = action.payload?.memberships;
      if (membershipsState) {
        return {
          ...initialState,
          defaultHiddenColumns: membershipsState.defaultHiddenColumns,
          companyHiddenColumns: membershipsState.companyHiddenColumns
        };
      }

      return initialState;
    }

    case SERVER_APP_STATE_REHYDRATE:
      if (action.payload.memberships) {
        return {
          ...state,
          defaultHiddenColumns: action.payload.memberships.defaultHiddenColumns,
          companyHiddenColumns: action.payload.memberships.companyHiddenColumns
        };
      }

      return state;

    case MEMBERSHIPS_GET:
      return {
        ...state,
        areMembershipsLoading: true
      };

    case MEMBERSHIPS_SET:
      return {
        ...state,
        memberships: action.payload.memberships,
        areMembershipsLoading: false
      };

    case MEMBERSHIP_CREATE:
      return {
        ...state,
        memberships: [...state.memberships, action.payload]
      };

    case MEMBERSHIP_UPDATE:
      const memberships = state.memberships.map(membership => {
        if (membership.id === action.payload.membership.id) {
          return { ...membership, ...action.payload.membership };
        }
        return membership;
      });

      return {
        ...state,
        memberships
      };

    case MEMBERSHIPS_SET_TOTAL:
      return {
        ...state,
        total: action.payload.total
      };

    case MEMBERSHIPS_SET_PAGE:
      return {
        ...state,
        page: action.payload.page
      };

    case CALENDAR_FETCH_COMPLETE:
      if (action.payload.memberships?.length > 0) {
        return {
          ...state,
          memberships: mergeMemberships(state.memberships, action.payload.memberships)
        };
      } else {
        return state;
      }

    case inventoryFetchComplete.type:
      if (action.payload.memberships) {
        return {
          ...state,
          memberships: mergeMemberships(state.memberships, action.payload.memberships).filter(
            removeNulls
          )
        };
      } else {
        return state;
      }

    case RECOMMENDATION_FETCH_COMPLETE: {
      if (action.payload?.memberships) {
        return {
          ...state,
          memberships: mergeMemberships(state.memberships, action.payload.memberships)
        };
      } else {
        return state;
      }
    }

    case MEMBERSHIP_TOGGLE_FILTER:
      return {
        ...state,
        membershipFilters: toggleFilter(
          state.membershipFilters,
          action.payload.filterField,
          action.payload.filterValue
        )
      };

    case MEMBERSHIP_SET_SORT_BY:
      return {
        ...state,
        sortBy: toggleSortBy(state.sortBy, action.payload.sortBy)
      };

    case ADMIN_USERS_SELECT_BULK_ACTION:
      return {
        ...state,
        selectedMembershipIds: initialState.selectedMembershipIds
      };

    case ADMIN_USERS_BULK_ACTION_SELECT_MEMBERSHIP_ID: {
      const membershipId = action.payload;
      const selectedMembershipIds = state.selectedMembershipIds;
      return {
        ...state,
        selectedMembershipIds: selectedMembershipIds.includes(membershipId)
          ? selectedMembershipIds.filter(id => id !== membershipId)
          : [...selectedMembershipIds, membershipId]
      };
    }

    case ADMIN_USERS_BULK_ACTION_SELECT_ALL_MEMBERSHIP_IDS: {
      const { nextMembershipStatus } = action.payload;
      return {
        ...state,
        selectedMembershipIds:
          state.selectedMembershipIds.length === 0
            ? getElegibleMembershipIdsForStatusChange(nextMembershipStatus, state.memberships)
            : initialState.selectedMembershipIds
      };
    }

    case MEMBERSHIP_SAVE:
      return {
        ...state,
        isSaving: true
      };

    case MEMBERSHIP_SAVE_COMPLETE:
      return {
        ...state,
        isSaving: false
      };

    case MEMBERSHIP_TOGGLE_HIDDEN_COLUMN: {
      const { isDefault, companyId, column } = action.payload;

      if (isDefault) {
        return {
          ...state,
          defaultHiddenColumns: toggleColumn(column, state.defaultHiddenColumns)
        };
      } else if (companyId) {
        return {
          ...state,
          companyHiddenColumns: {
            ...state.companyHiddenColumns,
            [companyId]: toggleColumn(column, state.companyHiddenColumns?.[companyId])
          }
        };
      }

      return state;
    }

    default:
      return state;
  }
}

const toggleFilter = (filters, field, value) => {
  const filtered = filters.filter(filter => filter.field !== field);
  const parsedValue = parseValue(value);
  return value ? [...filtered, getFilterByFieldType(field, parsedValue)] : filtered;
};

const getFilterByFieldType = (field, value) => {
  switch (field) {
    case "user.fields.auctionAccessId": {
      const isPublic = value;
      return {
        field: field,
        value,
        filterType: "EXISTS",
        boolType: isPublic ? "MUST_NOT" : "MUST"
      };
    }
    default:
      return { field, value, filterType: "TERM", boolType: "MUST" };
  }
};

export const parseValue = value => (isBool(value) ? JSON.parse(value) : value);

// const getNewMemberships = action =>
//   uniqueBy(action.payload.events.map(getMemberships), "id").filter(removeNulls);

// const getMemberships = item => item.membership;

const isBool = value => value && (value === "true" || value === "false");

const mergeMemberships = (memberships, newMemberships) =>
  uniqueBy([...memberships, ...newMemberships], "id");

const removeNulls = item => item;

export const getMembershipForUser = (memberships = [], userId, companyId) =>
  memberships.find(
    membership => membership.userId === userId && membership.companyId === companyId
  );

export const getMembership = (state, membershipId) =>
  state.memberships.find(membership => membership.id === membershipId);

const toggleSortBy = (prevSortBy = "", nextSortBy = "") => {
  const { order, field } = getSortByOrder(prevSortBy);
  return field === nextSortBy ? `${order === "-" ? "+" : "-"}${nextSortBy}` : `+${nextSortBy}`;
};

const getSortByOrder = sortBy => {
  return {
    order: sortBy[0] || "+",
    field: sortBy.slice(1)
  };
};

export function getMembershipStatusBy(bulkActionType) {
  if (bulkActionType === BULK_ACTIONS_TYPE.APPROVE_REGISTRATION_STATUS) {
    return MEMBERSHIP_STATUS_TYPE.APPROVED;
  }
  return MEMBERSHIP_STATUS_TYPE.REJECTED;
}

export function getElegibleMembershipIdsForStatusChange(nextMembershipStatus, memberships) {
  return memberships.reduce((membershipIds, membership) => {
    if (isMembershipElegibleForStatusChange(membership.status, nextMembershipStatus)) {
      membershipIds.push(membership.id);
    }
    return membershipIds;
  }, []);
}

export function isMembershipElegibleForStatusChange(prevMembershipStatus, nextMembershipStatus) {
  return prevMembershipStatus !== nextMembershipStatus;
}

function toggleColumn(column, hiddenColumns = []) {
  return hiddenColumns.includes(column)
    ? hiddenColumns.filter(col => col !== column)
    : [...hiddenColumns, column];
}
