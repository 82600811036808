import { createSlice, createEntityAdapter, createSelector } from "@reduxjs/toolkit";
import { REHYDRATE } from "redux-persist/lib/constants";
import { inventoryFetchComplete } from "actions/sharedActions";

const NAME = "bids";
const bidsAdapter = createEntityAdapter({
  sortComparer: (a, b) => b.createdOn - a.createdOn
});

export const directedBidsSelector = bidsAdapter.getSelectors();

const bids = createSlice({
  name: NAME,
  initialState: bidsAdapter.getInitialState(),
  reducers: {
    cancelPrebidComplete: {
      reducer: (state, action) => {
        const { prebidChanges } = action.payload;
        if (prebidChanges) {
          bidsAdapter.updateOne(state, prebidChanges);
        }
      },
      prepare: prebidChanges => ({ payload: { prebidChanges } })
    }
  },
  extraReducers: builder => {
    builder
      .addCase(REHYDRATE, () => bidsAdapter.getInitialState())
      .addCase(inventoryFetchComplete, (state, action) => {
        const bids = action.payload[NAME];
        if (bids) {
          bidsAdapter.setAll(state, bids);
        }
      });
  }
});

function getBidIds(state) {
  return state.ids;
}

function getBids(state) {
  return state.entities;
}

function getEventId(_, eventId) {
  return eventId;
}

export function makeGetEventBids() {
  return createSelector(getBidIds, getBids, getEventId, (bidIds, bids, eventId) =>
    bidIds.reduce((eventbids, bidId) => {
      const bid = bids[bidId];
      if (bid && bid.eventId === eventId) {
        eventbids.push(bid);
      }
      return eventbids;
    }, [])
  );
}

const { reducer, actions } = bids;
export const { cancelPrebidComplete } = actions;
export default reducer;
