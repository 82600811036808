import React from "react";
import { SvgIcon } from "@mui/material";

interface IFilterIconProps {
  color?: "inherit" | "primary" | "secondary" | "action" | "disabled" | "error";
  viewBox?: string;
}

const FilterIcon = ({
  color = "inherit",
  viewBox = "0, 0, 18, 18"
}: IFilterIconProps): JSX.Element => {
  return (
    <SvgIcon color={color} viewBox={viewBox}>
      <g>
        <path d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5v-2z" />
      </g>
    </SvgIcon>
  );
};

export default FilterIcon;
