import {
  ACTIVITY_RESET_ACTIVITIES,
  NOTIFICATIONS_SET,
  NOTIFICATIONS_RESET,
  ACTIVITY_SET_FILTER_VALUE,
  ACTIVITY_SET_EVENT_FILTER_OPTION,
  ACTIVITY_RESET_SPECIFIC_FILTER
} from "./actionTypes";
import {
  getActivityFilters as getActivityFiltersFromApi,
  getBidActivity as getBidActivityFromApi,
  getLotActivity as getLotActivityApi,
  getNoteActivity as getNoteActivityApi
} from "api/activityRequests";
import moment from "moment";
import { setIsLoading } from "reducers/activity";
import { NOTIFICATIONS_GET } from "./actionTypes";
import { getNotifications as getNotificationsFromApi } from "api/notificationRequests";
import { getDealershipById, getDealershipIds } from "reducers/attendance";
import * as SearchUtil from "utils/SearchUtil";
import isEmpty from "lodash/isEmpty";
import { lotStatusOptions, lotStatusListedOptions } from "reducers/activityFilters";
import keyBy from "lodash/keyBy";
import { getNewNotificationCount } from "reducers/comet";
import { showError } from "./notificationActions";
import { inventoryFetchComplete } from "./sharedActions";

import SortUtil from "views/activity/components/sort/SortUtil";
import ViewType from "views/activity/types/ViewType";

const PAST_3_MONTHS = moment()
  .subtract(3, "month")
  .startOf("day")
  .valueOf();

export function getBidActivityInventory(bidType) {
  return async (dispatch, getState) => {
    dispatch(setIsLoading());
    try {
      const state = getState();
      const {
        activity: { page, perPage }
      } = state;
      const filters = getBidFilters(getFilters(state, "bids"), bidType);
      const rangeStart = page * perPage;
      const rangeEnd = (page + 1) * perPage - 1;
      const activityInventory = await fetchActivityInventory(
        "bids",
        filters,
        `items=${rangeStart}-${rangeEnd}`,
        SortUtil.getSorts(ViewType.bids)
      );
      dispatch(inventoryFetchComplete(activityInventory));
    } catch (error) {
      // dispatch(fetchInventoryComplete(null, error));
    }
  };
}

export function getBidActivityRecentEventsFilterOptions(bidType) {
  return async (dispatch, getState) => {
    const filters = getBidFilters(getFilters(getState()), bidType);

    filters.push(SearchUtil.createRangeFilter("createdOn", PAST_3_MONTHS, "GTE"));

    const events = await getActivityFiltersFromApi(filters, "bid");

    dispatch(setEventFilterOption(events));
  };
}

const consignorRoutes = ["history", "listed", "unlisted"];

export async function fetchActivityInventory(activityRoute, filters, range, sorts) {
  switch (activityRoute) {
    case "bids":
      return await getBidActivityFromApi(filters, range, sorts);
    case "notes":
      return await getNoteActivityApi(filters, range, sorts);
    default:
      return await getLotActivityApi(
        filters,
        range,
        sorts,
        consignorRoutes.includes(activityRoute)
      );
  }
}

export const getActivityInventory = (activityView, chargeTo = "BUYER") => async (
  dispatch,
  getState
) => {
  const state = getState();
  const {
    activity: { page, perPage, sortBy }
  } = state;
  const filters = getFilters(state, activityView, chargeTo);
  dispatch(setIsLoading());
  const rangeStart = page * perPage;
  const rangeEnd = (page + 1) * perPage - 1;
  const activityInventory = await fetchActivityInventory(
    activityView,
    filters,
    `items=${rangeStart}-${rangeEnd}`,
    SortUtil.getSorts(activityView, sortBy)
  );
  dispatch(inventoryFetchComplete(getMappedActivityInventory(activityInventory, activityView)));
};

const getFilters = (state, activityRoute, chargeTo) => {
  let {
    activityFilters,
    attendance,
    user: { user }
  } = state;
  const mappedActivityFilters = mapActivityFilters(
    activityFilters,
    attendance,
    activityRoute,
    chargeTo,
    user.id
  );
  return buildActivityFilters(mappedActivityFilters, activityRoute);
};

export const getActivityEventFilterOption = (activityRoute, chargeTo = "BUYER") => async (
  dispatch,
  getState
) => {
  const filters = getFilters(getState(), activityRoute, chargeTo);

  if (activityRoute !== "listed") {
    filters.push(
      SearchUtil.createRangeFilter(getRangeFilterPropName(activityRoute), PAST_3_MONTHS, "GTE")
    );
  }

  const result = await getActivityFiltersFromApi(filters, activityRoute);

  dispatch(setEventFilterOption(result));
};

export const resetActivities = () => ({
  type: ACTIVITY_RESET_ACTIVITIES
});

export const resetSpecificActivityFilter = prop => ({
  type: ACTIVITY_RESET_SPECIFIC_FILTER,
  payload: {
    prop
  }
});

export function getNotifications(newOnly) {
  return async (dispatch, getState) => {
    const {
      user: { user },
      notifications: { notifications, lastRequestTimestamp },
      comet
    } = getState();

    dispatch({ type: NOTIFICATIONS_GET });

    try {
      const filters = [SearchUtil.createTermFilter("userId", user.id)];

      if (newOnly) {
        filters.push(SearchUtil.createTermFilter("createdOn", lastRequestTimestamp, "GTE"));
      }

      const SHOW_MORE_COUNT = 4;
      const rangeStart = newOnly ? 0 : notifications.length;
      const rangeEnd = newOnly
        ? getNewNotificationCount(comet, user.userStatsId) - 1
        : notifications.length + SHOW_MORE_COUNT;

      const {
        total,
        notifications: newNotifications,
        lots,
        assets,
        companies,
        users
      } = await getNotificationsFromApi(filters, SearchUtil.createRange(rangeStart, rangeEnd), [
        "-createdOn"
      ]);

      const moreAvailable = notifications.length + newNotifications.length < total;

      const lotsMap = keyBy(lots, "id");
      const assetsMap = keyBy(assets, "id");
      const companiesMap = keyBy(companies, "id");
      const usersMap = keyBy(users, "id");

      const mappedNewNotifications = newNotifications.map(notification => {
        const lot = lotsMap[notification.lotId];

        notification.lot = lot;
        notification.asset = assetsMap[lot?.assetId];
        notification.company = companiesMap[notification.companyId];

        if (notification.entityType === "USER") {
          notification.user = usersMap[notification.entityId];
        }

        return notification;
      });

      dispatch(setNotifications(mappedNewNotifications, moreAvailable, newOnly));
    } catch (error) {
      dispatch(showError(error.response?.data || error.message));
    }
  };
}

export function setNotifications(notifications, moreAvailable, newOnly) {
  return {
    type: NOTIFICATIONS_SET,
    payload: {
      notifications,
      moreAvailable,
      newOnly
    }
  };
}

export function resetNotifications() {
  return {
    type: NOTIFICATIONS_RESET
  };
}

export function setActivityFilterValue(prop, value = null) {
  return {
    type: ACTIVITY_SET_FILTER_VALUE,
    payload: {
      prop,
      value
    }
  };
}

export function setEventFilterOption(filterOption) {
  return {
    type: ACTIVITY_SET_EVENT_FILTER_OPTION,
    payload: {
      filterOption
    }
  };
}

export function buildActivityFilters(activityFilters, activityRoute) {
  return Object.keys(activityFilters)
    .filter(key =>
      Array.isArray(activityFilters[key]) ? !isEmpty(activityFilters[key]) : activityFilters[key]
    )
    .map(key => {
      let value = activityFilters[key];
      switch (key) {
        case "dateTo":
          return SearchUtil.createRangeFilter(getRangeFilterPropName(activityRoute), value, "LTE");
        case "dateFrom":
          return SearchUtil.createRangeFilter(getRangeFilterPropName(activityRoute), value, "GTE");
        case "userId":
          return SearchUtil.createTermFilter(getTermFilterPropName(key, activityRoute), value);
        case "selectedDealershipId":
          return SearchUtil.createTermFilter(getTermFilterPropName(key, activityRoute), value);
        case "dealershipIds":
          return SearchUtil.createTermsFilter(getTermFilterPropName(key, activityRoute), value);
        case "paymentStatus":
          return SearchUtil.createTermsFilter("paymentStatus", value);
        case "selectedLotStatus":
          return SearchUtil.createTermsFilter("status", value);
        case "selectedCompanyIds":
          return SearchUtil.createTermsFilter("companyId", value);
        case "selectedEventIds":
          return SearchUtil.createTermsFilter("eventId", value);
        case "saleType":
          return SearchUtil.createTermFilter(value.field, value.value, value.boolType);
        case "dateRange":
          return SearchUtil.createRangeFilter(value.field, value.value, value.equalityType);
        case "searchQuery":
          return SearchUtil.createQueryFilter(`*${value}*`);
        default:
          throw new Error("Invalid filter");
      }
    });
}

function getRangeFilterPropName(activityRoute) {
  switch (activityRoute) {
    case "purchases":
    case "offers":
      return "startTime";
    case "history":
    case "listed":
      return "sale.startTime";
    default:
      return "createdOn";
  }
}

function getTermFilterPropName(key, activityRoute) {
  switch (activityRoute) {
    case "purchases":
    case "offers":
      return key === "userId" ? "winningBid.userId" : "winningBid.dealershipId";
    case "notes":
      return "userId";
    case "history":
    case "listed":
    case "unlisted":
      return "consignorId";
    default:
      return key === "userId" ? "userId" : "dealershipId";
  }
}

export function mapActivityFilters(
  activityFilters,
  attendanceState,
  activityRoute,
  chargeTo,
  userId
) {
  switch (chargeTo) {
    case "SELLER":
      return mapSellerActivityFilters(activityFilters, attendanceState, activityRoute);
    default:
      return mapBuyerActivityFilters(activityFilters, attendanceState, activityRoute, userId);
  }
}

function mapSellerActivityFilters(
  { selectedLotStatus, shouldShowAllDealerships, paymentStatus, ...activityFilters },
  attendanceState,
  activityRoute
) {
  const selectedDealershipId = shouldShowAllDealerships
    ? null
    : getSelectedDealershipId(attendanceState);

  const dealershipIds = shouldShowAllDealerships ? getDealershipIds(attendanceState) : null;

  switch (activityRoute) {
    case "listed":
      return {
        ...activityFilters,
        selectedDealershipId,
        dealershipIds,
        dateRange: {
          field: "sale.startTime",
          value: moment()
            .startOf("day")
            .valueOf(),
          equalityType: "GTE"
        },
        selectedLotStatus:
          selectedLotStatus.length < 1 ? lotStatusListedOptions : selectedLotStatus,
        saleType: {
          field: "sale.saleType",
          value: "STAGED",
          boolType: "MUST_NOT"
        }
      };
    case "unlisted":
      return {
        ...activityFilters,
        selectedDealershipId,
        dealershipIds,
        saleType: {
          field: "sale.saleType",
          value: "STAGED",
          boolType: "MUST"
        },
        selectedLotStatus: selectedLotStatus.length < 1 ? lotStatusOptions : selectedLotStatus
      };
    default: {
      const filterMap = {
        ...activityFilters,
        selectedDealershipId,
        dealershipIds,
        selectedLotStatus: selectedLotStatus.length < 1 ? lotStatusOptions : selectedLotStatus,
        saleType: {
          field: "sale.saleType",
          value: "STAGED",
          boolType: "MUST_NOT"
        }
      };

      if (filterMap.dateFrom == null) {
        filterMap.dateFrom = moment()
          .startOf("day")
          .subtract(3, "month")
          .valueOf();
      }

      if (filterMap.dateTo == null) {
        filterMap.dateTo = moment()
          .endOf("day")
          .subtract(1, "day")
          .valueOf();
      }

      return filterMap;
    }
  }
}

function mapBuyerActivityFilters(
  { shouldShowAllDealerships, paymentStatus, ...activityFilters },
  attendanceState,
  activityRoute,
  userId
) {
  let selectedDealershipId = getSelectedDealershipId(attendanceState);

  const shouldShowUserActivity = shouldShowAllDealerships || !selectedDealershipId;

  selectedDealershipId = shouldShowUserActivity ? null : selectedDealershipId;

  const mappedActivityFilters = {
    ...activityFilters,
    userId: shouldShowUserActivity ? userId : null,
    selectedDealershipId
  };
  if (activityRoute === "purchases") {
    mappedActivityFilters.selectedLotStatus = ["sold"];
    if (paymentStatus?.length > 0) {
      mappedActivityFilters.paymentStatus = paymentStatus;
    }
  }
  if (activityRoute === "offers") {
    mappedActivityFilters.selectedLotStatus = ["ifsold"];
  }

  if (activityRoute === "notes" || activityRoute === "bids") {
    mappedActivityFilters.searchQuery = null;
  }
  return mappedActivityFilters;
}

export function getSelectedDealershipId(attendanceState) {
  const selectedDealership = getDealershipById(
    attendanceState,
    attendanceState.selectedDealershipId
  );
  return selectedDealership ? selectedDealership.id : null;
}

export function getBidFilters(filters, bidType) {
  filters.push(SearchUtil.createTermsFilter("bidType", getBidTypes(bidType)));

  return filters;
}

function getBidTypes(bidType) {
  switch (bidType) {
    case "preBids":
      return ["PRE"];
    case "maxBids":
      return ["MAX"];
    case "buyItNow":
      return ["BUY_NOW"];
    case "offers":
      return ["OFFER"];
    case "quick":
      return ["QUICK"];
    default:
      return ["PRE", "MAX", "BUY_NOW", "OFFER", "QUICK"];
  }
}

export function getMappedActivityInventory(activityInventory, activityRoute) {
  if (activityRoute === "history" && activityInventory?.events != null) {
    return {
      ...activityInventory,
      events: activityInventory.events.sort((a, b) => b.startTime - a.startTime)
    };
  }

  return activityInventory;
}
