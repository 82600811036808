import useCanBid from "./useCanBid";
import useSale from "./useSale";
import useCustomBid from "./useCustomBid";
import useLot from "./useLot";
import { formatNumber } from "utils/NumberUtil";

function customBidSelector(bid) {
  return bid?.amount || null;
}

function useMakeOffer(lotId, saleId, saleUserId) {
  const { isActive, error } = useCanBid(lotId, saleId, saleUserId, "OFFER");
  const offerBidAmount = useCustomBid(lotId, saleId, saleUserId, "OFFER", customBidSelector);
  const isBuyNowAllowed = useSale(saleId, sale => sale.allowBuyItNow, false);

  const { askAmount, buyNowAmount } = useLot(lotId, lot => ({
    askAmount: lot?.askAmount,
    buyNowAmount: lot?.buyNowAmount
  }));

  const { canBid, error: errorMessage } = getCanBid(error, offerBidAmount);

  return {
    isActive: isActive && isBuyNowAllowed && Boolean(buyNowAmount),
    canBid,
    error: errorMessage,
    offerBidAmount,
    askAmount
  };
}

function getCanBid(error, offerBidAmount) {
  if (offerBidAmount) {
    error = `Offer made for $${formatNumber(offerBidAmount)}`;
  }

  return {
    canBid: !Boolean(error),
    error
  };
}

export default useMakeOffer;
