import PopperMenu from "components/PopperMenu";
import { withStyles } from "tss-react/mui";
import Notifications from "components/notification/Notifications";

const styles = theme => ({
  popper: {
    zIndex: theme.zIndex.modal,
    width: 290,
    [theme.breakpoints.up("md")]: {
      width: 500
    }
  },
  notificationRoot: {
    maxHeight: 540,
    overflow: "auto"
  }
});

const NotificationDropdown = ({ classes, isOpen = false, elementRef = {}, handleClose }) => (
  <PopperMenu
    isOpen={isOpen}
    anchorEl={elementRef.current}
    handleClose={handleClose}
    classes={{ popper: classes.popper }}
  >
    <Notifications
      classes={{
        root: classes.notificationRoot
      }}
      displayHeader
    />
  </PopperMenu>
);

export default withStyles(NotificationDropdown, styles);
