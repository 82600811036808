import React from "react";
import { withStyles } from "tss-react/mui";
import SadIcon from "@mui/icons-material/SentimentDissatisfied";
import Typography from "@mui/material/Typography";
import NavFabs, { goBack, refresh } from "./NavFabs";
import PropTypes from "prop-types";

const styles = theme => ({
  root: {
    width: "100%",
    height: "100%",
    [theme.breakpoints.down("md")]: {
      height: "calc(100vh - 120px)"
    },
    display: "flex",
    flexDirection: "column"
  },
  main: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  iconWrapper: {
    textAlign: "center"
  },
  icon: {
    height: theme.spacing(15),
    width: theme.spacing(15),
    color: theme.typography.h4.color
  },
  text: {
    color: theme.typography.h4.color
  },
  link: {
    color: theme.palette.primary.main,
    cursor: "pointer"
  }
});

const ErrorPage = ({ classes, clearError }) => (
  <div className={classes.root}>
    <NavFabs clearError={clearError} />
    <div className={classes.main}>
      <div>
        <div className={classes.iconWrapper}>
          <SadIcon className={classes.icon} />
        </div>
        <Typography variant="h5" className={classes.text} align="center" gutterBottom>
          Something went wrong.
        </Typography>
        <Typography className={classes.text} align="center">
          This error has been reported and the team is working to fix the issue.
        </Typography>
        <Typography className={classes.text} align="center">
          Please go{" "}
          <span
            onClick={() => {
              clearError();
              goBack();
            }}
            className={classes.link}
          >
            back
          </span>{" "}
          to the previous page or{" "}
          <span onClick={refresh} className={classes.link}>
            refresh
          </span>
          .
        </Typography>
      </div>
    </div>
  </div>
);

ErrorPage.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(ErrorPage, styles);
