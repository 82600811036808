import { useState, useEffect, useCallback } from "react";
import { useComet } from "./sale";
import useLocalStorage from "hooks/useLocalStorage";
import { get } from "api/api";
import { useSelector } from "utils/StoreUtil";

function useApplicationMessage() {
  const [applicationMessageId, setApplicationMessageId] = useState(null);
  const companyId = useSelector(state => state.user.user.companyId);

  useEffect(() => {
    async function getApplicationMessageId() {
      const {
        data: { id }
      } = await get(`applicationMessage/rubix/${companyId}`);

      setApplicationMessageId(id);
    }

    if (companyId != null) {
      getApplicationMessageId();
    }
  }, [companyId]);

  const { message, level, updatedOn } = useComet(
    "applicationMessage",
    applicationMessageId,
    msg => msg,
    {}
  );

  const [hasDismissed, setDismissed] = useLocalStorage(
    `appMessage_${updatedOn}`,
    updatedOn == null ? true : false
  );

  const dismiss = useCallback(() => {
    setDismissed(true);
  }, [setDismissed]);

  return { message, level, hasDismissed, dismiss };
}

export { useApplicationMessage as default };
