import { createSlice, createEntityAdapter } from "@reduxjs/toolkit";
import { REHYDRATE } from "redux-persist/lib/constants";

import { FACILITIES_FETCH, FACILITIES_FETCH_COMPLETE } from "actions/actionTypes";
import { inventoryFetchComplete } from "actions/sharedActions";

const NAME = "facilities";

const facilitiesAdapter = createEntityAdapter();

const initialState = {
  isLoading: true
};

const facilities = createSlice({
  name: NAME,
  initialState: facilitiesAdapter.getInitialState(initialState),
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(REHYDRATE, () => facilitiesAdapter.getInitialState())
      .addCase(FACILITIES_FETCH, state => {
        state.isLoading = true;
      })
      .addCase(FACILITIES_FETCH_COMPLETE, (state, action) => {
        state.isLoading = false;
        facilitiesAdapter.setAll(state, action.payload || []);
      })
      .addCase(inventoryFetchComplete, (state, action) => {
        const facilities = action.payload[NAME];
        if (facilities) {
          facilitiesAdapter.setAll(state, facilities);
        }
      });
  }
});

const { reducer } = facilities;

export const facilitiesSelector = facilitiesAdapter.getSelectors(state => state.facilities);

export default reducer;
