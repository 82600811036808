import React from "react";
import {
  Button,
  DialogContent,
  DialogActions,
  Typography,
  CircularProgress,
  DialogTitle
} from "@mui/material";
import { useSelector, useDispatch } from "utils/StoreUtil";
import { showNotification, showError } from "actions/notificationActions";

import { removeSale, salesSelector } from "reducers/sales";
import { eventsSelector, removeEvent } from "reducers/events";
import useEventFlush from "hooks/flush/useEventFlush";
import useSaleFlush from "hooks/flush/useSaleFlush";
import DialogTitleContent from "../DialogTitleContent";
import useCompanyUserFlush from "hooks/flush/useCompanyUserFlush";
import { trackEvent } from "hooks/useTracking";

const getConfirmationText = (entityType, name) => {
  return `Are you sure you want to delete ${entityType} ${name} and everything related? Operation cannot be undone, if you wish to continue press DELETE`;
};

const EventFlushConfirmation = ({ handleClose, eventId }) => {
  const dispatch = useDispatch();

  const event = useSelector(state => eventsSelector.selectById(state.events, eventId));

  const { isFlushing, canFlush, error, flush } = useEventFlush(eventId);

  const handleDeleteClick = async () => {
    try {
      await flush();

      dispatch(removeEvent(eventId));
      dispatch(showNotification("Event deleted"));

      handleClose();
    } catch (error) {
      dispatch(showError(error.response?.data ?? error.message ?? "Unable to delete event"));
    }
  };

  return (
    <>
      <DialogTitle>
        <DialogTitleContent title="Delete Event Confirmation" onClose={handleClose} />
      </DialogTitle>
      <DialogContent>
        {canFlush && <Typography>{getConfirmationText("event", event?.name ?? "")}</Typography>}

        {!canFlush && (
          <Typography>
            Unable to delete event {event?.name ?? ""} due to error: {error}
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          disabled={!canFlush || isFlushing}
          onClick={handleDeleteClick}
          variant="contained"
          color="primary"
        >
          {isFlushing ? <CircularProgress size={20} /> : "Delete"}
        </Button>
      </DialogActions>
    </>
  );
};

const SaleFlushConfirmation = ({ handleClose, saleId }) => {
  const dispatch = useDispatch();

  const sale = useSelector(state => salesSelector.selectById(state.sales, saleId));

  const { isFlushing, canFlush, error, flush } = useSaleFlush(saleId);

  const handleDeleteClick = async () => {
    try {
      await flush();

      dispatch(removeSale(saleId));
      dispatch(showNotification("Sale deleted"));

      handleClose();
    } catch (error) {
      dispatch(showError(error.response?.data ?? error.message ?? "Unable to delete sale"));
    }
  };

  return (
    <>
      <DialogTitle>Delete Sale Confirmation</DialogTitle>
      <DialogContent>
        {canFlush && <Typography>{getConfirmationText("sale", sale?.name ?? "")}</Typography>}

        {!canFlush && (
          <Typography>
            Unable to delete sale {sale?.name ?? ""} due to error: {error}
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          disabled={!canFlush || isFlushing}
          onClick={handleDeleteClick}
          variant="contained"
          color="primary"
        >
          {isFlushing ? <CircularProgress size={20} /> : "Delete"}
        </Button>
      </DialogActions>
    </>
  );
};

const UserFlushConfirmation = ({ handleClose }) => {
  const dispatch = useDispatch();

  const userId = useSelector(state => state.user?.user?.id);

  const { isFlushing, flush } = useCompanyUserFlush();

  const handleDeleteClick = async () => {
    try {
      await flush();

      trackEvent({
        category: "Account Management",
        action: "delete account",
        label: `User:${userId}`
      });

      dispatch(showNotification("Your account has been deleted"));

      handleClose();
    } catch (error) {
      dispatch(showError(error.response?.data ?? error.message ?? "Unable to delete account"));
    }
  };
  return (
    <>
      <DialogTitle>
        <DialogTitleContent title="Delete User Account Confirmation" onClose={handleClose} />
      </DialogTitle>
      <DialogContent>
        Are you sure you want to delete your account? This action is irreversible. Click delete to
        continue.
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="primary">
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={isFlushing}
          onClick={handleDeleteClick}
        >
          {isFlushing ? <CircularProgress size={20} /> : "Delete"}
        </Button>
      </DialogActions>
    </>
  );
};

const DialogContentFlushConfirmation = ({ handleClose, flushType = "EVENT", eventId, saleId }) => {
  switch (flushType) {
    case "EVENT":
      return <EventFlushConfirmation eventId={eventId} handleClose={handleClose} />;

    case "SALE":
      return <SaleFlushConfirmation saleId={saleId} handleClose={handleClose} />;

    case "USER":
      return <UserFlushConfirmation handleClose={handleClose} />;

    default:
      handleClose();
      throw new Error(`Invalid flushType: ${flushType}`);
  }
};

export default DialogContentFlushConfirmation;
