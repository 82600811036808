enum ViewType {
  purchases = "purchases",
  offers = "offers",
  bids = "bids",
  notes = "notes",
  listed = "listed",
  history = "history",
  unlisted = "unlisted"
}

export default ViewType;
