import { useMemo } from "react";

import { useSelector } from "utils/StoreUtil";
import useMarketVisibility from "hooks/useMarketVisibility";
import { MARKET_SEGMENTS } from "utils/constants";
import useHtmlScriptElement from "hooks/app/useHtmlScriptElement";

const getVersion = () => {
  const day = new Date().getDate();
  const month = new Date().getMonth();
  const year = new Date().getFullYear();

  return `version=${month}${day}${year}`;
};

const useStockWaveLibrary = () => {
  const hostConfig = useSelector(state => state.config.useStockwave);
  const stockWaveIntegrationLibraryUrlConfig = useSelector(
    state => state.config.stockWaveIntegrationLibraryUrl
  );
  const isAutomotiveMarketSegment = useMarketVisibility(MARKET_SEGMENTS.AUTOMOTIVE);

  const onLoad = useMemo(() => {
    return () => {
      //@ts-ignore
      window.stockwave?.initialize?.();
      console.log("stockwave initialized");
    };
  }, []);

  let url = "";

  if (
    hostConfig &&
    isAutomotiveMarketSegment &&
    window.location != window.parent.location /* eslint-disable-line */
  ) {
    url = `${stockWaveIntegrationLibraryUrlConfig}?${getVersion()}`;
  }

  const didLoad = useHtmlScriptElement(url, onLoad);

  return didLoad;
};

export default useStockWaveLibrary;
