import {
  REPORTS_EVENTS_FETCH,
  REPORTS_EVENTS_FETCH_COMPLETE,
  REPORTS_SET_INVENTORY_STATUS,
  REPORTS_SET_PAGE,
  REPORTS_SET_PER_PAGE,
  REPORTS_SET_TO_DATE,
  REPORTS_SET_FROM_DATE,
  REPORTS_SET_COMPANIES,
  REPORTS_CONCAT_EVENTS,
  REPORTS_REFRESH_DATES,
  REPORTS_SET_SORT,
  REPORTS_SET_EVENTS_SORT,
  CONFIG_SET
} from "actions/actionTypes";
import { createReducer } from "@reduxjs/toolkit";
import moment from "moment";
import { getConfigValue } from "./config";
import { createRangeFilter, createTermFilter, createTermsFilter } from "utils/SearchUtil";
import { getDefaultLotSort } from "utils/MarketUtil";
import { MARKET_SEGMENTS } from "utils/constants";

const initialState = {
  events: [],
  isEventsLoading: true,
  totalEvents: 0,
  dateTo: getEndDate(),
  dateFrom: getStartDate(),
  page: 0,
  perPage: 25,
  sortBy: getDefaultLotSort(MARKET_SEGMENTS.AUTOMOTIVE),
  eventSortBy: "-startTime",
  selectedInventoryStatus: [],
  selectedCompanyIds: []
};

const saleReport = createReducer(initialState, builder => {
  builder
    .addCase(REPORTS_EVENTS_FETCH_COMPLETE, (state, action) => {
      state.events = action.payload.events;
      state.totalEvents = action.payload.total;
      state.isEventsLoading = false;
    })
    .addCase(REPORTS_EVENTS_FETCH, state => {
      state.isEventsLoading = true;
    })
    .addCase(REPORTS_SET_INVENTORY_STATUS, (state, action) => {
      const { label, value } = action.payload;
      if (value) {
        state.selectedInventoryStatus.push(label);
      } else {
        state.selectedInventoryStatus = state.selectedInventoryStatus.filter(val => val !== label);
      }
    })
    .addCase(REPORTS_SET_COMPANIES, (state, action) => {
      state.selectedCompanyIds = action.payload;
    })
    .addCase(REPORTS_SET_PAGE, (state, action) => {
      state.page = action.payload;
    })
    .addCase(REPORTS_SET_PER_PAGE, (state, action) => {
      state.page = initialState.page;
      state.perPage = action.payload;
    })
    .addCase(REPORTS_SET_TO_DATE, (state, action) => {
      state.dateTo = action.payload;
    })
    .addCase(REPORTS_SET_FROM_DATE, (state, action) => {
      state.dateFrom = action.payload;
    })
    .addCase(REPORTS_CONCAT_EVENTS, (state, action) => {
      state.events = [...state.events, ...action.payload.events];
      state.isEventsLoading = false;
    })
    .addCase(REPORTS_REFRESH_DATES, state => {
      state.dateTo = getEndDate();
    })
    .addCase(REPORTS_SET_SORT, (state, action) => {
      state.sortBy = toggleSort(state.sortBy, action.payload);
      state.page = initialState.page;
    })
    .addCase(REPORTS_SET_EVENTS_SORT, (state, action) => {
      state.eventSortBy = toggleSort(state.eventSortBy, action.payload);
      state.page = initialState.page;
    })
    .addCase(CONFIG_SET, (state, action) => {
      const marketSegment = getConfigValue(action.payload.config, "marketSegment");
      state.sortBy = getDefaultLotSort(marketSegment);
    });
});

export default saleReport;

export function toggleSort(currentSort, sort) {
  const defaultOrder = "+";

  if (currentSort.slice(1) === sort) {
    const oppositeOrder = currentSort[0] === "+" ? "-" : "+";
    return oppositeOrder + sort;
  } else {
    return defaultOrder + sort;
  }
}

function getStartDate() {
  return moment()
    .subtract(15, "days")
    .valueOf();
}

function getEndDate() {
  return moment().valueOf();
}

export function getActivityPurchaseReportFilters(selectedReportOptions, userId) {
  const { dealershipId, eventId, startTime, endTime, reportType } = selectedReportOptions;

  const filters = [getLotStatusFilter(reportType), getPurchasedByIdFilter(dealershipId, userId)];

  if (eventId) {
    filters.push(createTermsFilter("event.id", [eventId]));
  } else {
    filters.push(createRangeFilter("event.startTime", startTime, "GTE"));
    filters.push(createRangeFilter("event.endTime", endTime, "LTE"));
  }

  return filters;
}

function getLotStatusFilter(reportType) {
  return createTermsFilter("lot.status", [reportType === "OFFERS" ? "IFSOLD" : "SOLD"]);
}

function getPurchasedByIdFilter(dealershipId, userId) {
  const field = dealershipId ? "lot.winningBid.dealershipId" : "lot.winningBid.userId";
  const value = dealershipId || userId;
  return createTermFilter(field, value);
}
