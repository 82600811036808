import {
  EDIT_INVENTORY_SAVE,
  EDIT_INVENTORY_SAVE_COMPLETE,
  EDIT_INVENTORY_SELECT_BULK_ACTION,
  EDIT_INVENTORY_SELECT_BULK_ACTION_LOT_STATUS,
  EDIT_INVENTORY_BULK_ACTION_SELECT_IDS,
  EDIT_INVENTORY_BULK_ACTION_SELECT_ID,
  EDIT_INVENTORY_RESET,
  ADMIN_EVENT_MANAGEMENT_SET_SORT_BY
} from "actions/actionTypes";
import { createReducer } from "@reduxjs/toolkit";
import { inventoryFetch } from "actions/sharedActions";

export const BULK_ACTION_UPDATE_LOTS_STATUS = "update lots status";

export const lotStatus = ["OUT"];
export const LOT_STATUS_WITH_SALE_ACTIONS = ["OUT", "WITHDRAWN", "SOLD"];

const initialState = {
  isSaving: false,
  selectedBulkAction: null,
  selectedBulkActionLotStatus: lotStatus[0],
  selectedBulkActionIds: []
};

const editInventory = createReducer(initialState, builder => {
  builder
    .addCase(EDIT_INVENTORY_SAVE, state => {
      state.isSaving = true;
    })
    .addCase(EDIT_INVENTORY_SAVE_COMPLETE, state => {
      state.isSaving = false;
      state.selectedBulkActionIds = initialState.selectedBulkActionIds;
    })
    .addCase(EDIT_INVENTORY_SELECT_BULK_ACTION, (state, action) => {
      if (action.payload == null || state.selectedBulkAction === action.payload) {
        state.selectedBulkAction = initialState.selectedBulkAction;
      } else {
        state.selectedBulkAction = action.payload;
      }
      state.selectedBulkActionIds = initialState.selectedBulkActionIds;
    })
    .addCase(EDIT_INVENTORY_SELECT_BULK_ACTION_LOT_STATUS, (state, action) => {
      if (state.selectedBulkActionLotStatus !== action.payload) {
        state.selectedBulkActionLotStatus = action.payload;
      }
    })
    .addCase(EDIT_INVENTORY_BULK_ACTION_SELECT_IDS, (state, action) => {
      state.selectedBulkActionIds = action.payload.ids || initialState.selectedBulkActionIds;
    })
    .addCase(EDIT_INVENTORY_BULK_ACTION_SELECT_ID, (state, action) => {
      const { id } = action.payload;
      const stateSelectedIds = state.selectedBulkActionIds;
      state.selectedBulkActionIds = stateSelectedIds.includes(id)
        ? filterSelectedIds(stateSelectedIds, id)
        : [...stateSelectedIds, id];
    })
    .addCase(EDIT_INVENTORY_RESET, () => initialState)
    .addCase(ADMIN_EVENT_MANAGEMENT_SET_SORT_BY, () => initialState)
    .addCase(inventoryFetch, state => {
      state.selectedBulkActionIds = initialState.selectedBulkActionIds;
    });
});

function filterSelectedIds(selectedIds, id) {
  return selectedIds.filter(selectedId => selectedId !== id);
}

export function getElegibleLotIdsForStatusChange(newLotStatus, lotsObj) {
  if (!lotsObj) return;
  const ids = [];

  for (let lotId in lotsObj) {
    let lot = lotsObj[lotId];
    if (isLotElegibleForStatusChange(newLotStatus, lot)) {
      ids.push(lot.id);
    }
  }

  return ids;
}

export function isLotElegibleForStatusChange(newLotStatus, lot) {
  switch (newLotStatus) {
    case "OUT":
      return canBeOutted(lot);
    default:
      return false;
  }
}

function canBeOutted(lot) {
  const lotStatus = lot?.status;
  return lotStatus === "PREBID" || lotStatus === "UNSOLD";
}

export default editInventory;
