import useComet from "./useComet";

const DEFAULT_VALUE = {};

function useLot(lotId, selector, defaultValue = DEFAULT_VALUE) {
  const lot = useComet("lot", lotId, selector, defaultValue);

  return lot;
}

export { useLot as default };
