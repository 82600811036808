import { createEntityAdapter, createSelector, createSlice } from "@reduxjs/toolkit";
import { inventoryFetch, inventoryFetchComplete } from "actions/sharedActions";
import Lot from "types/Lot";

const lotsAdapter = createEntityAdapter<Lot>();

export const lotsSelector = lotsAdapter.getSelectors();

export const initialState = lotsAdapter.getInitialState({
  total: 0,
  isLoading: false
});

const lotsReducer = createSlice({
  name: "lots",
  initialState,
  reducers: {
    reset: (state, action) => {
      return initialState;
    },
    remove: (state, action) => {
      lotsAdapter.removeOne(state, action.payload);
    },
    setTotal: (state, action) => {
      state.total = action.payload;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    updateProp: (state, action) => {
      const { lotId, prop, value } = action.payload;
      lotsAdapter.updateOne(state, {
        id: lotId,
        changes: {
          [prop]: value
        }
      });
    },
    updateEntity: (state, action) => {
      const lot = action.payload;
      lotsAdapter.updateOne(state, {
        id: lot.id,
        changes: lot
      });
    }
  },
  extraReducers: builder => {
    builder
      .addCase(inventoryFetch, (state, action) => {
        state.isLoading = true;
      })
      .addCase(inventoryFetchComplete, (state, action) => {
        state.isLoading = false;
        if (action.payload.lots) {
          lotsAdapter.setAll(state, action.payload.lots);
        }
      });
  }
});

function getLotIds(state: any) {
  return lotsSelector.selectIds(state);
}

function getLots(state: any) {
  return lotsSelector.selectEntities(state);
}

function getEventId(_: any, eventId: number) {
  return eventId;
}

export function makeGetEventLotIds() {
  return createSelector(getLotIds, getLots, getEventId, (lotIds = [], lots = {}, eventId) => {
    const eventLotIds = lotIds.reduce((acc: number[], lotId: number) => {
      const lot = lots[lotId];
      if (lot && lot.eventId === eventId) {
        acc.push(lotId);
      }
      return acc;
    }, []);

    return eventLotIds;
  });
}

export function getSoldAmount(lot: Lot) {
  return lot?.soldAmount;
}

const { actions, reducer } = lotsReducer;

export const { updateProp, setTotal, setLoading, remove, reset, updateEntity } = actions;
export default reducer;
