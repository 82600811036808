import React, { FC } from "react";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(theme => ({
  root: {
    height: 40,
    width: 40,
    marginRight: theme.spacing(1),
    overflow: "hidden",
    borderRadius: "50%"
  },
  img: {
    width: "100%",
    height: "100%",
    objectFit: "contain"
  }
}));

const CompanyLogo: FC<{ mediaUrl?: string }> = ({ mediaUrl }) => {
  const { classes } = useStyles();
  return (
    <div className={classes.root}>
      <img src={mediaUrl} alt="company" className={classes.img} />
    </div>
  );
};

export default CompanyLogo;
