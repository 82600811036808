import { Action, ThunkAction } from "@reduxjs/toolkit";

import Asset from "types/Asset";
import Lot from "types/Lot";
import Event from "types/Event";
import Sale from "types/Sale";
import { UISchemaField } from "types/UISchema";
import { RootState } from "utils/StoreUtil";

import { chunkRequestsSync } from "api/api";
import { searchLite } from "api/inventoryRequests";
import { getMediaById } from "api/mediaRequests";
import { fetchUISchema } from "./schemaActions";
import { amsSelector } from "reducers/ams";
import { getCompanyById } from "reducers/companies";
import { getLanguageMap } from "reducers/language";
import { getSalesByEventId, salesSelector } from "reducers/sales";
import { getUISchemaSelectId, uiSchemasSelector } from "reducers/uiSchemas";

import { getDefaultLotSort } from "utils/MarketUtil";
import { createRangeFilter, createTermFilter } from "utils/SearchUtil";
import { toMap } from "utils/CollectionUtil";
import { Csv, getShouldFormatDate } from "./csv/utils";
import { INTEGRATION_TYPE, MARKET_SEGMENTS, UI_SCHEMA_TYPE } from "utils/constants";
import { getFormattedRow, getHeaders, getValue } from "utils/UISchemaUtil";
import { getCompanyAssetSchemas, getFields, handleAnyConversions } from "utils/AssetUtil";
import { handleLabelConversions } from "utils/LanguageUtil";
import { formatDate, formatDateTime } from "utils/DateUtil";
import moment from "moment";
import Company from "types/Company";
import Note from "types/Note";
import { eventsSelector } from "reducers/events";
import { formatNumber, isStringNumeric } from "utils/NumberUtil";

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export type InventoryItem = {
  lot: Lot;
  asset: Asset;
  note?: Note;
  sale?: Sale;
  event?: Event;
};

type Inventory = {
  lots: Lot[];
  assets: Record<number, Asset>;
  notes?: Record<number, Note>;
};

export type InventoryExport = {
  title?: string;
  logoUrl?: string;
  inventory: Inventory;
};

export type PDFLayout = "summary" | "fullDetails";

export function getWatchlistExport(format: string, layout: PDFLayout): AppThunk<Promise<any>> {
  return async (dispatch, getState) => {
    const state = getState();
    const {
      companies: { companies },
      config: { marketSegment },
      user: { user }
    } = state;

    const filters = [
      createRangeFilter(
        "sale.endTime",
        moment()
          .startOf("day")
          .valueOf(),
        "GTE"
      ),
      createTermFilter("watchlist", true),
      createTermFilter("lot.status", "withdrawn", "MUST_NOT")
    ];
    const sorts = [getDefaultLotSort(marketSegment)];

    const inventory = await getInventory(filters, sorts);

    const title = "Watchlist Export";

    if (format !== "csv" && layout === "fullDetails") {
      return await createFullDetailsProps(getState(), inventory, title, getLanguageMap(state));
    } else {
      const sales = salesSelector.selectAll(getState().sales);
      const content = await createCsv(getState(), inventory, sales);
      if (format === "csv") {
        return content;
      } else {
        const company = getCompanyById(companies, user?.companyId);
        return await createSummaryProps(content, company?.mediaId, title);
      }
    }
  };
}

export function getEventRunlistExport(
  event: Partial<Event>,
  format: string,
  layout: PDFLayout
): AppThunk<Promise<any>> {
  return async (dispatch, getState) => {
    const state = getState();
    const {
      companies: { companies },
      config: { marketSegment },
      user: {
        user: { locale }
      }
    } = state;

    const { id: eventId, companyId, startTime } = event;

    const filters = [
      createTermFilter("sale.eventId", eventId),
      createTermFilter("status", "withdrawn", "MUST_NOT")
    ];

    const sorts = ["+saleId", getDefaultLotSort(marketSegment)];

    const inventory = await getInventory(filters, sorts);

    const title = event?.name;

    if (format !== "csv" && layout === "fullDetails") {
      return await createFullDetailsProps(state, inventory, title, getLanguageMap(state));
    } else {
      const sales: Sale[] = getSalesByEventId(state.sales, eventId!); // only works because we already fetched sales for event filter options
      const content = await createRunlistCsv(dispatch, state, inventory, event, sales);
      if (format === "csv") {
        return content;
      } else {
        const company = getCompanyById(companies, companyId);

        return await createSummaryProps(
          content,
          company?.mediaId,
          title,
          formatDate(startTime, locale),
          getTotalLabel(state, inventory, companyId)
        );
      }
    }
  };
}

export function getSaleRunlistExport(
  sale: Partial<Sale>,
  format: string,
  layout: PDFLayout
): AppThunk<Promise<any>> {
  return async (dispatch, getState) => {
    const state = getState();
    const {
      companies: { companies },
      config: { marketSegment },
      user: {
        user: { locale }
      }
    } = state;

    const { id: saleId, companyId, startTime } = sale;

    const filters = [
      createTermFilter("sale.id", saleId),
      createTermFilter("status", "withdrawn", "MUST_NOT")
    ];

    const sorts = [getDefaultLotSort(marketSegment)];

    const inventory = await getInventory(filters, sorts);

    const title = sale?.name;

    if (format !== "csv" && layout === "fullDetails") {
      return await createFullDetailsProps(getState(), inventory, title, getLanguageMap(state));
    } else {
      const event = eventsSelector.selectById(getState().events, sale.eventId!);
      const content = await createRunlistCsv(dispatch, getState(), inventory, event, [sale]);
      if (format === "csv") {
        return content;
      } else {
        const company = getCompanyById(companies, companyId);

        return await createSummaryProps(
          content,
          company?.mediaId,
          title,
          formatDate(startTime, locale),
          getTotalLabel(state, inventory, companyId)
        );
      }
    }
  };
}

async function createCsv(state: RootState, inventory: Inventory, sales: Partial<Sale>[]) {
  const {
    // @ts-ignore
    assets: { schemas },
    companies: { companies },
    user: { user }
  } = state;

  const languageMap = getLanguageMap(state);

  const csv = new Csv(languageMap);

  const hostCompanyId = user.companyId;
  const hostCompanyAms = amsSelector.selectById(state.ams, hostCompanyId);
  const hasFLAMSIntegration = hostCompanyAms?.integrationType === INTEGRATION_TYPE.FLAMS;

  const saleMap = sales.reduce(toMap("id"), {});
  const companyMap = companies.reduce(toMap("id"), {});

  if (hasFLAMSIntegration) {
    createCsvFromAutomotiveFields(csv, inventory, saleMap, companyMap, user?.locale);
  } else {
    const saleAssetSchemaIds = getSaleAssetSchemaIds(sales);
    const assetSchemas = getAssetSchemas(saleAssetSchemaIds, schemas, state, hostCompanyId);
    createCsvFromAssetSchemas(csv, inventory, saleMap, companyMap, assetSchemas, user?.locale);
  }

  return csv._get();
}

async function createRunlistCsv(
  dispatch: any,
  state: RootState,
  inventory: Inventory,
  event: Partial<Event> = {},
  sales: Partial<Sale>[] = []
): Promise<any> {
  const {
    // @ts-ignore
    assets: { schemas },
    companies: { companies },
    user: { user }
  } = state;

  const { companyId } = event;

  const languageMap = getLanguageMap(state);

  let uiSchemaReport = uiSchemasSelector.selectById(
    state,
    getUISchemaSelectId(companyId, UI_SCHEMA_TYPE.REPORT_INVENTORY)
  );

  if (!uiSchemaReport && companyId) {
    const uiSchemas = await dispatch(fetchUISchema(companyId, UI_SCHEMA_TYPE.REPORT_INVENTORY));
    // @ts-ignore
    uiSchemaReport = uiSchemas?.[0];
  }

  const csv = new Csv(languageMap);

  if (uiSchemaReport?.schemaFields.length > 0) {
    createCsvFromReportSchemaConfig(uiSchemaReport, csv, inventory, event, sales);
  } else {
    const hostCompanyId = user.companyId;
    const hostCompanyAms = amsSelector.selectById(state.ams, hostCompanyId);
    const hasFLAMSIntegration = hostCompanyAms?.integrationType === INTEGRATION_TYPE.FLAMS;

    const saleMap = sales.reduce(toMap("id"), {});
    const companyMap = companies.reduce(toMap("id"), {});

    if (hasFLAMSIntegration) {
      createCsvFromAutomotiveFields(csv, inventory, saleMap, companyMap, user?.locale);
    } else {
      const saleAssetSchemaIds = getSaleAssetSchemaIds(sales);
      const assetSchemas = getAssetSchemas(saleAssetSchemaIds, schemas, state, hostCompanyId);
      createCsvFromAssetSchemas(csv, inventory, saleMap, companyMap, assetSchemas, user?.locale);
    }
  }

  return csv._get();
}

function createCsvFromReportSchemaConfig(
  uiSchemaReport: any,
  csv: any,
  inventory: Inventory,
  event: Partial<Event>,
  sales: Partial<Sale>[]
) {
  let headers = getHeaders(uiSchemaReport);
  const rows: string[][] = [];

  inventory.lots.forEach(lot => {
    const inventoryItem: InventoryItem = {
      lot,
      asset: inventory.assets[lot.assetId],
      sale: sales.find(sale => sale.id === lot.saleId) as Sale,
      event: event as Event
    };
    rows.push(getFormattedRow(uiSchemaReport, inventoryItem));
  });

  const schemasLength = uiSchemaReport.schemaFields.length;
  const indicesToRemove: number[] = [];

  for (let i = 0; i < schemasLength; i++) {
    const schemaField = uiSchemaReport.schemaFields[i];

    if (!schemaField.isRequired) {
      let empty = true;
      for (const row of rows) {
        if (row[i]) {
          empty = false;
          break;
        }
      }
      if (empty) {
        indicesToRemove.push(i);
      }
    }
  }

  if (indicesToRemove.length) {
    headers = headers.filter((_, index) => !indicesToRemove.includes(index));

    for (let i = 0; i < rows.length; i++) {
      rows[i] = rows[i].filter((_, index) => !indicesToRemove.includes(index));
    }
  }

  csv.setHeader(headers);
  csv.setRows(rows);
}

function createCsvFromAssetSchemas(
  csv: any,
  inventory: Inventory,
  saleMap: Record<number, Partial<Sale>>,
  companyMap: Record<number, Company>,
  assetSchemas: any[],
  locale: string = "EN_US"
) {
  const assetSchemaHeaders = getAssetSchemaHeaders(assetSchemas, {});

  const header = ["Lot Number", "Auction House", "Sale", "Start Time"]
    .concat(assetSchemaHeaders.map(assetSchemaHeader => assetSchemaHeader.label))
    .concat(["Consignor", "Consignor Email", "Consignor Address", "Notes"]);

  csv.setHeader(header);

  inventory.lots.forEach(lot => {
    const asset = inventory.assets[lot.assetId];
    const note = inventory.notes?.[lot.id];
    const sale = saleMap[lot.saleId];
    const company = companyMap[lot.companyId];
    const schemaName = asset?.fields?.schemaName;

    const row = [];

    row.push(lot?.lotNumber || "");
    row.push(company?.name || "");
    row.push(sale?.name || "");
    row.push(sale?.startTime ? formatDateTime(sale?.startTime, locale) : "");

    assetSchemaHeaders.forEach(assetSchemaHeader => {
      if (schemaName === assetSchemaHeader.schemaName) {
        // @ts-ignore
        const value = asset?.uifields?.[assetSchemaHeader.key]?.value || "";
        row.push(
          getShouldFormatDate(assetSchemaHeader, schemaName, asset)
            ? formatDate(Number(value))
            : value
        );
      } else {
        row.push("");
      }
    });

    row.push(lot.consignorName || "");
    row.push(lot.consignorEmail || "");
    row.push(lot.consignorAddress || "");
    row.push(note?.note || "");

    csv.addRow(row);
  });
}

function createCsvFromAutomotiveFields(
  csv: any,
  inventory: Inventory,
  saleMap: Record<number, Partial<Sale>>,
  companyMap: Record<number, Company>,
  locale: string = "EN_US"
) {
  csv.setHeader([
    "Lot Number",
    "Auction House",
    "Sale",
    "Start Time",
    "Main Description",
    "Secondary Description",
    "Title",
    "Grade",
    "MMR",
    "Odometer",
    "Engine",
    "Transmission",
    "Exterior Color",
    "Interior Color",
    "Consignor",
    "Consignor Email",
    "Consignor Address",
    "Notes"
  ]);

  inventory.lots.forEach(lot => {
    const asset = inventory.assets[lot.assetId];
    const note = inventory.notes?.[lot.id];
    const sale = saleMap[lot.saleId];
    const company = companyMap[lot.companyId];
    // @ts-ignore
    const mmr = asset?.estimates?.find?.(estimate => estimate.source === "Mmr");

    csv.addRow([
      lot.lotNumber || "",
      company?.name || "",
      sale?.name || "",
      sale?.startTime ? formatDateTime(sale?.startTime, locale) : "",
      asset?.uifields?.header?.value || "",
      asset?.uifields?.body?.value || "",
      asset?.fields.titleStatus || "",
      asset?.fields.overallConditionGrade || "",
      mmr?.average || "",
      asset?.fields?.mileage || "",
      asset?.fields?.engine || "",
      asset?.fields?.transmission || "",
      asset?.fields?.exteriorColor || "",
      asset?.fields?.interiorColor || "",
      lot.consignorName || "",
      lot.consignorEmail || "",
      lot.consignorAddress || "",
      note?.note || ""
    ]);
  });
}

async function createFullDetailsProps(
  state: RootState,
  inventory: Inventory,
  title?: string,
  languageMap?: Record<string, string>
): Promise<{ inventory: InventoryItem[]; title?: string }> {
  const augmentedInventory = inventory.lots.map(lot => {
    const asset = inventory.assets[lot.assetId];
    const note = inventory.notes?.[lot.id];
    return {
      lot,
      asset,
      note,
      fields: getFields(lot, asset, null, languageMap)
        .filter(field => field.value)
        .map(field => {
          field.value = handleAnyConversions(field.id, field.value, {
            isYear: asset?.fields?.dateOfBirthYearOnly,
            locale: ""
          });
          if (field.id === "announcements") {
            // @ts-ignore
            field.value = asset?.assetAnnouncements
              // @ts-ignore
              .map(assetAnnouncement => assetAnnouncement.announcement)
              .join(", ");
          }
          return field;
        })
    };
  });

  return { inventory: augmentedInventory, title };
}

async function createSummaryProps(
  content: any,
  mediaId?: number,
  title?: string,
  date?: string,
  totalLabel?: string
): Promise<{
  rows: Array<Array<string>>;
  logoUrl?: string;
  title?: string;
  date?: string;
  totalLabel?: string;
}> {
  let media;
  if (mediaId) {
    media = await getMediaById(mediaId);
  }
  return { rows: content, logoUrl: media?.urls?.small, title, date, totalLabel };
}

async function getInventory(filters: any[] = [], sorts: any[] = []) {
  const { total } = await searchLite(filters, "items=0-0");

  const results = await chunkRequestsSync({
    request: searchLite,
    total,
    sort: sorts,
    extraFilters: filters,
    filterValues: null,
    filterByProperty: null
  });

  const { lots = [], assets = [], notes = [] } = results.reduce((acc, result) => {
    for (const key in result) {
      if (Array.isArray(result[key])) {
        if (!acc[key]) acc[key] = [];
        acc[key] = acc[key].concat(result[key]);
      } else {
        acc[key] += result[key];
      }
    }
    return acc;
  }, {});

  return {
    lots,
    assets: assets.reduce(toMap("id"), {}),
    notes: notes.reduce(toMap("entityId"), {})
  } as Inventory;
}

function getSaleAssetSchemaIds(sales: Partial<Sale>[]) {
  return Array.from(
    new Set(sales.reduce<number[]>((arr, sale) => arr.concat(sale?.assetSchemaIds || []), []))
  );
}

function getAssetSchemas(
  saleAssetSchemaIds: number[],
  schemas: any[],
  state: RootState,
  hostCompanyId?: number
) {
  if (saleAssetSchemaIds.length > 0) {
    return schemas.filter((schema: any) => saleAssetSchemaIds.includes(schema.id));
  } else {
    // @ts-ignore
    return getCompanyAssetSchemas(state, hostCompanyId);
  }
}

function getAssetSchemaHeaders(assetSchemas: any[], languageMap: Record<string, string>) {
  const assetSchemaHeaders: any[] = [];

  assetSchemas.forEach(schema => {
    Object.keys(schema.uifields || {}).forEach(key => {
      const uifield = schema.uifields[key];
      const label = handleLabelConversions(uifield?.label, languageMap); // is this needed? doubling

      if (label) {
        assetSchemaHeaders.push({ schemaName: schema.name, key, label });
      }
    });
  });

  return assetSchemaHeaders;
}

export function formatName(name?: string) {
  if (!name) return "";
  return name
    ?.trim()
    .replace(/[^a-z0-9-_\s]/gi, "")
    .replace(/\W/g, "_");
}

function getTotalLabel(state: RootState, inventory: Inventory, companyId?: number) {
  const {
    config: { marketSegment }
  } = state;

  if (marketSegment === MARKET_SEGMENTS.CATTLE) {
    const headCountSchemaField: UISchemaField = getHeadCountSchemaField(state, companyId);
    if (headCountSchemaField == null) {
      return;
    }
    const totalCount = getHeadCountTotal(inventory, headCountSchemaField);

    if (totalCount > 0) {
      return `${headCountSchemaField.label} : ${formatNumber(totalCount)}`;
    }
  }
}

function getHeadCountTotal(inventory: Inventory, headCountSchemaField: UISchemaField) {
  return inventory.lots.reduce((count, lot) => {
    const inventoryItem: InventoryItem = {
      lot,
      asset: inventory.assets[lot.assetId]
    };
    const value = getValue(headCountSchemaField, inventoryItem);

    if (typeof value === "number") {
      count = count + value;
    } else if (isStringNumeric(value)) {
      count = count + parseInt(value, 10);
    }

    return count;
  }, 0);
}

function getHeadCountSchemaField(state: RootState, companyId?: number) {
  const uiSchemaReport = uiSchemasSelector.selectById(
    state,
    getUISchemaSelectId(companyId, UI_SCHEMA_TYPE.REPORT_INVENTORY)
  );

  return uiSchemaReport.schemaFields.find(
    (schemaField: UISchemaField) => schemaField.fieldName === "fields.headCount"
  );
}
