class Filter {
  constructor(field, value, values, filterType, boolType = "MUST", equalityType) {
    this.field = field;
    this.value = value;
    this.values = values;
    this.filterType = filterType;
    this.boolType = boolType;
    this.equalityType = equalityType;
  }
}

export function createTermFilter(field, value, boolType = "MUST") {
  return new Filter(field, value, null, "TERM", boolType);
}

export function createTermsFilter(field, values, boolType = "MUST") {
  return new Filter(field, null, values, "TERMS", boolType);
}

export function createRangeFilter(field, value, equalityType, boolType = "MUST") {
  return new Filter(field, value, null, "RANGE", boolType, equalityType);
}

export function createExistsFilter(field, boolType = "MUST") {
  return new Filter(field, null, null, "EXISTS", boolType);
}

export function createMissingFilter(field, boolType = "MUST") {
  return new Filter(field, null, null, "MISSING", boolType);
}

export function createSort(field, order) {
  return `${order === "ASC" ? "+" : "-"}${field}`;
}

export function createSortFromString(sort) {
  return {
    field: sort.substring(1, sort.length),
    order: sort[0] === "+" ? "ASC" : "DESC"
  };
}

export const toggleSort = currentSort => {
  if (currentSort.order === "DESC") {
    return "ASC";
  } else {
    return "DESC";
  }
};

export function createQueryFilter(value, fields = [], field = "", boolType = "MUST") {
  return {
    filterType: "QUERY",
    field,
    fields,
    value,
    boolType
  };
}

export function createRange(start, end) {
  return `items=${start}-${end}`;
}

export function createSingleRange() {
  return createRange(0, 0);
}
