import {
  FC,
  PropsWithChildren,
  useMemo,
  createContext,
  useReducer,
  useCallback,
  useEffect
} from "react";
import { Action, Location } from "history";
import { useDispatch, useSelector } from "utils/StoreUtil";
import { Prompt } from "react-router-dom";
import { openDialogWithProps, dialogResetState } from "actions/dialogActions";
import { DIALOG_CONTENT_TYPE } from "utils/constants";
import { history } from "store";
import { useExitPrompt } from "../hooks/useExitPrompt";

type When = boolean;

type UnsavedChangesGuardContextValue = {
  setWhen?: (when: When, dialogContentType: string) => void;
};

type State = {
  dialogContentType: string;
  when: When;
  confirmNavigation: boolean;
  nextLocationPathName: string | undefined;
};

type ReducerAction =
  | { type: "SET_WHEN"; payload: { when: boolean; dialogContentType: string } }
  | { type: "SET_NEXT_LOCATION_PATH_NAME"; payload: string }
  | { type: "SET_CONFIRM_NAVIGATION" }
  | { type: "RESET_STATE" };

const defaultValue = {};
const UnsavedChangesGuardContext = createContext<UnsavedChangesGuardContextValue>(defaultValue);

const initialState: State = {
  dialogContentType: "",
  when: false,
  confirmNavigation: false,
  nextLocationPathName: undefined
};

const reducer = (state: State, action: ReducerAction) => {
  switch (action.type) {
    case "SET_WHEN":
      return {
        ...state,
        when: action.payload.when,
        dialogContentType: action.payload.dialogContentType
      };

    case "SET_NEXT_LOCATION_PATH_NAME":
      return {
        ...state,
        nextLocationPathName: action.payload
      };

    case "SET_CONFIRM_NAVIGATION":
      return {
        ...state,
        confirmNavigation: true
      };

    case "RESET_STATE":
      return {
        ...initialState
      };

    default:
      return state;
  }
};

const UnsavedChangesGuardProvider: FC<PropsWithChildren> = ({ children }) => {
  const reduxDispatch = useDispatch();

  const [state, dispatch] = useReducer(reducer, initialState);

  const isAuthorized: boolean = useSelector((state: any) => state.user.isAuthorized);

  const confirmedNavigation = state.confirmNavigation;
  const nextLocationPathName = state.nextLocationPathName;
  const when = isAuthorized ? state.when : false;

  const handleOnConfirmClick = () => {
    dispatch({ type: "SET_CONFIRM_NAVIGATION" });
  };

  const handleOnMessage = (location: Location, action: Action) => {
    if (!confirmedNavigation) {
      dispatch({ type: "SET_NEXT_LOCATION_PATH_NAME", payload: location.pathname });
      reduxDispatch(
        openDialogWithProps(
          state.dialogContentType || DIALOG_CONTENT_TYPE.UNSAVED_CHANGES_CONFIRMATION,
          {
            handleOnConfirmClick
          }
        )
      );

      return false;
    }
    return true;
  };

  useEffect(() => {
    if (confirmedNavigation && nextLocationPathName != null) {
      history.push(nextLocationPathName);

      dispatch({ type: "RESET_STATE" });
    }
  }, [nextLocationPathName, confirmedNavigation]);

  useEffect(() => {
    if (!isAuthorized) {
      dispatch({ type: "RESET_STATE" });
      reduxDispatch(dialogResetState());
    }
  }, [isAuthorized, reduxDispatch]);

  const setWhen = useCallback((when: boolean, dialogContentType: string) => {
    dispatch({ type: "SET_WHEN", payload: { when, dialogContentType } });
  }, []);

  const value = useMemo(() => {
    return {
      setWhen
    };
  }, [setWhen]);

  useExitPrompt(when);

  return (
    <UnsavedChangesGuardContext.Provider value={value}>
      <Prompt when={when} message={handleOnMessage} />
      {children}
    </UnsavedChangesGuardContext.Provider>
  );
};

export {
  UnsavedChangesGuardProvider as default,
  UnsavedChangesGuardProvider,
  UnsavedChangesGuardContext
};
