interface OneSignalInfo {
  appBuild?: string;
  appId?: string;
  appVersion?: string;
  carrierName?: string;
  deviceName?: string;
  deviceRegKey?: string;
  distribution?: string;
  hardware?: string;
  installationId?: string;
  isFirstLaunch?: boolean;
  language?: string;
  model?: string;
  oneSignalPushToken?: string;
  oneSignalRequiresUserPrivacyConsent?: boolean;
  oneSignalSubscribed?: boolean;
  oneSignalUserId?: string;
  os?: string;
  osVersion?: string;
  platform?: string;
  publicKey?: string;
  timeZone?: string;
}

class GoNativeService {
  private instance = null;
  private isGoNative = false;
  private OneSignal: OneSignalInfo = {};

  IsGoNative = () => {
    return this.isGoNative || window.navigator.userAgent.indexOf("gonative") > -1;
  };

  private initialize() {
    this.isGoNative = window.navigator.userAgent.indexOf("gonative") > -1;

    if (this.IsGoNative() && this.appHasOneSignal()) {
      // @ts-ignore
      window.gonative.onesignal.onesignalInfo().then(info => {
        this.OneSignal = info;
      });
    }
  }

  appHasOneSignal() {
    // @ts-ignore
    return window.gonative?.onesignal;
  }

  updateUserRegisteredDevice(email: string) {
    if (!this.IsGoNative() || !this.appHasOneSignal()) return;

    if (this.instance === null) this.initialize();

    // @ts-ignore
    window.gonative.onesignal.externalUserId.set({
      externalId: email
    });
  }

  removeUserRegisteredDevice() {
    if (!this.IsGoNative() || !this.appHasOneSignal()) return;

    // @ts-ignore
    window.gonative.onesignal.externalUserId.remove();
  }
}

const GoNative = new GoNativeService();

export default GoNative;
