import { USER_SET_RECENT_SEARCHES } from "actions/actionTypes";

const initialState = {
  recentSearchList: []
};

export default function recentSearches(state = initialState, action) {
  switch (action.type) {
    case USER_SET_RECENT_SEARCHES:
      return {
        ...state,
        recentSearchList: action.payload
      };
    default:
      return state;
  }
}
