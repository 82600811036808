import React, { memo, useCallback, createContext } from "react";
import Sidebar from "components/Sidebar";
import List from "@mui/material/List";

export const SidebarContext = createContext(true);

const SidebarNavigation = ({
  location,
  classes,
  className,
  children,
  disablePadding = true,
  ...props
}) => {
  const render = useCallback(
    (_, isOpen) => <SidebarContext.Provider value={isOpen}>{children}</SidebarContext.Provider>,
    [children]
  );
  return (
    <Sidebar render={render}>
      <List disablePadding={disablePadding}>
        <SidebarContext.Provider value={true}>{children}</SidebarContext.Provider>
      </List>
    </Sidebar>
  );
};

export default memo(SidebarNavigation);
