import { IconButton, SvgIcon, Typography } from "@mui/material";
import Close from "@mui/icons-material/Close";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(theme => ({
  dialogTitle: {
    display: "flex",
    justifyContent: "space-between"
  },
  title: {
    position: "relative",
    textTransform: "capitalize",
    overflow: "hidden",
    textOverflow: "ellipsis",
    margin: "auto"
  },
  dialogLeft: {
    maxWidth: theme.spacing(40)
  },
  icon: {
    height: "100%",
    marginRight: theme.spacing(1.5),
    color: "inherit"
  },
  closeButton: {
    maxHeight: theme.spacing(4),
    padding: 0
  }
}));

const DialogTitleContent: React.FC<{
  onClose: () => void;
  title: string;
  subTitle?: string;
  icon?: React.FC;
  classes?: Record<string, string>;
}> = ({ onClose, title, subTitle, icon, ...props }) => {
  const { classes } = useStyles(undefined, { props });

  return (
    <div className={classes.dialogTitle}>
      <div className={classes.dialogLeft}>
        {icon && (
          <div>
            <SvgIcon className={classes.icon} component={icon} /> {title}
          </div>
        )}
        {!icon && (
          <Typography variant="h6" color="textSecondary" className={classes.title}>
            {title}
          </Typography>
        )}
        {subTitle && (
          <Typography variant="h6" color="textSecondary" className={classes.title}>
            {subTitle}
          </Typography>
        )}
      </div>
      <IconButton data-testid="close-btn" onClick={onClose} className={classes.closeButton}>
        <Close />
      </IconButton>
    </div>
  );
};

export default DialogTitleContent;
