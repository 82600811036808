import React, { Suspense, useEffect } from "react";
import { useSelector, useDispatch } from "utils/StoreUtil";
import { useTheme, useMediaQuery } from "@mui/material";
import { Dialog as MUIDialog, DialogTitle, CircularProgress } from "@mui/material";

import DialogContent from "./dialogContent/index";
import { dialogResetState } from "actions/dialogActions";
import { getDialogTitle, getDialogProps } from "reducers/dialog";
import DialogTitleContent from "./DialogTitleContent";

function Dialog() {
  const { isOpen, contentType, props } = useSelector(state => state.dialog);
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const dialogTitle = getDialogTitle(contentType);
  const dialogProps = getDialogProps(contentType);

  useEffect(() => {
    return () => {
      dispatch(dialogResetState());
    };
  }, [dispatch]);

  const handleClose = (_, reason) => {
    if (reason === "backdropClick") return;
    dispatch(dialogResetState());
  };

  return (
    <MUIDialog
      open={isOpen}
      onClose={handleClose}
      fullScreen={isMobile}
      id="noScroll"
      {...dialogProps}
    >
      {dialogTitle && (
        <DialogTitle>
          <DialogTitleContent title={dialogTitle} onClose={handleClose} />
        </DialogTitle>
      )}
      <Suspense fallback={<CircularProgress size={20} />}>
        <DialogContent contentType={contentType} {...props} handleClose={handleClose} />
      </Suspense>
    </MUIDialog>
  );
}

export default React.memo(Dialog);
