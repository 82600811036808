import React, { useEffect } from "react";
import { useSelector } from "utils/StoreUtil";
import { makeStyles } from "tss-react/mui";
import { Typography } from "@mui/material";

import PrintHeader from "./components/PrintHeader";
import PrintFooter from "./components/PrintFooter";
import UserText from "./components/UserText";
import Criteria from "./components/Criteria";
import TableHeader from "./components/TableHeader";
import BigTableList from "./components/BigTableList";
import MinimalTable from "./components/MinimalTable";
import useMediaLoadingToOrchestratePrint from "hooks/useMediaLoadingToOrchestratePrint";

const useStyles = makeStyles()(theme => ({
  root: {
    display: "inline",
    printColorAdjust: "exact"
  },
  endOfReport: {
    marginTop: theme.spacing(5)
  }
}));

function InventoryListPrint({ triggerPrint }) {
  const { classes } = useStyles();
  const isMinimalLayout = useSelector(state => state.vehicles.currentLayout === "minimal");

  const { isReadyToPrint, increment } = useMediaLoadingToOrchestratePrint(1); // just the company image

  useEffect(() => {
    if (isReadyToPrint) {
      triggerPrint(window);
    }
  }, [isReadyToPrint, triggerPrint]);

  return (
    <div className={classes.root}>
      <PrintHeader increment={increment} />

      <UserText />

      <Criteria />

      <TableHeader />

      {isMinimalLayout ? <MinimalTable /> : <BigTableList />}

      <Typography align="center" className={classes.endOfReport}>
        ********** End **********
      </Typography>

      <PrintFooter />
    </div>
  );
}

export default InventoryListPrint;
