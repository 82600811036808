import useComet from "./useComet";

const defaultSelector = bid => bid;

function useCustomBid(lotId, saleId, saleUserId, bidType, selector = defaultSelector) {
  const bid = useComet("customBids", saleId, bids => {
    const bid = bids.find(
      bid => bid.bidType === bidType && bid.lotId === lotId && bid.saleUserId === saleUserId
    );

    return bid ? selector(bid) : null;
  });

  return bid;
}

export { useCustomBid as default };
