import { useEffect } from "react";

const initBeforeUnLoad = showExitPrompt => {
  window.onbeforeunload = event => {
    if (showExitPrompt) {
      const e = event || window.event;
      e.preventDefault();
      if (e) {
        e.returnValue = "";
      }
      return "";
    }
  };
};

export const useExitPrompt = showExitPrompt => {
  useEffect(() => {
    initBeforeUnLoad(showExitPrompt);
  }, [showExitPrompt]);
};
