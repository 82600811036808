import { MARKET_SEGMENTS } from "./constants";

export function getDefaultSort(marketSegment) {
  switch (marketSegment) {
    case MARKET_SEGMENTS.AUTOMOTIVE:
      return "+lot.lotNumber";
    default:
      return "+lot.lotSequence";
  }
}

export function getDefaultLotSort(marketSegment) {
  switch (marketSegment) {
    case MARKET_SEGMENTS.AUTOMOTIVE:
      return "+lotNumber";
    default:
      return "+lotSequence";
  }
}

export function getLotNumberType(marketSegment) {
  switch (marketSegment) {
    case MARKET_SEGMENTS.AUTOMOTIVE:
      return "+lotNumber";
    default:
      return "+lotNumberInteger";
  }
}
