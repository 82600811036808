import { get } from "./api";

export async function getCurrencyCodes() {
  const { data } = await get("currencies");
  return data;
}

export async function getCurrencyExchangeRates() {
  const { data } = await get("currencyRates");
  return data;
}
