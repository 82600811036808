import { CREDITS_GET, CREDITS_SET, CREDIT_UPDATE } from "actions/actionTypes";
import { createTermsFilter } from "utils/SearchUtil";
import { getCredits as getCreditsAPI, updateCredit as updateCreditAPI } from "api/adminRequests";
import { updateSaleUserCreditLimit } from "actions/adminActions";
import { updateCredit as updateCreditScript } from "api/scriptRequests";
import { showNotification } from "actions/notificationActions";
import { Severity } from "types/alert";

export function getCredits(creditIds) {
  return async dispatch => {
    const filters = [createTermsFilter("id", creditIds)];
    dispatch({ type: CREDITS_GET });
    const { total } = await getCreditsAPI(filters);
    const { data: credits } = await getCreditsAPI(filters, `items=0-${total}`);
    dispatch(setCredits(credits));
  };
}

function setCredits(credits) {
  return {
    type: CREDITS_SET,
    payload: {
      credits
    }
  };
}

export function updateCredit(credit, notify = true) {
  return async (dispatch, getState) => {
    if (credit.saleUserId) {
      await dispatch(updateSaleUserCreditLimit(credit));
    } else {
      const { useCreditScript } = getState().config.featureFlags;
      if (useCreditScript) {
        await updateCreditScript(credit);
      } else {
        await updateCreditAPI(credit);
      }

      dispatch({
        type: CREDIT_UPDATE,
        payload: {
          credit
        }
      });
      if (notify) dispatch(showNotification("Credit limit updated", Severity.success));
    }
  };
}
