import { CURRENCY_FETCH, CURRENCY_FETCH_COMPLETE } from "./actionTypes";
import { getCurrencyCodes, getCurrencyExchangeRates } from "api/currencyRequests";

export function fetchCurrencies() {
  return async dispatch => {
    dispatch({ type: CURRENCY_FETCH });
    let codes = [];
    let rates = {};
    try {
      codes = await getCurrencyCodes();
      const ratesResponse = await getCurrencyExchangeRates();
      rates = ratesResponse.rates;
    } finally {
      dispatch({ type: CURRENCY_FETCH_COMPLETE, payload: { codes, rates } });
    }
  };
}
