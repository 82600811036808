import { Typography, TypographyProps } from "@mui/material";
import { makeStyles } from "tss-react/mui";

type ClippedTypographyProps = {
  lines: number;
};

const useStyles = makeStyles<ClippedTypographyProps>()((theme, { lines }) => ({
  clippedTypography: {
    display: "-webkit-box",
    overflow: "hidden",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: lines
  }
}));

export const ClippedTypography: React.FC<TypographyProps & ClippedTypographyProps> = ({
  lines,
  ...props
}) => {
  const { classes } = useStyles({ lines }, { props });
  return <Typography {...props} className={classes.clippedTypography} />;
};
