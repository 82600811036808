import React from "react";
import { withStyles } from "tss-react/mui";
import Grid from "@mui/material/Grid";
import ConnectedUpcomingAuctions from "./components/ConnectedUpcomingAuctions";
import UpcomingAuctionsList from "./components/UpcomingAuctionsList";
import Typography from "@mui/material/Typography";
import JoinTextContainer from "./components/JoinTextContainer";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";

const styles = theme => ({
  root: {
    marginTop: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%"
  },
  flexCenter: {
    display: "flex",
    width: "100%",
    [theme.breakpoints.up("md")]: {
      justifyContent: "center"
    }
  },
  whyJoinContainer: {
    width: "60%",
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down("md")]: {
      width: "100%",
      justifyContent: "center"
    }
  },
  bottomText: {
    textDecoration: "none",
    color: theme.palette.secondary.main
  },
  whyJoinText: {
    [theme.breakpoints.down("md")]: {
      order: "2"
    }
  },
  whyText: {
    color: "#ffc107",
    fontWeight: "bold",
    padding: 15,
    marginTop: 30
  },
  joinCommunity: {
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    width: "100%",
    justifyContent: "space-between"
  },
  joinCommunityText: {
    padding: "50px 0 50px 50px",
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    color: theme.palette.common.white,
    "& :first-child": {
      color: "#ffc107",
      fontWeight: "bold"
    },
    [theme.breakpoints.down("md")]: {
      padding: "50px 0 50px 0",
      textAlign: "center"
    }
  },
  buttonContainer: {
    padding: "50px 0px 50px 50px",
    [theme.breakpoints.down("md")]: {
      padding: "0 0 50px 0"
    }
  },
  button: {
    margin: [[theme.spacing(2), 0, 0]],
    backgroundColor: theme.palette.success.main,
    "&:hover": {
      backgroundColor: theme.palette.success.dark
    }
  }
});

const LandingPageMainContent = ({ classes, focusOnLogin }) => {
  return (
    <div className={classes.root}>
      <div className={classes.flexCenter}>
        <ConnectedUpcomingAuctions
          render={properties => (
            <UpcomingAuctionsList {...properties} focusOnLogin={focusOnLogin} />
          )}
        />
      </div>
      <Typography component={Link} to="/register" className={classes.bottomText}>
        {" "}
        Sign up to see all upcoming auctions{" "}
      </Typography>

      <Typography variant="h5" className={classes.whyText}>
        Why Join?
      </Typography>

      <Grid container className={classes.whyJoinContainer}>
        <Grid item sm={12} md={6}>
          <img
            src={`${process.env.PUBLIC_URL}/images/usaLogo.png`}
            width={130}
            height={100}
            alt="usa"
          />
        </Grid>
        <Grid item sm={12} md={6} className={classes.whyJoinText}>
          <JoinTextContainer
            title="Be everywhere at once"
            text="Gain access to daily auctions for thousands of vehicles from across the country"
          />
        </Grid>
      </Grid>
      <Grid container className={classes.whyJoinContainer}>
        <Grid item sm={12} md={6} className={classes.whyJoinText}>
          <JoinTextContainer
            title="Take the auction with you"
            text="Bid at auctions near and far from your office or your mobile device"
          />
        </Grid>
        <Grid item sm={12} md={6}>
          <img
            src={`${process.env.PUBLIC_URL}/images/bidLogo.png`}
            width={130}
            height={100}
            alt="bid"
          />
        </Grid>
      </Grid>
      <Grid container className={classes.whyJoinContainer}>
        <Grid item sm={12} md={6}>
          <img
            src={`${process.env.PUBLIC_URL}/images/seaLogo.png`}
            width={130}
            height={100}
            alt="sea"
          />
        </Grid>
        <Grid item sm={12} md={6} className={classes.whyJoinText}>
          <JoinTextContainer
            title="Bid without being online"
            text="Place proxy bids pre-auction and let the system take care of the rest"
          />
        </Grid>
      </Grid>
      <Grid container className={classes.whyJoinContainer}>
        <Grid item sm={12} md={6} className={classes.whyJoinText}>
          <JoinTextContainer
            title="Do your research"
            text="Access past events for results and look ahead to see what is coming up"
          />
        </Grid>
        <Grid item sm={12} md={6}>
          <img
            src={`${process.env.PUBLIC_URL}/images/researchLogo.png`}
            width={130}
            height={100}
            alt="research"
          />
        </Grid>
      </Grid>
      <Grid container className={classes.joinCommunity}>
        <Grid item xs={12} md={6} className={classes.joinCommunityText}>
          <Typography variant="h5">Ready to bid</Typography>
          <Typography color="initial" variant="h5">
            Join the community today
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} className={classes.buttonContainer}>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            component={Link}
            to="/register"
          >
            SIGN UP FOR FREE
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

LandingPageMainContent.defaultProps = {
  imgSrc: "heroimage.jpg"
};

export default withStyles(LandingPageMainContent, styles);
