import { memo } from "react";
import Snackbar from "@mui/material/Snackbar";
import { makeStyles } from "tss-react/mui";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton, Collapse } from "@mui/material";
import { useDispatch } from "utils/StoreUtil";
import { compose } from "@reduxjs/toolkit";
import Message from "./banner/Message";
import useApplicationMessage from "hooks/useApplicationMessage";
import AuthWrapper from "./AuthWrapper";
import Null from "./Null";
import { DIALOG_CONTENT_TYPE } from "utils/constants";
import { openDialogWithProps } from "actions/dialogActions";

const useStyles = makeStyles()(theme => ({
  root: {
    position: "relative",
    display: "block"
  },
  anchorOriginTopCenter: {
    width: "100%",
    transform: "translateX(0%)",
    top: 0,
    left: 0,
    right: 0,
    [theme.breakpoints.up("md")]: {
      top: 0
    }
  },
  snackbarContent: {
    width: "100%",
    minWidth: "100%",
    borderRadius: 0,
    backgroundColor: theme.palette.background.paper,
    justifyContent: "center",
    position: "relative",
    padding: 0,
    height: theme.spacing(5.5),
    overflow: "hidden",
    display: "flex",
    [theme.breakpoints.down("md")]: {
      justifyContent: "space-between",
      flexWrap: "nowrap"
    }
  },
  action: {
    paddingLeft: 0,
    marginRight: 0,
    flex: 1,
    justifyContent: "flex-end",
    [theme.breakpoints.up("sm")]: {
      justifySelf: "flex-end",
      marginLeft: 0
    }
  },
  container: {
    width: "100%"
  },
  message: {
    padding: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "calc(100% - 44px)",
    [theme.breakpoints.down("md")]: {
      minHeight: "100%",
      justifyContent: "flex-start"
    }
  }
}));

const origin = { vertical: "top", horizontal: "center" };

function Banner() {
  const dispatch = useDispatch();

  const { message, level, hasDismissed, dismiss } = useApplicationMessage();

  const shouldDisplayAppMessage = message && !hasDismissed;

  const isOpen = shouldDisplayAppMessage;

  const { classes } = useStyles();

  const handleClose = event => {
    event.stopPropagation();
    if (shouldDisplayAppMessage) {
      dismiss();
    }
  };

  const showMessageModal = evt => {
    if (evt.target.tagName.toLowerCase() === "a") return;

    shouldDisplayAppMessage &&
      dispatch(
        openDialogWithProps(DIALOG_CONTENT_TYPE.APPLICATION_MESSAGE, {
          level: level,
          content: message
        })
      );
    dismiss();
  };

  return (
    <Snackbar
      message={<Message content={message} level={level} />}
      open={isOpen}
      anchorOrigin={origin}
      ContentProps={{
        classes: {
          root: classes.snackbarContent,
          action: classes.action,
          message: classes.message
        },
        elevation: 0
      }}
      classes={{
        anchorOriginTopCenter: classes.anchorOriginTopCenter,
        root: classes.root
      }}
      action={[
        <IconButton onClick={handleClose} key="close">
          <CloseIcon fontSize="small" />
        </IconButton>
      ]}
      transitionDuration={{ enter: 0, exit: 250 }}
      TransitionComponent={Collapse}
      TransitionProps={{
        classes: {
          root: classes.container
        }
      }}
      onClick={showMessageModal}
    />
  );
}

export default compose(AuthWrapper(Null), memo)(Banner);
