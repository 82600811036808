import React from "react";
import Typography from "@mui/material/Typography";
import { withStyles } from "tss-react/mui";

const styles = theme => ({
  root: {
    paddingLeft: 50,
    paddingRight: 50,
    maxWidth: 450,
    textAlign: "left",
    [theme.breakpoints.down("lg")]: {
      margin: "auto",
      textAlign: "center"
    }
  },
  title: {
    color: theme.palette.secondary.main,
    fontWeight: "bold"
  }
});

const JoinTextContainer = ({ title, text, classes }) => (
  <div className={classes.root}>
    <Typography variant="h5" className={classes.title}>
      {title}
    </Typography>
    <Typography>{text}</Typography>
  </div>
);

export default withStyles(JoinTextContainer, styles);
