import React, { FC } from "react";
import { Slide, useScrollTrigger } from "@mui/material";

interface IHideOnScroll {
  children: JSX.Element;
  threshold?: number;
  timeout?: number;
  alwaysShow?: boolean;
  direction?: "left" | "right" | "up" | "down";
  container?: null | Element;
}

const HideOnScroll: FC<IHideOnScroll> = ({
  children,
  threshold = 200,
  timeout = 300,
  alwaysShow = false,
  direction = "down",
  container
}): JSX.Element => {
  let trigger = !useScrollTrigger({ threshold: threshold });

  if (alwaysShow) {
    trigger = true;
  }

  return (
    <Slide
      appear={false}
      direction={direction}
      in={trigger}
      timeout={timeout}
      container={container}
    >
      {children}
    </Slide>
  );
};

export default HideOnScroll;
