import { DIALOG_RESET_STATE, DIALOG_OPEN } from "actions/actionTypes";

export const dialogResetState = () => ({
  type: DIALOG_RESET_STATE
});

export function openDialogWithProps(contentType, props) {
  return {
    type: DIALOG_OPEN,
    payload: {
      contentType,
      props
    }
  };
}
