import { createEntityAdapter, createReducer, createSelector } from "@reduxjs/toolkit";
import {
  inventoryFetch,
  inventoryFetchComplete,
  recommendationFetchComplete
} from "actions/sharedActions";
import { Inspection } from "types/Inspection";

const inspectionsAdapter = createEntityAdapter<Inspection, number>({
  selectId: inspection => inspection.assetId
});

export const inspectionsSelector = inspectionsAdapter.getSelectors();

export const initialState = inspectionsAdapter.getInitialState({
  isLoading: true
});

export default createReducer(initialState, builder => {
  builder
    .addCase(inventoryFetch, (state, action) => {
      state.isLoading = true;
    })
    .addCase(inventoryFetchComplete, (state, action) => {
      state.isLoading = false;
      if (action.payload.inspections?.length > 0) {
        inspectionsAdapter.upsertMany(state, action.payload.inspections);
      }
    })
    .addCase(recommendationFetchComplete, (state, action) => {
      if (action.payload.inspections?.length > 0) {
        inspectionsAdapter.upsertMany(state, action.payload.inspections);
      }
    });
});

export const makeHasInspection = () =>
  createSelector(
    (state: typeof initialState) => state.entities,
    (_: any, assetId: number) => assetId,
    (inspections: any, assetId: number) => Boolean(inspections[assetId])
  );
