import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { inventoryFetchComplete } from "actions/sharedActions";
import Condition from "types/Condition";

type Sort = {
  field: string;
  order: string;
};

const conditionsAdapter = createEntityAdapter<Condition>();

export const conditionsSelector = conditionsAdapter.getSelectors();

export const initialState = conditionsAdapter.getInitialState<{
  sort: Sort;
  hiddenColumns: string[];
  isLoading: boolean;
}>({
  hiddenColumns: [],
  sort: { field: "item", order: "ASC" },
  isLoading: true
});

const conditionsSlice = createSlice({
  name: "conditions",
  initialState,
  reducers: {
    fetch: (state, action) => {
      state.isLoading = true;
    },
    fetchComplete: (state, action) => {
      conditionsAdapter.setAll(state, action.payload);
      state.isLoading = false;
    },
    toggleSort: (state, action) => {
      const sort = action.payload;

      const newSort =
        state.sort.field === sort.field
          ? createSort(sort.field, toggleSort(state.sort, sort))
          : createSort(sort.field, sort.order);

      state.sort = newSort;
      state.ids = sortConditions(newSort, conditionsSelector.selectAll(state));
    },
    toggleColumn: (state, action) => {
      const columnId = action.payload;
      if (state.hiddenColumns.includes(columnId)) {
        state.hiddenColumns = state.hiddenColumns.filter(id => id !== columnId);
      } else {
        state.hiddenColumns.push(columnId);
      }
    }
  },
  extraReducers: builder => {
    builder.addCase(inventoryFetchComplete, (state, action) => {
      state.isLoading = false;
    });
  }
});

const createSort = (field: string, order: string) => ({ field, order });

const toggleSort = (currentSort: Sort, sort: Sort) => {
  if (currentSort.field === sort.field && currentSort.order === "DESC") {
    return "ASC";
  } else {
    return "DESC";
  }
};

const toIds = (item: Condition) => item.id;

const sortConditions = (sort: Sort, conditions: Condition[]) => {
  if (sort.order === "ASC") return conditions.sort(sortAscending(sort.field)).map(toIds);

  return conditions.sort(sortDescending(sort.field)).map(toIds);
};

const sortAscending = (field: string) => (a: Condition, b: Condition) =>
  // @ts-ignore
  a[field] < b[field] ? -1 : 1;

const sortDescending = (field: string) => (a: Condition, b: Condition) =>
  // @ts-ignore
  b[field] < a[field] ? -1 : 1;

export { conditionsSlice as default };
