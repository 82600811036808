import { createAction, createEntityAdapter, createReducer } from "@reduxjs/toolkit";
import { REHYDRATE } from "redux-persist/lib/constants";

import {
  recommendationFetchComplete,
  cometMessage,
  inventoryFetchComplete
} from "actions/sharedActions";

type Watch = {
  id: number;
  lotId: number;
};

const watchesAdapter = createEntityAdapter<Watch, number>({
  selectId: watch => watch.lotId
});

export const watchesSelector = watchesAdapter.getSelectors();

export const setWatchlistTotal = createAction<number>("watchlist/setTotal");

const initialState = watchesAdapter.getInitialState({ total: 0 });

export default createReducer(initialState, builder => {
  builder
    .addCase(REHYDRATE, () => {
      return initialState;
    })
    .addCase(setWatchlistTotal, (state, action) => {
      state.total = action.payload ?? 0;
    })
    .addCase(cometMessage, (state, action) => {
      const { data } = action.payload;

      if (data?.type === "watch") {
        if (data.deleted) {
          watchesAdapter.removeOne(state, data.lotId);
          state.total = Math.max(state.total - 1, 0);
        } else {
          watchesAdapter.upsertOne(state, { id: data.id, lotId: data.lotId });
          state.total = state.total + 1;
        }
      }
    })
    .addCase(inventoryFetchComplete, (state, action) => {
      if (action.payload.watches?.length > 0) {
        watchesAdapter.upsertMany(state, action.payload.watches);
      }
    })
    .addCase(recommendationFetchComplete, (state, action) => {
      if (action.payload.watches?.length > 0) {
        watchesAdapter.upsertMany(state, action.payload.watches);
      }
    });
});
