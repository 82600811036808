import { withStyles } from "tss-react/mui";
import PropTypes from "prop-types";
import ProblemIcon from "@mui/icons-material/ReportProblem";
import Typography from "@mui/material/Typography";

const styles = theme => ({
  root: {
    height: "calc(100% - 60px)",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    opacity: 0.5
  },
  icon: {
    height: theme.spacing(6),
    width: theme.spacing(6),
    marginTop: theme.spacing(2)
  },
  empty: {
    color: theme.palette.text.secondary,
    textAlign: "center"
  }
});

const EmptyPage = ({ children, classes, message, Icon = ProblemIcon, variant = "h5" }) => (
  <div className={classes.root}>
    <Typography variant={variant} className={classes.empty}>
      <Icon className={classes.icon} />
      <br />
      {message}
    </Typography>
    <div>{children}</div>
  </div>
);

EmptyPage.propTypes = {
  classes: PropTypes.object.isRequired,
  message: PropTypes.string.isRequired
};

export default withStyles(EmptyPage, styles);
