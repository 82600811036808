import moment from "moment-timezone";
import { LOCALE_CODE, DATE_UNIT } from "./constants";

import timezones from "jsons/timezones";

const { EN_US, EN_CA, EN_GB, EN_NZ, EN_ZA, EN_NA } = LOCALE_CODE;

class Locale {
  constructor(format, description) {
    this.format = format;
    this.description = description;
  }
}

const localeMap = {
  [EN_US]: new Locale("MM/DD/YYYY", "English/US"),
  [EN_CA]: new Locale("DD/MM/YYYY", "English/Canada"),
  [EN_GB]: new Locale("DD/MM/YYYY", "English/Great Britain"),
  [EN_NZ]: new Locale("D/MM/YYYY", "English/New Zealand"),
  [EN_ZA]: new Locale("YYYY/MM/DD", "English/South Africa"),
  [EN_NA]: new Locale("MM/DD/YYYY", "English/Namibia")
};

export const localeCodes = Object.keys(LOCALE_CODE);

export function formatYear(timestamp, timezone) {
  return getDate(timestamp, "YYYY", timezone);
}

export function formatDate(timestamp, locale, timezone) {
  return getDate(timestamp, getDateFormatBy(locale), timezone);
}

export function formatDateTime(timestamp, locale, timeFormat) {
  return getDate(timestamp, getDateTimeFormatBy(locale, timeFormat));
}

export function formatDateTimeZone(timestamp, locale) {
  return getDate(timestamp, `${getDateTimeFormatBy(locale)} z`);
}

export function getDateFormatBy(locale) {
  return localeMap[locale]?.format;
}

export function getLocaleDescription(locale) {
  return localeMap[locale]?.description;
}

export function getDateTimeFormatBy(locale, timeFormat = "hh:mm a") {
  return `${getDateFormatBy(locale)} ${timeFormat}`;
}

function getDate(timestamp, format, timezone) {
  if (!timezone) {
    timezone = moment.tz.guess();
  }
  return moment(timestamp)
    .tz(timezone)
    .format(format);
}

//timezone

export function getTimezones() {
  return timezones || [];
}

export function getTimezone(timeZoneCode) {
  return getTimezones().find(timezone => timezone.code === timeZoneCode);
}

// should not used for date display. Only used to get timestamp
export function getDateString(timestamp, timezone) {
  if (!timezone) {
    timezone = moment.tz.guess();
  }

  return moment(timestamp)
    .tz(timezone)
    .format("YYYY-MM-DD HH:mm")
    .toString();
}

export function getTimestamp(dateString, timezone) {
  if (!timezone) {
    timezone = moment.tz.guess();
  }

  return moment.tz(dateString, timezone).valueOf();
}

export function convertAgeToString(age) {
  if (age != null) {
    return _convertAgeToString(parseInt(age, 10));
  }
}

function _convertAgeToString(age) {
  if (age < 12) {
    return convertDateUnitToString(DATE_UNIT.MONTH, age);
  } else {
    let str;
    const years = parseInt(age / 12, 10);
    const months = age % 12;

    str = convertDateUnitToString(DATE_UNIT.YEAR, years);

    if (months > 0) {
      str += ` ${convertDateUnitToString(DATE_UNIT.MONTH, months)}`;
    }

    return str;
  }
}

function convertDateUnitToString(dateUnit, value) {
  return `${value} ${dateUnit}${value > 1 ? "s" : ""}`;
}

export function getTimeValue(timestamp, timezone) {
  if (!timestamp) {
    return null;
  }
  if (!timezone) {
    timezone = moment.tz.guess();
  }
  return moment(timestamp).tz(timezone);
}

export function formatDuration(milliseconds) {
  const dur = moment.duration(milliseconds);
  const months = dur.months();
  const weeks = dur.weeks();
  const days = dur.days();
  const hours = dur.hours();
  const minutes = dur.minutes();

  let results = [];

  if (months) {
    results.push(`${months}m`);
  }
  if (weeks) {
    results.push(`${weeks}w`);
  }
  if (days) {
    results.push(`${days}d`);
  }
  if (hours) {
    results.push(`${hours}h`);
  }
  results.push(`${minutes}m`);

  return results.join(", ");
}

export function fromNowAsDays(timestamp, withText = false) {
  const today = moment();

  return Math.floor(moment.duration(today - moment(timestamp)).asDays());
}
