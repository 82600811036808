import { createTheme, Theme, lighten } from "@mui/material";
import { CSSProperties } from "react";
import { deepOrange } from "@mui/material/colors";

declare module "@mui/material/styles" {
  // allow configuration of platte[<option>] namespace in `createTheme`
  interface SimplePaletteColorOptions {
    lighter?: string;
  }
  interface PaletteColor {
    lighter?: string;
  }
  // the `theme.platte.background` custom values
  interface TypeBackground {
    grey: string;
  }
  // the `theme.palette`
  interface Palette {
    company: {
      main: CSSProperties["color"];
      secondary: CSSProperties["color"];
      accent: CSSProperties["color"];
      header: string;
    };
    colors: {
      orange: string;
    };
  }
  // allow configuration of platte namespace in `createTheme`
  interface PaletteOptions {
    company?: {
      main: CSSProperties["color"];
      secondary: CSSProperties["color"];
      accent: CSSProperties["color"];
      header: string;
    };
    colors?: {
      orange: string;
    };
  }
  // the theme passed to components
  interface Theme {
    shape: {
      borderLight: CSSProperties["border"];
      border: CSSProperties["border"];
      borderHeavy: CSSProperties["border"];
      borderRadius: number;
      getBorder: (color?: string, size?: "light" | "heavy" | "default") => string;
    };
    laneLight: {
      red: CSSProperties["color"];
      green: CSSProperties["color"];
      yellow: CSSProperties["color"];
      blue: CSSProperties["color"];
      white: CSSProperties["color"];
    };
  }
  // allow configuration of main theme namespace using `createTheme`
  interface ThemeOptions {
    shape?: {
      borderLight?: CSSProperties["border"];
      border?: CSSProperties["border"];
      borderHeavy?: CSSProperties["border"];
      borderRadius: number;
      getBorder: (color?: string, size?: "light" | "heavy" | "default") => string;
    };
    laneLight?: {
      red?: CSSProperties["color"];
      green?: CSSProperties["color"];
      yellow?: CSSProperties["color"];
      blue?: CSSProperties["color"];
      white?: CSSProperties["color"];
    };
  }
}

interface ConfigState {
  darkMode: boolean;
}

interface CompanyThemeConfig {
  primaryColor: string;
  secondaryColor: string;
  accentColor: string;
  headerColor: string;
  initialWidth: string | undefined;
  contrastThreshold: string; // number?
}

interface CustomThemeOptions extends ConfigState, CompanyThemeConfig {}

const defaultOptions: CustomThemeOptions = {
  primaryColor: "#4b9bdd",
  secondaryColor: "#f50057",
  accentColor: "#FFC107",
  headerColor: "primary",
  darkMode: false,
  initialWidth: undefined,
  contrastThreshold: "1"
};

export function getTheme(options: CustomThemeOptions = defaultOptions): Theme {
  options = Object.assign(defaultOptions, options);

  let theme = createTheme({
    palette: {
      mode: options.darkMode ? "dark" : "light",
      primary: {
        main: options.primaryColor,
        lighter: lighten(options.primaryColor, 0.9)
      },
      secondary: {
        main: options.secondaryColor,
        lighter: lighten(options.secondaryColor, 0.9)
      },
      colors: {
        orange: deepOrange["A200"]
      }
    },
    laneLight: {
      red: "#ff0001",
      green: "#018001",
      yellow: "#ffff03",
      blue: "#0007ff",
      white: "#fff"
    },
    components: {
      MuiTypography: {
        defaultProps: {
          // display: "block",
          color: "textPrimary",
          variant: "body2"
        }
      },
      MuiFormControl: {
        defaultProps: {
          variant: "standard"
        }
      },
      MuiAppBar: {
        defaultProps: {
          enableColorOnDark: true
        }
      },
      MuiLink: {
        defaultProps: {
          underline: "hover"
        }
      },
      MuiTabs: {
        defaultProps: {
          indicatorColor: "primary",
          textColor: "inherit"
        }
      },
      MuiButton: {
        defaultProps: {
          size: "large"
        }
      }
    }
  });

  function getBorder(color?: string, size?: "light" | "heavy" | "default"): string {
    const borderWidth = size === "heavy" ? "4px" : size === "light" ? "1px" : "2px";
    const borderColor = color?.trim() || theme.palette.divider;
    return `${borderWidth} solid ${borderColor}`;
  }

  // Theme composition: use previous theme to derive values for a new theme
  theme = createTheme(theme, {
    palette: {
      background: {
        grey: getGreyBackground(theme)
      },
      company: {
        main: options.primaryColor,
        secondary: options.secondaryColor,
        accent: options.accentColor,
        header: getHeaderColor(theme, options.headerColor)
      }
    },
    shape: {
      borderLight: getBorder(theme.palette.divider, "light"),
      border: getBorder(theme.palette.divider),
      borderHeavy: getBorder(theme.palette.divider, "heavy"),
      borderRadius: 4,
      getBorder
    }
  });

  return theme;
}

function getHeaderColor(theme: Theme, headerColorConfig: string): string {
  switch (headerColorConfig) {
    case "default":
      return theme.palette.mode === "light" ? "#fff" : "#000";
    case "secondary":
      return theme.palette.secondary.main;
    case "primary":
    default:
      return theme.palette.primary.main;
  }
}

function getGreyBackground(theme: Theme): string {
  // colors MUI uses for 'default' color
  return theme.palette.mode === "light" ? "#f5f5f5" : "#212121";
}

export const customColors = {
  red: "#d32c2c",
  yellow: "#ffce00",
  transparentBlack: "rgba(0,0,0, 0.85)"
};

export const variantMapping = {
  h1: "h1",
  h2: "h2",
  h3: "h3",
  h4: "h4",
  h5: "h5",
  h6: "h6",
  subtitle1: "h6",
  subtitle2: "h6",
  body1: "p",
  body2: "p",
  inherit: "p",
  caption: "p"
};
