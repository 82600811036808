import { Link } from "react-router-dom";
import { makeStyles } from "tss-react/mui";
import { Grid, Typography, Button, CircularProgress, Hidden } from "@mui/material";

import useLanguage from "hooks/useLanguage";
import { useSelector } from "utils/StoreUtil";
import { getCompanyById } from "reducers/companies";

import { ClippedTypography } from "components/typography/ClippedTypography";

const useStyles = makeStyles()((theme, { logoUrl }) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[1],
    borderRadius: 5,
    height: 408,
    [theme.breakpoints.down("md")]: {
      display: "flex"
    },
    [theme.breakpoints.down("sm")]: {
      display: "block"
    },
    flexGrow: 1
  },
  title: {
    marginBottom: theme.spacing(2)
  },
  textContent: {
    overflow: "hidden",
    maxHeight: 258
  },
  button: {
    width: "160px"
  },
  imageWrapper: {
    maxHeight: 408,
    padding: 0
  },
  image: {
    objectFit: "cover",
    height: "100%",
    width: "100%"
  },
  contentWrapper: {
    textAlign: "left",
    padding: theme.spacing(2),
    position: "relative",
    height: "100%"
  },
  contentBackground: {
    "&::before": {
      height: 408,
      backgroundImage: `url(${logoUrl})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      opacity: 0.25,
      content: '""',
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0
    }
  }
}));

const PAGE_HEADER = "homepageHeader";
const PAGE_CONTENT = "homepageContent";

const defaultPageContent = "Powered by Online Ringman®";

const CompanyStatement = ({ logoUrl }) => {
  const [homepageHeader, homepageContent] = useLanguage(PAGE_HEADER, PAGE_CONTENT);
  const isMobile = useSelector(state => state.viewport.isMobile);
  const companyId = useSelector(state => state.user.user?.companyId);
  const companies = useSelector(state => state.companies.companies);
  const isLanguageLoading = useSelector(state => state.language.isLoading);

  const company = getCompanyById(companies, companyId);

  const { classes } = useStyles({ logoUrl });

  const showImage = logoUrl && !isMobile;

  return (
    <Grid container className={classes.root}>
      <Grid item padding={0} md={showImage ? 6 : 12} sm={12} sx={{ height: "100%" }}>
        <Grid
          container
          spacing={0}
          direction="column"
          justifyContent="center"
          alignItems="stretch"
          className={classes.contentWrapper}
        >
          <Grid item className={showImage ? "" : classes.contentBackground}>
            {!isLanguageLoading && (
              <ClippedTypography
                lines={1}
                variant="h4"
                classes={{ clippedTypography: classes.title }}
              >
                {homepageHeader === PAGE_HEADER && company != null ? company.name : homepageHeader}
              </ClippedTypography>
            )}
          </Grid>

          <Grid item>
            {isLanguageLoading ? (
              <Typography align="center">
                <CircularProgress color="inherit" />
              </Typography>
            ) : (
              <Typography align="justify" className={classes.textContent}>
                {homepageContent === PAGE_CONTENT ? defaultPageContent : homepageContent}
              </Typography>
            )}
          </Grid>

          <Grid item mt={2}>
            <Button
              variant="outlined"
              color="primary"
              className={classes.button}
              component={Link}
              to="/register"
            >
              REGISTER
            </Button>
          </Grid>
        </Grid>
      </Grid>

      {showImage && (
        <Hidden mdDown>
          <Grid item className={classes.imageWrapper} md={6} sm={12}>
            <img src={logoUrl} alt="" className={classes.image} />
          </Grid>
        </Hidden>
      )}
    </Grid>
  );
};

export default CompanyStatement;
