import React, { memo, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { makeStyles } from "tss-react/mui";
import { upload } from "api/api";
import { useDispatch } from "utils/StoreUtil";
import { setEntityMedia } from "actions/adminActions";
import IconButton from "@mui/material/IconButton";
import PhotoCamera from "@mui/icons-material/PhotoCamera";

const useStyles = makeStyles()(theme => ({
  photoIcon: {
    backgroundColor: `${theme.palette.grey["100"]}`,
    color: theme.palette.primary.dark,
    padding: theme.spacing(0.75),
    "&:hover": {
      backgroundColor: `${theme.palette.grey["100"]}`,
      color: theme.palette.primary.main
    }
  }
}));

function PhotoUploadIcon({
  entityType,
  entityId,
  companyId,
  mediaId,
  mediaName,
  onCompleteAction = setEntityMedia
}) {
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const [isLoading, setIsLoading] = useState(false);

  async function handleChange(evt) {
    const [file] = evt.target.files;

    if (file) {
      const media = {
        entityType: entityType?.toUpperCase(),
        entityId: entityId,
        primary: true,
        name: mediaName,
        companyId: companyId,
        id: mediaId || null
      };

      let data = new FormData();
      data.append("file", file);
      data.append("media", JSON.stringify(media));

      const headers = {
        "Content-Type": "multipart/form-data"
      };

      const url = mediaId ? `media/${mediaId}` : "media";
      const method = mediaId ? "PUT" : "POST";

      setIsLoading(true);

      try {
        let res = await upload(url, headers, data, undefined, method);

        if (!mediaId) {
          media.id = res.data;
        }

        dispatch(onCompleteAction(media));
      } finally {
        setIsLoading(false);
      }
    }
  }

  return (
    <>
      <input hidden accept="image/*" type="file" id="photo-upload" onChange={handleChange} />
      <label htmlFor="photo-upload">
        <IconButton
          color="primary"
          aria-label="upload picture"
          component="span"
          className={classes.photoIcon}
        >
          {isLoading ? <CircularProgress size={20} color="primary" /> : <PhotoCamera />}
        </IconButton>
      </label>
    </>
  );
}

export default memo(PhotoUploadIcon);
