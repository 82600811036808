import React from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import { makeStyles } from "tss-react/mui";
import { useSelector } from "utils/StoreUtil";
import { TableRow, TableCell, Typography, Chip } from "@mui/material";
import { salesSelector } from "reducers/sales";
import { barcodeUrl } from "utils/Barcode";
import { makeLightsString } from "utils/VehicleHelpers";
import { formatDateTime } from "utils/DateUtil";
import { handleAnyConversions } from "utils/AssetUtil";
import { getLanguageMap } from "reducers/language";
import { handleLabelConversions } from "utils/LanguageUtil";
import { inventoryNotesSelector } from "reducers/notes";
import { lotsSelector } from "reducers/lots";
import { assetsSelector } from "reducers/assets";
import { inspectionsSelector } from "reducers/inspections";
import { disclosuresSelector } from "reducers/disclosures";

function getDisplayFields(asset, languageMap) {
  let fields = [];

  switch (asset?.fields?.schemaName) {
    case "Bloodstock": {
      fields = [
        { label: "Sex", path: "fields.sex" },
        { label: "Sire", path: "fields.sire" },
        { label: "Dam", path: "fields.dam" },
        { label: "State", path: "fields.state", inline: true },
        { label: "Country", path: "fields.country", inline: true },
        { label: "DOB", path: "fields.dateOfBirth" },
        { label: "Barn", path: "fields.barn", inline: true },
        { label: "Black Type", path: "fields.blackTypeBool", inline: true }
      ];
      break;
    }

    case "Automotive": {
      fields = [
        { label: "Odometer", path: "uifields.field2.value", inline: true },
        { label: "Title", path: "uifields.field3.value", inline: true },
        { label: "VIN", path: "fields.vin" },
        { label: "Exterior", path: "fields.exteriorColor", inline: true },
        { label: "Interior", path: "fields.interiorColor", inline: true },
        { label: "Engine", path: "fields.engine" },
        { label: "Transmission", path: "fields.transmission" }
      ];
      break;
    }

    case "Merchandise": {
      fields = [
        { label: "Description", path: "uifields.body.value" },
        { label: "Stock#", path: "uifields.field1.value" }
      ];
      break;
    }

    default:
      fields = getDefaultDisplayFields(asset);
  }

  return fields.map(field => {
    field.label = handleLabelConversions(field.label, languageMap);
    return field;
  });
}

function getDefaultDisplayFields(asset) {
  const { header, ...uifields } = asset?.uifields || {};

  return Object.values(uifields).reduce((displayFields, uifield) => {
    if (uifield) {
      const { label, value } = uifield;

      displayFields.push({ label, value });
    }

    return displayFields;
  }, []);
}

function getCompanyById(companies = [], id) {
  return companies.companies.find(company => company.id === id);
}

const useStyles = makeStyles()(theme => ({
  root: {
    borderLeft: theme.shape.borderLight,
    borderRight: theme.shape.borderLight,
    pageBreakInside: "avoid",
    pageBreakBefore: "auto"
  },
  field: {
    display: "flex",
    "& :first-child": {
      marginRight: theme.spacing(1)
    }
  },
  inline: {
    display: "inline-block",
    marginRight: theme.spacing(2)
  },
  barcode: {
    marginTop: theme.spacing(1)
  },
  chip: {
    marginRight: theme.spacing(0.5)
  }
}));

function BigTableListItem({ lotId }) {
  const { classes } = useStyles();
  const lot = useSelector(state => lotsSelector.selectById(state.lots, lotId));
  const asset = useSelector(state => assetsSelector.selectById(state.assets, lot?.assetId));
  const companyName = useSelector(state => getCompanyById(state.companies, lot?.companyId)?.name);
  const sale = useSelector(state => salesSelector.selectById(state.sales, lot?.saleId));
  const disclosures = useSelector(state =>
    disclosuresSelector.selectById(state.disclosures, lot?.assetId)
  );
  const inspections = useSelector(state =>
    inspectionsSelector.selectById(state.inspections, lot?.assetId)
  );
  const note = useSelector(state => inventoryNotesSelector.selectById(state, lotId));
  const locale = useSelector(state => state.user.user.locale);
  const languageMap = useSelector(state => getLanguageMap(state));

  const displayFields = getDisplayFields(asset, languageMap);

  const saleLabel = languageMap.sale || "Sale";
  const runLabel = languageMap.lotNumber || "Run Number";
  const consignorLabel = languageMap.consignor || "Consignor";

  const lights = makeLightsString(asset?.fields);

  const consignorName = lot?.consignorName;
  const consignorType = lot?.consignorType;

  return (
    <TableRow className={classes.root}>
      <TableCell>
        <Typography>{asset?.uifields?.header?.value}</Typography>

        {displayFields.map(field => {
          const fieldValue = field.value || get(asset, field.path, null);
          const value = handleAnyConversions(field.label, fieldValue, {
            isYear: asset?.fields?.dateOfBirthYearOnly,
            locale
          });
          return (
            <div key={field.label} className={field.inline ? classes.inline : null}>
              <div className={classes.field}>
                <Typography color="textSecondary">{field.label}:</Typography>
                <Typography>{value || "-"}</Typography>
              </div>
            </div>
          );
        })}

        {asset?.fields?.schemaName === "Automotive" && (
          <div className={classes.barcode}>
            <img
              src={barcodeUrl(asset?.fields?.vin || undefined)}
              alt="barcode"
              height={16}
              width={304}
            />
          </div>
        )}
      </TableCell>

      <TableCell>
        <Typography>{companyName}</Typography>

        {consignorName && (
          <div className={classes.field}>
            <Typography color="textSecondary">{consignorLabel}:</Typography>
            <Typography>{consignorName}</Typography>
          </div>
        )}

        {consignorType && (
          <div className={classes.field}>
            <Typography color="textSecondary">{consignorLabel} Type:</Typography>
            <Typography>{consignorType}</Typography>
          </div>
        )}

        <div className={classes.field}>
          <Typography color="textSecondary">{saleLabel}:</Typography>
          <Typography>{sale?.name || "-"}</Typography>
        </div>

        <div className={classes.inline}>
          <div className={classes.field}>
            <Typography color="textSecondary">{runLabel}:</Typography>
            <Typography>{lot?.lotNumber || "-"}</Typography>
          </div>
        </div>

        <div className={classes.inline}>
          <div className={classes.field}>
            <Typography color="textSecondary">Stock Number:</Typography>
            <Typography>{asset?.fields?.stockNumber || "-"}</Typography>
          </div>
        </div>

        <div className={classes.field}>
          <Typography color="textSecondary">{saleLabel} Date:</Typography>
          <Typography>{formatDateTime(sale?.startTime, locale)}</Typography>
        </div>

        {note && (
          <div className={classes.field}>
            <Typography color="textSecondary">Note:</Typography>
            <Typography>{note.note}</Typography>
          </div>
        )}

        {lights && (
          <div className={classes.field}>
            <Typography color="textSecondary">Lights:</Typography>
            <Typography>{lights}</Typography>
          </div>
        )}

        {(lot?.status === "SOLD" || lot?.status === "IFSOLD") && (
          <div className={classes.field}>
            <Typography color="textSecondary">Status:</Typography>
            <Typography>{lot.status === "SOLD" ? "SOLD" : "PENDING"}</Typography>
          </div>
        )}

        {asset?.assetAnnouncements.length > 0 && (
          <Chip label="Announcements" size="small" classes={{ root: classes.chip }} />
        )}

        {disclosures && <Chip label="Disclosures" size="small" className={classes.chip} />}

        {inspections && <Chip label="Condition Report" size="small" className={classes.chip} />}
      </TableCell>
    </TableRow>
  );
}

BigTableListItem.propTypes = {
  lotId: PropTypes.number.isRequired
};

export default React.memo(BigTableListItem);
