import { createSlice, createEntityAdapter, createSelector } from "@reduxjs/toolkit";
import { REHYDRATE } from "redux-persist/lib/constants";

import {
  ADMIN_UPDATE_SALE,
  RESET_CALENDAR_ENTITIES,
  ADMIN_SALE_FETCH_COMPLETE,
  CALENDAR_FETCH_COMPLETE
} from "actions/actionTypes";
import Sale from "types/Sale";
import Event from "types/Event";
import Lot from "types/Lot";

import { inventoryFetchComplete } from "actions/sharedActions";
import { toMap } from "utils/CollectionUtil";

const NAME = "sales";

const salesAdapter = createEntityAdapter<Sale>();

const initialState = salesAdapter.getInitialState();

type SalesState = typeof initialState;

const salesSlice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    removeSale(state, action) {
      salesAdapter.removeOne(state, action.payload);
    },
    updateSales(state, action) {
      salesAdapter.upsertMany(state, action.payload);
    }
  },
  extraReducers: builder => {
    builder
      .addCase(REHYDRATE, () => {
        return salesAdapter.getInitialState();
      })
      .addCase(RESET_CALENDAR_ENTITIES, state => {
        salesAdapter.setAll(state, []);
      })
      .addCase(CALENDAR_FETCH_COMPLETE, (state, action) => {
        // @ts-ignore
        const sales = action.payload[NAME] || [];

        // @ts-ignore
        if (action.payload.shouldMerge) {
          salesAdapter.upsertMany(state, sales);
        } else {
          salesAdapter.setAll(state, sales);
        }

        // @ts-ignore
        if (action.payload.saleUserCount) {
          // @ts-ignore
          const saleUserCountMap = action.payload.saleUserCount;
          const saleUserCounts = [];

          for (let key in saleUserCountMap) {
            saleUserCounts.push({ id: key, saleUserCount: saleUserCountMap[key] });
          }

          // merge saleUsers count into the corresponding sale entities, with saleUserCount property
          // @ts-ignore
          salesAdapter.upsertMany(state, saleUserCounts);
        }
      })
      .addCase(ADMIN_UPDATE_SALE, (state, action) => {
        // @ts-ignore
        salesAdapter.updateOne(state, action.payload.update);
      })
      .addCase(inventoryFetchComplete, (state, action) => {
        // @ts-ignore
        if (action.payload[NAME]) {
          // @ts-ignore
          salesAdapter.upsertMany(state, action.payload[NAME]);
        }
      })
      .addCase(ADMIN_SALE_FETCH_COMPLETE, (state, action) => {
        // @ts-ignore
        if (action.payload) {
          // @ts-ignore
          salesAdapter.upsertOne(state, action.payload);
        }
      });
  }
});

export const { removeSale, updateSales } = salesSlice.actions;

export default salesSlice.reducer;

export const salesSelector = salesAdapter.getSelectors();

export const getSalesWithSelector = createSelector(
  // @ts-ignore
  state => state.sales,
  // @ts-ignore
  (_, eventId) => eventId,
  // @ts-ignore
  (saleState: SalesState, eventId) => {
    if (eventId == null) {
      return [];
    }
    return getSalesByEventId(saleState, eventId, false);
  }
);

export function getSalesByEventId(salesState: SalesState, eventId: number, shouldFilter = true) {
  // eslint-disable-next-line eqeqeq
  const sales = salesSelector.selectAll(salesState).filter(sale => sale.eventId == eventId);

  if (shouldFilter) {
    return sales.filter(sale => sale.totalLots - sale.totalWithdrawnLots > 0);
  } else {
    return sales;
  }
}

export function getSaleLotsCount(sale: Sale) {
  if (!sale) return 0;
  return sale.totalLots - sale.totalWithdrawnLots;
}

export function getSalesMap(sales?: Sale[]) {
  if (!Array.isArray(sales)) {
    return {};
  }
  return sales.reduce<Record<number, Sale>>(toMap("id"), {});
}

export function getSaleIdsFrom(lots?: Lot[]) {
  if (!Array.isArray(lots)) {
    return [];
  }
  return Array.from(new Set(lots.map(lot => lot.saleId)));
}

export function getSaleAssetSchemaId(saleAssetSchemaIds: any[] = [], assetSchemas: any[] = []) {
  const saleAssetSchemaId = saleAssetSchemaIds[0];
  const schema = assetSchemas.find(schema => schema.id === saleAssetSchemaId);
  if (schema) {
    return saleAssetSchemaId;
  }
}

export function getStatus(entity: Sale | Event) {
  const now = Date.now();
  if (entity.startTime <= now && now <= entity.endTime) {
    return "running";
  }
  if (entity.endTime <= now) {
    return "closed";
  }
  return "upcoming";
}
