import {
  MEDIAS_GET,
  MEDIAS_SET,
  MEDIA_SET,
  MEDIA_CREATE,
  MEDIA_DELETE,
  MEDIA_DELETE_COMPLETE,
  MEDIA_UPDATE,
  MEDIA_UPDATE_COMPLETE,
  MEDIAS_ASSET_SET
} from "actions/actionTypes";
import { createSelector } from "@reduxjs/toolkit";
import get from "lodash/get";
import { MEDIA_TYPE } from "utils/constants";
import uniqBy from "lodash/uniqBy";
import merge from "lodash/merge";
import { isCubedAms } from "./admin/inventory";
import { inventoryFetchComplete } from "actions/sharedActions";

export const defaultImgUrl = `${process.env.PUBLIC_URL}/images/inv-results-img.png`;
export const allowedMediaContentTypes = "image/jpg, image/jpeg, video/mp4, image/png";

export const allowedMediaFileExtensions = ["jpg", "jpeg", "mp4", "png"];

const { VIDEO, IMAGE_THREE_SIXTY, IMAGE_DOCUMENT, IMAGE_PANORAMA } = MEDIA_TYPE;

const MAX_BYTES = 104857600;

const initialState = {
  medias: [],
  entityMedia: {},
  areMediasLoading: false,
  isSaving: false
};

export default function medias(state = initialState, action) {
  switch (action.type) {
    case MEDIAS_GET:
      return {
        ...state,
        areMediasLoading: true
      };
    case inventoryFetchComplete.type:
    case MEDIAS_SET: {
      const { medias, shouldSetEntityType, shouldMerge } = action.payload;
      if (medias) {
        const sortedMedia = sortMedia(shouldMerge ? state.medias.concat(medias) : medias);
        return {
          ...state,
          medias: sortedMedia,
          entityMedia: reduceMediaByEntityId(sortedMedia, shouldSetEntityType),
          areMediasLoading: false
        };
      }
      return {
        ...state,
        areMediasLoading: false
      };
    }
    case MEDIA_SET: {
      const { id, media } = action.payload;
      const entityId = id == null ? getEntityMediaKey(media.entityId, media.entityType) : id;
      return {
        ...state,
        entityMedia: {
          ...state.entityMedia,
          [entityId]: [merge(state.entityMedia[entityId]?.[0] || {}, media)]
        }
      };
    }
    case MEDIA_CREATE:
    case MEDIA_DELETE:
      return {
        ...state,
        isSaving: true
      };

    case MEDIA_UPDATE:
      if (action.payload) {
        const { media: updatedMedia } = action.payload;

        const medias = state.medias.map(media => {
          if (media.id === updatedMedia.id) {
            return { ...media, ...updatedMedia };
          } else {
            return media;
          }
        });
        return {
          ...state,
          medias,
          entityMedia: reduceMediaByEntityId(medias)
        };
      } else {
        return {
          ...state,
          isSaving: true
        };
      }
    case MEDIA_DELETE_COMPLETE: {
      const mediaId = action.payload?.mediaId;

      if (!mediaId) {
        return {
          ...state,
          isSaving: false
        };
      }

      const medias = sortMedia(deleteMedia(state.medias, action.payload.mediaId));

      return {
        ...state,
        medias,
        entityMedia: reduceMediaByEntityId(medias),
        isSaving: false
      };
    }
    case MEDIA_UPDATE_COMPLETE:
      return {
        ...state,
        isSaving: false
      };

    case MEDIAS_ASSET_SET: {
      const { assetId, medias } = action.payload;
      if (assetId && medias) {
        return {
          ...state,
          areMediasLoading: false,
          medias: sortMedia(uniqBy([...state.medias, ...action.payload.medias], "id")),
          entityMedia: {
            ...state.entityMedia,
            [assetId]: medias
          }
        };
      }
      return {
        ...state,
        areMediasLoading: false
      };
    }

    default:
      return state;
  }
}

export const getEntityMediaKey = (entityId, entityType) => {
  return entityType == null ? entityId : `${entityType}/${entityId}`;
};

const getMedias = state => state.medias;

const getEntityMedia = (state, entityId) => state.entityMedia[entityId];

const deleteMedia = (medias, mediaId) => medias.filter(media => media.id !== mediaId);

export const makeGetPrimaryMediaId = () => getPrimaryMediaId;

export const getPrimaryMediaId = createSelector(
  getMedias,
  (_, assetId) => assetId,
  (medias, assetId) => {
    const media = medias.find(
      media => media.entityId === assetId && media.primary && media.entityType === "ASSET"
    );
    return get(media, "id", null);
  }
);

export function makeGetPrimaryMedia() {
  return createSelector(
    getMedias,
    (_, assetId) => assetId,
    (medias, assetId) => {
      return medias.find(
        media => media.entityId === assetId && media.primary && media.entityType === "ASSET"
      );
    }
  );
}

export const makeHasThreeSixtyImage = () =>
  createSelector(
    getMedias,
    (_, assetId) => assetId,
    (medias, assetId) =>
      Boolean(
        medias.find(
          media =>
            media.entityId === assetId &&
            [IMAGE_PANORAMA, IMAGE_THREE_SIXTY].includes(media.mediaType)
        )
      )
  );

export const makeHasVideo = () =>
  createSelector(
    getMedias,
    (_, assetId) => assetId,
    (medias, assetId) =>
      Boolean(medias.find(media => media.entityId === assetId && media.mediaType === VIDEO))
  );

export const makeHasImageDocument = () =>
  createSelector(getEntityMedia, entityMedias =>
    Boolean(entityMedias?.find(media => media.mediaType === IMAGE_DOCUMENT))
  );

function reduceMediaByEntityId(medias, shouldSetEntityType) {
  return medias.reduce((entityMedia, media) => {
    const entityMediaKey = shouldSetEntityType
      ? getEntityMediaKey(media.entityId, media.entityType)
      : media.entityId;

    if (entityMedia[entityMediaKey]) {
      entityMedia[entityMediaKey].push(media);
    } else {
      entityMedia[entityMediaKey] = [media];
    }
    return entityMedia;
  }, {});
}

export function isInventoryMediaEditable(sale, ams) {
  return isSaleStatusValid(sale) && isCubedAms(ams);
}

const saleStatus = ["STAGED", "SCHEDULED", "PRESALE", "ACTIVE"];

function isSaleStatusValid(sale) {
  return saleStatus.includes(sale?.status);
}

function sortMedia(media) {
  if (!media) return [];
  return media.sort((a, b) => {
    if (a.position === b.position) {
      return a.updatedOn - b.updatedOn;
    }
    return a.position - b.position;
  });
}

export function validateFileType(file, allowedFileExtensions) {
  if (!isValidFileType(file, allowedFileExtensions)) {
    throw new Error("Invalid file type");
  }
}

export function validateFileSize(file) {
  if (file.size > MAX_BYTES) {
    throw new Error("Max file size allowed is 100MB");
  }
}

function isValidFileType(file, allowedFileExtensions) {
  return allowedFileExtensions.includes(getFileExtension(file.name).toLowerCase());
}

function getFileExtension(fileName) {
  const fileParts = fileName.split(".");
  return fileParts[fileParts.length - 1] || "";
}

export function validateContentType(contentType, allowedContentTypes) {
  if (!isValidContentType(contentType, allowedContentTypes)) {
    throw new Error("Invalid content type");
  }
  return allowedContentTypes?.includes(contentType);
}

function isValidContentType(contentType, allowedContentTypes) {
  return allowedContentTypes?.includes(contentType);
}

export function findMedia(medias, mediaId) {
  return medias.find(media => media.id === mediaId);
}

export function isMediaTypeImageDocument(media) {
  return media?.mediaType === IMAGE_DOCUMENT;
}

export function getDocumentUrl(mediaList) {
  const media = mediaList?.[0];

  if (!media) return "";

  const url = media.external ? media.urls.source : media.urls.large;

  return url ?? "";
}
