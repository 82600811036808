import useCanBid from "./useCanBid";
import useCustomBid from "./useCustomBid";

function useMaxBid(lotId, saleId, saleUserId) {
  let { isActive, error } = useCanBid(lotId, saleId, saleUserId, "MAX");
  const maxBid = useCustomBid(lotId, saleId, saleUserId, "MAX", bid => ({
    amount: bid?.amount,
    id: bid?.id
  }));

  const { error: errorMessage, canBid } = getCanBid(error);

  return {
    isActive,
    canBid,
    error: errorMessage,
    maxBidAmount: maxBid?.amount,
    maxBidId: maxBid?.id
  };
}

function getCanBid(error) {
  if (error?.includes("max bid") || error === "You are the high bidder") {
    error = null;
  }
  return {
    canBid: !Boolean(error),
    error
  };
}

export { useMaxBid as default };
