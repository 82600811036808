import { REHYDRATE } from "redux-persist/lib/constants";
import {
  ADMIN_SET_ATTENDEES,
  ADMIN_SET_ATTENDEE_STATS,
  ADMIN_TOGGLE_ATTENDEE_SORT,
  ADMIN_SET_DEALERSHIPS_FILTER,
  ADMIN_UPDATE_SALEUSER_CREDIT_LIMIT,
  ADMIN_ATTENDEES_FETCH,
  ADMIN_ATTENDEES_SET_PAGE,
  ADMIN_TOGGLE_SALES_ID_FILTER,
  ADMIN_ATTENDEES_SET_FILTER
} from "actions/actionTypes";
import { createSort, createSortFromString, toggleSort } from "utils/SearchUtil";
import get from "lodash/get";
import startCase from "lodash/startCase";
import sortBy from "lodash/sortBy";
import { createSelector } from "@reduxjs/toolkit";
import { MARKET_SEGMENTS } from "utils/constants";
import { defaultIntlUtil } from "utils/IntlUtil";

const initialFilters = {
  isPublic: "",
  isLoggedIn: "",
  search: ""
};

const initialState = {
  sort: "+name",
  page: 0,
  total: 0,
  filters: initialFilters,
  attendees: [],
  dealershipFilter: "",
  areAttendeesLoading: true
};

export default function adminAttendees(state = initialState, action) {
  switch (action.type) {
    case REHYDRATE:
      if (action.payload) {
        return {
          ...state,
          ...action.payload.adminAttendees,
          attendeesVisitedEventId: null,
          attendees: [],
          attendeesTotal: 0,
          attendeesPage: 0,
          areAttendeesLoading: true
        };
      } else {
        return {
          ...state
        };
      }

    case ADMIN_TOGGLE_SALES_ID_FILTER:
      return {
        ...state,
        total: 0,
        page: 0
      };

    case ADMIN_ATTENDEES_SET_FILTER:
      return {
        ...state,
        page: 0,
        total: 0,
        filters: {
          ...state.filters,
          [action.payload.field]: action.payload.value
        }
      };

    case ADMIN_SET_DEALERSHIPS_FILTER:
      return {
        ...state,
        dealershipFilter: action.payload.filter
      };

    case ADMIN_ATTENDEES_FETCH:
      return {
        ...state,
        attendees: [],
        areAttendeesLoading: true
      };

    case ADMIN_ATTENDEES_SET_PAGE:
      return {
        ...state,
        page: action.payload
      };

    case ADMIN_SET_ATTENDEES:
      return {
        ...state,
        areAttendeesLoading: false,
        attendees: action.payload.attendees,
        total: action.payload.total
      };

    case ADMIN_SET_ATTENDEE_STATS: {
      return {
        ...state,
        attendees: state.attendees.map(attendee => {
          if (attendee.user.id === action.payload.user.id) {
            return action.payload;
          }

          return attendee;
        })
      };
    }

    // TODO: doesn't work at all rn, UI has no sorts enabled
    case ADMIN_TOGGLE_ATTENDEE_SORT:
      let currentSort = createSortFromString(state.sort);
      let sort =
        currentSort.field === action.payload.field
          ? createSort(action.payload.field, toggleSort(currentSort, action.payload))
          : createSort(action.payload.field, action.payload.order);
      return {
        ...state,
        sort
      };

    case ADMIN_UPDATE_SALEUSER_CREDIT_LIMIT: {
      const { pointsLimit, creditLimit, saleUserId, userId } = action.payload;
      return {
        ...state,
        attendees: state.attendees.map(attendee => {
          if (attendee.user.id === userId) {
            attendee.dealerships = attendee.dealerships.map(dealership => {
              if (dealership.saleUserId === saleUserId) {
                dealership.creditLimit = creditLimit;

                if (pointsLimit != null) {
                  dealership.pointsLimit = pointsLimit;
                }
              }

              return dealership;
            });
          }
          return attendee;
        })
      };
    }

    default:
      return state;
  }
}

function addressBuilder(fields) {
  const address = fields.address ? `${fields.address.trim()} ` : "";
  const city = fields.city ? `${fields.city.trim()}` : "";
  const state = fields.state ? `, ${fields.state.trim()} ` : "";
  const zip = fields.zip ? `${fields.zip.trim()} ` : "";
  const country = fields.country ? `${fields.country.trim()}` : "";
  return address + city + state + zip + country;
}

const defaultFormatAttendeeOptions = {
  marketSegment: MARKET_SEGMENTS.AUTOMOTIVE,
  formatter: defaultIntlUtil
};

export const formatAttendee = ({
  user,
  isPublic,
  unitsWithBids,
  dealerships,
  bidsStats,
  lotsStats,
  hasPaid,
  extraFields,
  options = defaultFormatAttendeeOptions
}) => {
  const publicDealerField =
    options.marketSegment === MARKET_SEGMENTS.AUTOMOTIVE
      ? { "public/dealer": isPublic ? "Public" : "Dealer" }
      : {};
  const formattedAttendee = {
    id: user.id,
    chatLogs: user.id ? "Chat Logs" : "",
    name: startCase((user?.name ?? "").toLowerCase()),
    ...publicDealerField,
    email: (user?.email ?? "").toLowerCase(),
    primaryPhone: user?.fields?.primaryPhone || "not available",
    address: addressBuilder(user?.fields) || "",
    dealerships: dealerships.length || 0,
    unitsWithBids: unitsWithBids || 0,
    bidsPlaced: bidsStats.quickBids.count + bidsStats.buyNowBids.count + bidsStats.maxBids.count,
    unitsPurchased:
      lotsStats.internetSold.count + lotsStats.proxySold.count + lotsStats.floorSold.count,
    amountPurchased: options.formatter.formatNumber(
      lotsStats.internetSold.amount + lotsStats.proxySold.amount + lotsStats.floorSold.amount,
      { style: "currency" }
    ),
    unitsPending:
      lotsStats.internetIfSold.count + lotsStats.proxyIfSold.count + lotsStats.floorIfSold.count,
    amountPending: options.formatter.formatNumber(
      lotsStats.internetIfSold.amount + lotsStats.proxyIfSold.amount + lotsStats.floorIfSold.amount,
      { style: "currency" }
    ),
    hasPaid,
    companyId: user?.companyId,
    userId: user.id,
    ...extraFields
  };
  return formattedAttendee;
};

export const formatDealership = ({
  name,
  dealershipName,
  creditLimit,
  pointsLimit,
  saleUserId,
  unitsWithBids,
  bidsStats,
  lotsStats,
  userId
}) => ({
  name,
  userId,
  dealershipName,
  creditLimit,
  pointsLimit,
  saleUserId,
  unitsWithBids: unitsWithBids || 0,
  bidsPlaced: bidsStats.quickBids.count + bidsStats.buyNowBids.count + bidsStats.maxBids.count,
  unitsPurchased:
    lotsStats.internetSold.count + lotsStats.proxySold.count + lotsStats.floorSold.count,
  amountPurchased:
    lotsStats.internetSold.amount + lotsStats.proxySold.amount + lotsStats.floorSold.amount,
  unitsPending:
    lotsStats.internetIfSold.count + lotsStats.proxyIfSold.count + lotsStats.floorIfSold.count,
  amountPending:
    lotsStats.internetIfSold.amount + lotsStats.proxyIfSold.amount + lotsStats.floorIfSold.amount
});

const getDealershipFilter = state => state.dealershipFilter;

export const getDealershipsByUserId = (state, userId) =>
  get(
    state.attendees.find(attendee => attendee.user.id === userId),
    "dealerships",
    []
  );

export const getFilteredDealerships = createSelector(
  getDealershipFilter,
  getDealershipsByUserId,
  (filter, dealerships) =>
    sortBy(
      dealerships.filter(
        dealership => dealership.dealershipName.toLowerCase().includes(filter.toLowerCase()),
        "name"
      )
    )
);

export const getAttendeeById = (attendees, userId) =>
  get(
    attendees.find(attendee => parseInt(attendee.user.id, 10) === parseInt(userId, 10)),
    "user",
    null
  );

export const getIsAttendeeOnline = (dealerships = []) => {
  const dealership = dealerships.find(dealership => dealership.hasLoggedIn);

  if (!dealership) {
    return;
  }

  return dealership.isLoggedIn;
};
