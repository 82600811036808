import moment from "moment";
import { Link } from "react-router-dom";
import { makeStyles } from "tss-react/mui";
import { Box, Divider, Stack, Typography } from "@mui/material";

import { getExternalLinks } from "reducers/config";
import { useSelector } from "utils/StoreUtil";

const currentYear = moment().format("YYYY");

const useStyles = makeStyles()(theme => ({
  hideOnPrint: {
    zIndex: 1,
    backgroundColor: theme.palette.background.default,
    "@media print": {
      display: "none"
    }
  },
  root: {
    padding: theme.spacing(1),
    [theme.breakpoints.down("md")]: {
      paddingBottom: theme.spacing(4)
    }
  }
}));

function FooterLink({ children, href }) {
  const isExternal = href?.startsWith("http");

  const linkProps = isExternal
    ? {
        component: "a",
        target: "_blank",
        href
      }
    : {
        component: Link,
        to: href
      };

  return (
    <Typography
      {...linkProps}
      color="primary"
      variant="subtitle1"
      sx={{
        textDecoration: "none",
        "&:hover": {
          color: "primary.dark",
          cursor: "pointer",
          textDecoration: "underline"
        }
      }}
    >
      {children}
    </Typography>
  );
}

function FooterV2() {
  const { classes } = useStyles();

  const hasHelpUrl = useSelector(state => Boolean(state.config.helpUrl));

  const externalLinks = useSelector(state => getExternalLinks(state.config));

  return (
    <footer className={classes.hideOnPrint}>
      <Divider />

      <Stack
        className={classes.root}
        direction={{ xs: "column", md: "row" }}
        justifyContent="space-between"
        spacing={2}
      >
        <Stack
          direction={{ xs: "column", md: "row" }}
          alignItems="center"
          spacing={1}
          divider={<Box sx={{ display: { xs: "none", md: "block" } }}>&bull;</Box>}
        >
          {externalLinks.map(link => (
            <FooterLink key={link.key} href={link.link}>
              {link.key}
            </FooterLink>
          ))}

          {hasHelpUrl && <FooterLink href="/help">Help</FooterLink>}

          <FooterLink href="https://www.xcira.com/cookie-policy/">Cookie Policy</FooterLink>

          <FooterLink href="https://www.xcira.com/privacy-policy/">Privacy Policy</FooterLink>
        </Stack>

        <Stack direction={{ xs: "column", md: "row" }} alignItems="center" spacing={1}>
          <Typography color="textSecondary" variant="subtitle1">
            &copy; Xcira & Cubed Community {currentYear}
          </Typography>

          <Typography color="textSecondary" variant="caption">
            v{process.env.REACT_APP_VERSION}
          </Typography>
        </Stack>
      </Stack>
    </footer>
  );
}

export default FooterV2;
