export default function matchesPermissions(requiredPermissions = [], permissions = []) {
  if (requiredPermissions.length === 0) {
    return true;
  }
  return requiredPermissions.every(requiredPermission =>
    matchesPermission(requiredPermission, permissions)
  );
}

function matchesPermission(requiredPermission, permissions) {
  if (typeof requiredPermission === "string") {
    return matchesAppPermission(requiredPermission, permissions);
  } else {
    return matchesEntityPermissions(requiredPermission, permissions);
  }
}

function matchesAppPermission(requiredPermission, permissions) {
  return permissions.includes(requiredPermission);
}

function matchesEntityPermissions(requiredPermission, permissions) {
  let matchingPermission = permissions.find(
    permission => requiredPermission.entity === permission.entity
  );
  return !!matchingPermission && matchesOperations(requiredPermission, matchingPermission);
}

function matchesOperations(requiredPermission, permission) {
  return requiredPermission.operations.every(operation =>
    permission.operations.includes(operation)
  );
}
