import { FC, PropsWithChildren } from "react";

const HtmlContainer: FC<PropsWithChildren> = ({ children }) => {
  return (
    <html>
      <head>
        <style>{"@page {size: landscape;}"}</style>
      </head>
      <body>{children}</body>
    </html>
  );
};

export default HtmlContainer;
