import { get, put, getTotal } from "./api";

export async function getInventoryInteractions(filters, range, sort) {
  const { data } = await get("application/community/interactions/inventory", filters, range, sort);

  return data;
}

export async function getInteraction(interactionId) {
  const { data } = await get(`interaction/${interactionId}`);

  return data;
}

export async function getInteractions(filters, range, sort) {
  const res = await get("interaction", filters, range, sort);

  return { data: res.data, total: getTotal(res) };
}

export async function updateInteractionField(interactionId, fieldName) {
  try {
    await put(`interaction/${interactionId}/field/${fieldName}`);
  } catch (error) {}
}
