import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";

import { Media } from "types/Media";

type SimilarItem = {
  lotId: number;
  assetId: number;
  media: Media;
  companyName: string | undefined;
  title: string | undefined;
  labels: any;
};

const similarItemsAdapter = createEntityAdapter<SimilarItem, number>({
  selectId: recommendItem => recommendItem.lotId
});

const similarItemsSlice = createSlice({
  name: "similarItems",
  initialState: similarItemsAdapter.getInitialState(),
  reducers: {
    setSimilarItems: (state, action) => {
      similarItemsAdapter.setAll(state, action.payload);
    },
    clearSimilarItems: state => {
      return similarItemsAdapter.getInitialState();
    }
  }
});

export const { setSimilarItems, clearSimilarItems } = similarItemsSlice.actions;

export default similarItemsSlice.reducer;
