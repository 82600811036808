import { LANGUAGE_FETCH_COMPLETE } from "./actionTypes";
import { getLanguageMap } from "api/configRequests";

export function fetchLanguageMap(selectedLanguage, marketSegment) {
  return async dispatch => {
    let languageMap;
    try {
      languageMap = await getLanguageMap(selectedLanguage, marketSegment);
    } finally {
      dispatch({ type: LANGUAGE_FETCH_COMPLETE, payload: languageMap || {} });
    }
  };
}
