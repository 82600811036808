import { ACTIVITY_SET_EVENT_FILTER_OPTION } from "actions/actionTypes";
import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  events: []
};

const activityFilterOptions = createReducer(initialState, builder => {
  builder.addCase(ACTIVITY_SET_EVENT_FILTER_OPTION, (state, action) => {
    state.events = action.payload.filterOption;
  });
});

export default activityFilterOptions;
