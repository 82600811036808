import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";

import Asset from "types/Asset";
import { filterAnnouncements, isAnnouncementTypeCustom } from "./announcements";
import { ASSET_SCHEMAS_FETCH_COMPLETE } from "actions/actionTypes";
import { inventoryFetch, inventoryFetchComplete } from "actions/sharedActions";

const assetsAdapter = createEntityAdapter<Asset>();

export const assetsSelector = assetsAdapter.getSelectors();

export const initialState = assetsAdapter.getInitialState({
  sort: "-createdOn",
  schemas: [],
  isLoading: false,
  page: 0,
  total: 0
});

const assetsSlice = createSlice({
  name: "assets",
  initialState,
  reducers: {
    addAnnouncement: (state, action) => {
      const { assetId, announcement } = action.payload;
      const asset = assetsSelector.selectById(state, assetId);
      if (asset) {
        const assetAnnouncements = asset.assetAnnouncements || [];

        assetsAdapter.updateOne(state, {
          id: assetId,
          changes: {
            assetAnnouncements: assetAnnouncements.concat(announcement)
          }
        });
      }
    },
    deleteAnnouncement: (state, action) => {
      const { assetId, announcement, index } = action.payload;
      const asset = assetsSelector.selectById(state, assetId);
      if (asset) {
        assetsAdapter.updateOne(state, {
          id: assetId,
          changes: {
            assetAnnouncements: asset.assetAnnouncements?.filter(
              filterAnnouncements(announcement, index)
            )
          }
        });
      }
    },
    editAnnouncement: (state, action) => {
      const { assetId, announcement: editedAnnouncement } = action.payload;
      const asset = assetsSelector.selectById(state, assetId);

      const edited = asset.assetAnnouncements?.map((announcement, index) => {
        if (announcement.announcementNumber) {
          if (editedAnnouncement.announcementNumber === announcement.announcementNumber) {
            return editedAnnouncement;
          }
        } else {
          if (index === action.payload.index) {
            return editedAnnouncement;
          }
        }
        return announcement;
      });

      assetsAdapter.updateOne(state, {
        id: assetId,
        changes: {
          assetAnnouncements: edited
        }
      });
    },
    addCannedAnnouncements: (state, action) => {
      const { assetId, announcements } = action.payload;
      const asset = assetsSelector.selectById(state, assetId);
      if (asset) {
        assetsAdapter.updateOne(state, {
          id: assetId,
          changes: {
            assetAnnouncements: announcements.concat(
              asset.assetAnnouncements?.filter(announcement =>
                isAnnouncementTypeCustom(announcement)
              )
            )
          }
        });
      }
    },
    setField: (state, action) => {
      const { assetId, prop, value } = action.payload;
      const asset = assetsSelector.selectById(state, assetId);
      if (asset) {
        assetsAdapter.updateOne(state, {
          id: assetId,
          changes: {
            fields: {
              ...asset.fields,
              [prop]: value
            }
          }
        });
      }
    },
    fetch: (state, action) => {
      state.isLoading = true;
    },
    fetchComplete: (state, action) => {
      const { total, assets } = action.payload;
      state.isLoading = false;
      state.total = total;
      assetsAdapter.setAll(state, assets);
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setSort: (state, action) => {
      state.sort = action.payload;
    },
    upsert: (state, action) => {
      assetsAdapter.upsertOne(state, action.payload);
    }
  },
  extraReducers: builder => {
    builder
      .addCase(ASSET_SCHEMAS_FETCH_COMPLETE, (state, action) => {
        // @ts-ignore
        state.schemas = action.payload;
      })
      .addCase(inventoryFetch, (state, action) => {
        state.isLoading = true;
      })
      .addCase(inventoryFetchComplete, (state, action) => {
        state.isLoading = false;
        if (action.payload.assets) {
          assetsAdapter.setAll(state, action.payload.assets);
        }
      });
  }
});

const { actions, reducer } = assetsSlice;

export const {
  addAnnouncement,
  deleteAnnouncement,
  editAnnouncement,
  addCannedAnnouncements,
  fetch: fetchAssets,
  fetchComplete: fetchAssetsComplete,
  setPage: setAssetsPage,
  setSort: setAssetsSort,
  upsert,
  setField
} = actions;

export default reducer;
