import { useEffect, useState } from "react";
import GoogleAnalytics from "react-ga";
import { useSelector } from "utils/StoreUtil";
import { useLocation } from "react-router-dom";

const trackPage = page => {
  GoogleAnalytics.set({ page });
  GoogleAnalytics.pageview(page);
};

export const trackEvent = event => {
  if (process.env.NODE_ENV !== "test") {
    GoogleAnalytics.event(event);
  }
};

export const initialize = googleAnalyticsKey => GoogleAnalytics.initialize(googleAnalyticsKey);

export const trackError = (description, fatal = true) => {
  if (process.env.NODE_ENV !== "test") {
    GoogleAnalytics.exception({ description, fatal });
  }
};

export const trackSearch = (filters, page) => {
  if (process.env.NODE_ENV !== "test") {
    const searchString = filters
      .filter(filter => filter.field !== "sale.endTime")
      .map(({ filterType: type, field, value, values, equalityType }) => {
        switch (type) {
          case "QUERY":
            return value;
          case "TERM":
            return `${field}:${value}`;
          case "TERMS":
            return `${field}:${values.join(",")}`;
          case "RANGE":
            return `${field}|${equalityType}|${value}`;
          default:
            return "";
        }
      })
      .join(",");
    if (searchString.length > 0) trackPage(`${page}?f=${searchString}`);
  }
};

const useTracking = () => {
  const { pathname: page, hash } = useLocation();

  const googleAnalyticsKey = useSelector(state => state.config.googleAnalyticsKey);
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    if (googleAnalyticsKey && !isInitialized) {
      initialize(googleAnalyticsKey);
      setIsInitialized(true);
    }
  }, [googleAnalyticsKey, isInitialized]);

  useEffect(() => {
    if (isInitialized) {
      const pageName = page.replace(/\/\d+/g, "/:id") + hash;
      trackPage(pageName);
    }
  }, [isInitialized, page, hash]);
};

export default useTracking;
