import React from "react";
import { withStyles } from "tss-react/mui";
import Grid from "@mui/material/Grid";

const styles = theme => ({
  root: {
    textAlign: "center",
    margin: "auto",
    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(5)
    },
    [theme.breakpoints.down("lg")]: {
      margin: [[theme.spacing(3), theme.spacing(2), 0]]
    }
  },
  title: {
    marginBottom: theme.spacing(3),
    color: theme.palette.secondary.main,
    fontWeight: "bold"
  }
});

const UpcomingAuctionsText = ({ classes, total }) => (
  <Grid item xs={12} sm={12} md={12} lg={8} className={classes.root}>
    <h1 className={classes.title}>{total} Upcoming Auctions</h1>
  </Grid>
);

export default withStyles(UpcomingAuctionsText, styles);
