import {
  ADMIN_FETCH_CONSIGNOR_REPORT,
  ADMIN_SET_CONSIGNOR_REPORT,
  ADMIN_CONSIGNOR_REPORT_TOGGLE_CONSIGNOR,
  ADMIN_CONSIGNOR_REPORT_TOGGLE_LANE,
  ADMIN_FETCH_CONSIGNORS,
  ADMIN_FETCH_CONSIGNORS_COMPLETE,
  ADMIN_CONSIGNOR_REPORT_TOGGLE_SELECT_ALL_CONSIGNORS,
  ADMIN_CONSIGNOR_REPORT_TOGGLE_SELECT_ALL_LANES
} from "actions/actionTypes";
import { createReducer } from "@reduxjs/toolkit";
import { formatNumber } from "utils/NumberUtil";
import { formatCurrency } from "utils/CurrencyUtil";

import { createSelector } from "@reduxjs/toolkit";
import moment from "moment";
import { formatDateTimeZone } from "utils/DateUtil";

const initialState = {
  summary: {},
  report: {},
  selectedLanes: [],
  areConsignorsLoading: true,
  consignors: [],
  selectedConsignorNumbers: [],
  isLoading: true
};

export default createReducer(initialState, builder => {
  builder
    .addCase(ADMIN_FETCH_CONSIGNOR_REPORT, state => {
      state.isLoading = true;
    })
    .addCase(ADMIN_FETCH_CONSIGNORS, state => {
      state.consignors = [];
      state.areConsignorsLoading = true;
    })
    .addCase(ADMIN_FETCH_CONSIGNORS_COMPLETE, (state, action) => {
      state.consignors = action.payload.consignors;
      state.areConsignorsLoading = false;
    })
    .addCase(ADMIN_SET_CONSIGNOR_REPORT, (state, action) => {
      state.isLoading = false;

      state.report = action.payload.report;
      state.summary = action.payload.summary;

      // TODO: set default selection
    })
    .addCase(ADMIN_CONSIGNOR_REPORT_TOGGLE_CONSIGNOR, (state, action) => {
      if (state.selectedConsignorNumbers.includes(action.payload.consignorNumber)) {
        state.selectedConsignorNumbers = state.selectedConsignorNumbers.filter(
          consignorNumber => consignorNumber !== action.payload.consignorNumber
        );
      } else {
        state.selectedConsignorNumbers.push(action.payload.consignorNumber);
      }
    })
    .addCase(ADMIN_CONSIGNOR_REPORT_TOGGLE_LANE, (state, action) => {
      if (state.selectedLanes.includes(action.payload.saleNumber)) {
        state.selectedLanes = state.selectedLanes.filter(
          lane => lane !== action.payload.saleNumber
        );
      } else {
        state.selectedLanes.push(action.payload.saleNumber);
      }
    })
    .addCase(ADMIN_CONSIGNOR_REPORT_TOGGLE_SELECT_ALL_CONSIGNORS, state => {
      const { consignors, selectedConsignorNumbers } = state;
      if (consignors.length === selectedConsignorNumbers.length && consignors.length > 0) {
        state.selectedConsignorNumbers = [];
      } else {
        state.selectedConsignorNumbers = consignors.map(consignor => consignor.consignorNumber);
      }
    })
    .addCase(ADMIN_CONSIGNOR_REPORT_TOGGLE_SELECT_ALL_LANES, (state, action) => {
      const { lanes } = action.payload;
      const { selectedLanes } = state;
      if (lanes.length === selectedLanes.length && lanes.length > 0) {
        state.selectedLanes = [];
      } else {
        state.selectedLanes = lanes.map(lanes => lanes.saleNumber);
      }
    });
});

const getReport = state => state.report;
const getConsignors = state => state.consignors;
const getSelectedConsignorNumbers = state => state.selectedConsignorNumbers;
const getLanes = (state, lanes) => lanes;
const getSelectedLanes = state => state.selectedLanes;
const getLocale = (state, lanes, locale) => locale;

export const getFormattedConsignorReport = createSelector(
  getReport,
  getConsignors,
  getSelectedConsignorNumbers,
  getLanes,
  getSelectedLanes,
  getLocale,
  (report, consignors, selectedConsignorNumbers, lanes, selectedLanes, locale) =>
    formatConsignorReport(
      report,
      consignors,
      selectedConsignorNumbers,
      lanes,
      selectedLanes,
      locale
    )
);

export function formatConsignorReport(
  report,
  consignors,
  selectedConsignorNumbers,
  lanes,
  selectedLanes,
  locale
) {
  if (!report) return [];
  const formattedConsignors = getFormattedConsignors(consignors, selectedConsignorNumbers);
  const formattedLanes = getFormattedLanes(lanes, selectedLanes);
  return [
    {
      label: report.sellerName ? "Consignor" : "Consignors",
      value: report.sellerName || formattedConsignors.join(", ")
    },
    {
      label: report.physicalLane ? "Sale" : "Sales",
      value: report.physicalLane || formattedLanes.join(", ")
    },
    {
      label: "Date",
      value: report.eventNumber && report.eventNumber.replace(/\D/g, "")
    },
    { label: "Registered On-Site Users", value: formatNumber(report.floorBadges) },
    { label: "Registered Online Users", value: formatNumber(report.onlineBadges) },
    { label: "Total Registered Users", value: formatNumber(report.totalBadges) },
    { label: "Online Attendees", value: formatNumber(report.onlineAttendees) },
    { label: "Total Online Bidders", value: formatNumber(report.totalOnlineBidders) },
    {
      label: "Total Online Buyers",
      value: formatNumber(report.uniqueBuyerBadgesOnlineCount)
    },
    { label: "Community Watch List Adds", value: formatNumber(report.watchCount) },
    { label: "Total Floor Sales", value: formatCurrency(report.unitsSoldFloorGross) },
    { label: "Total Online Sales", value: formatCurrency(report.unitsSoldOnlineGross) },
    { label: "Total Sales", value: formatCurrency(report.unitsSoldGross) },
    { label: "First Bid At", value: getFormattedTime(report.firstBidTimestamp, locale) },
    { label: "Last Bid At", value: getFormattedTime(report.lastBidTimestamp, locale) },
    { label: "Length of Sale", value: getFormattedDuration(report.lengthOfSale) },
    { label: "Number of Units", value: formatNumber(report.unitsRun) },
    { label: "Total Units If'd", value: formatNumber(report.unitsIfTotal) },
    { label: "Total Units If'd Online", value: formatNumber(report.unitsIfOnline) },
    { label: "Total Units Sold", value: formatNumber(report.unitsSoldCount) },
    { label: "Total Units Sold Online", value: formatNumber(report.unitsSoldOnlineCount) },
    {
      label: "Total Units Sold to Dealers",
      value: formatNumber(report.unitsSoldNotPublicCount)
    },
    {
      label: "Total Units Sold to Public Users",
      value: formatNumber(report.unitsSoldPublicCount)
    },
    { label: "Total Floor Ifs", value: formatNumber(report.unitsIfFloor) },
    { label: "Total Online Ifs", value: formatNumber(report.unitsIfOnline) },
    { label: "Total Ifs", value: formatNumber(report.unitsIfTotal) },
    { label: "Units With Impact Bids", value: formatNumber(report.unitsWithImpactBids) },
    { label: "Units With Online Bids", value: formatNumber(report.unitsWithOnlineBids) },
    { label: "Units With Proxy Bids", value: formatNumber(report.unitsWithProxyBids) },
    { label: "Unique Prebids", value: formatNumber(report.uniquePrebids) }
  ];
}

function getFormattedConsignors(consignors, selectedConsignorNumbers) {
  if (selectedConsignorNumbers.length > 0) {
    return consignors
      .filter(consignor => selectedConsignorNumbers.includes(consignor.consignorNumber))
      .map(consignor => consignor.name);
  }

  return consignors.map(consignor => consignor.name);
}

function getFormattedLanes(lanes, selectedLanes) {
  if (selectedLanes.length > 0) {
    return lanes.filter(lane => selectedLanes.includes(lane.saleNumber)).map(lane => lane.name);
  }

  return lanes.map(lane => lane.name);
}

function getFormattedTime(timestamp, locale) {
  return timestamp ? formatDateTimeZone(timestamp, locale) : "-";
}

function getFormattedDuration(timestamp) {
  if (timestamp) {
    const duration = moment.duration(timestamp);

    return `${duration.hours()} hours ${duration.minutes()} minutes`;
  }

  return "-";
}
