import { useSelector } from "utils/StoreUtil";

/**
 *
 * @param {String} marketSegments - any number of strings
 * @returns {Boolean} Boolean
 *
 * @description
 * Used to determine if something should show depending on which market segment the company is configured for
 *
 * @example
 * // single market
 * const isVisible = useMarketVisibility("automotive");
 * // multiple markets
 * const isVisible = useMarketVisibility("automotive", "bloodStock", "cattle");
 *
 * // ex 1
 * const value = isVisible ? "data" : null;
 * // ex 2
 * {isVisible && <Component />}
 * // ex 3
 * function Component() {
 *  const showComponent = useMarketVisibility("automotive");
 *
 *  if (!showComponent) return null;
 *
 *  // ...
 * }
 *
 */

export const getMarketVisibility = (currentSegment: string, marketSegments: string[]) => {
  for (const segment of marketSegments) {
    if (segment === currentSegment) {
      return true;
    }
  }

  return false;
};

export default function useMarketVisibility(...marketSegments: string[]) {
  const currentSegment = useSelector((state: any) => state.config.marketSegment);

  return getMarketVisibility(currentSegment, marketSegments);
}
