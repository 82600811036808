import cometd, { Message, SubscriptionHandle } from "cometd";

import CookieUtil from "utils/CookieUtil";
import { getHosts } from "api/cometRequests";

type CometClientState = {
  /** */
  connected: boolean;
  /** */
  hosts: string[];
  /** */
  subscriptions: { [channel: string]: SubscriptionHandle };
  /** */
  unsubscribeRequests: { [channel: string]: NodeJS.Timeout };
  /** */
  hostRetries: number;
  /** */
  maxHostRetries: number;
  /** */
  heartbeatFailures: number;
  /** */
  maxHeartbeatFailures: number;
};

class CometClient {
  /** */
  private cometd;
  /** */
  onConnect = () => {};
  /** */
  onReconnect = () => {};
  /** */
  onDisconnect = () => {};
  /** */
  onMessage = (msg: Message) => {};
  /** */
  onSubscribe = (channel: string) => {};
  /** */
  onUnsubscribe = (channel: string) => {};

  private state: CometClientState = {
    connected: true,
    hosts: [],
    subscriptions: {},
    unsubscribeRequests: {},
    hostRetries: 0,
    maxHostRetries: 3,
    heartbeatFailures: 0,
    maxHeartbeatFailures: 5
  };

  constructor() {
    this.cometd = new cometd.CometD();

    this.cometd.addListener("/meta/handshake", this._handshakeListener);
    this.cometd.addListener("/meta/connect", this._heartbeatListener);
  }

  init = async () => {
    await this.fetchHosts();
    this.connect();
  };

  bounce = () => {
    this.disconnect();
    setTimeout(() => this.connect(), 2000);
  };

  subscribe = (channel: string) => {
    const state = this.state;
    const cometd = this.cometd;

    if (channel in state.unsubscribeRequests) {
      clearTimeout(state.unsubscribeRequests[channel]);
      delete state.unsubscribeRequests[channel];
    }

    if (!(channel in state.subscriptions)) {
      if (cometd.isDisconnected()) {
        setTimeout(() => {
          this.subscribe(channel);
        }, 100);
        return;
      }

      this.onSubscribe?.(channel);
      state.subscriptions[channel] = cometd.subscribe(channel, this.onMessage);
    }
  };

  unsubscribe = (channel: string) => {
    const state = this.state;
    const cometd = this.cometd;

    if (!cometd.isDisconnected()) {
      state.unsubscribeRequests[channel] = setTimeout(() => {
        cometd.unsubscribe(state.subscriptions[channel]);
        delete state.subscriptions[channel];
        this.onUnsubscribe?.(channel);
      }, 1500);
    }
  };

  private resubscribe = () => {
    const state = this.state;
    const cometd = this.cometd;

    for (const channel in state.subscriptions) {
      state.subscriptions[channel] = cometd.subscribe(channel, this.onMessage);
    }
  };

  private fetchHosts = async () => {
    if (this.state.hosts.length < 1) {
      this.state.hosts = await getHosts();
    }
  };

  private rotateHosts = () => {
    const host = this.state.hosts.shift();
    if (host) {
      this.state.hosts.push(host);
    }
  };

  private connect = () => {
    const state = this.state;
    const cometd = this.cometd;

    if (cometd.isDisconnected()) {
      cometd.configure({
        url: state.hosts[0],
        autoBatch: true
      });

      const callback = () => this.onConnect?.();
      const credentials = CookieUtil.load("CompanyXD");

      if (credentials) {
        cometd.handshake({ Credential: JSON.parse(credentials) }, callback);
      } else {
        cometd.handshake(callback);
      }
    }
  };

  private disconnect = () => {
    const state = this.state;
    const cometd = this.cometd;

    if (!cometd.isDisconnected()) {
      for (const channelKey in state.unsubscribeRequests) {
        clearTimeout(state.unsubscribeRequests[channelKey]);
      }

      cometd.clearSubscriptions();
      state.subscriptions = {};
      state.unsubscribeRequests = {};

      cometd.disconnect(() => {});

      state.hostRetries = 0;
      state.heartbeatFailures = 0;
      state.maxHeartbeatFailures = 5;
    }
  };

  private _handshakeListener = (handshake: Message): void => {
    const state = this.state;

    if (!handshake.successful) {
      state.connected = false;
      state.hostRetries++;

      if (state.hostRetries <= state.hosts.length) {
        this.rotateHosts();
        setTimeout(this.connect, 2000);
      }
    }
  };

  private _heartbeatListener = (message: Message): void => {
    const state = this.state;
    const cometd = this.cometd;

    if (cometd.isDisconnected()) {
      return;
    }

    const wasConnected = state.connected;
    state.connected = message.successful;

    if (message.successful) {
      if (!wasConnected) {
        state.heartbeatFailures = 0;
        state.maxHeartbeatFailures = 5;
        this.onReconnect?.();
        this.resubscribe();
      }
    } else {
      state.heartbeatFailures++;

      if (
        state.heartbeatFailures >= state.maxHeartbeatFailures &&
        state.hostRetries <= state.hosts.length
      ) {
        // try another host
        this.rotateHosts();
        this.connect();
        state.maxHeartbeatFailures = Math.ceil(state.maxHeartbeatFailures / 2);
      }
      if (wasConnected) {
        this.onDisconnect?.();
      }
    }
  };
}

export default CometClient;
