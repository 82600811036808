import { useState, useRef, useCallback } from "react";
import { makeStyles } from "tss-react/mui";
import { Box, ClickAwayListener, Typography, TypographyVariant } from "@mui/material";
import { ClippedTypography } from "./typography/ClippedTypography";
import { variantMapping } from "styles/theme";
import { useTypographyHeight } from "hooks/theme/useTypographyHeight";

const typographyProps = {
  color: "textSecondary",
  variantMapping
};

const StyledTypography: React.FC<{ lines?: number; text: string; variant: TypographyVariant }> = ({
  lines,
  text,
  variant
}) => {
  if (Boolean(lines)) {
    return (
      <ClippedTypography lines={lines!} {...typographyProps} variant={variant}>
        {text}
      </ClippedTypography>
    );
  }

  return (
    <Typography {...typographyProps} variant={variant}>
      {text}
    </Typography>
  );
};

const useStyles = makeStyles()(theme => ({
  linkText: {
    cursor: "pointer",
    textDecoration: "underline",
    color: theme.palette.info.main
  },
  hiddenBlock: {
    visibility: "hidden"
  }
}));

const ReadMoreContainer: React.FC<{
  maxLines: number;
  text: string;
  moreText?: string;
  lessText?: string;
  typographyVariant?: TypographyVariant;
}> = ({
  maxLines = 2,
  text = "",
  moreText = "Read More",
  lessText = "Less",
  typographyVariant = "body2"
}) => {
  const { classes } = useStyles();

  const [elementHeight, setElementHeight] = useState(0);

  const resizeObserver = useRef(
    new ResizeObserver(entries => {
      const entry = entries[0];
      setElementHeight(entry.contentRect.height || 0);
    })
  );

  const setElementRef = useCallback((element: HTMLElement) => {
    if (element !== null) {
      resizeObserver.current.observe(element);
    } else {
      if (resizeObserver.current) {
        resizeObserver.current.disconnect();
      }
    }
  }, []);

  const [showExtraContent, setShowExtraContent] = useState(false);

  function handleClickAway() {
    if (showExtraContent) {
      setShowExtraContent(false);
    }
  }

  const typographyHeight = useTypographyHeight(typographyVariant);

  const clippedHeight = typographyHeight * maxLines;

  const hasExtraContent = elementHeight > clippedHeight;

  const shouldClip = hasExtraContent && !showExtraContent;

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div>
        <Box
          sx={{
            maxHeight: shouldClip ? clippedHeight : elementHeight,
            overflow: "hidden"
          }}
        >
          <StyledTypography
            lines={shouldClip ? maxLines : 0}
            text={text}
            variant={typographyVariant}
          />

          {/* Used to determine the height of the block of text */}
          {/* careful causing the height to change by state, can cause infinite updates */}
          <Box className={classes.hiddenBlock} ref={setElementRef}>
            <StyledTypography text={text} variant={typographyVariant} />
          </Box>
        </Box>

        {shouldClip && (
          <Typography
            className={classes.linkText}
            component="span"
            variant={typographyVariant}
            variantMapping={variantMapping}
            onClick={() => setShowExtraContent(true)}
          >
            {moreText}
          </Typography>
        )}

        {hasExtraContent && !shouldClip && (
          <Typography
            className={classes.linkText}
            component="span"
            variant={typographyVariant}
            variantMapping={variantMapping}
            onClick={() => setShowExtraContent(false)}
          >
            {lessText}
          </Typography>
        )}
      </div>
    </ClickAwayListener>
  );
};

export { ReadMoreContainer as default, ReadMoreContainer };
