import React, { useState, useRef } from "react";
import { IconButton, Menu, MenuItem } from "@mui/material";
import MoreIcon from "@mui/icons-material/MoreHoriz";

function defaultGetMenuItemValue(item) {
  return item;
}

function defaultGetMenuItemLabel(item) {
  return item;
}

function SimpleButtonAndMenu(props) {
  const {
    ButtonComponent = IconButton,
    buttonProps,
    buttonInnerContent = <MoreIcon />,
    menuProps,
    children,
    menuItems,
    selectedMenuItem,
    menuItemClassName,
    getMenuItemValue = defaultGetMenuItemValue,
    getMenuItemLabel = defaultGetMenuItemLabel,
    onMenuItemClick
  } = props;

  const anchorELRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const hasChildren = Boolean(children);

  function open() {
    setIsOpen(true);
  }

  function close() {
    setIsOpen(false);
  }

  return (
    <>
      <ButtonComponent onClick={open} ref={anchorELRef} {...buttonProps}>
        {buttonInnerContent}
      </ButtonComponent>
      <Menu
        anchorEl={anchorELRef.current}
        open={isOpen}
        onClick={close}
        onClose={close}
        {...menuProps}
      >
        {hasChildren && children}
        {!hasChildren &&
          menuItems?.map(item => {
            const value = getMenuItemValue(item);
            return (
              <MenuItem
                key={value}
                value={value}
                selected={value === selectedMenuItem}
                className={menuItemClassName}
                onClick={() => {
                  if (onMenuItemClick) {
                    onMenuItemClick(value);
                  }
                  close();
                }}
              >
                {getMenuItemLabel(item)}
              </MenuItem>
            );
          })}
      </Menu>
    </>
  );
}

export default SimpleButtonAndMenu;
