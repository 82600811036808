import { createSlice, createEntityAdapter } from "@reduxjs/toolkit";
import { inventoryFetchComplete } from "actions/sharedActions";

export const NOTES_PER_PAGE = 10;

const inventoryNotesAdapter = createEntityAdapter({
  selectId: note => note.entityId
});

const adminUserNotesAdapter = createEntityAdapter({
  sortComparer: (a, b) => a.createdOn < b.createdOn
});

export const inventoryNotesSelector = inventoryNotesAdapter.getSelectors(
  state => state.notes.inventory
);

export const adminUserNotesSelector = adminUserNotesAdapter.getSelectors(
  state => state.notes.admin
);

const initialState = {
  isLoading: true,
  isSaving: false,
  // multiple entities with a single note
  inventory: {
    ...inventoryNotesAdapter.getInitialState()
  },
  // multiple notes on a single entity
  admin: {
    ...adminUserNotesAdapter.getInitialState(),
    hasNew: false,
    page: 0,
    total: 0
  }
};

const notesSlice = createSlice({
  name: "notes",
  initialState,
  reducers: {
    setLoadingStatus: (state, action) => {
      state.isLoading = action.payload;
    },
    setSavingStatus: (state, action) => {
      state.isSaving = action.payload;
    },
    // inventory concern
    setInventoryNotes: (state, action) => {
      inventoryNotesAdapter.setAll(state.inventory, action);
      state.isLoading = false;
    },
    addInventoryNote: (state, action) => {
      inventoryNotesAdapter.addOne(state.inventory, action);
    },
    updateInventoryNote: (state, action) => {
      inventoryNotesAdapter.updateOne(state.inventory, action);
    },
    deleteInventoryNote: (state, action) => {
      inventoryNotesAdapter.removeOne(state.inventory, action);
    },
    // admin user concern
    setAdminNotes: (state, action) => {
      adminUserNotesAdapter.setAll(state.admin, action.payload.notes);
      state.admin.total = action.payload.total ?? 0;
      state.admin.page = action.payload.page ?? 0;
      state.admin.hasNew = false;
      state.isLoading = false;
    },
    setHasNew: (state, action) => {
      state.admin.hasNew = action.payload;
    },
    updateAdminNote: (state, action) => {
      adminUserNotesAdapter.updateOne(state.admin, action);
    },
    resetAdminNotes: state => {
      adminUserNotesAdapter.setAll(state.admin, []);
      state.admin.total = 0;
      state.admin.page = 0;
      state.isLoading = true;
      state.admin.hasNew = false;
    }
  },
  extraReducers: builder => {
    builder.addCase(inventoryFetchComplete, (state, action) => {
      if (action?.payload.notes?.length > 0) {
        inventoryNotesAdapter.setAll(state.inventory, action.payload.notes);
        state.isLoading = false;
      }
    });
  }
});

export default notesSlice;

export const regEx = /[^\w\s`~!@#$%^&*()_+-='";:?\\.,<>]/g;

export function filterOutBadCharacters(text) {
  return text.replace(regEx, "").trim();
}
