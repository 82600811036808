import { useMemo, useState, useCallback, useRef } from "react";
import { AppBar, Tab, Toolbar, IconButton } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import NavTabs from "./NavTabs";
import get from "lodash/get";
import { useSelector } from "utils/StoreUtil";
import { matchPath, Link, useLocation } from "react-router-dom";
import { getCompanyById } from "reducers/companies";
import { getCombinedPermissions } from "reducers/user";
import matchesPermissions from "utils/Permissions";
import CompanyLogo from "../CompanyLogo";
import LoginOrRegister, { LoginButton } from "../LoginOrRegister";
import MenuButton from "../MenuButton";
import NotificationDropdown from "../notification/NotificationDropdown";
import NotificationIndicator from "../notification/NotificationIndicator";
import SearchBox from "views/inventory/search/search-options/SearchBox";
import DealershipSelectMenu from "../DealershipSelectMenu";
import Search from "@mui/icons-material/Search";
import useReportsRoute from "hooks/useReportsRoute";
import UserDealershipSyncMessage from "../UserDealershipSyncMessage";
import useLanguage from "hooks/useLanguage";
import { REGISTRATION_PATH } from "utils/constants";
import { usePublicPaths } from "./usePublicPaths";

const useStyles = makeStyles()(theme => ({
  root: {
    maxHeight: theme.spacing(8),
    boxShadow: "none",
    backgroundColor: theme.palette.company.header,
    color: theme.palette.getContrastText(theme.palette.company.header)
  },
  nav: {
    flex: 1,
    display: "flex",
    justifyContent: "flex-end"
  },
  wrapper: {
    flexDirection: "row"
  },
  indicator: {
    height: "4px",
    background: theme.palette.company.accent
  },
  labelIcon: {
    minHeight: theme.spacing(8),
    padding: 0
  },
  tabRoot: {
    minWidth: "auto",
    padding: theme.spacing(1.5),
    lineHeight: "40px"
  },
  tabsFixed: {
    overflowX: "initial"
  },
  searchIconButton: {
    borderRadius: "0",
    paddingLeft: theme.spacing(2.5),
    paddingRight: theme.spacing(2.5)
  },
  searchIconOverride: {
    color: theme.palette.getContrastText(theme.palette.company.header)
  },
  button: {
    color: theme.palette.primary.contrastText,
    [theme.breakpoints.up("md")]: {
      marginLeft: theme.spacing(1)
    },
    [theme.breakpoints.down("lg")]: {
      marginRight: theme.spacing(1)
    }
  }
}));

const adminPermissions = [{ entity: "EVENT", operations: ["UPDATE"] }];
const configurationPermissions = [{ entity: "MEMBERSHIP_SCHEMA", operations: ["CREATE"] }];

const NavbarDesktop = () => {
  const { classes } = useStyles();
  const tabClasses = useMemo(
    () => ({
      root: classes.tabRoot,
      wrapper: classes.wrapper,
      labelIcon: classes.labelIcon
    }),
    [classes]
  );

  const user = useSelector(state => state.user);
  const isAuthorized = user.isAuthorized;

  const permissions = useSelector(state => getCombinedPermissions(user));

  const mediaId = useSelector(state =>
    get(getCompanyById(state.companies.companies, get(user.user, "companyId", 0)), "mediaId")
  );

  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [isSearchOpen, setSearchIsOpen] = useState(false);
  const buttonRef = useRef(null);

  const [inventoryText, researchText] = useLanguage("inventory", "research");

  const reportsRoute = useReportsRoute(permissions);
  const hasAdminPermission = matchesPermissions(adminPermissions, permissions);

  const hasConfigurationPermission = matchesPermissions(configurationPermissions, permissions);

  const publicPaths = usePublicPaths();

  const handleClick = useCallback(() => {
    setIsOpen(true);
  }, []);
  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);
  const isMobile = useSelector(state => state.viewport.isMobile);
  const handleSearchClick = evt => {
    setSearchIsOpen(true);
    evt.nativeEvent.stopImmediatePropagation();
  };
  const handleSearchClose = useCallback(evt => {
    setSearchIsOpen(false);
  }, []);

  return (
    <AppBar position="sticky" className={classes.root}>
      <Toolbar disableGutters={isMobile}>
        <CompanyLogo mediaId={mediaId} />

        {isAuthorized && (
          <>
            <DealershipSelectMenu />
            <UserDealershipSyncMessage />
          </>
        )}
        <nav className={classes.nav}>
          {isAuthorized && (
            <>
              <IconButton className={classes.searchIconButton} onClick={handleSearchClick}>
                <Search classes={{ root: classes.searchIconOverride }} />
              </IconButton>

              <NavTabs
                classes={{
                  indicator: classes.indicator,
                  root: classes.tabsRoot,
                  flexContainer: classes.tabsFlexContainer,
                  fixed: classes.tabsFixed
                }}
              >
                {matchesPermissions(["viewCalendar"], permissions) && (
                  <Tab
                    label={"Calendar"}
                    component={Link}
                    to="/calendar"
                    value="/calendar"
                    data-testid="calendar-nav-tab"
                    classes={tabClasses}
                  />
                )}
                {matchesPermissions(["viewInventory"], permissions) && (
                  <Tab
                    label={inventoryText}
                    component={Link}
                    to="/inventory"
                    value="/inventory"
                    data-testid="inventory-nav-tab"
                    classes={tabClasses}
                  />
                )}
                {matchesPermissions(["viewPurchases"], permissions) && (
                  <Tab
                    label={"My Activity"}
                    component={Link}
                    to="/home/activity/buyer/purchases"
                    value="/home"
                    data-testid="activity-nav-tab"
                    classes={tabClasses}
                  />
                )}
                {reportsRoute && (
                  <Tab
                    data-testid="research-nav-tab"
                    label={researchText}
                    component={Link}
                    to={reportsRoute}
                    value="/research"
                    classes={tabClasses}
                  />
                )}
                {hasAdminPermission && (
                  <Tab
                    label={"Admin"}
                    component={Link}
                    to="/admin"
                    value="/admin"
                    data-testid="admin-nav-tab"
                    classes={tabClasses}
                  />
                )}
                {hasConfigurationPermission && (
                  <Tab
                    label={"Configuration"}
                    component={Link}
                    to="/configuration"
                    data-testid="configuration-nav-tab"
                    value="/configuration"
                    classes={tabClasses}
                  />
                )}
              </NavTabs>

              <NotificationIndicator buttonRef={buttonRef} handleClick={handleClick} />
              <NotificationDropdown
                isOpen={isOpen}
                elementRef={buttonRef}
                handleClose={handleClose}
              />
              <MenuButton />
            </>
          )}

          {!isAuthorized && matchPath(location.pathname, { path: publicPaths }) && (
            <LoginOrRegister />
          )}
          {!isAuthorized && matchPath(location.pathname, { path: REGISTRATION_PATH.REGISTER }) && (
            <LoginButton classes={{ button: classes.button }} />
          )}
        </nav>
        <SearchBox isOpen={isSearchOpen} handleClose={handleSearchClose} />
      </Toolbar>
    </AppBar>
  );
};

export default NavbarDesktop;
