import { useMemo, useCallback, useState } from "react";
import { useSelector } from "utils/StoreUtil";
import { createSelector } from "@reduxjs/toolkit";

import { getSalesByEventId } from "reducers/sales";
import { FLUSHABLE_SALE_STATUS, getIsValidSalesStatus } from "hooks/flush/useSaleFlush";
import { flushEvent } from "api/adminRequests";

const getError = (isValidSaleStatus, invalidSaleStatusCount) => {
  let error;

  if (!isValidSaleStatus) {
    error = `${invalidSaleStatusCount} sale${
      invalidSaleStatusCount > 1 ? "s" : ""
    } with invalid sale status. Valid sale status [${FLUSHABLE_SALE_STATUS.toString()}]`;
  }

  return error;
};

const getCanFlush = sales => {
  const { isValidSaleStatus, invalidSaleStatusCount } = getIsValidSalesStatus(sales);

  return {
    canFlush: isValidSaleStatus,
    error: getError(isValidSaleStatus, invalidSaleStatusCount)
  };
};

const getSales = createSelector(
  state => state.sales,
  (_, eventId) => eventId,
  (saleState, eventId) => getSalesByEventId(saleState, eventId, false)
);

const useEventFlush = eventId => {
  const [isFlushing, setIsFlushing] = useState(false);

  const sales = useSelector(state => getSales(state, eventId));

  const flush = useCallback(async () => {
    try {
      setIsFlushing(true);

      await flushEvent(eventId);
    } finally {
      setIsFlushing(false);
    }
  }, [eventId]);

  return useMemo(() => {
    const { canFlush, error } = getCanFlush(sales);

    return {
      canFlush,
      error,
      flush,
      isFlushing
    };
  }, [sales, flush, isFlushing]);
};

export default useEventFlush;
