import React, { Component } from "react";
import Header from "./Header";
import { connect } from "utils/StoreUtil";
import LandingPageMainContent from "../landing/cubed/LandingPageMainContent";
import { compose } from "@reduxjs/toolkit";
import { withStyles } from "tss-react/mui";
import { Redirect } from "react-router-dom";
import { loginAttempt, resendActivationEmail } from "actions/userActions";
import { shouldResendActivationEmail } from "reducers/user";
import { openDialogWithProps } from "actions/dialogActions";
import get from "lodash/get";
import { getCompanyById } from "reducers/companies";
import DefaultMainContent from "views/login/DefaultMainContent";

const styles = theme => ({
  root: {
    [theme.breakpoints.down("sm")]: {
      overflow: "hidden"
    }
  }
});

class Login extends Component {
  focusOnLogin = () => this.emailRef.focus();

  render() {
    const {
      classes,
      isAuthorized,
      isLoading,
      loginAttempt,
      showResendLink,
      resendActivationEmail,
      showTechSupportNumber,
      techSupportNumber,
      mediaId,
      helpUrl,
      openDialogWithProps,
      appAndroidUrl,
      appAppleUrl,
      useLandingPage
    } = this.props;
    if (isAuthorized) {
      return <Redirect to="/" />;
    }
    return (
      <div className={classes.root}>
        <Header
          mediaId={mediaId}
          isLoading={isLoading}
          loginAttempt={loginAttempt}
          emailRef={node => (this.emailRef = node)}
          showResendLink={showResendLink}
          resendActivationEmail={resendActivationEmail}
          showTechSupportNumber={showTechSupportNumber}
          techSupportNumber={techSupportNumber}
          helpUrl={helpUrl}
          openDialogWithProps={openDialogWithProps}
          appAndroidUrl={appAndroidUrl}
          appAppleUrl={appAppleUrl}
        />
        {useLandingPage && <LandingPageMainContent focusOnLogin={this.focusOnLogin} />}
        {!useLandingPage && <DefaultMainContent />}
      </div>
    );
  }
}

const mapStateToProps = ({
  user,
  companies: { companies },
  config: {
    showTechSupportNumber = false,
    helpUrl,
    techSupportNumber,
    appAndroidUrl,
    appAppleUrl,
    useLandingPage = false
  }
}) => ({
  isAuthorized: user.isAuthorized,
  mediaId: get(getCompanyById(companies, get(user.user, "companyId", 0)), "mediaId"),
  isLoading: user.isLoading,
  showResendLink: shouldResendActivationEmail(user),
  showTechSupportNumber: showTechSupportNumber,
  helpUrl,
  techSupportNumber,
  appAndroidUrl,
  appAppleUrl,
  useLandingPage: JSON.parse(useLandingPage)
});

export default compose(
  connect(mapStateToProps, {
    loginAttempt,
    resendActivationEmail,
    openDialogWithProps
  })
)(withStyles(Login, styles));
