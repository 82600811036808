import { memo } from "react";
import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import FilterNone from "@mui/icons-material/FilterNone";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(theme => ({
  root: {
    ...theme.mixins.toolbar,
    display: "flex"
  },
  link: {
    color: theme.palette.getContrastText(theme.palette.company.header),
    textDecoration: "none"
  },
  default: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  }
}));

const CompanyLogo = ({ mediaId }) => {
  const { classes, cx } = useStyles();

  return (
    <div
      className={cx(classes.root, "logoContainer")}
      style={
        mediaId
          ? {
              backgroundImage: `url(/media/${mediaId})`,
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
              height: "100%",
              width: "160px" //this is the size of a small image
            }
          : null
      }
    >
      {!mediaId && (
        <div className={classes.default}>
          <FilterNone />
          <Typography className={classes.link} component={Link} to="/">
            Cubed Community
          </Typography>
        </div>
      )}
    </div>
  );
};

export default memo(CompanyLogo);
