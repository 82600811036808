import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "tss-react/mui";
import NoSsr from "@mui/material/NoSsr";
import Frame from "react-frame-component";
import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";

const styles = theme => ({
  root: {}
});

class StyledFrame extends React.Component {
  state = {
    ready: false
  };

  contentDocumentRef = React.createRef();
  contentWindowRef = React.createRef();

  handleRef = ref => {
    this.contentDocumentRef.current = ref ? ref.node.contentDocument : null;
    this.contentWindowRef.current = ref ? ref.node.contentWindow : null;
  };

  onContentDidMount = () => {
    this.setState({
      ready: true,
      container: this.contentDocumentRef.current.body
    });
  };

  render() {
    const { children, classes, triggerPrint, ...otherProps } = this.props;

    const cache = createCache({
      key: "print",
      container: this.state.container
    });

    // NoSsr fixes a strange concurrency issue with iframe and quick React mount/unmount
    return (
      <NoSsr>
        <Frame
          {...otherProps}
          ref={this.handleRef}
          className={classes.root}
          contentDidMount={this.onContentDidMount}
          contentDidUpdate={this.onContentDidUpdate}
        >
          <div id="iframe-jss" />
          {this.state.ready ? (
            <CacheProvider value={cache}>
              {React.cloneElement(children, {
                container: this.state.container,
                triggerPrint: () => this.props.triggerPrint(this.contentWindowRef.current)
              })}
            </CacheProvider>
          ) : null}
        </Frame>
      </NoSsr>
    );
  }
}

StyledFrame.propTypes = {
  children: PropTypes.node.isRequired
};

export default withStyles(StyledFrame, styles);
