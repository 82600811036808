import { REHYDRATE } from "redux-persist/lib/constants";
import {
  ADMIN_GET_BID_LOGS,
  ADMIN_SET_BID_LOGS,
  ADMIN_SET_BID_LOGS_TOTAL,
  ADMIN_SET_BID_LOGS_PAGE,
  ADMIN_GET_SALE_LOG_ACTIONS,
  ADMIN_SET_SALE_LOG_ACTIONS,
  ADMIN_TOGGLE_BIDLOG_ACTION_FILTER,
  ADMIN_GET_LOT,
  ADMIN_SET_LOT
} from "actions/actionTypes";
import { createTermsFilter } from "utils/SearchUtil";
import startCase from "lodash/startCase";
import get from "lodash/get";
import { getFormattedCurrencyBidAmount } from "utils/CurrencyUtil";
import { createSelector } from "@reduxjs/toolkit";
import { SALE_ACTION_LOG_TYPE } from "utils/constants";
import { formatDateTime } from "utils/DateUtil";

const initialState = {
  bidLogs: [],
  bidLogsTotal: 0,
  areBidLogsLoading: true,
  bidLogFilters: [],
  bidLogsPage: 0,
  saleLogActions: [],
  areSaleLogActionsLoading: true,
  isLotLoading: true,
  lot: {}
};

export default function adminBidlogs(state = initialState, action) {
  switch (action.type) {
    case REHYDRATE:
      if (action.payload) {
        return {
          ...state,
          ...action.payload.adminBidlogs,
          bidLogs: [],
          saleLogActions: [],
          areBidLogsLoading: true,
          areSaleLogActionsLoading: true,
          lot: {},
          isLotLoading: true
        };
      } else {
        return {
          ...state
        };
      }

    case ADMIN_GET_BID_LOGS:
      return {
        ...state,
        areBidLogsLoading: true
      };

    case ADMIN_SET_BID_LOGS:
      return {
        ...state,
        areBidLogsLoading: false,
        bidLogs: action.payload.bidLogs
      };

    case ADMIN_SET_BID_LOGS_TOTAL:
      return {
        ...state,
        bidLogsTotal: action.payload.total
      };

    case ADMIN_SET_BID_LOGS_PAGE:
      return {
        ...state,
        bidLogsPage: action.payload.page
      };

    case ADMIN_GET_SALE_LOG_ACTIONS:
      return {
        ...state,
        areSaleLogActionsLoading: true
      };

    case ADMIN_SET_SALE_LOG_ACTIONS:
      return {
        ...state,
        areSaleLogActionsLoading: false,
        saleLogActions: action.payload.actions.map(action => ({
          name: startCase(action),
          value: action
        }))
      };

    case ADMIN_TOGGLE_BIDLOG_ACTION_FILTER:
      return {
        ...state,
        bidLogFilters: isActionFilterSet(action.payload.action, state.bidLogFilters)
          ? removeActionFilter(action.payload.action, state.bidLogFilters)
          : addActionFilter(action.payload.action, state.bidLogFilters)
      };

    case ADMIN_GET_LOT:
      return {
        ...state,
        isLotLoading: true
      };
    case ADMIN_SET_LOT:
      return {
        ...state,
        isLotLoading: false,
        lot: action.payload.lot
      };

    default:
      return state;
  }
}

export const isActionFilterSet = (bidLogActionType, bidLogFilters) => {
  if (bidLogFilters.length <= 0) {
    return false;
  }
  const actionFilter = getActionFilter(bidLogFilters);
  if (actionFilter == null) {
    return false;
  }
  return actionFilter.values.includes(bidLogActionType.toLowerCase());
};

export const isActionFilter = filter =>
  filter.filterType === "TERMS" && filter.field === "saleAction.type";

export const getActionFilter = bidLogFilters => bidLogFilters.find(isActionFilter);

export const removeActionFilter = (action, bidLogFilters) => {
  let actionFilter = getActionFilter(bidLogFilters);
  actionFilter.values = actionFilter.values.filter(value => value !== action.toLowerCase());
  if (actionFilter.values <= 0) {
    return bidLogFilters.filter(filter => filter !== getActionFilter(bidLogFilters));
  } else {
    return bidLogFilters.map(filter => {
      if (filter === getActionFilter(bidLogFilters)) {
        filter = actionFilter;
      }
      return filter;
    });
  }
};

export const addActionFilter = (action, bidLogFilters) => {
  let actionFilter = getActionFilter(bidLogFilters);
  if (actionFilter) {
    actionFilter.values = [...actionFilter.values, action.toLowerCase()];
  } else {
    actionFilter = createActionFilter(action);
  }
  return [...bidLogFilters.filter(filter => !isActionFilter), actionFilter];
};

export const createActionFilter = action =>
  createTermsFilter("saleAction.type", [action.toLowerCase()]);

export const getSelectedActions = bidLogFilters => {
  const actionFilter = getActionFilter(bidLogFilters);
  if (actionFilter) {
    return actionFilter.values;
  } else {
    return [];
  }
};

export const isActionSelected = (action, selectedActions) =>
  selectedActions.includes(action.toLowerCase());

export function formatSaleActionLog(saleActionLog, sale, userName, locale) {
  const timestamp = saleActionLog.saleAction?.timestamp;

  return {
    id: saleActionLog.id,
    createdOn: timestamp && locale ? formatDateTime(timestamp, locale, "h:mm:ssa z") : "",
    bidType: startCase(get(saleActionLog, "saleAction.bidType", "")),
    originType: startCase(get(saleActionLog, "saleAction.origin", "")),
    action: getAction(saleActionLog),
    actionValue: getActionValue(saleActionLog),
    askAmount: getFormattedCurrencyBidAmount(
      saleActionLog?.saleAction?.askAmount,
      sale,
      saleActionLog?.saleAction?.source
    ),
    amount:
      !(
        saleActionLog.saleAction?.type === "cancelBid" ||
        (saleActionLog.saleAction?.type === "bid" && saleActionLog.saleAction?.bidType === "PRE")
      ) &&
      getFormattedCurrencyBidAmount(
        getAmount(saleActionLog),
        sale,
        saleActionLog?.saleAction?.source
      ),
    bidderBadge: get(saleActionLog, "saleAction.saleUserName", ""),
    bidder: userName ?? saleActionLog.saleAction?.winningBid?.saleUserName ?? ""
  };
}

function hasError(saleActionLog) {
  return saleActionLog?.logType === "ERROR";
}

function getAction(saleActionLog) {
  return `${startCase(get(saleActionLog, "saleAction.type", ""))} ${
    hasError(saleActionLog) ? "(error)" : ""
  }`;
}

function getActionValue(saleActionLog) {
  if (hasError(saleActionLog)) {
    return saleActionLog.message;
  }

  return formatFields(saleActionLog.saleAction?.fields);
}

export function formatChatLog(log = {}, sale, fromUser, toUser, lot, locale) {
  const timestamp = log.saleAction?.timestamp;
  return {
    timestamp: timestamp && locale ? formatDateTime(timestamp, locale, "h:mm:ssa z") : "",
    from: fromUser?.name ?? log.saleAction?.from,
    to: toUser?.name ?? log.saleAction?.to,
    message: log.saleAction?.message,
    saleName: sale?.name,
    lotNumber: lot?.lotNumber ?? log.saleAction?.lotNumber ?? "N/A"
  };
}

function getAmount(saleActionLog) {
  if (saleActionLog.saleAction?.amount) {
    return get(saleActionLog, "saleAction.amount", "");
  }
  if (saleActionLog.saleAction?.winningBid) {
    return get(saleActionLog, "saleAction.winningBid.amount", "");
  }
  if (saleActionLog.saleAction?.bid) {
    return get(saleActionLog, "saleAction.bid.amount", "");
  }

  return "";
}

export function formatFields(fields) {
  if (!fields) return "";

  return Object.keys(fields)
    .reduce((strings, fieldKey) => {
      const fieldValue = fields[fieldKey];

      if (fieldValue) {
        if (strings.length > 0) {
          strings.push(", ");
        }
        strings.push(`${startCase(fieldKey)}= ${fieldValue}`);
      }
      return strings;
    }, [])
    .join("");
}

const getBidLogs = state => state.bidLogs;
const getUserId = (_, userId) => userId;
const getAttendeeNames = (_, userId, attendeeNames) => attendeeNames;

const findChatLog = (userId, attendeeNames = []) => log => {
  const logTo = log.saleAction.to?.toLowerCase();
  return (
    (log.saleAction.userId === userId ||
      logTo === "all" ||
      logTo === `a${userId}` ||
      attendeeNames.includes(log.saleAction.to)) &&
    log.saleAction.type === SALE_ACTION_LOG_TYPE.CHAT
  );
};

export const makeHasChatLogs = () =>
  createSelector(getBidLogs, getUserId, getAttendeeNames, (logs = [], userId, attendeeNames = []) =>
    Boolean(logs.find(findChatLog(userId, attendeeNames)))
  );

export const getUserIdNumber = userIdString => {
  try {
    return userIdString.replace(/[^0-9]/g, "");
  } catch (error) {
    return;
  }
};
