import { CONFIG_SET, SET_WELCOME_SCREEN_FLAG } from "actions/actionTypes";
import { createSelector } from "@reduxjs/toolkit";
import { isStringNumeric } from "utils/NumberUtil";

const initialState = {
  isLoading: true,
  darkMode: false,
  showMobileWelcomeScreen: false,
  featureFlags: {
    // To add new feature flags to the app, add them here
    biddingSection: null,
    searchOptionsV2: null,
    useCreditScript: null,
    enableAutoGrade: null
  }
};

export default function config(state = initialState, action) {
  switch (action.type) {
    case CONFIG_SET: {
      const config = action.payload.config.reduce((config, configItem) => {
        config[configItem.name] = getValue(configItem);
        return config;
      }, {});

      return {
        ...state,
        isLoading: false,
        ...config,
        featureFlags: getFeatureFlags(config)
      };
    }

    case "TOGGLE_DARK_MODE":
      return {
        ...state,
        darkMode: !state.darkMode
      };

    case "SET_DARK_MODE":
      return {
        ...state,
        darkMode: action.payload.darkMode
      };

    case SET_WELCOME_SCREEN_FLAG:
      return {
        ...state,
        showMobileWelcomeScreen: action.payload.showMobileWelcomeScreen
      };

    default:
      return state;
  }
}

function getFeatureFlags(config) {
  const featureFlags = { ...initialState.featureFlags };

  const values = config["featureFlags"] || "";

  for (const flag in featureFlags) {
    featureFlags[flag] = values.includes(flag);
  }

  return featureFlags;
}

const ignoredConfigValues = ["n/a", "na", "null"];

export function getValue(configItem) {
  const value = configItem.value;
  if (isBoolean(configItem)) {
    return parseBoolean(value);
  } else if (value.trim().length < 1 || ignoredConfigValues.includes(value.trim().toLowerCase())) {
    return null;
  } else if (isStringNumeric(value)) {
    return parseInt(value);
  }
  return value;
}

function isBoolean(configItem) {
  return configItem.configurationPropertyType === "CHECKBOX";
}

function parseBoolean(value) {
  try {
    return JSON.parse(value);
  } catch (error) {
    return false;
  }
}

export function getHostNames({ config: { autoSignInHostNames } = {} }) {
  if (autoSignInHostNames && autoSignInHostNames.toLowerCase() !== "n/a") {
    return autoSignInHostNames.split(",").map(autoSignInHostName => autoSignInHostName.trim());
  } else {
    return [];
  }
}

const _getExternalLinks = state => state.externalLinks;

export const getExternalLinks = createSelector(_getExternalLinks, (rawLinks = `"{}"`) => {
  const links = JSON.parse(rawLinks.replace(/'/g, `"`));
  return Object.keys(links).map(key => {
    const link = { key, link: links[key].trim() };
    if (!link.link.includes("http")) link.link = `${window.location.protocol}//${link.link}`;
    return link;
  });
});

export function getConfigValue(appConfig, property) {
  const configItem = appConfig?.find(configItem => configItem.name === property);
  if (configItem) {
    return getValue(configItem);
  }
}
