import { useMemo, useRef } from "react";
import useTimeout from "./useTimeout";

const ONE_DAY = 86400000;

function useScheduled(callback, timestamp, executeIfExpired = false) {
  const hasExecuted = useRef(false);

  const timeout = useMemo(() => {
    const now = Date.now();

    if (Number.isInteger(timestamp)) {
      if (timestamp >= now) {
        const result = timestamp - now;

        if (result < ONE_DAY) {
          return result;
        }
      } else if (executeIfExpired && timestamp < now && !hasExecuted.current) {
        hasExecuted.current = true;
        return 1;
      }
    }

    return null;
  }, [timestamp, executeIfExpired]);

  useTimeout(callback, timeout);
}

export { useScheduled as default };
