import React, { Suspense } from "react";
import { useSelector, useDispatch } from "utils/StoreUtil";
import { makeStyles } from "tss-react/mui";
import { CircularProgress, Drawer as MUIDrawer } from "@mui/material";
import DrawerContent from "./drawerContent";
import { drawerResetState } from "reducers/drawer";

const useStyles = makeStyles()(() => ({
  paper: {
    minWidth: 300
  }
}));

function Drawer() {
  const { classes } = useStyles();
  const dispatch = useDispatch();

  const isOpen = useSelector(state => state.drawer.isOpen);
  const anchor = useSelector(state => state.drawer.anchor);

  return (
    <MUIDrawer
      classes={{ paper: classes.paper }}
      open={isOpen}
      anchor={anchor}
      onClose={() => dispatch(drawerResetState())}
    >
      <Suspense fallback={<CircularProgress size={20} />}>
        <DrawerContent />
      </Suspense>
    </MUIDrawer>
  );
}

export default Drawer;
