import useComet from "./useComet";

const DEFAULT_VALUE = {};

function useSale(saleId, selector, defaultValue = DEFAULT_VALUE) {
  const sale = useComet("sale", saleId, selector, defaultValue);

  return sale;
}

export { useSale as default };
