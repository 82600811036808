import { ADMIN_USERS_SELECT_BULK_ACTION } from "actions/actionTypes";
import { REHYDRATE } from "redux-persist/lib/constants";
import { createSlice, createEntityAdapter } from "@reduxjs/toolkit";
import { toggleSort } from "./reports";
import { inventoryFetchComplete } from "actions/sharedActions";

const NAME = "users";
const initialState = {
  isLoading: true,
  total: 0,
  page: 0,
  perPage: 25,
  sort: "+lastName",
  selectedBulkAction: null
};

const usersAdapter = createEntityAdapter();

function fetchComplete(state, action) {
  const { total, [NAME]: users } = action.payload;

  if (Array.isArray(users)) {
    state.isLoading = false;

    usersAdapter.setAll(state, users);
    if (total != null) {
      state.total = total;
    }
  } else {
    usersAdapter.removeAll(state);
    state.total = initialState.total;
  }
}

function reset() {
  return usersAdapter.getInitialState(initialState);
}

const users = createSlice({
  name: NAME,
  initialState: usersAdapter.getInitialState(initialState),
  reducers: {
    fetchUsers(state) {
      state.isLoading = true;
    },
    fetchUsersComplete: {
      reducer: fetchComplete,
      prepare: (users, total) => ({ payload: { users, total } })
    },
    addUser(state, action) {
      const user = action.payload;
      const sort = state.sort.slice(1);
      const order = state.sort[0];
      let users = sortUsers(getUsers(state.entities, user), sort, order);
      usersAdapter.setAll(state, users);
      state.total++;
    },
    removeUser(state, action) {
      usersAdapter.removeOne(state, action.payload);
      state.total--;
    },
    setUsersPage(state, action) {
      state.page = action.payload;
    },
    setUsersSort(state, action) {
      state.sort = toggleSort(state.sort, action.payload);
      state.page = initialState.page;
    },
    resetUsers: reset
  },
  extraReducers: builder => {
    builder
      .addCase(inventoryFetchComplete, fetchComplete)
      .addCase(REHYDRATE, reset)
      .addCase(ADMIN_USERS_SELECT_BULK_ACTION, (state, action) => {
        const bulkAction = action.payload;
        if (!bulkAction || bulkAction === state.selectedBulkAction) {
          state.selectedBulkAction = initialState.selectedBulkAction;
        } else {
          state.selectedBulkAction = bulkAction;
        }
      });
  }
});

function sortUsers(users, sort, order) {
  return users.sort((a, b) => {
    if (order === "+") {
      return a[sort].localeCompare(b[sort]);
    } else {
      return b[sort].localeCompare(a[sort]);
    }
  });
}

function getUsers(usersObj, user) {
  return [...Object.values(usersObj), user];
}

export const usersSelector = usersAdapter.getSelectors(state => state[NAME]);

export const directUsersSelectors = usersAdapter.getSelectors();

const { reducer, actions } = users;
export const {
  fetchUsers,
  fetchUsersComplete,
  removeUser,
  setUsersPage,
  setUsersSort,
  resetUsers,
  addUser
} = actions;
export default reducer;
