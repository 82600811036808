import useLot from "./useLot";

function useProposedBidAmounts(lotId) {
  const proposedBidAmounts = useLot(
    lotId,
    lot => {
      const lastAction = lot.lastAction;

      const proposalAmounts =
        lastAction && lastAction.type === "reject" && lastAction.proposalAmounts;

      if (proposalAmounts && proposalAmounts.length > 0) {
        return proposalAmounts;
      }

      return null;
    },
    null
  );

  return proposedBidAmounts;
}

export { useProposedBidAmounts as default };
