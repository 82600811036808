import React, { createContext } from "react";
import { useSelector } from "utils/StoreUtil";

import IntlUtil, { defaultIntlUtil } from "utils/IntlUtil";

const IntlContext = createContext(defaultIntlUtil);

function IntlProvider({ children }) {
  const currency = useSelector(state => state.user.user.currency);

  const intl = new IntlUtil(currency);

  return <IntlContext.Provider value={intl}>{children}</IntlContext.Provider>;
}

export { IntlContext };

export default IntlProvider;
