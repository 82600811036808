import { createReducer } from "@reduxjs/toolkit";
import {
  ACTIVITY_SET_FILTER_VALUE,
  ACTIVITY_RESET_SPECIFIC_FILTER,
  ACTIVITY_RESET_ACTIVITIES
} from "actions/actionTypes";
import { createSelector } from "@reduxjs/toolkit";
import { toggleArrayValue } from "./vehicles";

export const initialState = {
  dateFrom: null,
  dateTo: null,
  selectedLotStatus: [],
  selectedEventIds: [],
  selectedCompanyIds: [],
  shouldShowAllDealerships: false,
  paymentStatus: [],
  searchQuery: ""
};

const eventIdClearableFilterKeys = ["shouldShowAllDealerships", "dateFrom", "dateTo"];

const activityFilters = createReducer(initialState, builder => {
  builder
    .addCase(ACTIVITY_RESET_ACTIVITIES, () => initialState)
    .addCase(ACTIVITY_SET_FILTER_VALUE, (state, action) => {
      const { prop, value } = action.payload;
      const shouldToggleArrayValue = Array.isArray(state[prop]) && !Array.isArray(value);

      state[prop] = shouldToggleArrayValue ? toggleArrayValue(state[prop], value) : value;

      if (eventIdClearableFilterKeys.includes(prop)) {
        state.selectedEventIds = initialState.selectedEventIds;
      }
    })
    .addCase(ACTIVITY_RESET_SPECIFIC_FILTER, (state, action) => {
      const prop = action.payload.prop;
      if (Array.isArray(prop)) {
        prop.forEach(key => {
          state[key] = initialState[key];
        });
      } else {
        state[prop] = initialState[prop];
      }
    });
});

export const lotStatusOptions = ["sold", "ifsold", "unsold"];
export const lotStatusListedOptions = lotStatusOptions.concat(["prebid", "nosale"]);

const getActivityFilters = state => state.activityFilters;
export const getActivityFilterCount = createSelector(getActivityFilters, filters => {
  return Object.values(filters).reduce((accumulator, filter) => {
    if (Array.isArray(filter)) {
      accumulator += filter.length;
    } else if (filter) {
      accumulator++;
    }
    return accumulator;
  }, 0);
});

const getEvents = state => state.activityFilterOptions.events;
const getSelectedEventIds = state => state.activityFilters.selectedEventIds;
export const getSelectedEvents = createSelector(
  getEvents,
  getSelectedEventIds,
  (events = [], selectedEventIds = []) =>
    events.filter(event => selectedEventIds.includes(event.id))
);

export function getLotStatusFilterOptions(route) {
  switch (getActivityName(route)) {
    case "listed":
      return lotStatusListedOptions;
    case "unlisted":
      return ["sold", "ifsold"];
    default:
      return lotStatusOptions;
  }
}

function getActivityName(name) {
  const names = name.split("/");
  return names[names.length - 1];
}

export default activityFilters;
