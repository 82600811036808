import ViewType from "views/activity/types/ViewType";

const sortableViews = [ViewType.listed, ViewType.unlisted];

class SortUtil {
  static getSorts(activityView: ViewType, sortBy?: string) {
    return !SortUtil.isSortable(activityView) || !sortBy
      ? SortUtil.getDefaultSorts(activityView)
      : [sortBy];
  }

  static isSortable(activityView: ViewType) {
    return sortableViews.includes(activityView);
  }

  private static getDefaultSorts(activityView: ViewType) {
    switch (activityView) {
      case ViewType.bids:
        return ["-createdOn"];
      case ViewType.listed:
        return ["+startTime", "+eventId", "+lotSequence"];
      default:
        return ["-startTime", "-eventId", "+lotSequence"];
    }
  }
}

export { SortUtil as default };
