import { createSelector } from "@reduxjs/toolkit";
import moment from "moment";
import * as SearchUtil from "utils/SearchUtil";

const getAccumulatedCount = filters => {
  return Object.values(filters).reduce((accumulator, filter) => {
    if (Array.isArray(filter)) {
      accumulator += filter.length;
    } else if (filter) {
      accumulator++;
    }
    return accumulator;
  }, 0);
};

export const getFiltersCount = (optionsType, state, subSection) => {
  switch (optionsType) {
    case "Activity": {
      const getActivityFiltersCount = createSelector(
        state => state.activityFilters,
        (_, subSection) => subSection,
        (filters, subSection) => {
          switch (subSection) {
            case "purchases":
              return getAccumulatedCount(filters);
            default:
              return getAccumulatedCount({ ...filters, paymentStatus: null });
          }
        }
      );
      return getActivityFiltersCount(state, subSection);
    }

    case "Inventory": {
      const getInventoryFilterCount = createSelector(
        state => state.inventorySearchOptions?.defaultSelectedSearchOptions?.companies,
        selectedCompanyIds => {
          return getAccumulatedCount({ selectedCompanyIds });
        }
      );
      return getInventoryFilterCount(state);
    }

    case "Calendar": {
      const getCalendarFilterCount = createSelector(
        state => state.calendar,
        filters => {
          const calendarFilters = {
            ...filters.selectedAssetSchemasIds,
            saleType: filters.saleType,
            selectedCompanyIds: filters.selectedCompanyIds
          };
          return getAccumulatedCount(calendarFilters);
        }
      );
      return getCalendarFilterCount(state);
    }

    case "Research": {
      const getResearchFilterCount = createSelector(
        state => state.dashboard,
        filters => getAccumulatedCount(filters)
      );
      return getResearchFilterCount(state);
    }

    case "AdminEvents": {
      const getAdminEventsFilterCount = createSelector(
        state => state.adminEvents,
        filters => {
          const usedAdminEventsFilters = {
            saleType: filters.saleType,
            selectedCompanyIds: filters.selectedCompanyIds
          };
          return getAccumulatedCount(usedAdminEventsFilters);
        }
      );
      return getAdminEventsFilterCount(state);
    }

    case "AdminUsers": {
      const getAdminUsersFilterCount = createSelector(
        state => state.memberships.membershipFilters,
        filters => {
          return getAccumulatedCount(filters);
        }
      );
      return getAdminUsersFilterCount(state);
    }

    case "AdminAttendees": {
      const getAdminAttendeesFilterCount = createSelector(
        state => state.adminAttendees.filters,
        filters => {
          return getAccumulatedCount(filters);
        }
      );
      return getAdminAttendeesFilterCount(state);
    }

    default:
      return 0;
  }
};

export const getSaleFiltersCount = saleFilters => {
  return saleFilters.reduce((accumulator, value) => {
    accumulator += value.values.length;
    return accumulator;
  }, 0);
};

export const getLotEndTimeFilter = () => {
  return SearchUtil.createRangeFilter(
    "lot.endTime",
    moment()
      .startOf("day")
      .valueOf(),
    "GTE"
  );
};
