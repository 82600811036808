class CookieUtil {
  static load(cookieName) {
    return document.cookie
      .split(";")
      .map(item => item.trim().split("="))
      .find(([key]) => key === cookieName)?.[1];
  }
}

export { CookieUtil as default };
