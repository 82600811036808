import React, { FC, useCallback } from "react";
import { useDispatch } from "utils/StoreUtil";
import { useHistory } from "react-router-dom";
import { makeStyles } from "tss-react/mui";
import { Consignor as ConsignorEntity } from "./EventConsignorsProvider";
import { Avatar } from "components/UserAvatar";
import { Card, Typography, ListItem, ListItemText, ListItemAvatar, Chip, Box } from "@mui/material";
import WorkIcon from "@mui/icons-material/Business";

import { dialogResetState } from "actions/dialogActions";
import { toggleSearchOption, resetInventorySearchOptionsState } from "actions/inventoryActions";

type ConsignorVariantProp = "list" | "card";

type ConsignorProps = {
  consignor: ConsignorEntity;
  variant?: ConsignorVariantProp;
  eventId: number;
};

const useStyles = makeStyles<{ variant: ConsignorVariantProp }>()((theme, { variant }) => ({
  root: {
    border: theme.shape.borderLight,
    width: 145,
    height: 165,
    margin: "auto",
    padding: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    cursor: "pointer"
  },
  consignorImage: Object.assign(
    variant === "list"
      ? {
          width: "100%",
          "& img": {
            objectFit: "contain"
          }
        }
      : {
          height: 68,
          width: "100%",
          "& img": {
            objectFit: "contain"
          }
        }
  ),
  listItemAvatarRoot: {
    width: 75,
    marginRight: theme.spacing(3)
  },
  consignorInfo: {
    flex: 1,
    padding: theme.spacing(2, 0, 1),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    "& > span": {
      width: "100%"
    }
  },
  name: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    lineClamp: 2,
    boxOrient: "vertical",
    height: 42,
    marginTop: theme.spacing(0.5)
  },
  chip: {
    minWidth: 35
  }
}));

const Consignor: FC<ConsignorProps> = ({ consignor, variant = "card", eventId }) => {
  const { classes } = useStyles({ variant });

  const consignedInventoryCount = consignor.consignedInventoryCount || 0;

  const dispatch = useDispatch();
  const history = useHistory();

  const consignorName = consignor.name;

  const handleSearchOptionChange = useCallback(() => {
    dispatch(resetInventorySearchOptionsState());

    dispatch(toggleSearchOption("events", Number(eventId)));
    if (consignorName) {
      dispatch(toggleSearchOption("consignor", consignorName.toLowerCase()));
    }
    history.push("/inventory");

    if (variant === "list") {
      dispatch(dialogResetState());
    }
  }, [dispatch, eventId, consignorName, history, variant]);

  const itemsCount = consignedInventoryCount > 0 && (
    <Typography noWrap variant="caption">
      {consignedInventoryCount} Item{consignedInventoryCount > 1 ? "s" : ""}
    </Typography>
  );

  if (variant === "list") {
    return (
      <ListItem onClick={handleSearchOptionChange} divider disableGutters alignItems="flex-start">
        <ListItemAvatar classes={{ root: classes.listItemAvatarRoot }}>
          <Avatar
            Icon={WorkIcon}
            className={classes.consignorImage}
            variant="square"
            media={consignor.media}
          />
        </ListItemAvatar>
        <ListItemText
          disableTypography
          primary={
            <Typography noWrap gutterBottom>
              {consignor.name}
            </Typography>
          }
          secondary={itemsCount}
        />
      </ListItem>
    );
  }

  return (
    <Card onClick={handleSearchOptionChange} elevation={0} classes={{ root: classes.root }}>
      <Box display="flex" justifyContent="right" mb={1}>
        <Chip
          size="small"
          color="primary"
          classes={{ root: classes.chip }}
          label={consignedInventoryCount}
        />
      </Box>

      <Avatar
        Icon={WorkIcon}
        className={classes.consignorImage}
        variant="square"
        media={consignor.media}
      />

      <Typography className={classes.name}>{consignor.name}</Typography>
    </Card>
  );
};

export default Consignor;
