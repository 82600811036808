import { createSlice } from "@reduxjs/toolkit";

const viewportSlice = createSlice({
  name: "viewport",
  initialState: {
    isMobile: true
  },
  reducers: {
    setViewportSize: (state, action) => {
      state.isMobile = action.payload;
    }
  }
});

export default viewportSlice.reducer;

export const { actions: viewportActions } = viewportSlice;
