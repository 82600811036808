import React from "react";
import { makeStyles } from "tss-react/mui";
import startCase from "lodash/startCase";
import { useSelector } from "utils/StoreUtil";
import { Typography } from "@mui/material";

import { eventsSelector } from "reducers/events";
import { salesSelector } from "reducers/sales";

const useStyles = makeStyles()(theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    border: theme.shape.borderLight,
    padding: theme.spacing(1)
  },
  filterHeader: {
    padding: theme.spacing(0.5, 1),
    backgroundColor: theme.palette.grey[200],
    border: theme.shape.borderLight,
    textTransform: "capitalize"
  },
  filter: {
    padding: theme.spacing(0.5, 1, 1)
  }
}));

function Criteria(props) {
  const { classes } = useStyles();

  const selectedSearchOptions = useSelector(
    state => state.inventorySearchOptions.defaultSelectedSearchOptions
  );
  const events = useSelector(state => eventsSelector.selectAll(state.events));
  const sales = useSelector(state => salesSelector.selectAll(state.sales));
  const companies = useSelector(state => state.companies.companies);

  const selectedCompanyIds = useSelector(
    state => state.inventorySearchOptions?.defaultSelectedSearchOptions?.companies
  );

  let selectedCompanies = companies.filter(
    company => selectedCompanyIds && selectedCompanyIds.includes(company.id)
  );

  selectedCompanies = selectedCompanies.length > 0 ? selectedCompanies : companies;

  const filters = Object.entries(selectedSearchOptions).map(
    mapFilterForPrint(selectedCompanies, { events, sales }, selectedSearchOptions)
  );

  if (!filters) return null;

  return (
    <div>
      <Typography align="center" variant="subtitle1">
        Search Options
      </Typography>
      <div className={classes.container}>
        {filters.map(filter =>
          filter.value ? (
            <div key={filter.label}>
              <Typography align="center" className={classes.filterHeader}>
                {filter.label}
              </Typography>
              <Typography align="center" className={classes.filter}>
                {filter.value}
              </Typography>
            </div>
          ) : null
        )}
      </div>
    </div>
  );
}

export default React.memo(Criteria);

function mapFilterForPrint(companies, searchOptions, selectedSearchOptions) {
  // TODO: fix handling of range filters
  const { events, sales } = searchOptions;
  const { events: eventIds, sales: saleIds } = selectedSearchOptions;
  const combinedEventIds = sales
    .filter(sale => saleIds.includes(String(sale.id)))
    .map(sale => sale.eventId)
    .concat(eventIds.map(eventId => Number(eventId)));
  return function(entry) {
    let [key, value] = entry;
    let filter = {};
    filter.label = startCase(key);
    if (key === "saleType") {
      filter.value = value
        ? value
            .toString()
            .replace("live,live_legacy", "live")
            .replace("timed,timed_legacy", "timed")
        : null;
    } else if (key === "companies") {
      filter.value =
        value.length > 0
          ? value.map(value => companies.find(company => company.id === value).name).join(", ")
          : companies.map(company => company.name).join(", ");
      filter.label = "Auction Houses";
    } else if (key === "events") {
      filter.value =
        combinedEventIds.length > 0
          ? events
              .filter(event => combinedEventIds.includes(event.id))
              .map(event => {
                const eventSales = sales
                  .filter(sale => saleIds.includes(String(sale.id)))
                  .filter(sale => sale.eventId === event.id);
                const eventName =
                  eventSales.length > 0
                    ? `${event.name} (${eventSales.map(sale => sale.name).join(", ")})`
                    : `${event.name} (All Lanes)`;
                return eventName;
              })
              .join(", ")
          : null;
    } else if (key === "sales") {
      filter.value = null;
    } else if ((Array.isArray(value) && value.length > 0) || filter.value) {
      filter.value = value.toString();
    } else {
      filter.value = null;
    }
    return filter;
  };
}
