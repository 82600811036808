import { get, post, getTotal, put } from "./api";

export async function getNotifications(filters, range, sort) {
  const { data } = await get("application/community/userNotifications", filters, range, sort);
  return data;
}

export async function getNotificationGroups(filters, range, sort) {
  const res = await get("notificationGroup", filters, range, sort);

  return { data: res.data, total: getTotal(res) };
}

export async function createNotificationGroup(notificationGroup) {
  const res = await post("notificationGroup", notificationGroup);

  return res.data;
}

export async function updateNotificationGroup(notificationGroup) {
  await put(`notificationGroup/${notificationGroup.id}`, notificationGroup);
}

export async function getNotificationTemplates(filters, range, sort, extraHeaders) {
  const res = await get("notificationTemplate", filters, range, sort, undefined, extraHeaders);

  return { data: res.data, total: getTotal(res) };
}

export async function createNotificationTemplate(notificationTemplate) {
  const res = await post("notificationTemplate", notificationTemplate);

  return res.data;
}

export async function updateNotificationTemplate(notificationTemplate) {
  await put(`notificationTemplate/${notificationTemplate.id}`, notificationTemplate);
}
