import { useState, useEffect } from "react";
import StorageUtil from "utils/StorageUtil";

function getValueFromLocalStorage(key, initial) {
  return StorageUtil.getItem(key, initial);
}

function useLocalStorage(key, initial = null) {
  const [value, setValue] = useState(() => {
    return getValueFromLocalStorage(key, initial);
  });

  const saveValue = value => {
    setValue(value);

    StorageUtil.setItem(key, value);
  };

  useEffect(() => {
    setValue(getValueFromLocalStorage(key, initial));
  }, [key, initial]);

  return [value, saveValue];
}

export { useLocalStorage as default, getValueFromLocalStorage };
