import { get, getTotal, upload, del, put, post } from "./api";
import { createTermFilter, createRange, createTermsFilter } from "utils/SearchUtil";
import { COMPANY_DEFAULT_MEDIA, ENTITY_TYPE } from "utils/constants";
import { uniqueId } from "lodash";

export async function getMedias(filters, range, sort, uniqueId) {
  const url = uniqueId ? `media?request.preventCache=${uniqueId}` : "media";
  const res = await get(url, filters, range, sort);
  return { data: res.data, total: getTotal(res) };
}

export async function getMediasV2(filters, range, sort, uniqueId) {
  const url = uniqueId
    ? `application/community/media?request.preventCache=${uniqueId}`
    : "application/community/media";
  const res = await get(url, filters, range, sort);
  return { data: res.data, total: getTotal(res) };
}

export async function uploadMedia(file = { name: "" }, saleId, preventCacheId) {
  let data = new FormData();
  const filters = [
    createTermFilter("lot.saleId", saleId),
    createTermFilter("lot.status", "WITHDRAWN", "MUST_NOT"),
    createTermFilter("media.name", file.name),
    createTermFilter("media.entityType", "asset")
  ];
  data.append("file", file);
  const headers = {
    Filters: JSON.stringify(filters),
    "Content-Type": "multipart/form-data"
  };
  return upload(
    `application/community/asset/media?request.preventCache=${preventCacheId}`,
    headers,
    data,
    undefined,
    "put"
  );
}

export async function uploadUsers(headers, file) {
  const { data } = await upload("application/community/importUsers", headers, file);
  return data;
}

export async function deleteMedia(mediaId) {
  await del(`media/${mediaId}`);
}

export async function updateMedia(media) {
  await put(`media/${media.id}`, media);
}

export async function createMedia(media) {
  const { data } = await post("media", media);
  return data;
}

export async function getUserMedia(user) {
  return await getMedia("USER", user.id);
}

export async function getUsersMedia(userIds) {
  const filters = [
    createTermsFilter("entityId", userIds),
    createTermFilter("entityType", "USER"),
    createTermFilter("primary", true)
  ];
  const { data } = await getMedias(filters, createRange(0, userIds.length));

  return data;
}

export async function getCompanyFavIconMedias(companyIds) {
  const filters = [
    createTermFilter("entityType", ENTITY_TYPE.COMPANY),
    createTermFilter("name", COMPANY_DEFAULT_MEDIA.LOGO),
    createTermsFilter("companyId", companyIds)
  ];

  const { data } = await getMedias(
    filters,
    createRange(0, companyIds.length),
    undefined,
    uniqueId("companyFavIconMedias")
  );

  return data;
}

export async function getCompanyDefaultEventMedias(companyIds) {
  const filters = [
    createTermFilter("entityType", ENTITY_TYPE.COMPANY),
    createTermFilter("name", COMPANY_DEFAULT_MEDIA.EVENT),
    createTermsFilter("companyId", companyIds)
  ];

  const { data } = await getMedias(
    filters,
    createRange(0, companyIds.length),
    undefined,
    uniqueId("companyDefaultEventMedias")
  );

  return data;
}

export async function getEventsMedia(eventIds) {
  const filters = [
    createTermsFilter("entityId", eventIds),
    createTermFilter("entityType", "EVENT"),
    createTermFilter("primary", true)
  ];
  const { data } = await getMedias(
    filters,
    createRange(0, eventIds.length),
    undefined,
    uniqueId("eventMedia")
  );
  return data;
}

export async function getMedia(entityType, entityId) {
  let filters = [
    createTermFilter("entityType", entityType),
    createTermFilter("entityId", entityId),
    createTermFilter("primary", true)
  ];
  const { data: medias } = await getMedias(filters, "items=0-0");
  if (medias.length > 0) {
    return medias[0];
  }
}

export async function getMediaById(mediaId) {
  const res = await get(`media/${mediaId}`);

  return res.data;
}

export async function getDealershipsMedia(dealershipIds) {
  const filters = [
    createTermsFilter("entityId", dealershipIds),
    createTermFilter("entityType", "DEALERSHIP"),
    createTermFilter("primary", true)
  ];
  const { data } = await getMediasV2(filters, createRange(0, dealershipIds.length));

  return data;
}
