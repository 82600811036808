import { flushSale } from "api/adminRequests";
import { useMemo, useCallback, useState } from "react";
import { useSelector } from "utils/StoreUtil";
import { salesSelector } from "reducers/sales";

export const FLUSHABLE_SALE_STATUS = ["STAGED", "PRESALE"];

const getIsValidSaleStatus = sale => FLUSHABLE_SALE_STATUS.includes(sale?.status);

export const getIsValidSalesStatus = (sales = []) =>
  sales.reduce(
    (prevState, sale) => {
      if (!getIsValidSaleStatus(sale)) {
        prevState.isValidSaleStatus = false;
        prevState.invalidSaleStatusCount++;
      }
      return prevState;
    },
    { isValidSaleStatus: true, invalidSaleStatusCount: 0 }
  );

const getError = isValidSaleStatus => {
  let error;

  if (!isValidSaleStatus) {
    error = `Invalid sale status. Valid sale status [${FLUSHABLE_SALE_STATUS.toString()}]`;
  }

  return error;
};

const getCanFlush = sale => {
  const isValidSaleStatus = getIsValidSaleStatus(sale);

  return {
    canFlush: isValidSaleStatus,
    error: getError(isValidSaleStatus)
  };
};

const useSaleFlush = saleId => {
  const [isFlushing, setIsFlushing] = useState(false);

  const sale = useSelector(state => salesSelector.selectById(state.sales, saleId));

  const flush = useCallback(async () => {
    try {
      setIsFlushing(true);
      await flushSale(saleId);
    } finally {
      setIsFlushing(false);
    }
  }, [saleId]);

  return useMemo(() => {
    const { canFlush, error } = getCanFlush(sale);

    return {
      canFlush,
      error,
      flush,
      isFlushing
    };
  }, [sale, flush, isFlushing]);
};

export default useSaleFlush;
