import { FC, useEffect } from "react";

import useMediaLoadingToOrchestratePrint from "hooks/useMediaLoadingToOrchestratePrint";
import HtmlContainer from "components/pdf/HtmlContainer";
import Table from "components/pdf/Table";
import Title from "components/pdf/Title";

interface SimpleTableProps {
  rows: Array<Array<string>>;
  title?: string;
  logoUrl?: string;
  triggerPrint?: () => void;
  date?: string;
  totalLabel?: string;
}

const imgStyle = { width: "160px", height: "100%" };

const SimpleTable: FC<SimpleTableProps> = props => {
  const { triggerPrint, logoUrl } = props;

  const totalImagesToLoad = Boolean(logoUrl?.trim()) ? 1 : 0;

  const { isReadyToPrint, increment } = useMediaLoadingToOrchestratePrint(totalImagesToLoad);

  useEffect(() => {
    if (isReadyToPrint) {
      triggerPrint?.();
    }
  }, [triggerPrint, isReadyToPrint]);

  return (
    <HtmlContainer>
      {logoUrl && (
        <img src={logoUrl} alt="" onLoad={increment} onError={increment} style={imgStyle} />
      )}

      <Title title={props.title} date={props.date} totalLabel={props.totalLabel} />

      <Table rows={props.rows} />
    </HtmlContainer>
  );
};

export default SimpleTable;
