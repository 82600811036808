import { SERVER_APP_STATE_REHYDRATE, SERVER_APP_STATE_UPDATE } from "actions/actionTypes";
import {
  getServerAppState as getServerAppStateFromApi,
  updateServerAppState as updateServerAppStateFromApi
} from "api/systemRequests";
import pick from "lodash/pick";
import omit from "lodash/omit";

const mapAppStateFromConfig = (config = {}, state = {}) => {
  return getWhitelistProps(config.whitelist, getPropsExcludingBlacklist(config.blacklist, state));
};

const getWhitelistProps = (whitelist = [], state = {}) => pick(state, whitelist);

const getPropsExcludingBlacklist = (blacklist = [], state = {}) => omit(state, blacklist);

const serverAppStateConfig = {
  whitelist: [
    "dashboardStyle",
    "dashboardLayout",
    "savedSearches.savedSearches",
    "attendance.selectedDealershipId",
    "statisticsCharts.displayChart",
    "statisticsCharts.charts",
    "savedEmails.emails",
    "memberships.defaultHiddenColumns",
    "memberships.companyHiddenColumns",
    "adminInventory.defaultHiddenColumns",
    "adminInventory.companyHiddenColumns"
  ]
};

export const rehydrateServerAppState = () => async (dispatch, getState) => {
  const serverAppState = await getServerAppStateFromApi();
  const appState = mapAppStateFromConfig(serverAppStateConfig, getState());

  dispatch({
    type: SERVER_APP_STATE_REHYDRATE,
    payload: {
      ...appState,
      ...serverAppState.applicationState
    }
  });
};

export const updateServerAppState = () => async (dispatch, getState) => {
  const serverAppState = await getServerAppStateFromApi();
  const appState = mapAppStateFromConfig(serverAppStateConfig, getState());
  const updatedServerAppState = {
    ...serverAppState,
    applicationState: {
      ...serverAppState.applicationState,
      ...appState
    }
  };
  await updateServerAppStateFromApi(updatedServerAppState);
  dispatch({ type: SERVER_APP_STATE_UPDATE });
};
