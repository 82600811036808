import { useEffect, useRef } from "react";

function useTimeFunction(callback, delay, timeFunction, clearTimeFunction) {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    if (delay !== null) {
      function tick() {
        savedCallback.current();
      }
      let id = timeFunction(tick, delay);
      return () => clearTimeFunction(id);
    }
  }, [delay, timeFunction, clearTimeFunction]);
}

export { useTimeFunction as default };
