import { useState } from "react";
import GetApp from "@mui/icons-material/GetApp";
import { Button, Stack, Typography } from "@mui/material";
import { renderToString } from "react-dom/server";

import Event from "types/Event";
import Sale from "types/Sale";
import { FILE_FORMAT } from "utils/constants";
import { useDispatch } from "utils/StoreUtil";
import { downloadCsv, downloadPdf } from "api/outputRequests";
import { getEventRunlistExport, getSaleRunlistExport, formatName } from "actions/exportActions";
import { trackEvent } from "hooks/useTracking";
import useLanguage from "hooks/useLanguage";

import SimpleButtonAndMenu from "components/buttons/SimpleButtonAndMenu";
import SimpleTable from "components/pdf/SimpleTable";
import PrintFrame from "components/print/PrintFrame";
import SimpleTablePrint from "components/print/components/SimpleTable";

const fileFormats = Object.keys(FILE_FORMAT);

const DownloadReport: React.FC<{
  entity: Event | Sale;
  entityType: "event" | "sale";
  tooltip?: string;
  color?: string;
}> = ({ entity, entityType, tooltip, color }) => {
  const dispatch = useDispatch();

  const [busy, setBusy] = useState(false);
  const [printing, setPrinting] = useState(false);
  const [componentProps, setComponentProps] = useState<any>(null);

  const [exportLabel] = useLanguage("export");

  async function handleOnClick(fileFormat: string) {
    try {
      setBusy(true);

      let data: any = null;

      if (entityType === "event") {
        data = await dispatch(getEventRunlistExport(entity, fileFormat.toLowerCase(), "summary"));
      } else {
        data = await dispatch(getSaleRunlistExport(entity, fileFormat.toLowerCase(), "summary"));
      }

      if (data && fileFormat === FILE_FORMAT.PRINT) {
        setComponentProps(data);
        setPrinting(true);
        return;
      }

      const fileName = `runlist-${formatName(entity.name)}`;

      if (data && fileFormat === FILE_FORMAT.CSV) {
        await downloadCsv(fileName, JSON.stringify(data));
      }
      if (data && fileFormat === FILE_FORMAT.PDF) {
        const content = renderToString(<SimpleTable {...data} />);
        await downloadPdf(fileName, content);
      }
    } finally {
      setBusy(false);

      trackEvent({
        category: "Downloads",
        action: "download runlist",
        label: `${entityType}:${entity?.id}:${fileFormat}`
      });
    }
  }

  function cleanupPrint() {
    setComponentProps(null);
    setPrinting(false);
  }

  return (
    <>
      <SimpleButtonAndMenu
        ButtonComponent={Button}
        buttonInnerContent={
          <>
            <Stack justifyContent="center" alignItems="center">
              <GetApp sx={{ width: 24, height: 24 }} />
              <Typography color="inherit" sx={{ height: 20 }}>
                {exportLabel}
              </Typography>
            </Stack>
          </>
        }
        menuItems={fileFormats}
        buttonProps={{
          disabled: busy,
          title: tooltip,
          color: color,
          sx: {
            width: 48,
            height: 44,
            padding: 0,
            textTransform: "none"
          }
        }}
        onMenuItemClick={handleOnClick}
      />

      {printing && (
        <PrintFrame
          callback={cleanupPrint}
          trackedEvent={{
            category: "Downloads",
            action: "print runlist",
            label: `${entityType}:${entity.id}`
          }}
        >
          <SimpleTablePrint {...componentProps} />
        </PrintFrame>
      )}
    </>
  );
};

export default DownloadReport;
