import { useMemo } from "react";
import matchesPermissions from "utils/Permissions";
import { useSelector } from "utils/StoreUtil";

function useReportsRoute(permissions) {
  const showPostSaleReport = useSelector(state => state.config.usePostSaleReport);

  const showMarketReport = useSelector(state => state.config.showMarketReport);
  const auctionAccessId = useSelector(state => state.user?.user?.fields?.auctionAccessId);
  const showDashboard = useSelector(state => state.config.showDashboard);
  const hasDashboardPermission = useMemo(() => matchesPermissions(["viewDashboard"], permissions), [
    permissions
  ]);

  if (showDashboard && hasDashboardPermission) {
    return "/research/dashboard";
  } else if (showPostSaleReport) {
    return "/research/postsale";
  } else if (showMarketReport && auctionAccessId) {
    return "/research/market";
  } else {
    return null;
  }
}

export { useReportsRoute as default };
