import { useEffect } from "react";
import { Box, CircularProgress, Grid, Paper, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { getLandingPageResources } from "actions/landingPageActions";
import { canAttend } from "reducers/attendance";
import useLanguage from "hooks/useLanguage";
import { useSelector, useDispatch } from "utils/StoreUtil";
import { IStringIndex } from "utils/MediaUtil";
import { salesSelector } from "reducers/sales";
import SalePreview from "views/calendar/eventDetails/SalePreview";
import Company from "types/Company";
import { getCompanyById } from "reducers/companies";
import { resetCalendarEntities } from "actions/calendarActions";

const useStyles = makeStyles()(theme => ({
  root: {
    marginTop: theme.spacing(2)
  },
  salesCss: {
    backgroundColor: theme.palette.background.grey,
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  title: {
    marginBottom: theme.spacing(3),
    fontWeight: "bold"
  }
}));

const SaleList = () => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const [salesText] = useLanguage("sales");

  const showAttendViewOnly: boolean = useSelector((state: any) => state.config.showAttendViewOnly);
  const sales: Array<IStringIndex> = useSelector((state: any) =>
    salesSelector.selectAll(state.sales)
  );
  const filteredSales = showAttendViewOnly ? sales?.filter(sale => canAttend(sale)) : [];
  const isLoading = useSelector((state: any) => state.events.isLoading);
  const user = useSelector((state: any) => state.user?.user);
  const companies: Array<Company> = useSelector(state => state.companies.companies);

  const userId = user?.id;
  const hostCompanyId = user?.companyId;

  useEffect(() => {
    if (userId != null) {
      dispatch(getLandingPageResources());
    }

    return () => {
      dispatch(resetCalendarEntities());
    };
  }, [dispatch, userId]);

  if ((!isLoading && filteredSales.length === 0) || userId == null) {
    return null;
  }

  if (isLoading) {
    return (
      <Paper classes={{ root: classes.root }}>
        <CircularProgress color="inherit" />
      </Paper>
    );
  }

  const hostCompany = getCompanyById(companies, hostCompanyId);

  return (
    <Paper classes={{ root: classes.root }}>
      <Box
        paddingY={1}
        paddingX={2}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h6" align="left">
          Upcoming {salesText}
        </Typography>
      </Box>

      <div className={classes.salesCss}>
        <Grid spacing={2} container mt={0} justifyContent={"space-evenly"}>
          {filteredSales?.slice(0, 5).map(sale => (
            <Grid item key={sale.id}>
              <SalePreview saleId={sale.id} locale={hostCompany?.locale} />
            </Grid>
          ))}
        </Grid>
      </div>
    </Paper>
  );
};

export default SaleList;
