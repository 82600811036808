import { logout } from "actions/userActions";
import { flushCompanyUser } from "api/authRequests";
import { useMemo, useCallback, useState } from "react";
import { useSelector, useDispatch } from "utils/StoreUtil";

const useCompanyUserFlush = () => {
  const dispatch = useDispatch();

  const [isFlushing, setIsFlushing] = useState(false);

  const user = useSelector(state => state.user.user);

  const userId = user?.id;

  const flush = useCallback(async () => {
    try {
      setIsFlushing(true);

      await flushCompanyUser(userId);
    } finally {
      dispatch(logout());
      setIsFlushing(false);
    }
  }, [userId, dispatch]);

  return useMemo(
    () => ({
      flush,
      isFlushing
    }),
    [flush, isFlushing]
  );
};

export default useCompanyUserFlush;
