import { get } from "./api";

export async function getApplicationConfiguration() {
  const { data } = await get("application/rubix/configuration");
  return data;
}

export async function getAmsConfigurations() {
  const { data } = await get("application/community/ams/configuration");

  return data;
}

export async function getLanguageMap(language, marketSegment) {
  const { data } = await get(
    `application/rubix/language/${language}/marketSegment/${marketSegment}`
  );

  return data;
}
