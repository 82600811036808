import { PropsWithChildren, useEffect } from "react";

const getFields = (lot: any, asset: any, note: any, fields: any[], consignorText: string = "") => {
  const defaultFields: any = [];

  if (asset?.fields?.stockNumber) {
    defaultFields.push({ label: "Stock Number", value: asset?.fields?.stockNumber });
  }
  if (lot?.consignorName) {
    defaultFields.push({ label: `${consignorText} Name`, value: lot.consignorName });
  }
  if (lot?.consignorType) {
    defaultFields.push({ label: `${consignorText} Type`, value: lot.consignorType });
  }
  if (note?.note) {
    defaultFields.push({ label: "Notes", value: note.note });
  }
  if (asset?.fields?.options) {
    defaultFields.push({ label: "Equipment", value: asset.fields.options });
  }

  return Array.isArray(fields) ? defaultFields.concat(fields) : defaultFields;
};

const Column: React.FC<PropsWithChildren> = ({ children }) => {
  return <div style={{ boxSizing: "border-box", float: "left", width: "50%" }}>{children}</div>;
};
const ColumnItem: React.FC<PropsWithChildren> = ({ children }) => {
  return <div style={{ width: "100%", overflow: "hidden", marginBottom: "4px" }}>{children}</div>;
};
const LeftColumnItem: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <div
      style={{
        float: "left",
        width: "23%",
        fontWeight: "bold",
        whiteSpace: "normal",
        overflowWrap: "break-word",
        overflow: "hidden",
        textOverflow: "ellipsis"
      }}
    >
      {children}
    </div>
  );
};
const RightColumnItem: React.FC<PropsWithChildren> = ({ children }) => {
  return <div style={{ float: "right", width: "75%" }}>{children}</div>;
};

const FullDetailsPdf: React.FC<{
  title: string;
  lotNumberText: string;
  consignorText: string;
  inventory: any;
  triggerPrint?: () => void;
}> = ({ title, inventory, triggerPrint, lotNumberText = "", consignorText = "" }) => {
  useEffect(() => {
    triggerPrint?.();
  }, [triggerPrint]);

  return (
    <html>
      <body>
        <div>
          <h3>{title}</h3>
        </div>
        {inventory.map((item: any, i: number) => {
          const fields = getFields(item.lot, item.asset, item.note, item.fields, consignorText);

          const middle = Math.ceil(fields.length / 2);
          const leftColumnFields = fields.slice(0, middle);
          const rightColumnFields = fields.slice(middle);

          return (
            <div
              key={i}
              style={{
                borderBottom: "1px solid grey",
                pageBreakInside: "avoid"
              }}
            >
              <h3 style={{ marginBottom: "4px", marginTop: "4px" }}>
                {lotNumberText ?? ""} {item?.lot?.lotNumber ?? ""}{" "}
                {item?.asset?.uifields?.header?.value}
              </h3>
              <div style={{ overflow: "hidden" }}>
                <Column>
                  {leftColumnFields.map((field: any) => (
                    <ColumnItem>
                      <LeftColumnItem>{field.label}</LeftColumnItem>
                      <RightColumnItem>{field.value}</RightColumnItem>
                    </ColumnItem>
                  ))}
                </Column>

                <Column>
                  {rightColumnFields.map((field: any) => (
                    <ColumnItem>
                      <LeftColumnItem>{field.label}</LeftColumnItem>
                      <RightColumnItem>{field.value}</RightColumnItem>
                    </ColumnItem>
                  ))}
                </Column>
              </div>
            </div>
          );
        })}
      </body>
    </html>
  );
};

export default FullDetailsPdf;
