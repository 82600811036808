import { isEmpty } from "lodash";
import { createTermsFilter, createRangeFilter, createTermFilter } from "./SearchUtil";
import { firstLetterToLowerCase, firstLetterToUpperCase } from "./StringUtil";

const capitalLettersRegEx = /(?=[A-Z])/;

export const unfetchableSearchOptions = [
  "age",
  "asIs",
  "averageWeight",
  "baseWeight",
  "buyItNow",
  "dateOfBirth",
  "events",
  "grade",
  "groupedItems",
  "hasCR",
  "headCount",
  "keys",
  "mileage",
  "MMR",
  "pregnant",
  "quantity",
  "saleType",
  "titleStatus",
  "totalWeight",
  "upcomingInventory",
  "weaned",
  "weaningWeight",
  "yearlingWeight"
];

export const getAssetSearchFilters = selectedSearchOptions => {
  const filters = Object.keys(selectedSearchOptions)
    .filter(key => selectedSearchOptions[key] === true || !isEmpty(selectedSearchOptions[key]))
    .map(key => {
      let value = selectedSearchOptions[key];

      if (Array.isArray(value)) {
        return createTermsFilter(`asset.fields.${key}`, value);
      } else {
        const searchOptionStrings = key.split(capitalLettersRegEx);

        if (searchOptionStrings[0] === "from") {
          return createRangeFilter(
            `asset.fields.${firstLetterToLowerCase(key.replace("from", ""))}`,
            value,
            "GTE"
          );
        }

        if (searchOptionStrings[0] === "to") {
          return createRangeFilter(
            `asset.fields.${firstLetterToLowerCase(key.replace("to", ""))}`,
            value,
            "LTE"
          );
        } else {
          return createTermFilter(`asset.fields.${key}`, value);
        }
      }
    });

  return filters;
};

export const getFromRangeSearchOption = searchOption => getRangeSearchOption(searchOption, "from");

export const getToRangeSearchOption = searchOption => getRangeSearchOption(searchOption, "to");

export const toggleArrayValue = (array, value) => {
  return array.includes(value)
    ? array.filter(searchOption => searchOption !== value)
    : [...array, value];
};

export const getSelectedSearchOptionCount = (
  selectedSearchOptions = {},
  filterEventOrSales = false
) =>
  Object.keys(selectedSearchOptions).reduce((count, key) => {
    if (filterEventOrSales && (key === "sales" || key === "events")) return count;

    if (Array.isArray(selectedSearchOptions[key])) {
      count += selectedSearchOptions[key].length;
    } else if (selectedSearchOptions[key]) {
      count++;
    }

    return count;
  }, 0);

export const mapSelectedSearchOption = (selectedSearchOption = "") => {
  if (selectedSearchOption.startsWith("from") || selectedSearchOption.startsWith("to")) {
    return firstLetterToLowerCase(selectedSearchOption.replace(/^from|to/, ""));
  } else if (selectedSearchOption === "odometerUnit") {
    return "mileage";
  }

  return selectedSearchOption;
};

export const shouldFetchSearchOption = (
  selectedSearchOption,
  openedSearchOptionName,
  openedSearchOptionValue = false,
  selectedSearchOptions = {}
) => {
  const searchOption = mapSelectedSearchOption(selectedSearchOption);

  return (
    openedSearchOptionValue &&
    (searchOption !== openedSearchOptionName ||
      (selectedSearchOption === "make" && selectedSearchOptions.make.length === 0)) &&
    !unfetchableSearchOptions.includes(openedSearchOptionName)
  );
};

const getRangeSearchOption = (searchOption, rangeDirection) =>
  `${rangeDirection}${firstLetterToUpperCase(searchOption)}`;
