import { get, post, del, put, getTotal } from "./api";

export async function getCompanyUsers(filters, range, sorts, extraHeaders) {
  const res = await get("companyUser", filters, range, sorts, undefined, extraHeaders);

  return { data: res.data, total: getTotal(res) };
}

export async function getInventoryCompanyUsers(filters, range, sort) {
  const { data } = await get("application/community/companyUsers", filters, range, sort);
  return data;
}

export async function createCompanyUser(user) {
  const { data } = await post("companyUser", user);

  return data;
}

export async function tryUpdateCompanyUser(user) {
  try {
    await put("application/community/communityUser", user, undefined, {
      shouldShowError: false
    });
  } catch (error) {}
}

export async function registerCompanyUser(user, membershipFields) {
  const { data } = await post("application/community/registration", {
    user,
    membershipFields
  });
  return data;
}

export async function updateCompanyUser(user) {
  await put("application/community/communityUser", user);
}

export async function updateUser(user) {
  await put(`companyUser/${user.id}`, user);
}

export async function validateAuctionAccessId(user, dealershipProvider = "AuctionAccess") {
  const { data } = await post(`application/community/validateUser/${dealershipProvider}`, user);
  return data;
}

export async function getSavedSearches(filters, range, sorts) {
  const { data } = await get("application/community/savedSearch", filters, range, sorts);

  return data;
}

export async function saveSearch(search) {
  const { data } = await post("application/community/savedSearch", search);

  return data;
}

export async function updateSavedSearch(search) {
  await put(`application/community/savedSearch/${search.id}`, search);
}

export async function deleteSavedSearch(searchId) {
  await del(`application/community/savedSearch/${searchId}`);
}

export async function getBuyerDetailsFromAuth(user) {
  const { data } = await post("application/community/admin/checkDealerships", user);
  return data;
}

export async function adminSyncDealerships(userId) {
  const { data } = await get(`application/community/admin/syncDealerships/${userId}`);
  return data;
}

export async function requestVerificationCode(user) {
  await put(`companyUser/${user.id}/verificationCode`, user);
}

export async function verifyVerificationCode(userId, verificationCode) {
  await put(`companyUser/${userId}/verificationCode/${verificationCode}`);
}

export async function getFavorites(filters, range) {
  const res = await get("favorite", filters, range);
  return { data: res.data, total: getTotal(res) };
}

export async function createFavorite(favorite = {}) {
  const { data } = await post("favorite", favorite);
  return data;
}

export async function removeFavorite(favoriteId) {
  const { data } = await del(`favorite/${favoriteId}`);
  return data;
}
