import { store } from "store";
import {
  TypedUseSelectorHook,
  useDispatch as useReduxDispatch,
  useSelector as useReduxSelector
} from "react-redux";

// workaround for avoiding typing all of the reducers and actions
// TODO: move to store file

export type RootState = ReturnType<typeof store.getState>;
type AppDispatch = typeof store.dispatch;

export * from "react-redux";

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useDispatch: () => AppDispatch = useReduxDispatch;
export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;
