import React, { Component } from "react";
import ErrorPage from "views/error/ErrorPage";
import { trackError } from "hooks/useTracking";
import { connect } from "utils/StoreUtil";

class ErrorBoundary extends Component {
  state = {
    hasError: false
  };

  componentDidCatch(error, info) {
    const {
      user: { firstName, lastName, id, companyId }
    } = this.props;
    this.setState({ hasError: true });
    const description = {
      error: error.message,
      info: JSON.stringify(info.componentStack),
      user: { firstName, lastName, id, companyId }
    };
    const descriptionString = JSON.stringify(description);
    trackError(descriptionString);
  }

  clearError = () => this.setState({ hasError: false });

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return <ErrorPage clearError={this.clearError} />;
    }

    return children;
  }
}

const mapState = state => ({ user: state.user.user });

export default connect(mapState)(ErrorBoundary);
