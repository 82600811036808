import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "utils/StoreUtil";
import { makeStyles } from "tss-react/mui";
import Button from "@mui/material/Button";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import Link from "@mui/material/Link";
import Popper from "@mui/material/Popper";
import Paper from "@mui/material/Paper";
import { getRecentSearches } from "actions/recentSearchActions";
import { setQueryString as setSavedQueryString } from "actions/inventoryActions";
import Hidden from "@mui/material/Hidden";
import Typography from "@mui/material/Typography";

const useStyles = makeStyles()(theme => ({
  recentSearches: {
    zIndex: "10000"
  },
  recentSearchesList: {
    padding: "10px",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      boxShadow: "none",
      borderRadius: 0,
      border: "none",
      textAlign: "left",
      borderTop: "1px solid #ddd"
    }
  },
  recentButton: {
    fontSize: "13px",
    width: "175px",
    paddingRight: "10px"
  },
  recentIcon: {
    paddingLeft: "10px"
  },
  recentSearchItem: {
    display: "block",
    cursor: "pointer",
    width: "155px",
    boxSizing: "border-box",
    paddingBottom: "5px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    "&:last-of-type": {
      paddingBottom: 0
    },
    [theme.breakpoints.down("md")]: {
      width: "345px"
    }
  },
  recentSearchTitle: {
    color: theme.palette.common.black,
    fontSize: "15px",
    fontWeight: "normal",
    letterSpacing: ".1px",
    margin: "0 0 10px",
    padding: 0
  }
}));

const RecentSearchList = ({ recentList, handleSearchItemClick }) => {
  const { classes } = useStyles();

  return (
    <Paper className={classes.recentSearchesList}>
      <Hidden mdUp>
        <Typography variant="h2" className={classes.recentSearchTitle}>
          Recent Searches:
        </Typography>
      </Hidden>
      {recentList.map((searchItem, index) => (
        <Link
          className={classes.recentSearchItem}
          key={index}
          onClick={evt => handleSearchItemClick(searchItem, evt)}
        >
          {searchItem}
        </Link>
      ))}
    </Paper>
  );
};

const RecentSearches = ({ inputRef, onUpdate }) => {
  const [isOpen, setIsOpen] = useState(false);
  const recentList = useSelector(state => state.recentSearches.recentSearchList);
  const isAuthorized = useSelector(state => state.user.isAuthorized);
  const { classes } = useStyles({ isOpen: isOpen });
  const anchorRef = useRef(null);
  const dispatch = useDispatch();

  const toggleIsOpen = evt => {
    setIsOpen(isOpen => !isOpen);
    if (evt.nativeEvent) evt.nativeEvent.stopImmediatePropagation();
  };

  const handleSearchItemClick = (searchItem, evt) => {
    toggleIsOpen(evt);
    const input = inputRef.current;
    input.value = searchItem;
    dispatch(setSavedQueryString(searchItem));
    onUpdate();
  };

  useEffect(() => {
    if (isAuthorized && isOpen) {
      dispatch(getRecentSearches());
    }
  }, [isAuthorized, dispatch, isOpen]);

  if (!recentList.length) return null;

  return (
    <React.Fragment>
      <Hidden mdDown>
        <Button ref={anchorRef} className={classes.recentButton} onClick={toggleIsOpen}>
          Recent Searches <KeyboardArrowDown className={classes.recentIcon} />
        </Button>
        <ClickAwayListener onClickAway={toggleIsOpen}>
          <Popper className={classes.recentSearches} open={isOpen} anchorEl={anchorRef.current}>
            <RecentSearchList
              recentList={recentList}
              handleSearchItemClick={handleSearchItemClick}
            />
          </Popper>
        </ClickAwayListener>
      </Hidden>

      <Hidden mdUp>
        <RecentSearchList recentList={recentList} handleSearchItemClick={handleSearchItemClick} />
      </Hidden>
    </React.Fragment>
  );
};

export default RecentSearches;
