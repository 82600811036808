import { createSlice, createEntityAdapter, createSelector } from "@reduxjs/toolkit";
import { usersSelector } from "./users";
import { NOTIFICATION_GROUP_TYPE } from "utils/constants";
import { REHYDRATE } from "redux-persist/lib/constants";

const NAME = "notificationGroups";

const notificationGroupsAdapter = createEntityAdapter({
  selectId: notificationGroup => notificationGroup.companyId
});

const initialState = {
  isLoading: true,
  total: 0,
  isSaving: false
};

function fetchComplete(state, action) {
  const { total, notificationGroups } = action.payload;

  state.isLoading = false;

  if (Array.isArray(notificationGroups)) {
    notificationGroupsAdapter.setAll(state, notificationGroups);

    if (total != null) {
      state.total = total;
    }
  }
}

const notificationGroups = createSlice({
  name: NAME,
  initialState: notificationGroupsAdapter.getInitialState(initialState),
  reducers: {
    fetchNotificationGroups(state) {
      state.isLoading = true;
    },
    fetchNotificationGroupsComplete: {
      reducer: fetchComplete,
      prepare: (notificationGroups, total) => ({ payload: { notificationGroups, total } })
    },
    save(state) {
      state.isSaving = true;
    },
    saveComplete(state) {
      state.isSaving = false;
    },
    addNotificationGroup(state, action) {
      const notificationGroup = action.payload;
      if (notificationGroup) {
        notificationGroupsAdapter.addOne(state, notificationGroup);
      }
    },
    updateNotificationGroup(state, action) {
      const update = action.payload;
      if (update) {
        notificationGroupsAdapter.updateOne(state, update);
      }
    }
  },
  extraReducers: builder => {
    builder.addCase(REHYDRATE, () => notificationGroupsAdapter.getInitialState(initialState));
  }
});

function getUsers(state) {
  return usersSelector.selectEntities(state);
}
function getUserIds(_, userIds) {
  return userIds;
}

export function makeGetNotificationGroupUsers() {
  return createSelector(getUsers, getUserIds, (usersObj, userIds) =>
    userIds.reduce((users, userId) => {
      const user = usersObj[userId];
      if (user) {
        users.push(user);
      }
      return users;
    }, [])
  );
}

export function toggleCustomerAdminNotificationGroupType(notificationTemplate) {
  const notificationGroupTypes = notificationTemplate.notificationGroupTypes;
  const includesCustomerAdmin = notificationGroupTypes.includes(
    NOTIFICATION_GROUP_TYPE.CUSTOMER_ADMIN
  );
  return {
    ...notificationTemplate,
    notificationGroupTypes: includesCustomerAdmin
      ? notificationGroupTypes.filter(
          groupType => groupType !== NOTIFICATION_GROUP_TYPE.CUSTOMER_ADMIN
        )
      : [...notificationGroupTypes, NOTIFICATION_GROUP_TYPE.CUSTOMER_ADMIN]
  };
}

export const notificationGroupsSelector = notificationGroupsAdapter.getSelectors(
  state => state[NAME]
);

export const directNotificationGroupsSelectors = notificationGroupsAdapter.getSelectors();

const { reducer, actions } = notificationGroups;
export const {
  fetchNotificationGroups,
  fetchNotificationGroupsComplete,
  addNotificationGroup,
  updateNotificationGroup,
  save,
  saveComplete
} = actions;
export default reducer;
