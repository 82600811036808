import { PureComponent } from "react";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import { connect } from "utils/StoreUtil";
import { withStyles } from "tss-react/mui";
import { compose } from "@reduxjs/toolkit";
import lowerCase from "lodash/lowerCase";
import { updateServerAppState } from "actions/serverAppStateActions";
import { setDealershipId } from "actions/attendanceActions";

const styles = theme => ({
  secondaryText: {
    textTransform: "capitalize"
  }
});

function getTypographyProps(title) {
  return {
    noWrap: true,
    title
  };
}

class DealershipMenuItem extends PureComponent {
  handleClick = async () => {
    const { handleClose, updateServerAppState, dealership, setDealershipId } = this.props;
    await setDealershipId(dealership.id);
    updateServerAppState();
    handleClose();
  };
  render() {
    const { selectedDealershipId, dealership, classes } = this.props;
    const secondaryText =
      dealership.city &&
      dealership.state &&
      `${lowerCase(dealership.city)}, ${lowerCase(dealership.state)}`;

    return (
      <MenuItem onClick={this.handleClick} selected={dealership.id === selectedDealershipId}>
        <ListItemText
          classes={{ secondary: classes.secondaryText }}
          primary={dealership.name}
          primaryTypographyProps={getTypographyProps(dealership.name)}
          secondary={secondaryText}
          secondaryTypographyProps={getTypographyProps(secondaryText)}
        />
      </MenuItem>
    );
  }
}

const mapStateToProps = ({ attendance: { selectedDealershipId } }) => ({
  selectedDealershipId
});

const mapDispatchToProps = { updateServerAppState, setDealershipId };

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  withStyles(DealershipMenuItem, styles)
);
