export function makeLightsString(fields = {}) {
  let arr = [];
  if (fields.greenLight) arr.push("GREEN");
  if (fields.yellowLight) arr.push("YELLOW");
  if (fields.redLight) arr.push("RED");
  if (fields.blueLight) arr.push("BLUE");
  if (fields.whiteLight) arr.push("WHITE");
  return arr.join(", ");
}

export function getTitle(fields) {
  let arr = [];
  if (fields?.titleStatus) arr.push(fields.titleStatus);
  if (fields?.titleState) arr.push(fields.titleState);
  if (arr.length > 0) return arr.join(" ");
  else return null;
}
