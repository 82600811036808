import {
  DETAILS_FETCH_USER_DEALERSHIP_DETAILS,
  DETAILS_USER_DEALERSHIP_SET_DETAILS,
  USER_UPDATE_ATTEMPT,
  USER_UPDATE_RESOLUTION,
  DETAILS_SYNC_DEALERSHIPS,
  DETAILS_SYNC_DEALERSHIPS_COMPLETE
} from "actions/actionTypes";

export const initialState = {
  isLoading: true,
  isUpdating: false,
  details: null,
  members: [],
  events: [],
  topAssets: [],
  assetProp: "make",
  isSyncing: false
};

export default function userDealershipDetails(state = initialState, action) {
  switch (action.type) {
    case DETAILS_FETCH_USER_DEALERSHIP_DETAILS:
      return {
        ...state,
        isLoading: true
      };
    case DETAILS_USER_DEALERSHIP_SET_DETAILS:
      return {
        ...state,
        details: action.payload.detailsData.details,
        members: action.payload.detailsData.members,
        events: action.payload.detailsData.events,
        topAssets: getTopAssets(action.payload.detailsData.topAssets),
        isLoading: false
      };
    case USER_UPDATE_ATTEMPT:
      return {
        ...state,
        isUpdating: true
      };
    case USER_UPDATE_RESOLUTION:
      return {
        ...state,
        details: action.payload ? action.payload.user : state.details,
        isUpdating: false
      };
    case DETAILS_SYNC_DEALERSHIPS:
      return {
        ...state,
        isSyncing: true
      };
    case DETAILS_SYNC_DEALERSHIPS_COMPLETE:
      if (action.payload) {
        return {
          ...state,
          isSyncing: false,
          members: action.payload,
          details: {
            ...state.details,
            lastDealershipSync: Date.now()
          }
        };
      }
      return {
        ...state,
        isSyncing: false
      };
    default:
      return state;
  }
}

function getTopAssets(topAssets = []) {
  return topAssets.sort((a, b) => b.count - a.count).slice(0, 5);
}
