import _toLower from "lodash/toLower";
import _startCase from "lodash/startCase";
import _capitalize from "lodash/startCase";

export { _toLower as toLowerCase };
export { _startCase as startCase };
export { _capitalize as capitalize };

export function isEmpty(value) {
  return typeof value === "string" && value.trim().length === 0;
}

export function toCamelCase(str) {
  return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function(match, index) {
    if (+match === 0) return "";
    return index === 0 ? match.toLowerCase() : match.toUpperCase();
  });
}

export function firstLetterToUpperCase(value) {
  if (!isEmpty(value)) {
    const firstLetter = value.slice(0, 1).toUpperCase();

    return `${firstLetter}${value.slice(1)}`;
  }
}

export function firstLetterToLowerCase(value) {
  if (!isEmpty(value)) {
    const firstLetter = value.slice(0, 1).toLowerCase();

    return `${firstLetter}${value.slice(1)}`;
  }
}

export function replaceSpecialCharacters(value) {
  if (!isEmpty(value)) {
    return value.replace(/[&^\\#,+()$~%.'"*?<>{};]/g, "");
  }
}

export function ellipsisAfterMax(str, maxCharacters) {
  if (str && str.length > maxCharacters) {
    return str.slice(0, maxCharacters - 3).concat("...");
  }
  return str;
}
