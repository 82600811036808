import { useDispatch, useSelector } from "utils/StoreUtil";
import { Link, useLocation } from "react-router-dom";
import { makeStyles } from "tss-react/mui";
import Button from "@mui/material/Button";

import { SET_REDIRECT_URL } from "actions/actionTypes";

const useStyles = makeStyles()(theme => ({
  root: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%"
  },
  button: {
    color: theme.palette.primary.contrastText,
    [theme.breakpoints.up("md")]: {
      marginLeft: theme.spacing(1)
    },
    [theme.breakpoints.down("lg")]: {
      marginRight: theme.spacing(1)
    },
    whiteSpace: "nowrap"
  }
}));

const BLACK_LIST = ["/login", "/register"];

export function useSavedRedirect() {
  const dispatch = useDispatch();
  const location = useLocation();

  return function saveRedirect() {
    if (BLACK_LIST.includes(location.pathname)) {
      return;
    }
    dispatch({
      type: SET_REDIRECT_URL,
      payload: { url: location.pathname }
    });
  };
}

const LoginOrRegister = () => {
  const { classes } = useStyles();
  const saveRedirect = useSavedRedirect();

  const useExternalLogin = useSelector(state => state.config.useExternalLogin);

  function handleClick() {
    saveRedirect();
  }

  return (
    <div className={classes.root}>
      {!useExternalLogin && <RegisterButton classes={classes} onClick={handleClick} />}
      {!useExternalLogin && <LoginButton classes={classes} onClick={handleClick} />}
    </div>
  );
};

export const RegisterButton = ({ classes = {}, onClick, testIdPrefix = "", ...props }) => {
  return (
    <Button
      color="primary"
      {...props}
      component={Link}
      to="/register"
      onClick={onClick}
      className={classes.button}
      data-testid={`${testIdPrefix}register-button`}
    >
      Register
    </Button>
  );
};

export const LoginButton = ({ classes = {}, onClick, testIdPrefix = "", ...props }) => {
  return (
    <Button
      color="primary"
      {...props}
      component={Link}
      to="/login"
      onClick={onClick}
      className={classes.button}
      data-testid={`${testIdPrefix}login-button`}
    >
      Log in
    </Button>
  );
};

export default LoginOrRegister;
