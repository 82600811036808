import { memo, useState, useRef } from "react";
import { Button, Hidden, MenuItem, ListItemIcon, ListItemText, Box } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import ExitToApp from "@mui/icons-material/ExitToApp";
import NotificationIcon from "@mui/icons-material/Notifications";
import EmailIcon from "@mui/icons-material/Email";
import { logout } from "actions/userActions";
import { useSelector, useDispatch } from "utils/StoreUtil";
import { Link } from "react-router-dom";
import PopperMenu from "./PopperMenu";
import DealershipSelectMenu from "./DealershipSelectMenu";
import UserAvatar from "components/UserAvatar";
import DarkModeSwitch from "./DarkModeSwitch";
import UserDealershipSyncMessage from "./UserDealershipSyncMessage";
import UserSidebarHeader from "../views/user/UserSidebarHeader";
import NotesIcon from "@mui/icons-material/Notes";
import Settings from "@mui/icons-material/Settings";
import Person from "@mui/icons-material/Person";
import { EXTERNAL_URL } from "utils/constants";

const useStyles = makeStyles()((theme, { isOpen }) => ({
  root: {
    display: "flex",
    color: theme.palette.getContrastText(theme.palette.company.header)
  },
  icon: {
    marginRight: theme.spacing(1),
    height: 24,
    width: 24,
    boxShadow: theme.shadows[1],
    [theme.breakpoints.down("md")]: {
      marginRight: 0
    }
  },
  button: {
    [theme.breakpoints.down("md")]: {
      padding: 0,
      minWidth: 0
    }
  },
  label: {
    display: "flex"
  },
  arrowIcon: {
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down("md")]: {
      marginLeft: -theme.spacing(1),
      width: "30px"
    }
  },
  popper: {
    zIndex: isOpen ? 2000 : -1,
    width: "244px",
    top: 0,
    [theme.breakpoints.down("md")]: {
      top: "12px !important"
    },
    "& .MuiPaper-rounded": {
      borderRadius: "0px"
    }
  },
  badge: {
    height: theme.spacing(3),
    width: theme.spacing(3),
    right: -4,
    top: -8,
    border: `2px solid ${theme.palette.primary.main}`
  },
  textPrimary: {
    color: theme.palette.text.primary
  }
}));

const defaultComponentProps = {
  size: "medium",
  variant: "text",
  color: "inherit"
};

const MenuButton = props => {
  const {
    keepMounted,
    as = Button,
    componentProps = defaultComponentProps,
    MenuProps = {}
  } = props;
  const Component = as;

  const dispatch = useDispatch();

  const auctionAccessId = useSelector(state => state.user.user.fields?.auctionAccessId);

  const buttonRef = useRef(null);

  const [isOpen, setIsOpen] = useState(false);

  const { classes } = useStyles({ isOpen });

  const useExternalLogin = useSelector(state => state.config.useExternalLogin);

  const handleLogout = () => dispatch(logout());

  const handleClick = () => {
    setIsOpen(isOpen => !isOpen);
  };

  const handleClose = evt => {
    if (buttonRef?.current?.contains?.(evt.target)) return;

    setIsOpen(false);
  };

  return (
    <div className={classes.root}>
      <Component
        {...componentProps}
        onClick={handleClick}
        classes={{ root: classes.button, label: classes.label }}
        ref={buttonRef}
        aria-owns={isOpen ? "menu-list-grow" : null}
        aria-haspopup="true"
        data-testid="profile-menu-button"
      >
        <UserAvatar className={classes.icon} />
        <Hidden mdDown>
          <ArrowDropDown className={classes.arrowIcon} />
        </Hidden>
      </Component>
      <PopperMenu
        isOpen={isOpen}
        anchorEl={buttonRef.current}
        handleClose={handleClose}
        classes={{ popper: classes.popper }}
        keepMounted={keepMounted && auctionAccessId}
        square
        {...MenuProps}
      >
        <Hidden mdUp>
          <Box mx="auto" className={classes.textPrimary}>
            <UserSidebarHeader />
          </Box>
          <>
            <DealershipSelectMenu anchorVariant="menuItem" />
            <UserDealershipSyncMessage variant="menuItem" />
          </>
        </Hidden>
        {process.env.NODE_ENV === "development" && <DarkModeSwitch />}
        {!useExternalLogin && (
          <MenuItem onClick={handleClose} component={Link} to="/user/editProfile">
            <ListItemIcon className={classes.textPrimary}>
              <Person />
            </ListItemIcon>
            <ListItemText primary="Profile" />
          </MenuItem>
        )}
        {!useExternalLogin && (
          <MenuItem onClick={handleClose} component={Link} to="/user/accountSettings">
            <ListItemIcon className={classes.textPrimary}>
              <Settings />
            </ListItemIcon>
            <ListItemText primary="Manage Account" />
          </MenuItem>
        )}
        <MenuItem onClick={handleClose} component={Link} to="/user/notifications">
          <ListItemIcon className={classes.textPrimary}>
            <NotificationIcon />
          </ListItemIcon>
          <ListItemText primary="Notifications" />
        </MenuItem>
        <MenuItem onClick={handleClose} component={Link} to="/user/profile#savedemails">
          <ListItemIcon className={classes.textPrimary}>
            <EmailIcon />
          </ListItemIcon>
          <ListItemText primary="Mailing List" />
        </MenuItem>
        <MenuItem
          onClick={handleClose}
          component={Link}
          to={{ pathname: EXTERNAL_URL.RELEASE_NOTES }}
          target="_blank"
        >
          <ListItemIcon className={classes.textPrimary}>
            <NotesIcon />
          </ListItemIcon>
          <ListItemText primary="Release Notes" />
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          <ListItemIcon className={classes.textPrimary}>
            <ExitToApp />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </MenuItem>
      </PopperMenu>
    </div>
  );
};

export default memo(MenuButton);
