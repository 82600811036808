import { get, put, post } from "./api";

export async function getStream(streamId) {
  const { data } = await get(`/stream/${streamId}`);
  return data;
}

export async function getFacility(facilityId) {
  const { data } = await get(`/facility/${facilityId}`);
  return data;
}

export async function getTime() {
  const { data } = await get("/time");
  return data;
}

export async function getServerAppState() {
  const { data } = await get("application/rubix/state");
  return data;
}

export async function updateServerAppState(appState) {
  const { data } = await put("application/rubix/state", appState);
  return data;
}

export async function verifyCaptchaToken(token) {
  const { data } = await post("/companyUser/verifyCaptchaToken", { accessToken: token });
  return data;
}
