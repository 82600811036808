import { memo } from "react";
import { Typography, Divider, Badge, Card, Stack } from "@mui/material";
import NotificationIcon from "@mui/icons-material/MailOutlined";
import moment from "moment";
import WithClickToNewWindow from "components/WithClickToNewWindow";
import {
  getNotificationSecondaryTextByType,
  getNotificationPathByType
} from "reducers/notifications";
import ChevronRight from "@mui/icons-material/ArrowForwardIos";
import { makeStyles } from "tss-react/mui";
import { useSelector } from "utils/StoreUtil";
import { getNotificationTemplateTypeLabel } from "utils/NotificationUtil";

const useStyles = makeStyles()((theme, { shouldOpenNewWindow }) => ({
  root: Object.assign(
    {
      padding: theme.spacing(0, 2),
      display: "block",
      textDecoration: "none",
      borderRadius: 0,
      width: 290,
      [theme.breakpoints.up("md")]: {
        width: 480
      }
    },
    shouldOpenNewWindow ? { "&:hover": { boxShadow: theme.shadows[2] } } : {}
  ),
  contentWrapper: {
    display: "flex",
    alignItems: "center",
    position: "relative",
    width: "100%",
    padding: theme.spacing(0.5, 0)
  },
  iconWrapper: {
    padding: theme.spacing(1),
    marginRight: theme.spacing(2),
    borderRadius: "50%",
    backgroundColor: theme.palette.primary.light,
    display: "flex",
    alignItems: "center",
    "& > svg": {
      color: theme.palette.getContrastText(theme.palette.company.header)
    }
  },
  assetText: {
    textTransform: "lowercase"
  },
  badge: {
    height: theme.spacing(0.75),
    width: theme.spacing(0.75),
    left: -theme.spacing(1.75),
    top: "50%"
  },
  arrowIcon: {
    "& svg": {
      color: theme.palette.divider
    }
  },
  companyName: {
    textTransform: "capitalize"
  }
}));

const Notification = props => {
  const { notification = {}, displayDivider } = props;
  const { notificationTemplateType, createdOn, isNewNotification, lotId, lot } = notification;
  const secondaryText = getNotificationSecondaryTextByType(notification);
  const companyName = notification.company?.name;
  const path = getNotificationPathByType(notification);
  const companies = useSelector(state => state.companies.companies);
  const company = companies?.find(company => company.id === notification.companyId);

  let shouldOpenNewWindow = secondaryText && company;

  if (lotId) {
    shouldOpenNewWindow = shouldOpenNewWindow && lot?.status !== "WITHDRAWN";
  }

  const { classes } = useStyles({ shouldOpenNewWindow });
  return (
    <div>
      <WithClickToNewWindow
        id={lotId}
        disabled={!shouldOpenNewWindow}
        component={Card}
        elevation={0}
        path={path}
        className={classes.root}
      >
        <div className={classes.contentWrapper}>
          <Badge
            color="primary"
            badgeContent={""}
            invisible={!isNewNotification}
            variant="dot"
            classes={{ badge: classes.badge }}
          >
            <div className={classes.iconWrapper}>
              <NotificationIcon fontSize="small" color="primary" />
            </div>
          </Badge>

          <Stack spacing={-0.5} sx={{ width: "100%" }}>
            <Typography align="left" variant="caption" color="textPrimary" noWrap>
              {getNotificationTemplateTypeLabel(notificationTemplateType)}
            </Typography>
            {companyName && (
              <Typography align="left" variant="caption" noWrap className={classes.companyName}>
                {companyName}
              </Typography>
            )}
            <Typography align="left" variant="caption" noWrap className={classes.assetText}>
              {secondaryText}
            </Typography>
            <Typography align="left" variant="caption" color="textSecondary" noWrap>
              {moment(createdOn).fromNow()}
            </Typography>
          </Stack>
          {shouldOpenNewWindow && (
            <div className={classes.arrowIcon}>
              <ChevronRight fontSize="small" color="textSecondary" />
            </div>
          )}
        </div>
        {displayDivider && <Divider />}
      </WithClickToNewWindow>
    </div>
  );
};

export default memo(Notification);
