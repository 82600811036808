import { Box } from "@mui/material";
import { ClippedTypography } from "components/typography/ClippedTypography";
import ReadMoreContainer from "components/ReadMoreContainer";
import { useTypographyHeight } from "hooks/theme/useTypographyHeight";

const PreviewDescription: React.FC<{
  text: string | undefined;
  viewable?: boolean;
}> = ({ text = "", viewable = false }) => {
  const typographyVariant = "body2";
  const typographyHeight = useTypographyHeight(typographyVariant);

  if (viewable) {
    return (
      <Box sx={{ textAlign: "left" }}>
        <ReadMoreContainer maxLines={2} text={text} typographyVariant={typographyVariant} />
      </Box>
    );
  }

  return (
    <ClippedTypography
      lines={1}
      variant={typographyVariant}
      color="textSecondary"
      align="left"
      sx={{ height: typographyHeight }}
    >
      {text}
    </ClippedTypography>
  );
};

export { PreviewDescription as default, PreviewDescription };
