import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { createTermFilter } from "utils/SearchUtil";

export const salesApi = createApi({
  reducerPath: "salesApi",
  baseQuery: fetchBaseQuery({
    baseUrl: window.location.protocol + "//" + window.location.host + "/services/"
  }),
  endpoints: builder => ({
    getSaleRangeLotCounts: builder.query<Array<Record<string, string | number>>, number>({
      query: saleId => ({
        url: `application/community/searchOption/rangeNumber`,
        headers: {
          Filters: JSON.stringify([createTermFilter("sale.id", saleId, "MUST")])
        }
      })
    })
  })
});

export const { useGetSaleRangeLotCountsQuery } = salesApi;
