interface CustomCurrencyOptions {
  symbol: string;
  formattingCurrency: string;
  prefixed: boolean;
}

type CustomCurrencyMap = {
  [currency: string]: CustomCurrencyOptions;
};

const customCurrencies: CustomCurrencyMap = {
  GNS: {
    symbol: "GN",
    formattingCurrency: "GBP",
    prefixed: true
  }
};

interface FormatNumberOptions {
  style?: "currency" | "number";
  currency?: string;
  showFractionalDigits?: boolean;
  removeCurrencySymbol?: boolean;
}

class IntlUtil {
  removeSymbolsRegEx = /[^,.0-9]/g;
  keepSymbolsRegEx = /(0|\.|,|\s)/g;

  locale;
  currency;

  constructor(currency?: string) {
    this.currency = currency || "USD";
    // @ts-ignore userLanguage is used by IE
    this.locale = window?.navigator?.userLanguage || window?.navigator?.language || "en-US";
  }

  formatNumber(amount: number, options: FormatNumberOptions = {}): string {
    const optionsWithDefaults = Object.assign(getFormatNumberDefaults(options), options);

    if (optionsWithDefaults.style === "number") {
      return this._toNumber(amount, optionsWithDefaults);
    }

    if (optionsWithDefaults.style === "currency") {
      if (optionsWithDefaults.currency! in customCurrencies) {
        return this._toCustomCurrency(amount, optionsWithDefaults);
      }

      return this._toCurrency(amount, optionsWithDefaults);
    }

    return "";
  }

  _toCustomCurrency(amount: number, options: Partial<FormatNumberOptions>) {
    const customCurrencyOptions = customCurrencies[options.currency || this.currency];

    const value = this._toCurrency(amount, {
      ...options,
      currency: customCurrencyOptions.formattingCurrency
    });

    const strippedValue = value?.replace(this.removeSymbolsRegEx, "").trim();

    return customCurrencyOptions.prefixed
      ? `${customCurrencyOptions.symbol}${strippedValue}`
      : `${strippedValue}${customCurrencyOptions.symbol}`;
  }

  _toCurrency(amount: number, options: Partial<FormatNumberOptions>) {
    const value = new Intl.NumberFormat(this.locale, {
      style: "currency",
      currency: options.currency || this.currency,
      // currencyDisplay: "narrowSymbol",
      minimumFractionDigits: options.showFractionalDigits ? 2 : 0,
      maximumFractionDigits: options.showFractionalDigits ? 2 : 0
    }).format(amount);

    return options.removeCurrencySymbol
      ? value?.replace(this.removeSymbolsRegEx, "").trim()
      : value;
  }

  _toNumber(amount: number, options: Partial<FormatNumberOptions>) {
    return new Intl.NumberFormat(this.locale, {
      minimumFractionDigits: options.showFractionalDigits ? 2 : 0,
      maximumFractionDigits: options.showFractionalDigits ? 2 : 0
    }).format(amount);
  }
}

const defaultIntlUtil = new IntlUtil();

export { defaultIntlUtil, customCurrencies };

export default IntlUtil;

function getFormatNumberDefaults(options: FormatNumberOptions) {
  const defaults = { style: "number", showFractionalDigits: false, removeCurrencySymbol: false };
  if (options.style === "currency") {
    defaults.showFractionalDigits = true;
    defaults.removeCurrencySymbol = false;
  }
  return defaults;
}
