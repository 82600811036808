import { DeliveryType, NotificationTemplateType } from "types/notification";
import { DeliveryPreference } from "types/user";
import { startCase } from "./StringUtil";

export type HandleNotificationDeliveryTypeOnChange = (
  deliveryPreference: DeliveryPreference,
  notificationTemplateType: NotificationTemplateType,
  deliveryType: DeliveryType | "ALL"
) => void;

export const getNotificationTemplateTypeLabel = (
  notificationTemplateType: NotificationTemplateType
): string => {
  if (notificationTemplateType == null) {
    return "";
  } else if (notificationTemplateType === "SOLD") {
    return "Purchased";
  } else if (notificationTemplateType === "SOLD_TIMED") {
    return "Purchased Timed";
  } else if (
    notificationTemplateType === "IFSOLD_CONSIGNOR" ||
    notificationTemplateType === "IFSOLD"
  ) {
    return "Pending";
  } else if (
    notificationTemplateType === "TITLE_UPDATE" ||
    notificationTemplateType === "TITLE_UPDATE_CONSIGNOR"
  ) {
    return "Title Status";
  } else {
    return startCase(notificationTemplateType.toLowerCase());
  }
};

export const getUpdatedDeliveryPreference = (
  deliveryPreference: DeliveryPreference,
  notificationTemplateType: NotificationTemplateType,
  deliveryType: DeliveryType | "ALL"
): DeliveryPreference => {
  if (deliveryType === "ALL") {
    const isDisabledInAllDeliveryTypes = getIsDisabledInAllDeliveryTypes(
      notificationTemplateType,
      deliveryPreference
    );

    return Object.keys(deliveryPreference).reduce<DeliveryPreference>((accumulator, key) => {
      if (Array.isArray(deliveryPreference[key]) && deliveryPreference[getDeliveryTypeKey(key)]) {
        const notificationTemplateTypes = deliveryPreference[key] as Array<
          NotificationTemplateType
        >;

        accumulator[key] = isDisabledInAllDeliveryTypes
          ? notificationTemplateTypes.filter(type => notificationTemplateType !== type)
          : Array.from(new Set([...notificationTemplateTypes, notificationTemplateType]));
      } else {
        accumulator[key] = deliveryPreference[key];
      }

      return accumulator;
    }, {});
  } else {
    const key = getEnabledDeliveryTypeKey(deliveryType);

    return {
      ...deliveryPreference,
      [key]: toggleDeliveryPreference(
        deliveryPreference[key] as Array<NotificationTemplateType>,
        notificationTemplateType
      )
    };
  }
};

export const getIsDisabledInAllDeliveryTypes = (
  notificationTemplateType: NotificationTemplateType,
  deliveryPreference: DeliveryPreference
) => {
  const disabledDeliveryTypesKeys = Object.keys(deliveryPreference).reduce<string[]>(
    (accumulator, key) => {
      if (Array.isArray(deliveryPreference[key]) && deliveryPreference[getDeliveryTypeKey(key)]) {
        accumulator.push(key);
      }
      return accumulator;
    },
    []
  );

  return disabledDeliveryTypesKeys.every(disabledDeliveryTypesKey =>
    (deliveryPreference[disabledDeliveryTypesKey] as NotificationTemplateType[]).includes(
      notificationTemplateType
    )
  );
};

const getEnabledDeliveryTypeKey = (deliveryType: DeliveryType) => {
  switch (deliveryType) {
    case "EMAIL":
      return "enabledEmailNotificationTemplateTypes";

    case "SMS":
      return "enabledSmsNotificationTemplateTypes";

    case "PUSH":
      return "enabledPushNotificationTemplateTypes";

    default:
      throw Error("Invalid delivery type");
  }
};

const getDeliveryTypeKey = (deliveryPreferenceKey: string) => {
  switch (deliveryPreferenceKey) {
    case "enabledEmailNotificationTemplateTypes":
      return "email";

    case "enabledSmsNotificationTemplateTypes":
      return "sms";

    case "enabledPushNotificationTemplateTypes":
      return "push";

    default:
      throw Error("Invalid delivery preference key");
  }
};

const toggleDeliveryPreference = (
  notificationTemplateTypes: Array<NotificationTemplateType>,
  notificationTemplateType: NotificationTemplateType
) => {
  return notificationTemplateTypes.includes(notificationTemplateType)
    ? notificationTemplateTypes.filter(type => notificationTemplateType !== type)
    : [...notificationTemplateTypes, notificationTemplateType];
};
