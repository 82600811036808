import startCase from "lodash/startCase";
import { REHYDRATE } from "redux-persist/lib/constants";

import {
  ADMIN_EVENT_INVENTORY_SET_FILTER_STOCK_ID,
  ADMIN_EVENT_INVENTORY_SET_FILTER_RUN_NUMBER,
  ADMIN_EVENT_INVENTORY_SET_FILTER_STATUS,
  ADMIN_EVENT_INVENTORY_SET_FILTER_CONSIGNOR,
  ADMIN_EVENT_INVENTORY_SET_FILTER_BUYER,
  ADMIN_GET_INVENTORY,
  ADMIN_SET_INVENTORY,
  ADMIN_TOGGLE_INVENTORY_SORT,
  ADMIN_SET_INVENTORY_TOTAL,
  ADMIN_SET_INVENTORY_PAGE,
  ADMIN_CLEAR_SALES_ID_FILTER,
  ADMIN_RESET_INVENTORY_PAGE,
  ADMIN_SET_COMPANY_USERS,
  ADMIN_SET_EVENT_CONSIGNORS,
  ADMIN_INVENTORY_TOGGLE_HIDDEN_COLUMN,
  CONFIG_SET,
  SERVER_APP_STATE_REHYDRATE
} from "actions/actionTypes";
import { getConfigValue } from "reducers/config";
import { getSoldAmount } from "reducers/lots";
import { MARKET_SEGMENTS } from "utils/constants";
import { getDescription } from "utils/AssetUtil";
import { getDefaultLotSort, getLotNumberType } from "utils/MarketUtil";
import { getFormattedCurrencyBidAmount } from "utils/CurrencyUtil";
import { formatNumber } from "utils/NumberUtil";
import { formatDateTime } from "utils/DateUtil";
import { createSort, createSortFromString } from "utils/SearchUtil";

export const initialFilters = {
  status: "",
  consignors: [],
  consignorsInitialized: false,
  buyers: [],
  buyersInitialized: false,
  search: {
    stockId: "",
    runNumber: ""
  }
};

export const initialState = {
  sort: getDefaultLotSort(MARKET_SEGMENTS.AUTOMOTIVE),
  isInventoryLoading: true,
  filters: initialFilters,
  inventory: [],
  inventoryPage: 0,
  inventoryTotal: 0,
  inventoryVisitedEventId: null,
  defaultHiddenColumns: undefined,
  companyUsers: [],
  eventConsignors: [],
  companyHiddenColumns: undefined
};

export default function adminInventory(state = initialState, action) {
  switch (action.type) {
    case REHYDRATE:
      if (action.payload) {
        return {
          ...state,
          ...action.payload.inventory,
          inventory: [],
          isInventoryLoading: true,
          defaultHiddenColumns: action.payload?.adminInventory.defaultHiddenColumns,
          companyHiddenColumns: action.payload?.adminInventory.companyHiddenColumns
        };
      } else {
        return {
          ...state
        };
      }

    case CONFIG_SET:
      const marketSegment = getConfigValue(action.payload.config, "marketSegment");
      return {
        ...state,
        sort: getDefaultLotSort(marketSegment)
      };

    case ADMIN_GET_INVENTORY:
      return {
        ...state,
        isInventoryLoading: true
      };

    case ADMIN_SET_COMPANY_USERS:
      return {
        ...state,
        companyUsers: action.payload.companyUsers,
        filters: {
          ...state.filters,
          buyersInitialized: true
        }
      };

    case ADMIN_SET_EVENT_CONSIGNORS:
      return {
        ...state,
        eventConsignors: action.payload.eventConsignors,
        filters: {
          ...state.filters,
          consignorsInitialized: true
        }
      };

    case ADMIN_SET_INVENTORY:
      return {
        ...state,
        isInventoryLoading: false,
        inventory: action.payload.inventory,
        inventoryVisitedEventId: action.payload.visitedEventId
      };

    case ADMIN_CLEAR_SALES_ID_FILTER:
      return {
        ...state,
        inventoryVisitedEventId: null
      };

    case ADMIN_TOGGLE_INVENTORY_SORT:
      let currentSort = createSortFromString(state.sort);
      let sort =
        currentSort.field === action.payload.field
          ? createSort(action.payload.field, toggleSort(currentSort, action.payload))
          : createSort(action.payload.field, action.payload.order);
      return {
        ...state,
        sort
      };

    case ADMIN_SET_INVENTORY_TOTAL:
      return {
        ...state,
        inventoryTotal: action.payload.total
      };

    case ADMIN_SET_INVENTORY_PAGE:
      return {
        ...state,
        inventoryPage: action.payload.page
      };

    case ADMIN_RESET_INVENTORY_PAGE:
      return {
        ...state,
        sort: getDefaultLotSort(action.payload.marketSegment),
        inventoryPage: 0,
        filters: initialFilters
      };

    case ADMIN_EVENT_INVENTORY_SET_FILTER_STOCK_ID:
      return {
        ...state,
        filters: {
          ...state.filters,
          search: {
            ...state.filters.search,
            stockId: action.payload.stockId ?? ""
          }
        }
      };

    case ADMIN_EVENT_INVENTORY_SET_FILTER_RUN_NUMBER:
      return {
        ...state,
        filters: {
          ...state.filters,
          search: {
            ...state.filters.search,
            runNumber: action.payload.runNumber ?? ""
          }
        }
      };

    case ADMIN_EVENT_INVENTORY_SET_FILTER_STATUS:
      return {
        ...state,
        filters: {
          ...state.filters,
          status: action.payload.status ?? ""
        }
      };

    case ADMIN_EVENT_INVENTORY_SET_FILTER_CONSIGNOR: {
      const { consignor } = action.payload;
      const { consignors } = state.filters;
      const isActive = Boolean(consignors.find(_consignor => _consignor === consignor));
      return {
        ...state,
        filters: {
          ...state.filters,
          consignors: isActive
            ? consignors.filter(_consignor => _consignor !== consignor)
            : consignors.concat(consignor)
        }
      };
    }

    case ADMIN_EVENT_INVENTORY_SET_FILTER_BUYER: {
      const { buyer } = action.payload;
      const { buyers } = state.filters;
      const isActive = Boolean(buyers.find(_buyer => _buyer === buyer));
      return {
        ...state,
        filters: {
          ...state.filters,
          buyers: isActive ? buyers.filter(_buyer => _buyer !== buyer) : buyers.concat(buyer)
        }
      };
    }

    case ADMIN_INVENTORY_TOGGLE_HIDDEN_COLUMN: {
      const { isDefault, companyId, column } = action.payload;
      if (isDefault) {
        return {
          ...state,
          defaultHiddenColumns: toggleColumn(column, state.defaultHiddenColumns)
        };
      } else if (companyId) {
        return {
          ...state,
          companyHiddenColumns: {
            ...state.companyHiddenColumns,
            [companyId]: toggleColumn(column, state.companyHiddenColumns?.[companyId])
          }
        };
      }

      return state;
    }

    case SERVER_APP_STATE_REHYDRATE:
      if (action.payload.adminInventory) {
        return {
          ...state,
          defaultHiddenColumns: action.payload.adminInventory.defaultHiddenColumns,
          companyHiddenColumns: action.payload.adminInventory.companyHiddenColumns
        };
      }
      break;

    default:
      return state;
  }
}

export function getAdminEventInventoryFilterCount(filters) {
  let total = 0;

  if (filters.status !== "") {
    total++;
  }
  if (filters.consignors.length) {
    total += filters.consignors.length;
  }
  if (filters.buyers.length) {
    total += filters.buyers.length;
  }
  if (filters.search.stockId !== "") {
    total++;
  }
  if (filters.search.runNumber !== "") {
    total++;
  }

  return total;
}

function toggleColumn(column, hiddenColumns = []) {
  return hiddenColumns.includes(column)
    ? hiddenColumns.filter(col => col !== column)
    : [...hiddenColumns, column];
}

export function getLotNumberSortName(marketSegment) {
  return getLotNumberType(marketSegment).replace(/\W/g, "");
}

const toggleSort = (currentSort, sort) => {
  if (currentSort.order === "DESC") {
    return "ASC";
  } else {
    return "DESC";
  }
};

export const formatInventoryItem = ({
  asset,
  lot,
  dealerships,
  users,
  sale,
  saleAction,
  extraFields,
  locale
}) => {
  const status = lot?.status;
  const winningBid = lot?.winningBid;
  const bidCount = lot?.bidCount;
  const highBid = lot?.highBid;

  const schemaName = asset?.fields?.schemaName || "Automotive";
  const buyerDealership = dealerships?.[winningBid?.dealershipId];
  const buyerUser = users?.[winningBid?.userId];
  const buyerSaleUserName = winningBid?.saleUserName;
  const highBidderDealership = dealerships?.[highBid?.dealershipId];
  const highBidderUser = users?.[highBid?.userId];
  const buyerName = getName(buyerDealership, buyerUser, buyerSaleUserName);
  const statusTimestamp = saleAction?.saleAction?.timestamp;

  return {
    lotNumber: lot?.lotNumber,
    lotSequence: lot?.lotSequence,
    bidCount: bidCount ? formatNumber(bidCount) : 0,
    highBidInfo: getHighBidderInfo(highBid, highBidderDealership, highBidderUser, sale),
    calculatedHighBidAmount: getCalculatedHighBidAmount(highBid, sale, lot),
    description: getDescription(asset),
    amount: getFormattedCurrencyBidAmount(getSoldAmount(lot), sale, lot?.winningBid?.source) || "",
    buyNowAmount: lot?.buyNowAmount?.toLocaleString("en-US") ?? "",
    reserveAmount: lot?.reserveAmount?.toLocaleString("en-US") ?? "",
    status: status && startCase(status.toLowerCase()),
    buyerName: startCase(buyerName.toLowerCase()),
    buyerDetailsLink: getBuyerDetailsLink(buyerDealership, buyerUser),
    sellerName: startCase(lot?.consignorName?.toLowerCase()),
    isVehicle: schemaName && schemaName.toLowerCase() === "automotive",
    statusTimestamp:
      statusTimestamp && locale ? formatDateTime(statusTimestamp, locale, "h:mm:ssa z") : "",
    userId: buyerUser?.id,
    ...extraFields
  };
};

export function getHighBidderInfo(highBid, dealership, user, sale) {
  let str = "";
  if (!highBid) return str;
  const name = startCase(getName(dealership, user).toLowerCase());
  const amount = getFormattedCurrencyBidAmount(highBid.amount, sale, highBid.source);
  if (name) {
    str += name;
  }
  str += amount ? ` ${amount}` : "";
  return str;
}

function getCalculatedHighBidAmount(highBid, sale, lot) {
  if (highBid?.amount == null) return;

  const amount =
    lot.sellingType === "QUANTITY" && lot.quantity != null
      ? highBid.amount * lot.quantity
      : highBid.amount;

  return getFormattedCurrencyBidAmount(amount, sale, highBid.source);
}

function getName(dealership, user, saleUserName) {
  return dealership?.name ?? user?.name ?? saleUserName ?? "";
}

function getBuyerDetailsLink(dealership, user) {
  if (dealership?.id) {
    return `/admin/dealership/details/${dealership.id}`;
  } else if (user?.id) {
    return `/admin/user/details/${user.id}`;
  } else {
    return;
  }
}

export function searchResultsToMap(searchResults = {}) {
  return Object.keys(searchResults).reduce((accumulator, key) => {
    if (Array.isArray(searchResults[key])) {
      if (!accumulator[key]) {
        accumulator[key] = {
          [key]: {},
          ids: []
        };
      }

      searchResults[key].forEach(addItemAndItemIdToAccumulator(accumulator, key));
    }
    return accumulator;
  }, {});
}

function addItemAndItemIdToAccumulator(accumulator, key) {
  return item => {
    accumulator[key][key][item.id] = item;
    accumulator[key].ids.push(item.id);
  };
}

export function getSaleType(saleOrEvent = {}) {
  let value = "";

  if ("saleType" in saleOrEvent) {
    value = saleOrEvent?.saleType;
  } else if ("saleTypes" in saleOrEvent) {
    value = saleOrEvent?.saleTypes[0];
  }

  switch (value) {
    case "LIVE":
    case "LIVE_LEGACY":
      return "Live";
    case "TIMED":
    case "TIMED_LEGACY":
      return "Timed";
    default:
      return null;
  }
}

export function isAdminInventoryEditable(lot, sale, ams) {
  return (
    isSaleTypeTimed(sale) ||
    (isLotStatusSold(lot) && isWinningBidTypeBuyNow(lot)) ||
    isCubedAms(ams)
  );
}

export function isCubedAms(ams) {
  return ams?.integrationType === "CUBED";
}

function isSaleTypeTimed(sale) {
  return getSaleType(sale) === "Timed";
}

function isLotStatusSold(lot) {
  return lot?.status === "SOLD";
}

function isWinningBidTypeBuyNow(lot) {
  return lot?.winningBid?.bidType === "BUY_NOW";
}

export function getInventorySoldBidType(lot) {
  return lot.status === "SOLD" ? getBuyNowBidTypeText(lot) : null;
}

function getBuyNowBidTypeText(lot) {
  return isWinningBidTypeBuyNow(lot) ? "Buy Now" : null;
}

export function isFloorSold(lot) {
  return lot?.status === "SOLD" && lot?.winningBid?.origin === "FLOOR";
}

export function getDefaultColumnValue(value) {
  return value != null ? value : "-";
}
