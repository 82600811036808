import {
  COMET_CONNECT,
  COMET_DISCONNECT,
  COMET_SUBSCRIBE_REQUEST,
  COMET_UNSUBSCRIBE_REQUEST
} from "./actionTypes";

export function connect() {
  return {
    type: COMET_CONNECT
  };
}

export function disconnect() {
  return {
    type: COMET_DISCONNECT
  };
}

export function subscribe(channel) {
  return async dispatch => {
    dispatch({
      type: COMET_SUBSCRIBE_REQUEST,
      payload: { channel }
    });
  };
}

export function unsubscribe(channel) {
  return {
    type: COMET_UNSUBSCRIBE_REQUEST,
    payload: { channel }
  };
}
