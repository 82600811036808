import { createSlice } from "@reduxjs/toolkit";
import { CURRENCY_FETCH, CURRENCY_FETCH_COMPLETE } from "actions/actionTypes";

export const initialState = {
  codes: [],
  ratesMap: {},
  isLoading: true
};

const currencySlice = createSlice({
  name: "currency",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(CURRENCY_FETCH, state => {
        state.isLoading = true;
      })
      .addCase(CURRENCY_FETCH_COMPLETE, (state, action) => {
        state.isLoading = false;
        state.codes = action.payload.codes;
        state.ratesMap = action.payload.rates;
      });
  }
});

const { reducer } = currencySlice;

export default reducer;
