import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { Dealership } from "types/Dealership";

const dealershipsAdapter = createEntityAdapter<Dealership>();

export const dealershipsSelector = dealershipsAdapter.getSelectors();

export const initialState = dealershipsAdapter.getInitialState({
  isLoading: true,
  sort: "+name",
  page: 0,
  total: 0
});

const dealershipsSlice = createSlice({
  name: "dealerships",
  initialState,
  reducers: {
    fetch: (state, action) => {
      state.isLoading = true;
    },
    fetchComplete: (state, action) => {
      state.isLoading = false;
      dealershipsAdapter.setAll(state, action.payload);
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setTotal: (state, action) => {
      state.total = action.payload;
    },
    setSort: (state, action) => {
      state.sort = action.payload;
    },
    upsertDealership: (state, action) => {
      state.isLoading = false;
      dealershipsAdapter.upsertOne(state, action.payload);
    }
  }
});

const { actions, reducer, name } = dealershipsSlice;

export { dealershipsSlice as default, actions, reducer, name };
