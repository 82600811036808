import {
  CALENDAR_GET_EVENT_FROM_ENDPOINT,
  CALENDAR_SET_LAST_PAGE_TYPE,
  CALENDAR_FILTER_SET_SALE_TYPE,
  CALENDAR_FILTER_TOGGLE_ASSET_SCHEMA,
  CALENDAR_FILTER_TOGGLE_COMPANY,
  CALENDAR_FETCH,
  CALENDAR_FETCH_COMPLETE,
  RESET_CALENDAR_ENTITIES,
  CALENDAR_MY_AUCTION_EVENTS,
  CALENDAR_TODAY_EVENTS,
  CALENDAR_UPCOMING_EVENTS
} from "actions/actionTypes";
import moment from "moment";
import {
  createRange,
  createRangeFilter,
  createTermFilter,
  createTermsFilter
} from "utils/SearchUtil";
import { trackSearch } from "hooks/useTracking";
import {
  getCompanyDefaultEventMedias,
  getCompanyFavIconMedias,
  getEventsMedia
} from "api/mediaRequests";
import { setMedias } from "./mediaActions";
import { batch } from "react-redux";

import { toMap, uniq } from "utils/CollectionUtil";
import { getCalendarEntitiesForCalendar } from "api/calendarRequests";
import { getRange } from "utils/Pagination";
import { getFavorites } from "api/userRequests";
import { eventsSelector } from "reducers/events";

function getCalendarResources({
  filters = [],
  page = 0,
  perPage = 8,
  shouldMerge = false,
  pageSubType
}) {
  return async (dispatch, getState) => {
    try {
      const {
        calendar: { selectedSaleType, selectedAssetSchemasIds, selectedCompanyIds }
      } = getState();

      dispatch({ type: CALENDAR_FETCH });

      if (selectedSaleType) {
        let saleTypes = [
          selectedSaleType.toLowerCase(),
          selectedSaleType.toLowerCase() + "_legacy"
        ];
        filters.push(createTermsFilter("saleTypes", saleTypes));
      }
      if (selectedCompanyIds?.length > 0) {
        filters.push(createTermsFilter("companyId", selectedCompanyIds));
      }
      if (selectedAssetSchemasIds?.length > 0) {
        filters.push(createTermsFilter("assetSchemaIds", selectedAssetSchemasIds));
      }

      trackSearch(filters, "/calendar");

      const { data: payload } = await getCalendarEntitiesForCalendar(
        filters,
        getRange(page, perPage),
        "+startTime",
        pageSubType
      );

      const results = { ...payload };
      results.shouldMerge = shouldMerge;
      results.lastPageType = "calendar";

      let medias = [];

      if (results?.events?.length) {
        medias = await getCalendarMedia(results.events);
      }

      batch(() => {
        dispatch({ type: CALENDAR_FETCH_COMPLETE, payload: results, shouldMerge });
        dispatch(setMedias(medias, shouldMerge));
      });

      return results;
    } catch (error) {
      return {};
    }
  };
}

async function getCalendarMedia(events) {
  const eventsMap = events.reduce(toMap("id"), {});
  const eventIds = Object.keys(eventsMap);

  let companyIds = uniq(eventIds.map(eventId => eventsMap[eventId].companyId));
  const favIconMediasCall = getCompanyFavIconMedias(companyIds);
  const eventMediasCall = getEventsMedia(eventIds);

  const results = await Promise.allSettled([eventMediasCall, favIconMediasCall]);

  let medias = [];

  results.forEach(result => {
    if (result.status === "fulfilled") {
      medias = medias.concat(result.value);
    }
  });

  const eventsWithMedia = medias
    .map(media => (media.entityType === "event" ? media.entityId : null))
    .filter(id => id);

  const eventsWithoutMedia = eventIds.filter(eventId => !eventsWithMedia.includes(eventId));

  if (eventsWithoutMedia.length > 0) {
    try {
      companyIds = uniq(eventsWithoutMedia.map(eventId => eventsMap[eventId].companyId));

      const defaultMedias = await getCompanyDefaultEventMedias(companyIds);
      medias = medias.concat(defaultMedias);
    } catch (error) {}
  }

  return medias;
}

export function getFavoriteAuctions({ page: newPage, shouldMerge = false }) {
  return async (dispatch, getState) => {
    const {
      user: { user },
      calendar: {
        myAuctionEvents: { page: currentPage }
      },
      companies: { companies }
    } = getState();

    let events = [],
      totalEvents = 0;

    const page = typeof newPage === "number" && !isNaN(newPage) ? newPage : currentPage;
    const companyIds = companies.map(company => company.id);
    const favoriteFilters = [
      createTermFilter("entityType", "COMPANY", "MUST"),
      createTermFilter("userId", user.id, "MUST"),
      createTermsFilter("entityId", companyIds, "MUST")
    ];

    const { total } = await getFavorites(favoriteFilters, createRange(0, 0));
    const { data: favorites = [] } = await getFavorites(favoriteFilters, createRange(0, total));

    const favoriteCompanyIds = favorites.map(({ entityId }) => entityId);

    if (favoriteCompanyIds.length > 0) {
      const startOfDay = moment()
        .startOf("day")
        .valueOf();

      const filters = [
        createTermsFilter("companyId", favoriteCompanyIds, "MUST"),
        createRangeFilter("endTime", startOfDay, "GT", "MUST")
      ];

      const res = await dispatch(
        getCalendarResources({
          filters,
          page,
          shouldMerge,
          pageSubType: "myAuctionEvents"
        })
      );

      events = res.events?.map(({ id }) => id) || [];
      totalEvents = res.total || 0;
    }

    dispatch({
      type: CALENDAR_MY_AUCTION_EVENTS,
      payload: {
        eventIds: events,
        total: totalEvents,
        page,
        shouldMerge
      }
    });
  };
}

export function getTodayAuctions({ page: newPage, shouldMerge = false }) {
  return async (dispatch, getState) => {
    const {
      calendar: {
        todayEvents: { page: currentPage }
      }
    } = getState();

    const page = typeof newPage === "number" && !isNaN(newPage) ? newPage : currentPage;

    const startOfDay = moment()
      .startOf("day")
      .valueOf();
    const endOfDay = moment()
      .endOf("day")
      .valueOf();

    const filters = [
      createRangeFilter("startTime", endOfDay, "LTE", "MUST"),
      createRangeFilter("endTime", startOfDay, "GT", "MUST")
    ];

    const res = await dispatch(
      getCalendarResources({
        filters,
        page,
        perPage: 16,
        shouldMerge,
        pageSubType: "todayEvents"
      })
    );

    dispatch({
      type: CALENDAR_TODAY_EVENTS,
      payload: {
        eventIds: res.events?.map(({ id }) => id) || [],
        total: res.total || 0,
        page,
        shouldMerge
      }
    });
  };
}

export function getUpcomingAuctions({ page: newPage, shouldMerge = false }) {
  return async (dispatch, getState) => {
    const {
      calendar: {
        upcomingEvents: { page: currentPage }
      }
    } = getState();

    const page = typeof newPage === "number" && !isNaN(newPage) ? newPage : currentPage;

    const endOfDay = moment()
      .endOf("day")
      .valueOf();

    const filters = [createRangeFilter("startTime", endOfDay, "GT", "MUST")];

    const res = await dispatch(
      getCalendarResources({
        filters,
        page,
        shouldMerge,
        pageSubType: "upcomingEvents"
      })
    );

    dispatch({
      type: CALENDAR_UPCOMING_EVENTS,
      payload: {
        eventIds: res.events?.map(({ id }) => id) || [],
        total: res.total || 0,
        page,
        shouldMerge
      }
    });
  };
}

export function initializeEntitiesForCalendar() {
  return async (dispatch, getState) => {
    const {
      companies: { companies }
    } = getState();

    dispatch({ type: RESET_CALENDAR_ENTITIES });
    const shouldMerge = true;

    if (companies.length !== 1) {
      dispatch(getFavoriteAuctions({ page: 0, shouldMerge }));
    }
    dispatch(getTodayAuctions({ page: 0, shouldMerge }));
    dispatch(getUpcomingAuctions({ page: 0, shouldMerge }));
  };
}

export function resetCalendarEntities() {
  return {
    type: RESET_CALENDAR_ENTITIES
  };
}

export function getEventMedia(eventId) {
  return async (dispatch, getState) => {
    const event = eventsSelector.selectById(getState().events, eventId);
    const medias = await getCalendarMedia([event]);
    dispatch(setMedias(medias, true));
  };
}

export function getEventFromCalendarEndpoint(eventId) {
  return async dispatch => {
    dispatch({ type: CALENDAR_GET_EVENT_FROM_ENDPOINT });
    const filters = [createTermFilter("id", eventId)];

    const response = await dispatch(
      getCalendarResources({
        filters,
        shouldMerge: true,
        pageType: "calendar"
      })
    );

    return response?.payload?.events[0];
  };
}

export function setCalendarFilterSaleType(saleType) {
  return {
    type: CALENDAR_FILTER_SET_SALE_TYPE,
    payload: {
      saleType
    }
  };
}

export function toggleCalendarFilterCompanyId(companyId) {
  return {
    type: CALENDAR_FILTER_TOGGLE_COMPANY,
    payload: {
      companyId
    }
  };
}

export function toggleCalendarFilterAssetSchemaType(assetSchemaId) {
  return {
    type: CALENDAR_FILTER_TOGGLE_ASSET_SCHEMA,
    payload: {
      assetSchemaId
    }
  };
}

export function setLastPageType(lastPageType) {
  return {
    type: CALENDAR_SET_LAST_PAGE_TYPE,
    payload: {
      lastPageType
    }
  };
}

export function mapAnnouncementsToText(announcements = []) {
  return announcements.map(announcement => announcement.announcement).join(" ");
}
