import React from "react";

import { PURCHASE, ANNOUNCEMENT, NOTE, GROUP, TITLE } from "components/card/ActionsBarIconConfig";
import { DIALOG_CONTENT_TYPE } from "utils/constants";
import DialogContentFlushConfirmation from "./DialogContentFlushConfirmation";
import DialogContentConsignorsList from "./DialogContentConsignorsList";
import DialogContentEventPrint from "./DialogContentEventPrint";

const DialogContentUpsertDealership = React.lazy(() => import("./DialogContentUpsertDealership"));
const DialogContentNote = React.lazy(() => import("./DialogContentNote"));
const DialogContentBuyingDetails = React.lazy(() => import("./DialogContentBuyingDetails"));
const DialogContentAnnouncement = React.lazy(() => import("./DialogContentAnnouncement"));
const DialogContentAgreement = React.lazy(() => import("./DialogContentAgreement"));
const DialogContentAttend = React.lazy(() => import("./DialogContentAttend"));
const DialogContentAttendNew = React.lazy(() => import("./DialogContentAttendNew"));

const DialogContentAttendGetSaleUsers = React.lazy(() =>
  import("./DialogContentAttendGetSaleUsers")
);
const DialogContentBuyNowConfirmation = React.lazy(() =>
  import("./DialogContentBuyNowConfirmation")
);
const DialogContentLotWithdrawConfirmation = React.lazy(() =>
  import("./DialogContentLotWithdrawConfirmation")
);
const DialogContentBadgeError = React.lazy(() => import("./DialogContentBadgeError"));
const DialogContentSaveSearch = React.lazy(() => import("./DialogContentSaveSearch"));
const DialogContentLotNumberExistsError = React.lazy(() =>
  import("./DialogContentLotNumberExistsError")
);
const DialogContentMediaManagement = React.lazy(() => import("./DialogContentMediaManagement"));
const DialogContentMakeOfferConfirmation = React.lazy(() =>
  import("./DialogContentMakeOfferConfirmation")
);
const DialogContentDeposit = React.lazy(() => import("./DialogContentDeposit"));
const DialogContentAddToGroup = React.lazy(() => import("./DialogContentAddToGroup"));
const DialogContentShowGroup = React.lazy(() => import("./DialogContentShowGroup"));
const DialogContentFailedLogin = React.lazy(() => import("./DialogContentFailedLogin"));
const DialogContentPasswordReset = React.lazy(() => import("./DialogContentPasswordReset"));
const DialogContentResultList = React.lazy(() => import("./DialogContentResultList"));
const DialogContentEventNotificationsConfirmation = React.lazy(() =>
  import("./DialogContentEventNotificationsConfirmation")
);
const DialogContentBulkStatusChange = React.lazy(() => import("./DialogContentBulkStatusChange"));
const DialogContentBidControls = React.lazy(() => import("./DialogContentBidControls"));
const DialogContentUpsertAdmin = React.lazy(() => import("./DialogContentUpsertAdmin"));
const DialogContentUpdateAuctionAccessId = React.lazy(() =>
  import("./DialogContentUpdateAuctionAccessId")
);
const DialogContentAttendViewOnly = React.lazy(() => import("./DialogContentAttendViewOnly"));
const DialogContentAdminPasswordUpdate = React.lazy(() =>
  import("./DialogContentAdminPasswordUpdate")
);
const DialogContentPersonalInfoUpdate = React.lazy(() =>
  import("./DialogContentPersonalInfoUpdate")
);
const DialogContentRegistrationConfirmation = React.lazy(() =>
  import("./DialogContentRegistationConfirmation")
);
const DialogContentCheckDealerships = React.lazy(() => import("./DialogContentCheckDealerships"));
const DialogContentCheckBadges = React.lazy(() => import("./DialogContentCheckBadges"));
const DialogContentNotificationGroup = React.lazy(() => import("./DialogContentNotificationGroup"));
const DialogContentMembershipStatusUpdateConfirmation = React.lazy(() =>
  import("./DialogContentMembershipStatusUpdateConfirmation")
);
const DialogContentUserDealershipSyncMessage = React.lazy(() =>
  import("./DialogContentUserDealershipSyncMessage")
);
const DialogContentUploadUsers = React.lazy(() => import("./DialogContentUploadUsers"));
const DialogContentAdminBidderInfo = React.lazy(() => import("./DialogContentAdminBidderInfo"));
const DialogContentUpsertMembershipSchemaField = React.lazy(() =>
  import("./DialogContentUpsertMembershipSchemaField")
);
const DialogContentMembershipSchemaFields = React.lazy(() =>
  import("./DialogContentMembershipSchemaFields")
);
const DialogContentMembershipStatus = React.lazy(() => import("./DialogContentMembershipStatus"));
const DialogContentBidInfoHelp = React.lazy(() => import("./DialogContentBidInfoHelp"));
const DialogContentUpsertUISchemaField = React.lazy(() =>
  import("./DialogContentUpsertUISchemaField")
);
const DialogContentResendActivationEmail = React.lazy(() =>
  import("./DialogContentResendActivationEmail")
);
const DialogContentWarningDifferentFilesUploaded = React.lazy(() =>
  import("./DialogContentWarningDifferentFilesUploaded")
);
const DialogContentApplicationMessage = React.lazy(() =>
  import("./DialogContentApplicationMessage")
);
const DialogContentVerifyMobileNumber = React.lazy(() =>
  import("./DialogContentVerifyMobileNumber")
);
const DialogContentUnsavedChangesConfirmation = React.lazy(() =>
  import("./DialogContentUnsavedChangesConfirmation")
);
const DialogContentUnresolvedPSIConfirmation = React.lazy(() =>
  import("./DialogContentUnresolvedPSIConfirmation")
);
const DialogContentUploadMedia = React.lazy(() => import("./DialogContentUploadMedia"));
const DialogContentSellTimedLot = React.lazy(() => import("./DialogContentSellTimedLot"));
const DialogContentTitleHistory = React.lazy(() => import("./DialogContentTitleHistory"));
const DialogContentBarcode = React.lazy(() => import("./DialogContentBarcode"));
const DialogContentCreateMemberships = React.lazy(() => import("./DialogContentCreateMemberships"));
const DialogContentCommunityDealerships = React.lazy(() =>
  import("./DialogContentCommunityDealerships")
);
const DialogContentLoginPrompt = React.lazy(() => import("./DialogContentLoginPrompt"));
const DialogContentActivateAccount = React.lazy(() => import("./DialogContentActivateAccount"));
const DialogContentFloorplan = React.lazy(() => import("./floorplan"));
const DialogContentAddAuctionAccessId = React.lazy(() =>
  import("./DialogContentAddAuctionAccessId")
);

// TODO: fix agreement/attend/buy_now so that they come from same place as other ones, figure out why they are passing stuff down directly in this prop called props

const DialogContent = ({ contentType, ...props }) => {
  switch (contentType) {
    case PURCHASE:
      return <DialogContentBuyingDetails {...props} />;
    case TITLE:
      return <DialogContentTitleHistory {...props} />;
    case ANNOUNCEMENT:
      return <DialogContentAnnouncement {...props} />;
    case NOTE:
      return <DialogContentNote noteType="INVENTORY" {...props} />;
    case DIALOG_CONTENT_TYPE.ADMIN_NOTE:
      return <DialogContentNote noteType="ADMIN_USER" {...props} />;
    case GROUP:
      return <DialogContentShowGroup {...props} />;
    case "AGREEMENT":
      return <DialogContentAgreement {...props} />;
    case DIALOG_CONTENT_TYPE.ATTEND:
      return <DialogContentAttend {...props} />;
    case DIALOG_CONTENT_TYPE.ATTEND_NEW:
      return <DialogContentAttendNew {...props} />;
    case DIALOG_CONTENT_TYPE.GET_SALE_USERS:
      return <DialogContentAttendGetSaleUsers {...props} />;
    case "BADGE_ERROR":
      return <DialogContentBadgeError {...props} />;
    case "BUY_NOW":
      return <DialogContentBuyNowConfirmation {...props} />;
    case "EDIT_LOT_STATUS_WITHDRAW":
      return <DialogContentLotWithdrawConfirmation {...props} />;
    case "EDIT SAVED SEARCH":
    case "SAVE SEARCH":
      return <DialogContentSaveSearch {...props} />;
    case "RUN NUMBER ALREADY EXISTS ERROR":
      return <DialogContentLotNumberExistsError {...props} />;
    case "Media Management":
      return <DialogContentMediaManagement {...props} />;
    case "Make Offer Confirmation":
      return <DialogContentMakeOfferConfirmation {...props} />;
    case "DEPOSIT":
      return <DialogContentDeposit {...props} />;
    case "Add To Group":
      return <DialogContentAddToGroup {...props} />;
    case "Password Help":
      return <DialogContentFailedLogin {...props} />;
    case "Password Reset":
      return <DialogContentPasswordReset {...props} />;
    case DIALOG_CONTENT_TYPE.UPDATE_PASSWORD:
      return <DialogContentAdminPasswordUpdate {...props} />;
    case "Result List":
      return <DialogContentResultList {...props} />;
    case "Event Notifications Confirmation":
      return <DialogContentEventNotificationsConfirmation {...props} />;
    case "Lot Status To Out Confirmation":
      return <DialogContentBulkStatusChange {...props} />;
    case DIALOG_CONTENT_TYPE.BID_CONTROLS:
      return <DialogContentBidControls {...props} />;
    case DIALOG_CONTENT_TYPE.ADD_ADMIN:
      return <DialogContentUpsertAdmin {...props} />;
    case DIALOG_CONTENT_TYPE.UPDATE_AUCTION_ACCESS:
      return <DialogContentUpdateAuctionAccessId {...props} />;
    case DIALOG_CONTENT_TYPE.ATTEND_VIEW_ONLY:
      return <DialogContentAttendViewOnly {...props} />;
    case DIALOG_CONTENT_TYPE.REGISTRATION_CONFIRMATION:
      return <DialogContentRegistrationConfirmation {...props} />;
    case DIALOG_CONTENT_TYPE.CHECK_DEALERSHIPS:
      return <DialogContentCheckDealerships {...props} />;
    case DIALOG_CONTENT_TYPE.CHECK_BADGES:
      return <DialogContentCheckBadges {...props} />;
    case DIALOG_CONTENT_TYPE.NOTIFICATION_GROUP:
      return <DialogContentNotificationGroup {...props} />;
    case DIALOG_CONTENT_TYPE.MEMBERSHIP_STATUS_UPDATE_CONFIRMATION:
      return <DialogContentMembershipStatusUpdateConfirmation {...props} />;
    case DIALOG_CONTENT_TYPE.USER_DEALERSHIP_SYNC_MESSAGE:
      return <DialogContentUserDealershipSyncMessage {...props} />;
    case DIALOG_CONTENT_TYPE.UPLOAD_USERS:
      return <DialogContentUploadUsers {...props} />;
    case DIALOG_CONTENT_TYPE.UPSERT_DEALERSHIP:
      return <DialogContentUpsertDealership {...props} />;
    case DIALOG_CONTENT_TYPE.ADMIN_BIDDER_INFO:
      return <DialogContentAdminBidderInfo {...props} />;
    case DIALOG_CONTENT_TYPE.MEMBERSHIP_STATUS:
      return <DialogContentMembershipStatus {...props} />;
    case DIALOG_CONTENT_TYPE.MEMBERSHIP_FIELDS:
      return <DialogContentMembershipSchemaFields {...props} />;
    case DIALOG_CONTENT_TYPE.UPSERT_MEMBERSHIP_SCHEMA_FIELD:
      return <DialogContentUpsertMembershipSchemaField {...props} />;
    case DIALOG_CONTENT_TYPE.UPSERT_UI_SCHEMA_FIELD:
      return <DialogContentUpsertUISchemaField {...props} />;
    case DIALOG_CONTENT_TYPE.BIDDING_HELP:
      return <DialogContentBidInfoHelp {...props} />;
    case DIALOG_CONTENT_TYPE.FLUSH_CONFIRMATION:
      return <DialogContentFlushConfirmation {...props} />;
    case DIALOG_CONTENT_TYPE.RESEND_ACTIVATION_EMAIL:
      return <DialogContentResendActivationEmail {...props} />;
    case DIALOG_CONTENT_TYPE.UPDATE_PERSONAL_INFO:
      return <DialogContentPersonalInfoUpdate {...props} />;
    case DIALOG_CONTENT_TYPE.WARNING_DIFFERENT_FILES_UPLOADED:
      return <DialogContentWarningDifferentFilesUploaded {...props} />;
    case DIALOG_CONTENT_TYPE.SHOW_ALL_CONSIGNORS:
      return <DialogContentConsignorsList {...props} />;
    case DIALOG_CONTENT_TYPE.APPLICATION_MESSAGE:
      return <DialogContentApplicationMessage {...props} />;
    case DIALOG_CONTENT_TYPE.VERIFY_MOBILE_NUMBER:
      return <DialogContentVerifyMobileNumber {...props} />;
    case DIALOG_CONTENT_TYPE.UNSAVED_CHANGES_CONFIRMATION:
      return <DialogContentUnsavedChangesConfirmation {...props} />;
    case DIALOG_CONTENT_TYPE.UNRESOLVED_PSI_REQUEST:
      return <DialogContentUnresolvedPSIConfirmation {...props} />;
    case DIALOG_CONTENT_TYPE.UPLOAD_MEDIA:
      return <DialogContentUploadMedia {...props} />;
    case DIALOG_CONTENT_TYPE.SELL_TIMED_LOT:
      return <DialogContentSellTimedLot {...props} />;
    case DIALOG_CONTENT_TYPE.BARCODE:
      return <DialogContentBarcode {...props} />;
    case DIALOG_CONTENT_TYPE.CREATE_MEMBERSHIPS:
      return <DialogContentCreateMemberships {...props} />;
    case DIALOG_CONTENT_TYPE.MANAGE_COMMUNITY_DEALERSHIPS:
      return <DialogContentCommunityDealerships {...props} />;
    case DIALOG_CONTENT_TYPE.LOGIN_PROMPT:
      return <DialogContentLoginPrompt {...props} />;
    case DIALOG_CONTENT_TYPE.EVENT_PRINT:
      return <DialogContentEventPrint {...props} />;
    case DIALOG_CONTENT_TYPE.ACTIVATE_ACCOUNT:
      return <DialogContentActivateAccount {...props} />;
    case DIALOG_CONTENT_TYPE.FLOORPLAN_ERRORS:
      return <DialogContentFloorplan contentType={contentType} {...props} />;
    case DIALOG_CONTENT_TYPE.ADD_AAID:
      return <DialogContentAddAuctionAccessId {...props} />;
    default:
      return null;
  }
};

export default DialogContent;
