import { useState, useEffect } from "react";

const useHtmlScriptElement = (url: string, onLoad?: () => void) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (url) {
      const script = document.createElement("script");
      script.src = url;
      script.async = true;

      const onLoadListener = () => {
        onLoad?.();
        setLoaded(true);
      };
      const onErrorListener = (e: any) => {
        setLoaded(false);

        if (e) {
          console.log(e);
        }
      };

      script.addEventListener("load", onLoadListener);
      script.addEventListener("error", onErrorListener);

      document.body.appendChild(script);

      return () => {
        script.removeEventListener("load", onLoadListener);
        script.removeEventListener("error", onErrorListener);
        document.body.removeChild(script);
      };
    }
  }, [url, onLoad]);

  return loaded;
};

export default useHtmlScriptElement;
