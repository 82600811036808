import { makeStyles } from "tss-react/mui";
import { Link } from "react-router-dom";
import { Typography } from "@mui/material";

import LevelMessageIcon from "./LevelMessageIcon";

const getColor = (theme, level) => {
  switch (level?.toLowerCase()) {
    case "info":
      return theme.palette.info.main;
    case "warning":
      return theme.palette.warning.main;
    case "error":
      return theme.palette.error.main;
    default:
      throw new Error();
  }
};

const useStyles = makeStyles()((theme, { level }) => ({
  linkStyle: {
    cursor: "pointer",
    textDecoration: "underline",
    color: theme.palette.info.main,
    "&:hover": {
      color: theme.palette.info.dark
    }
  },
  wrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "100%",
    paddingLeft: theme.spacing(1.5)
  },
  content: {
    textAlign: "left",
    textOverflow: "elipsis",
    display: "-webkit-box",
    "-webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical"
  },
  icon: {
    marginRight: theme.spacing(1.5),
    color: level ? getColor(theme, level) : "inherit"
  }
}));

const regEx = /^{[\d]+}$/;
const http = "http";

export const formatContent = (content, classes) => {
  const linksStart = content.indexOf("[") + 1;
  const linksEnd = content.indexOf("]");

  if (linksStart < 0 || linksEnd < 0) return content;

  const links = content.substring(linksStart, linksEnd).split(",");

  if (links.length < 1) return content;

  return content
    .substring(0, linksStart - 1)
    .split(" ")
    .reduce((acc, str) => {
      if (str.match(regEx)) {
        const index = parseInt(str[1], 10);
        const link = links[index];

        if (link == null) return "";

        const [label, url] = link.split("::").map(link => link.trim());

        acc.push(
          url.startsWith(http) ? (
            <a
              data-testid={`appMessageLink${index}`}
              key={index}
              href={url}
              onClick={evt => evt.stopPropagation()}
              className={classes.linkStyle}
            >
              {label}
            </a>
          ) : (
            <Link
              data-testid={`appMessageLink${index}`}
              key={index}
              to={url}
              className={classes.linkStyle}
            >
              {label}
            </Link>
          )
        );
      } else {
        acc.push(str);
      }

      acc.push(" ");

      return acc;
    }, []);
};

const Message = ({ content, level, ...props }) => {
  const { classes } = useStyles({ level }, { props });

  return (
    <div className={classes.wrapper}>
      {content && (
        <>
          <LevelMessageIcon className={classes.icon} level={level} />
          <Typography className={classes.content}>{formatContent(content, classes)}</Typography>
        </>
      )}
    </div>
  );
};

export { Message as default };
