import React, { useMemo, memo } from "react";
import Tabs from "@mui/material/Tabs";
import { matchPath, useLocation } from "react-router-dom";

const NavTabs = ({ classes, children, variant }) => {
  const location = useLocation();
  const paths = useMemo(() => React.Children.map(children, child => child && child.props.value), [
    children
  ]);
  const value = useMemo(
    () =>
      paths.find(path => Boolean(matchPath(location.pathname, { path, exact: false }))) || false,
    [paths, location]
  );
  return (
    <Tabs
      value={value}
      variant={variant}
      classes={{
        indicator: classes.indicator,
        root: classes.root,
        flexContainer: classes.flexContainer,
        fixed: classes.tabsFixed
      }}
    >
      {children}
    </Tabs>
  );
};

export default memo(NavTabs);
