import { PropsWithChildren, useState } from "react";
import { Button, ButtonProps, CircularProgress } from "@mui/material";
import { useDispatch, useSelector } from "utils/StoreUtil";
import useSale from "hooks/sale/useSale";
import { eventsSelector } from "reducers/events";
import { attendFlow } from "actions/attendanceActions";
import { getSalesByEventId, getStatus } from "reducers/sales";
import { canAttend, isComplete } from "reducers/attendance";
import Sale from "types/Sale";

type AttendButtonProps = {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  loading: boolean;
} & Pick<ButtonProps, "fullWidth" | "disabled">;

export const AttendButton: React.FC<PropsWithChildren<AttendButtonProps>> = ({
  children,
  loading,
  ...props
}) => (
  <Button
    {...props}
    variant="contained"
    color="primary"
    disabled={props.disabled}
    startIcon={loading ? <CircularProgress size={20} /> : null}
    sx={{ padding: 0, minWidth: "160px", height: "42px" }}
  >
    {children}
  </Button>
);

function useDisabledState(sales: Sale[] = []) {
  const showAttendViewOnly = useSelector(state => state.config.showAttendViewOnly);
  const isAuthorized = useSelector(state => state.user.isAuthorized);

  const isAttendable = sales.some(sale => canAttend(sale));
  const complete = sales.every(sale => isComplete(sale));

  return complete || (!isAuthorized && (!isAttendable || !showAttendViewOnly));
}

export const AttendEventButton: React.FC<{ eventId: number }> = ({ eventId }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const event = useSelector(state => eventsSelector.selectById(state.events, eventId));
  const salesState = useSelector((state: any) => state.sales);

  const sales: Sale[] = getSalesByEventId(salesState, eventId).filter(
    sale => getStatus(sale) !== "closed"
  );

  const disabled = useDisabledState(sales);

  async function handleClick(evt: React.MouseEvent<HTMLButtonElement>) {
    try {
      evt.stopPropagation();
      setLoading(true);
      await dispatch(
        attendFlow({
          companyId: event.companyId,
          eventId,
          saleIds: sales?.map(sale => sale.id)
        })
      );
    } finally {
      setLoading(false);
    }
  }

  return (
    <AttendButton fullWidth loading={loading} disabled={loading || disabled} onClick={handleClick}>
      Attend
    </AttendButton>
  );
};

export const AttendSaleButton: React.FC<{ saleId: number }> = ({ saleId }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const sale = useSale(saleId);

  const disabled = useDisabledState([sale]);

  async function handleClick(evt: React.MouseEvent<HTMLButtonElement>) {
    try {
      evt.stopPropagation();
      setLoading(true);
      await dispatch(
        attendFlow({
          companyId: sale.companyId,
          eventId: sale.eventId,
          saleIds: [saleId]
        })
      );
    } finally {
      setLoading(false);
    }
  }

  return (
    <AttendButton fullWidth loading={loading} disabled={loading || disabled} onClick={handleClick}>
      Attend
    </AttendButton>
  );
};
