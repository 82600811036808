import { useEffect, useMemo } from "react";
import { useSelector, useDispatch, shallowEqual } from "utils/StoreUtil";
import { subscribe, unsubscribe } from "actions/cometActions";
import useUniqueId from "hooks/useUniqueId";

const subs = {};

const defaultSelector = state => state;

function useComet(
  entity,
  entityId,
  selector = defaultSelector,
  defaultValue = null,
  equalityFn = shallowEqual
) {
  const uniqueId = useUniqueId();
  const dispatch = useDispatch();

  const channel = useMemo(() => {
    if (entityId != null) {
      return `/${entity}${entityId === "" ? "" : `/${entityId}`}`;
    }
    return null;
  }, [entity, entityId]);

  const channelState = useSelector(state => {
    let channelState = state.comet.channels[channel];

    let result = channelState && channelState.message && selector(channelState.message);

    result = result == null ? defaultValue : result;

    return result;
  }, equalityFn);

  useEffect(() => {
    if (channel) {
      if (!subs[channel]) {
        subs[channel] = new Set();
        dispatch(subscribe(channel));
      }

      subs[channel].add(uniqueId);

      return () => {
        subs[channel].delete(uniqueId);

        if (subs[channel].size <= 0) {
          delete subs[channel];
          dispatch(unsubscribe(channel));
        }
      };
    }
  }, [channel, dispatch, uniqueId]);

  return channelState;
}

export { useComet as default };
