import { get, post, getTotal, put } from "./api";
import uniqueId from "lodash/uniqueId";

export async function getAgreement(agreementId) {
  const res = await get(`agreement/${agreementId}`);
  return res.data;
}

export async function getAgreements(filters, range, sort) {
  const res = await get("agreement", filters, range, sort);
  return { data: res.data, total: getTotal(res) };
}

export async function getAgrees(filters, range, sort) {
  const res = await get(`agree?request.preventCache=${uniqueId("agree")}`, filters, range, sort);
  return { data: res.data, total: getTotal(res) };
}

export async function agree(agree) {
  const { data } = await post("agree", agree);
  return data;
}

export async function adminUpdateMembership(membership) {
  const { data } = await put(`membership/${membership?.id}`, membership);
  return data;
}

export async function updateMembership(membership) {
  const { data } = await put(`application/community/membership/${membership?.id}`, membership);
  return data;
}

export async function createMembership(membership = {}) {
  const { data } = await post(
    `application/community/membership?request.preventCache=${uniqueId("membership")}`,
    membership
  );
  return data;
}

export async function tryCreateMembership(membership) {
  try {
    const { data } = await post(
      `application/community/membership?request.preventCache=${uniqueId("membership")}`,
      membership,
      undefined,
      undefined,
      undefined,
      undefined,
      {
        shouldShowError: false
      }
    );

    return data;
  } catch (error) {
    return `companyId ${membership.companyId}: ${error.response?.data ?? error.message}`;
  }
}

export async function getMembership(membershipId) {
  const res = await get(`membership/${membershipId}`);
  return res.data;
}

export async function getMemberships(filters, range, sort) {
  const res = await get(
    `application/community/membership?request.preventCache=${uniqueId("membership")}`,
    filters,
    range,
    sort
  );

  return { data: res.data, total: getTotal(res) };
}

export async function fetchAuthorizedSaleUsers(eventId) {
  const res = await get(`ams/authorizedSaleUsers/event/${eventId}`);
  return { data: res.data, total: getTotal(res) };
}

export async function getTestBadgesForCompany(companyId, user) {
  const res = await post(`ams/testAuthorizedSaleUsers/company/${companyId}`, user);
  return { data: res.data, total: getTotal(res) };
}

export async function enableSaleUsers(saleId, saleUserNumbers) {
  const res = await post(
    `application/community/enableSaleUsers/sale/${saleId}?request.preventCache=${uniqueId(
      "enableSaleUsers"
    )}`,
    saleUserNumbers
  );
  return { data: res.data };
}

export async function enablePrioritySaleUsers(saleId, saleUserNumbers) {
  const res = await post(
    `application/community/enablePrioritySaleUsers/sale/${saleId}?request.preventCache=${uniqueId(
      "enableSaleUsers"
    )}`,
    saleUserNumbers
  );
  return { data: res.data };
}

export async function getUserDealerships(forceSync = false) {
  const res = await get(`application/community/userDealerships?forceSync=${forceSync}`);
  return { data: res.data, total: getTotal(res) };
}
