import React, { Component } from "react";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { connect } from "utils/StoreUtil";
import { getTheme } from "styles/theme";

class ThemeProvider extends Component {
  shouldComponentUpdate(nextProps) {
    return (
      (this.props.isLoading && !nextProps.isLoading) || this.props.darkMode !== nextProps.darkMode
    );
  }
  render() {
    const { children, config, isLoading } = this.props;
    if (isLoading) return null;
    const theme = getTheme(config);
    return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
  }
}

const mapStateToProps = ({ config }) => ({
  config,
  isLoading: config.isLoading,
  darkMode: config.darkMode
});

export default connect(mapStateToProps)(ThemeProvider);
