import { defaultIntlUtil } from "./IntlUtil";

export function formatCurrency(amount, currency, options = {}) {
  try {
    return defaultIntlUtil.formatNumber(amount, {
      style: "currency",
      currency,
      ...options
    });
  } catch (error) {
    return "";
  }
}

export function getFormattedAmountWithCents(amount, sale, source = null) {
  if (
    sale?.isCents &&
    amount &&
    typeof amount === "number" &&
    Math.floor(amount) === amount &&
    source === "AUCTION_SYSTEM"
  ) {
    return amount / 100;
  }
  return amount;
}

export function getFormattedCurrencyBidAmount(amount, sale, source = null) {
  const currency = sale?.currency;

  amount = getFormattedAmountWithCents(amount, sale, source);

  if (amount != null && typeof amount === "number") {
    amount = formatCurrency(amount, currency);
  }
  return amount;
}

export function getCurrencySymbol(currency) {
  return defaultIntlUtil
    .formatNumber(0, { style: "currency", currency })
    .replace(defaultIntlUtil.keepSymbolsRegEx, "");
}

export function convertCurrency(ratesMap, amount, currentCurrency, targetCurrency) {
  let result = amount;

  if (currentCurrency !== "USD") {
    result = amount / ratesMap[currentCurrency];
  }

  return result * ratesMap[targetCurrency];
}
