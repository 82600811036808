import useScrollTrigger from "@mui/material/useScrollTrigger";
import Zoom from "@mui/material/Zoom";

import { makeStyles } from "tss-react/mui";
import { useSelector } from "utils/StoreUtil";
import { BACK_TO_TOP_ANCHOR, MAIN_CONTAINER_DIV } from "utils/constants";
import Gonative from "services/gonative";

const useStyles = makeStyles<{ bottomSpacing: number }>()((theme, { bottomSpacing }) => ({
  root: {
    position: "fixed",
    bottom: theme.spacing(bottomSpacing),
    left: "auto",
    right: theme.spacing(2),
    zIndex: theme.zIndex.speedDial
  }
}));

function getBottomSpacing(isAuthorized: boolean, isMobile: boolean) {
  if (!isAuthorized && isMobile) return 2;
  if (Gonative.IsGoNative()) return 2;
  if (isMobile) return 9;
  return 7;
}

function ScrollTop({ children }: { children: React.ReactElement }) {
  const isAuthorized = useSelector(state => state.user.isAuthorized);
  const isMobile = useSelector(state => state.viewport.isMobile);
  const bottomSpacing = getBottomSpacing(isAuthorized, isMobile);
  const { classes } = useStyles({ bottomSpacing });
  const dialogIsOpen = useSelector(state => state.dialog.isOpen);

  const mainContainer = document.querySelector(`#${MAIN_CONTAINER_DIV}`) || window;
  const scrollTo = isMobile ? window : mainContainer;

  const trigger = useScrollTrigger({
    target: scrollTo,
    disableHysteresis: true,
    threshold: 120
  });

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const anchor = ((event.target as HTMLDivElement).ownerDocument || document).querySelector(
      `#${BACK_TO_TOP_ANCHOR}`
    );

    if (anchor && isMobile) {
      anchor.scrollIntoView({ behavior: "smooth", block: "start" });
    } else if (mainContainer) {
      mainContainer.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth"
      });
    }
  };

  if (dialogIsOpen) return null;

  return (
    <Zoom in={trigger}>
      <div onClick={handleClick} role="presentation" className={classes.root}>
        {children}
      </div>
    </Zoom>
  );
}

export default ScrollTop;
