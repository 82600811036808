import { REHYDRATE } from "redux-persist/lib/constants";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isOpen: false,
  contentType: null,
  props: null,
  anchor: "right"
};

const drawer = createSlice({
  name: "drawer",
  initialState,
  reducers: {
    openDrawerWithProps: {
      reducer: (state, action) => {
        const { contentType, props, anchor } = action.payload;

        state.isOpen = true;
        state.contentType = contentType;
        state.props = props;
        state.anchor = anchor || initialState.anchor;
      },
      prepare: (contentType, props, anchor) => ({ payload: { contentType, props, anchor } })
    },
    drawerResetState() {
      return initialState;
    }
  },
  extraReducers: builder => {
    builder.addCase(REHYDRATE, () => ({ ...initialState }));
  }
});

export const { openDrawerWithProps, drawerResetState } = drawer.actions;
export default drawer.reducer;
