import React from "react";
import { withStyles } from "tss-react/mui";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

const styles = theme => ({
  button: {
    minHeight: 30,
    textTransform: "initial",
    fontWeight: theme.typography.fontWeightRegular
  }
});

const ShowMore = ({ classes, isLoading, handleClick, text = "Show more ...", ...props }) => (
  <Button
    disabled={isLoading}
    onClick={handleClick}
    fullWidth
    classes={{ root: classes.button }}
    {...props}
  >
    {isLoading ? <CircularProgress size={20} /> : text}
  </Button>
);

ShowMore.defaultProps = {
  variant: "text",
  size: "small"
};

export default withStyles(ShowMore, styles);
