import { useEffect, useMemo, useRef, useState } from "react";
import { renderToString } from "react-dom/server";
import moment from "moment";
import {
  Autocomplete,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  MenuItem,
  Stack,
  TextField
} from "@mui/material";
import useLanguage from "hooks/useLanguage";

import { useDispatch, useSelector } from "utils/StoreUtil";
import { createRangeFilter, createTermFilter } from "utils/SearchUtil";
import { getEvents } from "api/saleRequests";
import { downloadCsv, downloadPdf } from "api/outputRequests";
import {
  PDFLayout,
  getEventRunlistExport,
  getWatchlistExport,
  formatName
} from "actions/exportActions";
import { trackEvent } from "hooks/useTracking";

import PrintFrame from "components/print/PrintFrame";
import InventoryListPrint from "components/print/InventoryListPrint";
import SimpleTable from "components/pdf/SimpleTable";
import FullDetailsPdf from "components/pdf/FullDetailsPdf";
import SimpleTablePrint from "components/print/components/SimpleTable";

type ExportType =
  | "search-print"
  | "runlist-print"
  | "runlist-pdf"
  | "runlist-csv"
  | "watchlist-print"
  | "watchlist-pdf"
  | "watchlist-csv";

function useCompaniesNameMap() {
  const companies = useSelector(state => state.companies.companies);

  const length = companies.length;

  return useMemo(() => {
    return companies.reduce((acc, company) => {
      // @ts-ignore
      acc[company.id] = company.name;
      return acc;
    }, {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [length]);
}

function getOptionLabel(option: any) {
  return `${moment(option.startTime).format("MMM DD")} - ${option.name}`;
}

const DialogContentEventPrint: React.FC<{ handleClose: () => void }> = ({ handleClose }) => {
  const dispatch = useDispatch();

  const isGuest = useSelector(state => state.user.isGuest);
  const [lotNumberText, consignorText] = useLanguage("lotNumber", "consignor");

  const [exportType, setExportType] = useState<ExportType>("search-print");
  const [layout, setLayout] = useState<PDFLayout>("summary");
  const [selectedEvent, setSelectedEvent] = useState<any>();

  const [open, setOpen] = useState(false);

  const companiesNameMap: Record<number, string> = useCompaniesNameMap();

  const [events, setEvents] = useState([]);

  const initialized = useRef(false);

  const loading = open && events.length === 0;

  useEffect(() => {
    async function init() {
      const startOfDay = moment()
        .startOf("day")
        .valueOf();

      const filters = [
        createRangeFilter("endTime", startOfDay, "GT", "MUST"),
        createTermFilter("saleTypes", "STAGED", "MUST_NOT")
      ];

      const { total } = await getEvents(filters, "items=0-0");
      const { data } = await getEvents(filters, `items=0-${total}`, ["+companyId", "+startTime"]);
      setEvents(data);
    }

    if (loading && !initialized.current) {
      init();
      initialized.current = true;
    }
  }, [loading]);

  const [printing, setPrinting] = useState(false);
  const [componentProps, setComponentProps] = useState<any>(null);

  const [busy, setBusy] = useState(false);

  function cleanupPrint() {
    setComponentProps(null);
    setPrinting(false);
  }

  const isPrintCurrentPageType = exportType === "search-print";
  const isDetailsSelectionDisabled = isPrintCurrentPageType || exportType.endsWith("-csv");
  const isEventSelectionDisabled = isPrintCurrentPageType || exportType.startsWith("watchlist-");
  const isConfirmDisabled = exportType.startsWith("runlist-") && !selectedEvent;

  async function handleConfirm() {
    try {
      setBusy(true);

      if (isPrintCurrentPageType) {
        // everything already in state;
        setPrinting(true);
        return;
      }

      const [inventoryType, format] = exportType.split("-");

      let data: any = null;
      let fileName = `${inventoryType}-export`;

      if (exportType.startsWith("runlist-")) {
        fileName += `-${formatName(selectedEvent?.name)}`;
        data = await dispatch(getEventRunlistExport(selectedEvent, format, layout));
      }
      if (exportType.startsWith("watchlist-")) {
        data = await dispatch(getWatchlistExport(format, layout));
      }

      if (data && exportType.endsWith("-print")) {
        setComponentProps(data);
        setPrinting(true);
      }
      if (data && exportType.endsWith("-csv")) {
        await downloadCsv(fileName, JSON.stringify(data));
      }
      if (data && exportType.endsWith("-pdf")) {
        let content = "";
        if (layout === "fullDetails") {
          content = renderToString(
            <FullDetailsPdf {...data} consignorText={consignorText} lotNumberText={lotNumberText} />
          );
        } else {
          content = renderToString(<SimpleTable {...data} />);
        }
        await downloadPdf(fileName, content);
      }
    } finally {
      setBusy(false);
      trackEvent({
        category: "Downloads",
        action: "inventory export",
        label: `${exportType}${isDetailsSelectionDisabled ? "" : `:${layout}`}`
      });
    }
  }

  const watchlistOptions = [
    { value: "watchlist-print", label: "Print Watchlist" },
    { value: "watchlist-pdf", label: "Download PDF Watchlist" },
    { value: "watchlist-csv", label: "Download CSV Watchlist" }
  ];

  return (
    <>
      <DialogContent>
        <Stack spacing={2}>
          <div />
          <TextField
            data-testid="exportOptions-options"
            fullWidth
            select
            label="Options"
            value={exportType}
            onChange={e => setExportType(e.target.value as ExportType)}
          >
            <MenuItem value="search-print">Print Current Page</MenuItem>
            <MenuItem value="runlist-print">Print Inventory</MenuItem>
            <MenuItem value="runlist-pdf">Download PDF Inventory</MenuItem>
            <MenuItem value="runlist-csv">Download CSV Inventory</MenuItem>
            {!isGuest &&
              watchlistOptions.map(option => (
                <MenuItem value={option.value} key={option.value}>
                  {option.label}
                </MenuItem>
              ))}
          </TextField>

          <TextField
            data-testid="exportOptions-details"
            fullWidth
            select
            label="Details"
            disabled={isDetailsSelectionDisabled}
            value={isDetailsSelectionDisabled ? "" : layout}
            onChange={e => setLayout(e.target.value as PDFLayout)}
          >
            <MenuItem value="summary">Summary</MenuItem>
            <MenuItem value="fullDetails">Full Details</MenuItem>
          </TextField>

          <Autocomplete
            data-testid="exportOptions-events"
            disableClearable
            renderInput={params => (
              <TextField
                {...params}
                label="Event"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading ? <CircularProgress size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  )
                }}
              />
            )}
            options={events}
            getOptionLabel={option => getOptionLabel(option)}
            renderOption={(props, option) => (
              <MenuItem {...props} key={option.id}>
                {getOptionLabel(option)}
              </MenuItem>
            )}
            groupBy={option => companiesNameMap[option.companyId]}
            isOptionEqualToValue={(option, value) => option.id === value?.id}
            disabled={isEventSelectionDisabled}
            value={isEventSelectionDisabled ? null : selectedEvent}
            onChange={(e, value) => {
              if (value) {
                setSelectedEvent(value);
              }
            }}
            loading={loading}
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
          />
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button sx={{ width: 160 }} variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          sx={{ width: 160 }}
          variant="contained"
          onClick={handleConfirm}
          disabled={isConfirmDisabled || busy}
        >
          {busy ? <CircularProgress size={20} /> : "Confirm"}
        </Button>
      </DialogActions>

      {printing && isPrintCurrentPageType && (
        <PrintFrame
          callback={cleanupPrint}
          trackedEvent={{
            category: "Search",
            action: "print search results"
          }}
        >
          <InventoryListPrint triggerPrint={undefined} />
        </PrintFrame>
      )}
      {printing &&
        !isPrintCurrentPageType &&
        exportType.endsWith("-print") &&
        layout === "summary" && (
          <PrintFrame callback={cleanupPrint} trackedEvent={{}}>
            <SimpleTablePrint {...componentProps} />
          </PrintFrame>
        )}
      {printing &&
        !isPrintCurrentPageType &&
        exportType.endsWith("-print") &&
        layout === "fullDetails" && (
          <PrintFrame callback={cleanupPrint} trackedEvent={{}}>
            <FullDetailsPdf
              {...componentProps}
              consignorText={consignorText}
              lotNumberText={lotNumberText}
            />
          </PrintFrame>
        )}
    </>
  );
};

export default DialogContentEventPrint;
