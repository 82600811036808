import React from "react";
import { useSelector } from "utils/StoreUtil";

import { DRAWER_CONTENT_TYPE } from "utils/constants";

const DrawerContentAdminInventoryFilters = React.lazy(() =>
  import("./DrawerContentAdminInventoryFilters")
);
const DrawerContentAdminSaleFilters = React.lazy(() => import("./DrawerContentAdminSaleFilters"));
const DrawerContentAdminAttendeeFilters = React.lazy(() =>
  import("./DrawerContentAdminAttendeeFilters")
);
const DrawerContentAdminConsignorFilters = React.lazy(() =>
  import("./DrawerContentAdminConsignorFilters")
);
const DrawerContentAdminSaleActionLogFilters = React.lazy(() =>
  import("./DrawerContentAdminSaleActionLogFilters")
);

function DrawerContent() {
  const contentType = useSelector(state => state.drawer.contentType);
  const drawerProps = useSelector(state => state.drawer.props);

  switch (contentType) {
    case DRAWER_CONTENT_TYPE.ADMIN_INVENTORY_FILTERS:
      return <DrawerContentAdminInventoryFilters {...drawerProps} />;

    case DRAWER_CONTENT_TYPE.ADMIN_SALE_FILTERS:
      return <DrawerContentAdminSaleFilters {...drawerProps} />;

    case DRAWER_CONTENT_TYPE.ADMIN_ATTENDEE_FILTERS:
      return <DrawerContentAdminAttendeeFilters {...drawerProps} />;

    case DRAWER_CONTENT_TYPE.ADMIN_CONSIGNOR_FILTERS:
      return <DrawerContentAdminConsignorFilters {...drawerProps} />;

    case DRAWER_CONTENT_TYPE.ADMIN_SALE_ACTION_FILTERS:
      return <DrawerContentAdminSaleActionLogFilters {...drawerProps} />;

    default:
      return null;
  }
}

export default DrawerContent;
