import { DASHBOARD_ATTENDEES_CHART_SET_PROP } from "actions/actionTypes";
import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  entityTypeStats: "dealershipsStats",
  sortBy: "amount",
  stat: "internetSold"
};

export default createReducer(initialState, builder => {
  builder.addCase(DASHBOARD_ATTENDEES_CHART_SET_PROP, (state, action) => {
    const { key, value } = action.payload;
    state[key] = value;
  });
});
