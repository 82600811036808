import { ASSET_LINKS_FETCH, ASSET_LINKS_FETCH_COMPLETE } from "actions/actionTypes";
import { createSlice, createEntityAdapter } from "@reduxjs/toolkit";
import { REHYDRATE } from "redux-persist/lib/constants";

const NAME = "links";

const linksAdapter = createEntityAdapter();

const initialState = linksAdapter.getInitialState({ isLoading: true });

const contentLinks = createSlice({
  name: NAME,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(REHYDRATE, () => ({ ...initialState }))
      .addCase(ASSET_LINKS_FETCH, state => {
        state.isLoading = true;
      })
      .addCase(ASSET_LINKS_FETCH_COMPLETE, (state, action) => {
        state.isLoading = false;
        linksAdapter.setAll(state, action.payload || []);
      });
  }
});

export const linksSelector = linksAdapter.getSelectors(state => state[NAME]);

const { reducer } = contentLinks;
export default reducer;
