import { useMemo } from "react";
import { matchPath, useLocation } from "react-router-dom";
import { useSelector } from "utils/StoreUtil";

export const usePublicPaths = () => {
  const isPostSalePublic = useSelector(state => state.config.allowPostSaleReportToGuest);

  return useMemo(() => {
    const paths = ["/inventory"];

    if (isPostSalePublic) {
      paths.push("/research/postsale");
    }

    return paths;
  }, [isPostSalePublic]);
};

export const useShowTopNavbarMobile = () => {
  const { pathname } = useLocation();
  const publicPaths = usePublicPaths();

  const isAuthorized = useSelector(state => state.user.isAuthorized);

  return isAuthorized || matchPath(pathname, publicPaths);
};
