import {
  INVENTORY_SELECT_SEARCH_OPTION,
  INVENTORY_SET_SEARCH_OPTION_VALUES,
  INVENTORY_SEARCH_OPTIONS_RESET_STATE,
  INVENTORY_RESET_SEARCH_OPTIONS,
  INVENTORY_TOGGLE_ASSET_SCHEMA_ID_SEARCH_OPTION,
  INVENTORY_RESET_SELECTED_SEARCH_OPTION,
  CONFIG_SET,
  INVENTORY_TOGGLE_SEARCH_OPTION,
  INVENTORY_SET_SELECTED_SEARCH_OPTIONS,
  INVENTORY_SET_QUERY_STRING,
  INVENTORY_ADD_SEARCH_OPTION_FROM_PARAMS,
  INVENTORY_FILTER_EVENT_SELECT
} from "actions/actionTypes";
import { REHYDRATE } from "redux-persist/lib/constants";
import { createSlice } from "@reduxjs/toolkit";
import { DATE_UNIT, FILTER_TYPE } from "utils/constants";
import { toggleSearchOptionValue } from "./vehicles";
import { getConfigValue } from "./config";

const marketSegmentOpenedSearchOptions = {
  automotive: ["make"]
};

export const initialState = {
  searchOptions: {
    year: null,
    make: null,
    model: null,
    mileage: null,
    consignor: null,
    engine: null,
    transmission: null,
    fuelType: null,
    drive: null,
    exteriorColor: null,
    interiorColor: null,
    bodyStyle: null,
    trim: null,
    sequence: null,
    quantity: null,
    category: null
  },
  selectedSearchOptions: {},
  defaultSelectedSearchOptions: {
    category: [],
    toYear: null,
    fromYear: null,
    make: [],
    model: [],
    toMileage: null,
    fromMileage: null,
    toGrade: null,
    fromGrade: null,
    engine: [],
    transmission: [],
    fuelType: [],
    drive: [],
    exteriorColor: [],
    interiorColor: [],
    bodyStyle: [],
    trim: [],
    consignor: [],
    consignorType: [],
    consignorIds: [],
    saleId: null,
    watchlist: null,
    note: null,
    companies: [],
    events: [],
    sales: [],
    rangeNumbers: [],
    odometerUnit: null,
    queryString: "",
    MMR: null,
    keys: null,
    upcomingInventory: null,
    saleType: null,
    buyItNow: null,
    groupedItems: null,
    sire: [],
    sex: [],
    dam: [],
    coveringSire: [],
    type: [],
    pregnant: null,
    toAge: null,
    fromAge: null,
    toAverageWeight: null,
    fromAverageWeight: null,
    toBaseWeight: null,
    fromBaseWeight: null,
    toTotalWeight: null,
    fromTotalWeight: null,
    toHeadCount: null,
    fromHeadCount: null,
    fromWeaningWeight: null,
    toWeaningWeight: null,
    fromYearlingWeight: null,
    toYearlingWeight: null,
    weaned: null,
    color: [],
    fromQuantity: null,
    toQuantity: null,
    breed: [],
    feed: [],
    flesh: [],
    frame: [],
    asIs: null,
    titleStatus: null,
    hasCR: null
  },
  selectedSearchOption: null,
  openedSearchOptions: {},
  ageDateUnit: DATE_UNIT.MONTH
};

const resetSearchOptionState = state => {
  state.searchOptions = initialState.searchOptions;
  state.selectedSearchOption = initialState.selectedSearchOption;
};

const inventorySearchOptions = createSlice({
  name: "inventorySearchOptions",
  initialState,
  reducers: {
    updateAgeDateUnit(state, action) {
      state.ageDateUnit = action.payload;
    },
    toggleSelectedSearchOption: {
      reducer: (state, action) => {
        const { searchOption, value, filterType } = action.payload;

        let selectedSearchOption = state.selectedSearchOptions[searchOption];

        if (selectedSearchOption == null) {
          state.selectedSearchOptions[searchOption] =
            filterType === FILTER_TYPE.SELECT ? [value] : value;
        } else {
          state.selectedSearchOptions[searchOption] = toggleSearchOptionValue(
            state.selectedSearchOptions,
            action.payload
          );
        }
      },
      prepare: (searchOption, value, filterType) => ({
        payload: { searchOption, value, filterType }
      })
    },
    toggleOpenedSearchOptions(state, action) {
      if (Array.isArray(action.payload)) {
        action.payload.forEach(searchOption => {
          state.openedSearchOptions[searchOption] = !state.openedSearchOptions[searchOption];
        });
      } else if (action.payload != null) {
        state.openedSearchOptions[action.payload] = !state.openedSearchOptions[action.payload];
      }
    }
  },
  extraReducers: builder => {
    builder
      .addCase(REHYDRATE, () => ({ ...initialState }))
      .addCase(INVENTORY_SEARCH_OPTIONS_RESET_STATE, (state, action) => {
        const { marketSegment } = action.payload;
        return {
          ...initialState,
          defaultSelectedSearchOptions: {
            ...initialState.defaultSelectedSearchOptions,
            watchlist: state.defaultSelectedSearchOptions.watchlist
          },
          openedSearchOptions: getOpenedSearchOptionsByMarketSegment(
            marketSegment,
            initialState.openedSearchOptions
          )
        };
      })
      .addCase(INVENTORY_RESET_SEARCH_OPTIONS, resetSearchOptionState)
      .addCase(INVENTORY_SELECT_SEARCH_OPTION, (state, action) => {
        const { searchOption } = action.payload;
        if (searchOption === state.selectedSearchOption) {
          state.selectedSearchOption = initialState.selectedSearchOption;
        } else {
          state.selectedSearchOption = searchOption;
        }
      })
      .addCase(INVENTORY_SET_SEARCH_OPTION_VALUES, (state, action) => {
        const { searchOption, values } = action.payload;
        state.searchOptions[searchOption] = values;
      })
      .addCase(INVENTORY_TOGGLE_ASSET_SCHEMA_ID_SEARCH_OPTION, (state, action) => {
        return {
          ...initialState,
          defaultSelectedSearchOptions: {
            ...initialState.defaultSelectedSearchOptions,
            events: state.defaultSelectedSearchOptions.events,
            sales: state.defaultSelectedSearchOptions.sales,
            watchlist: state.defaultSelectedSearchOptions.watchlist,
            upcomingInventory: state.defaultSelectedSearchOptions.upcomingInventory,
            saleType: state.defaultSelectedSearchOptions.saleType,
            buyItNow: state.defaultSelectedSearchOptions.buyItNow,
            groupedItems: state.defaultSelectedSearchOptions.groupedItems,
            assetSchemaId: action.payload.assetSchemaId
          }
        };
      })
      .addCase(INVENTORY_RESET_SELECTED_SEARCH_OPTION, (state, action) => {
        if (Array.isArray(action.payload.searchOption)) {
          const searchOptions = action.payload.searchOption;

          searchOptions.forEach(searchOption => {
            state.selectedSearchOptions[searchOption] =
              initialState.selectedSearchOptions[searchOption];
            state.defaultSelectedSearchOptions[searchOption] =
              initialState.defaultSelectedSearchOptions[searchOption];

            resetOtherSearchOptions(searchOption, state);
          });
        } else {
          const searchOption = action.payload.searchOption;

          delete state.selectedSearchOptions[searchOption];

          state.defaultSelectedSearchOptions[searchOption] =
            initialState.defaultSelectedSearchOptions[searchOption];

          resetOtherSearchOptions(searchOption, state);
        }
      })
      .addCase(CONFIG_SET, (state, action) => {
        const marketSegment = getConfigValue(action.payload.config, "marketSegment");

        state.openedSearchOptions = getOpenedSearchOptionsByMarketSegment(
          marketSegment,
          state.openedSearchOptions
        );
      })
      .addCase(INVENTORY_TOGGLE_SEARCH_OPTION, (state, action) => {
        const searchOption = action.payload.searchOption;
        const searchOptionValue = toggleSearchOptionValue(
          state.defaultSelectedSearchOptions,
          action.payload
        );

        state.defaultSelectedSearchOptions[searchOption] = searchOptionValue;

        resetOtherSearchOptions(searchOption, state);
      })
      .addCase(INVENTORY_FILTER_EVENT_SELECT, (state, action) => {
        const { companyId, eventId, saleId, rangeNumber } = action.payload;

        state.defaultSelectedSearchOptions.companies = [];
        state.defaultSelectedSearchOptions.events = [];
        state.defaultSelectedSearchOptions.sales = [];
        state.defaultSelectedSearchOptions.rangeNumbers = [];

        if (companyId) {
          state.defaultSelectedSearchOptions.companies = [companyId];
        }
        if (eventId) {
          state.defaultSelectedSearchOptions.events = [eventId];
        }
        if (saleId) {
          state.defaultSelectedSearchOptions.sales = [saleId];
        }
        if (rangeNumber) {
          state.defaultSelectedSearchOptions.rangeNumbers = [rangeNumber];
        }
      })
      .addCase(INVENTORY_SET_SELECTED_SEARCH_OPTIONS, (state, action) => {
        state.defaultSelectedSearchOptions = {
          ...initialState.defaultSelectedSearchOptions,
          ...action.payload.selectedSearchOptions,
          watchlist: state.defaultSelectedSearchOptions.watchlist
        };
      })
      .addCase(INVENTORY_SET_QUERY_STRING, (state, action) => {
        state.defaultSelectedSearchOptions.queryString = action.payload.queryString;
      })
      .addCase(INVENTORY_ADD_SEARCH_OPTION_FROM_PARAMS, (state, action) => {
        const { searchOption, value } = action.payload;
        if (value) {
          state.defaultSelectedSearchOptions = {
            ...initialState.defaultSelectedSearchOptions,
            [searchOption]: [value]
          };
        }
      });
  }
});

const resetOtherSearchOptions = (searchOption, state) => {
  if (searchOption === "make" && state.defaultSelectedSearchOptions.make.length === 0) {
    state.defaultSelectedSearchOptions.model = initialState.defaultSelectedSearchOptions.model;
    state.openedSearchOptions.model = false;
    state.defaultSelectedSearchOptions.trim = initialState.defaultSelectedSearchOptions.trim;
    state.openedSearchOptions.trim = false;
  } else if (searchOption === "model" && state.defaultSelectedSearchOptions.model.length === 0) {
    state.defaultSelectedSearchOptions.trim = initialState.defaultSelectedSearchOptions.trim;
    state.openedSearchOptions.trim = false;
  }
};

const getOpenedSearchOptionsByMarketSegment = (marketSegment, openedSearchOptions) => {
  if (marketSegment == null) return openedSearchOptions;

  const result = {
    ...openedSearchOptions
  };

  marketSegmentOpenedSearchOptions[marketSegment]?.forEach(searchOption => {
    result[searchOption] = true;
  });

  return result;
};

export function calculateAgeSelectedSearchOptions(ageDateUnit, selectedSearchOptions) {
  const ageOptions = {
    fromAge: selectedSearchOptions?.fromAge,
    toAge: selectedSearchOptions?.toAge
  };

  if (ageDateUnit === DATE_UNIT.YEAR) {
    if (ageOptions.fromAge != null) {
      ageOptions.fromAge = `${ageOptions.fromAge * 12}`;
    }
    if (ageOptions.toAge != null) {
      ageOptions.toAge = `${ageOptions.toAge * 12}`;
    }
  }

  return ageOptions;
}

export const mapSelectedSearchOptionName = value => {
  switch (value) {
    case "mileage":
      return "odometer";
    case "assetSchemaId":
      return "Inventory Asset Type";
    default:
      return value;
  }
};

export const getDefaultSelectedSearchOptions = searchOptions => {
  const { watchlist, ...defaultSelectedSearchOptions } = searchOptions;

  return defaultSelectedSearchOptions;
};

const { reducer, actions } = inventorySearchOptions;

export const { updateAgeDateUnit, toggleSelectedSearchOption, toggleOpenedSearchOptions } = actions;
export default reducer;
