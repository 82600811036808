import moment from "moment";
import { getLandingPageData } from "api/calendarRequests";
import { setMedia } from "./mediaActions";
import { CALENDAR_FETCH, CALENDAR_FETCH_COMPLETE } from "./actionTypes";
import { getMedias as getMediasFromApi } from "api/mediaRequests";
import {
  createRange,
  createRangeFilter,
  createTermFilter,
  createTermsFilter
} from "utils/SearchUtil";
import { COMPANY_DEFAULT_MEDIA, ENTITY_TYPE } from "utils/constants";
import { batch } from "utils/StoreUtil";

export function getDataFromLandingPage() {
  return async dispatch => {
    dispatch({ type: CALENDAR_FETCH });
    try {
      const today = new Date().getTime();
      const filters = [createRangeFilter("startTime", today, "GTE")];
      const range = createRange(0, 2);
      const sort = "+startTime";
      const { data } = await getLandingPageData(filters, range, sort);
      dispatch({
        type: CALENDAR_FETCH_COMPLETE,
        payload: {
          ...data,
          lastPageType: "landing",
          shouldMerge: false
        }
      });
    } catch (error) {}
  };
}

export function getLandingPageResources() {
  return async dispatch => {
    dispatch({ type: CALENDAR_FETCH });

    const startDate = moment()
      .startOf("day")
      .valueOf();

    const saleFilters = [
      createRangeFilter("endTime", startDate, "GTE"),
      createTermFilter("saleTypes", "LIVE_LEGACY")
    ];

    const { data: landingData } = await getLandingPageData(
      saleFilters,
      createRange(0, 5),
      "+startTime"
    );

    let medias = [];

    if (landingData.sales.length > 0) {
      const companyIds = landingData.sales
        .map(item => item.companyId)
        .filter((value, index, self) => self.indexOf(value) === index);

      const mediaFilters = [
        createTermFilter("entityType", ENTITY_TYPE.COMPANY),
        createTermsFilter("companyId", companyIds),
        createTermFilter("name", COMPANY_DEFAULT_MEDIA.LOGO)
      ];

      const { data } = await getMediasFromApi(mediaFilters, `items=0-${companyIds.length}`);
      medias = data;
    }

    batch(() => {
      dispatch({
        type: CALENDAR_FETCH_COMPLETE,
        payload: {
          ...landingData,
          lastPageType: "landing",
          shouldMerge: false
        }
      });
      if (medias.length > 0) {
        dispatch(setMedia(medias[0]));
      }
    });
  };
}
