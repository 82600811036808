import React, { FC } from "react";
import Warning from "@mui/icons-material/Warning";
import Error from "@mui/icons-material/Error";
import Info from "@mui/icons-material/Info";

type Level = "WARNING" | "ERROR" | "INFO";

const LevelMessageIcon: FC<{ level: Level }> = ({ level, ...props }) => {
  switch (level) {
    case "WARNING":
      return <Warning {...props} />;
    case "ERROR":
      return <Error {...props} />;
    case "INFO":
      return <Info {...props} />;
    default:
      return null;
  }
};

export default LevelMessageIcon;
