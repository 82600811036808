import {
  MARKET_REPORT_SET_SORT_BY,
  MARKET_REPORT_SET_PAGE,
  MARKET_REPORT_SET_PER_PAGE,
  MARKET_REPORT_TOGGLE_HIDDEN_COLUMNS,
  MARKET_REPORT_SET_STATS,
  MARKET_REPORT_SELECT_LOT_ID,
  MARKET_REPORT_FETCH_STATS,
  MARKET_REPORT_SELECT_DATE,
  MARKET_REPORT_SET_DAYS_RANGE,
  INVENTORY_SEARCH_OPTIONS_RESET_STATE
} from "actions/actionTypes";
import { REHYDRATE } from "redux-persist/lib/constants";
import { createReducer } from "@reduxjs/toolkit";
import { toggleSort } from "./reports";
import moment from "moment";

export const steps = ["year", "make", "model", "trim"];
const dateFrom = setDateFrom(30);
const dateTo = moment()
  .endOf("day")
  .valueOf();

export const initialState = {
  perPage: 25,
  page: 0,
  sortBy: "-lastStatusChangeTime",
  hiddenColumns: [],
  areStatsLoading: true,
  stats: {},
  selectedLotId: null,
  dateFrom,
  dateTo,
  days: 30
};

const marketReport = createReducer(initialState, builder => {
  builder
    .addCase(REHYDRATE, (state, action) => {
      const marketReport = action.payload?.marketReport;
      if (marketReport) {
        state.selectedLotId = marketReport?.selectedLotId;
        state.days = marketReport?.days;
        state.dateTo = marketReport?.dateTo;
        state.dateFrom = marketReport?.dateFrom;
      }
    })
    .addCase(MARKET_REPORT_SET_SORT_BY, (state, action) => {
      state.sortBy = toggleSort(state.sortBy, action.payload.sortBy);
      state.page = initialState.page;
    })
    .addCase(MARKET_REPORT_SET_PAGE, (state, action) => {
      state.page = action.payload.page;
    })
    .addCase(MARKET_REPORT_SET_PER_PAGE, (state, action) => {
      state.page = initialState.page;
      state.perPage = action.payload.perPage;
    })
    .addCase(MARKET_REPORT_TOGGLE_HIDDEN_COLUMNS, (state, action) => {
      const { column } = action.payload;
      if (state.hiddenColumns.includes(column)) {
        state.hiddenColumns = state.hiddenColumns.filter(hiddenColumn => hiddenColumn !== column);
      } else {
        state.hiddenColumns.push(column);
      }
    })
    .addCase(MARKET_REPORT_SET_STATS, (state, action) => {
      const { stats } = action.payload;
      if (!stats) {
        state.stats = initialState.stats;
      } else {
        state.stats = action.payload.stats;
      }
      state.areStatsLoading = false;
    })
    .addCase(MARKET_REPORT_SELECT_LOT_ID, (state, action) => {
      state.selectedLotId = action.payload.lotId;
      state.page = initialState.page;
    })
    .addCase(MARKET_REPORT_FETCH_STATS, state => {
      state.areStatsLoading = true;
    })
    .addCase(MARKET_REPORT_SELECT_DATE, (state, action) => {
      const { prop, date } = action.payload;
      if (date) {
        state[prop] = date;
      } else {
        state[prop] = initialState[prop];
      }
    })
    .addCase(MARKET_REPORT_SET_DAYS_RANGE, (state, action) => {
      const { days } = action.payload;
      state.days = days;
      if (days > 0) {
        state.dateFrom = setDateFrom(days);
        state.dateTo = initialState.dateTo;
      } else {
        state.dateFrom = initialState.dateFrom;
        state.dateTo = initialState.dateTo;
      }
    })
    .addCase(INVENTORY_SEARCH_OPTIONS_RESET_STATE, state => {
      state.days = initialState.days;
      state.dateFrom = initialState.dateFrom;
      state.dateTo = initialState.dateTo;
    });
});

function setDateFrom(amount, unit = "days") {
  return moment()
    .subtract(amount, unit)
    .startOf("day")
    .valueOf();
}

export function getMarketReportSummaryPageTitle(lot, selectedLotId, selectedSearchOptions) {
  return selectedLotId
    ? getTitleFromLotYearMakeModelTrim(lot)
    : getTitleFromSelectedSearchOptions(
        selectedSearchOptions,
        "queryString",
        "fromYear",
        "toYear",
        "make",
        "model",
        "trim"
      );
}

export function getTitleFromLotYearMakeModelTrim(lot) {
  const year = lot?.searchable?.year ?? "";
  const make = lot?.searchable?.make ?? "";
  const model = lot?.searchable?.model ?? "";
  const trim = lot?.searchable?.trim ?? "";
  return `${year} ${make} ${model} ${trim}`;
}

function getTitleFromSelectedSearchOptions(selectedSearchOptions, ...whiteList) {
  return whiteList
    .filter(filterSearchOptionsWithValue(selectedSearchOptions))
    .reduce((stringValue, searchOptionKey, index) => {
      const searchOptionValue = selectedSearchOptions[searchOptionKey];
      if (index > 0) {
        stringValue += ", ";
      }
      if (Array.isArray(searchOptionValue)) {
        stringValue += searchOptionKey + ": " + searchOptionValue.join(", ");
      } else {
        if (searchOptionKey === "queryString") {
          stringValue += "search: " + searchOptionValue;
        } else {
          stringValue += `${searchOptionKey.replace("Year", "")}: ${searchOptionValue}`;
        }
      }
      return stringValue;
    }, "");
}

function filterSearchOptionsWithValue(selectedSearchOptions) {
  return searchOptionKey => {
    const searchOption = selectedSearchOptions[searchOptionKey];
    return (
      (Array.isArray(searchOption) && searchOption.length > 0) ||
      (!Array.isArray(searchOption) && searchOption)
    );
  };
}

export default marketReport;
