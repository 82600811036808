import { get, getTotal, put, del } from "./api";
import uniqueId from "lodash/uniqueId";

export async function getEvents(filters, range, sorts) {
  const res = await get("event", filters, range, sorts);
  return { data: res.data, total: getTotal(res) };
}

export async function getEventsFromCommunity(filters, range, sorts) {
  const res = await get("application/community/event", filters, range, sorts);
  return { data: res.data, total: getTotal(res) };
}

export async function resetSync(saleId) {
  return get(`/sale/${saleId}/resetSync`);
}

export async function getSaleLogActions(filters) {
  const { data } = await get("sale/saleLogAction", filters);
  return data;
}

export async function getSaleActionLogs(filters, range, sorts) {
  const res = await get(
    `saleActionLog?request.preventCache=${uniqueId("saleActionLog")}`,
    filters,
    range,
    sorts
  );
  return { data: res.data, total: getTotal(res) };
}

export async function getSaleActionLogResults(filters, range, sorts) {
  const res = await get("application/community/saleActionLogResults", filters, range, sorts);
  return { data: res.data, total: getTotal(res) };
}

export async function getSaleUsers(filters, range, sorts) {
  const res = await get("saleUser", filters, range, sorts);
  return { data: res.data, total: getTotal(res) };
}

export async function bid(bid) {
  const { data } = await put("bid", bid);
  return data;
}

export function removeBid(bidId) {
  return del(`bid/${bidId}`);
}

export async function getSales(filters, range, sorts) {
  const res = await get("sale", filters, range, sorts);

  return { data: res.data, total: getTotal(res) };
}

export async function getSalesFromCommunity(filters, range, sorts) {
  const res = await get("application/community/sale", filters, range, sorts);

  return { data: res.data, total: getTotal(res) };
}

export async function getAttendUrl(saleId) {
  const res = await get(`sale/${saleId}/attendUrl`);

  return res.data;
}

export async function getGuestAttendUrl(saleId, userName) {
  const res = await get(`sale/${saleId}/guestAttendUrl${userName == null ? "" : `/${userName}`}`);

  return res.data;
}
